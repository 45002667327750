import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getPageServiceFetch = async () => {
    try {
        const result = await httpClient.get(server.GET_PAGE_SERVICE_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getServiceAllFetch = async () => {
    try {
        const result = await httpClient.get(server.GET_SERVICE_ALL_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getServiceAstrologicalAllFetch = async () => {
    try {
        const result = await httpClient.get(server.GET_SERVICE_ASTROLOGICAL_ALL_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getServiceBySearchFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.GET_SERVICE_BY_SEARCH_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getServiceAstrologicalBySearchFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.GET_SERVICE_ASTROLOGICAL_BY_SEARCH_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertServiceFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_SERVICE_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const insertServiceAstrologicalFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_SERVICE_ASTROLOGICAL_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateServiceByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_SERVICE_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateServiceAstrologicalByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_SERVICE_ASTROLOGICAL_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updatePageServiceByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_PAGE_SERVICE_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteServiceByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(server.DELETE_SERVICE_BY_ID_URL + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteServiceAstrologicalByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(server.DELETE_SERVICE_ASTROLOGICAL_BY_ID_URL + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getPageServiceFetch,
    getServiceAllFetch,
    getServiceAstrologicalAllFetch,
    getServiceBySearchFetch,
    getServiceAstrologicalBySearchFetch,

    // insert
    insertServiceFetch,
    insertServiceAstrologicalFetch,

    // update
    updateServiceByIdFetch,
    updateServiceAstrologicalByIdFetch,
    updatePageServiceByIdFetch,

    // delete
    deleteServiceByIdFetch,
    deleteServiceAstrologicalByIdFetch,

}