/* eslint-disable jsx-a11y/alt-text */

import { useState, useEffect, useRef } from "react"
import { useDimensions } from "../../../common/mamager/ScreenManager"
import {
    Row,
    Col,
    Modal
} from 'antd'
import { getPageServiceFetch, getServiceAllFetch } from "../API"
import { serverUrl } from "../../../constants"
import parse from 'html-react-parser'
import LoadingPage from "../../../common/components/loading/page"
import Video from "../../../common/components/video"
import { useNavigate } from "react-router"

export default function ServiceBoard() {
    const navigate = useNavigate()
    
    const [loading, setLoading] = useState(false)
    const { width } = useDimensions()

    const audioReboundRef = useRef()

    const [pageServiceData, setPageServiceData] = useState(null)
    const [serviceAllData, setServiceAllData] = useState(null)

    const getPageService = async () => {
        const result = await getPageServiceFetch()
        setPageServiceData(result)
    }

    const getServiceAll = async () => {
        const result = await getServiceAllFetch()
        // console.log("result : ", result)
        setServiceAllData(result)
    }

    const [modalReadMore, setModalReadMore] = useState({
        title: null,
        isShow: false,
        data: null
    })

    const baseAPI = async () => {
        getPageService()
        getServiceAll()
    }

    useEffect(() => {
        baseAPI()
    }, [])

    // console.log("pageServiceData : ", pageServiceData)
    // console.log("serviceAllData : ", serviceAllData)

    return (
        <>
            {!loading ?
                <div
                    style={{
                        display: "grid",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingLeft: width > 925 ? '15%' : 12,
                        paddingRight: width > 925 ? '15%' : 12,
                        paddingTop: 24,
                        paddingBottom: 24,
                        textAlign: "center"
                    }}
                >
                    <label style={{ fontWeight: 600, fontSize: 24 }}>{pageServiceData?.title}</label>
                    <label style={{ paddingBottom: 6 }}>{pageServiceData ? parse(pageServiceData?.subtitle) : []}</label>

                    <div
                        style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: 12, cursor: "pointer" }}
                        onClick={() => {
							navigate("/prediction")
							window.scrollTo(0, 0)
						}}
                    >
                        <div style={{ width: 70, backgroundColor: "red", color: "white", textAlign: "center", padding: 4, borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}>
                            <label style={{ fontWeight: 600, cursor: "pointer" }}>{pageServiceData?.titleMenuList}</label>
                        </div>

                        <div style={{ width: 210, border: "1px solid red", color: "red", textAlign: "center", padding: 2, borderTopRightRadius: 8, borderBottomRightRadius: 8 }}>
                            <label style={{ cursor: "pointer"}}>{pageServiceData?.subTitleMenuList}</label>
                        </div>
                    </div>

                    <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "center", paddingTop: 24 }}>
                        <Row gutter={[8, 8]}>
                            {serviceAllData?.map((item) => {
                                return (
                                    <Col xs={24} md={12} xl={6}>
                                        <div style={{ border: "1px dotted #D1D1D1", paddingTop: 12, paddingBottom: 4, height: 175, borderRadius: 8 }}>
                                            <div style={{ paddingBottom: 12 }}>
                                                <img
                                                    src={`${serverUrl}/${item.imageURL}`}
                                                    style={{ height: 40, width: 40, backgroundColor: "#EEEEEE", borderRadius: 4, padding: 8 }}
                                                />
                                            </div>

                                            <label style={{ paddingLeft: 6, paddingRight: 6 }}>{item.title}</label>

                                            {item.detail ?
                                                <div style={{ paddingTop: 12, textDecoration: "underline", cursor: "pointer" }}>
                                                    <label
                                                        onClick={() => {
                                                            audioReboundRef.current.play()
                                                            if (
                                                                item.externalLinkURL === null ||
                                                                item.externalLinkURL === "" ||
                                                                item.externalLinkURL === undefined
                                                            ) {
                                                                setModalReadMore({
                                                                    title: "ข้อมูลเพิ่มเติม",
                                                                    isShow: true,
                                                                    data: {
                                                                        detail: item.detail,
                                                                        imageDetailURL: item.imageDetailURL,
                                                                        videoDetailURL: item.videoDetailURL,
                                                                        isCoverTypeDisplay: item.isCoverTypeDisplay
                                                                    }
                                                                })
                                                            } else {
                                                                window.open(item.externalLinkURL)
                                                            }
                                                        }}
                                                        style={{ cursor: "pointer" }}
                                                    >อ่านเพิ่มเติม</label>
                                                </div>
                                                : []
                                            }
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                    </div>
                </div>
                :
                <LoadingPage loading={loading} />
            }

            <Modal
                maskClosable={false}
                title={<span>{modalReadMore.title}</span>}
                visible={modalReadMore.isShow}
                width={590}
                onCancel={() => {
                    // close modal
                    setModalReadMore({
                        isShow: false,
                        data: null,
                    })
                }}
                footer={[
                    // <Button
                    //     type="primary"
                    //     onClick={() => {

                    //     }}
                    // >
                    //     ปิด
                    // </Button>
                ]}
            >
                <Row>
                    {modalReadMore?.data?.imageDetailURL && modalReadMore?.data?.isCoverTypeDisplay === 1 ?
                        <Col span={24}>
                            <img
                                src={`${serverUrl}/${modalReadMore?.data?.imageDetailURL}`}
                                style={{ width: "100%", top: 0, left: 0, borderRadius: 8 }}
                            />
                        </Col>
                        : []
                    }

                    {modalReadMore?.data?.videoDetailURL && modalReadMore?.data?.isCoverTypeDisplay === 2 ?
                        <Col span={24}>
                            <Video
                                url={modalReadMore?.data?.videoDetailURL}
                                title={""}
                                height={"100%"}
                                width={"100%"}
                            />
                        </Col>
                        : []
                    }

                    <Col span={24}>
                        <label>{modalReadMore?.data?.detail ? parse(modalReadMore?.data?.detail) : []}</label>
                    </Col>
                </Row>
            </Modal>
            <audio ref={audioReboundRef}>
				<source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
				Your browser does not support the audio element.
			</audio>
        </>
    )
}
