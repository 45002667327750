/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */

import '../../modules/prediction/css/prediction.css'
import { useState, useEffect, useRef } from 'react'
import ReactPlayer from 'react-player'
import {
    useNavigate
} from 'react-router-dom'
import { Row, Col, Button, Modal, Divider, Popover } from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from '../../common/mamager/ScreenManager'
import Header from '../../common/components/header'
import BreadcrumbPage from '../../common/components/breadcrumbPage'
import Footer from '../../common/components/footer'
import LoadingPage from '../../common/components/loading/page'
import { serverUrl } from '../../constants'
import { getPredictionAllFetch } from './API'
import parse from 'html-react-parser'
import TalkToUs from '../contract/components/TalkToUs'
import { setBasketOrderStateToSuccess } from '../../actions/basketOrder.action'
import { useDispatch, useSelector } from 'react-redux'
import { detectPredictionReadyPayment } from './components/detectOrder'
import { getAccountVerifyIdentifyByIdFetch } from '../account/API'
import Video from '../../common/components/video'
import { getUsageByIdFetch } from '../erp/manage/usage/API'

export default function Prediction({ authorized, firebase, user }) {

    const { width } = useDimensions()
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()

    const audioReboundRef = useRef()

    const [predictionList, setPredictionList] = useState([])

    const [isPopoverVisible, setIsPopoverVisible] = useState(false)
    const [isPopoverVisible2, setIsPopoverVisible2] = useState(false)
    const [isVideoPlaying, setIsVideoPlaying] = useState(false)
    const [isVideoPlaying2, setIsVideoPlaying2] = useState(false)

    useEffect(() => {
        if (!isPopoverVisible) {
            setIsVideoPlaying(false)
        }
    }, [isPopoverVisible])

    useEffect(() => {
        if (!isPopoverVisible2) {
            setIsVideoPlaying2(false)
        }
    }, [isPopoverVisible2])

    const handlePopoverVisibleChange = (visible) => {
        if (visible) {
            setIsPopoverVisible(true)
        } else {
            setTimeout(() => {
                setIsPopoverVisible(false)
            }, 500)
        }
    }

    const handlePopoverVisibleChange2 = (visible) => {
        if (visible) {
            setIsPopoverVisible2(true)
        } else {
            setTimeout(() => {
                setIsPopoverVisible2(false)
            }, 500)
        }
    }

    const navigate = useNavigate()

    const { resultBasketOrder } = useSelector(({ basketOrderReducer }) => basketOrderReducer)

    const [usage, setUsage] = useState(false)
    const [usage2, setUsage2] = useState(false)
    const getUsageById = async () => {
        let objSearch = {
            type: "payment-prediction"
        }
        const result = await getUsageByIdFetch(objSearch)
        // console.log("getUsageByIdFetch : ", result)
        setUsage(result)

        let objSearch2 = {
            type: "Use of the Forecast Room"
        }
        const result2 = await getUsageByIdFetch(objSearch2)
        // console.log("getUsageByIdFetch : ", result)
        setUsage2(result2)
    }

    const getBaseApi = async () => {
        const result = await getPredictionAllFetch()
        // console.log("result : ", result)

        if (result?.length > 0) {
            setPredictionSelected(result[0])
        }

        setPredictionList(result)
        await getUsageById()
    }

    const [predictionSelected, setPredictionSelected] = useState(null)
    const [isReadyPayment, setIsReadyPayment] = useState(null)

    const [modalBasket, setModalBasket] = useState({
        isShow: false,
        data: null
    })

    const [modalPromotion, setModalPromotion] = useState({
        isShow: false,
        data: null
    })

    // const [modalVerify, setModalVerify] = useState({
    //     isShow: false,
    //     data: null
    // })

    const delectBasketOrderAlreadySelected = () => {
        let find = resultBasketOrder.find(val => val.predictionId === predictionSelected?.predictionId)
        if (!find) {
            return true
        } else {
            return false
        }
    }

    const reloadDetectPredictionReadyPayment = async () => {
        if (user?.uid && predictionSelected?.predictionId) {
            let result = await detectPredictionReadyPayment(user?.uid, predictionSelected?.predictionId)
            setIsReadyPayment(result)
            // console.log("predictionSelected : ", predictionSelected)
            // console.log("isReadyPayment --- : ", result)
        }
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    useEffect(() => {
        reloadDetectPredictionReadyPayment()
    }, [predictionSelected?.predictionId])

    // console.log("predictionSelected : ", predictionSelected)
    // console.log("resultBasketOrder : ", resultBasketOrder)

    return (
        <>
            <Header firebase={firebase} user={user} />
            <BreadcrumbPage pageCurrentName={"ห้องโหร ออนไลน์"} />

            <div>
                {!loading ?
                    <>
                        <div
                            style={{
                                paddingLeft: width > 925 ? '10%' : 12,
                                paddingRight: width > 925 ? '10%' : 12,
                                paddingTop: 24
                            }}
                        >
                            <Row gutter={[16, 0]}>
                                <Col xs={24} md={16} xl={18}>
                                    <Popover
                                        content={
                                            <div style={{ display: "grid" }}>
                                                <div style={{ paddingBottom: 12 }}>
                                                    <label>{usage2?.detail}</label>
                                                </div>
                                                <div style={{ paddingBottom: 12 }}>
                                                    <div style={{ backgroundColor: "black" }}>
                                                        <ReactPlayer
                                                            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                                            onContextMenu={e => e.preventDefault()}
                                                            url={`${serverUrl}/${usage2?.videoUrl}`}
                                                            controls
                                                            playing={isVideoPlaying2}
                                                            width={"100%"}
                                                            height={200}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        title={usage2?.title}
                                        visible={isPopoverVisible2}
                                        onVisibleChange={(visible) => {
                                            setIsVideoPlaying2(visible)
                                            handlePopoverVisibleChange2(visible)
                                        }}
                                    >
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                audioReboundRef.current.play()
                                                setTimeout(function () {
                                                    navigate("/prediction-room")
                                                    window.scrollTo(0, 0)
                                                }, 1000)
                                            }}
                                        >
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                                                <Icon icon="carbon:volume-block-storage" style={{ color: "white", height: 18, width: 18, cursor: "pointer" }} />
                                                <label style={{ paddingLeft: 10, cursor: "pointer" }} onClick={() => audioReboundRef.current.play()}>ห้องโหรออนไลน์ของฉัน / สมัคร</label>
                                            </div>
                                        </Button>
                                    </Popover>
                                    <Divider />
                                </Col>

                                <Col xs={24} md={16} xl={18}>
                                    <div style={{ backgroundColor: "#F5F5F5", padding: 8, borderRadius: 8 }}>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} md={12} xl={12}>
                                                {predictionSelected?.imgCoverURL && predictionSelected?.isCoverTypeDisplay === 1 ?
                                                    <img
                                                        src={`${serverUrl}/${predictionSelected?.imgCoverURL}`}
                                                        style={{ width: "100%", top: 0, left: 0, borderRadius: 8 }}
                                                    />
                                                    : []
                                                }

                                                {predictionSelected?.videoUrl && predictionSelected?.isCoverTypeDisplay === 2 ?
                                                    <Video
                                                        url={predictionSelected?.videoUrl}
                                                        title={""}
                                                        height={"100%"}
                                                        width={"100%"}
                                                    />
                                                    : []
                                                }

                                            </Col>

                                            <Col xs={24} md={12} xl={12}>
                                                <Row gutter={[8, 8]}>
                                                    <Col span={24}>
                                                        <label style={{ fontWeight: 600 }}>{predictionSelected?.title}</label>
                                                    </Col>
                                                    <Col span={24}>
                                                        <label style={{}}>{predictionSelected?.subTitle}</label>
                                                    </Col>
                                                    <Col span={24} >
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                            {predictionSelected?.price > 0 ?
                                                                <>
                                                                    <label style={{ fontSize: 26 }}>เริ่มต้นที่ {Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format((predictionSelected?.price))} บาท</label>

                                                                    {delectBasketOrderAlreadySelected() ?
                                                                        <>
                                                                            {isReadyPayment?.massage === "WAITING_PAYMENT" ?
                                                                                <label style={{ paddingRight: 10 }}>รอตรวจสอบชำระเงิน</label>
                                                                                : []
                                                                            }

                                                                            {isReadyPayment?.massage === "PURCHASED_AND_NOT_EXPIRED" ?
                                                                                <label style={{ paddingRight: 10 }}>ซื้อพยากรณ์นี้เเล้ว</label>
                                                                                : []
                                                                            }

                                                                            {isReadyPayment?.isReady ?
                                                                                <Popover
                                                                                    content={
                                                                                        <div style={{ display: "grid" }}>
                                                                                            <div style={{ paddingBottom: 12 }}>
                                                                                                <label>{usage?.detail}</label>
                                                                                            </div>
                                                                                            <div style={{ paddingBottom: 12 }}>
                                                                                                <div style={{ backgroundColor: "black" }}>
                                                                                                    <ReactPlayer
                                                                                                        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                                                                                        onContextMenu={e => e.preventDefault()}
                                                                                                        url={`${serverUrl}/${usage?.videoUrl}`}
                                                                                                        controls
                                                                                                        playing={isVideoPlaying}
                                                                                                        width={"100%"}
                                                                                                        height={200}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    title={usage?.title}
                                                                                    visible={isPopoverVisible}
                                                                                    onVisibleChange={(visible) => {
                                                                                        setIsVideoPlaying(visible)
                                                                                        handlePopoverVisibleChange(visible)
                                                                                    }}
                                                                                >
                                                                                    <Button
                                                                                        type="primary"
                                                                                        onClick={async () => {
                                                                                            audioReboundRef.current.play()
                                                                                            // let result = await getAccountVerifyIdentifyByIdFetch(user ? (user.uid ? user.uid : null) : null)
                                                                                            // if (result?.isIdentityVerificationSuccessful) {
                                                                                            let tmpArr = [...resultBasketOrder, ...[predictionSelected]]
                                                                                            dispatch(setBasketOrderStateToSuccess(tmpArr))

                                                                                            setModalBasket({
                                                                                                isShow: true,
                                                                                                data: null
                                                                                            })
                                                                                            // } else {
                                                                                            //     setModalVerify({
                                                                                            //         isShow: true,
                                                                                            //         data: null
                                                                                            //     })
                                                                                            // }
                                                                                        }}
                                                                                    >
                                                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                                                                                            <Icon icon="subway:basket" style={{ color: "white", height: 18, width: 18, cursor: "pointer" }} />
                                                                                            <label style={{ paddingLeft: 10, cursor: "pointer" }}>เพิ่มลงตระกร้า</label>
                                                                                        </div>
                                                                                    </Button>
                                                                                </Popover>
                                                                                : []
                                                                            }
                                                                        </>
                                                                        :
                                                                        <Button
                                                                            type="primary"
                                                                            onClick={() => {
                                                                                audioReboundRef.current.play()
                                                                                let tmpArr = resultBasketOrder.filter(item => item.predictionId !== predictionSelected?.predictionId)
                                                                                dispatch(setBasketOrderStateToSuccess(tmpArr))
                                                                            }}
                                                                        >
                                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                                                                                <Icon icon="bx:log-out" style={{ color: "white", height: 18, width: 18, cursor: "pointer" }} />
                                                                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>นำออกตระกร้า</label>
                                                                            </div>
                                                                        </Button>
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    {predictionSelected?.predictionId === 3 ?
                                                                        <label style={{ fontSize: 18, fontWeight: 600 }}>เเถมฟรี! เมื่อสั่งซื้อ “ขุนโหร-ONLINE”</label>
                                                                        :
                                                                        <label style={{ fontSize: 18, fontWeight: 600 }}>ฟรี!”</label>
                                                                    }
                                                                </>
                                                            }

                                                            {!isReadyPayment ?
                                                                <>
                                                                    <Popover
                                                                        content={
                                                                            <div style={{ display: "grid" }}>
                                                                                <div style={{ paddingBottom: 12 }}>
                                                                                    <label>{usage?.detail}</label>
                                                                                </div>
                                                                                <div style={{ paddingBottom: 12 }}>
                                                                                    <div style={{ backgroundColor: "black" }}>
                                                                                        <ReactPlayer
                                                                                            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                                                                            onContextMenu={e => e.preventDefault()}
                                                                                            url={`${serverUrl}/${usage?.videoUrl}`}
                                                                                            controls
                                                                                            playing={isVideoPlaying}
                                                                                            width={"100%"}
                                                                                            height={200}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        title={usage?.title}
                                                                        visible={isPopoverVisible}
                                                                        onVisibleChange={(visible) => {
                                                                            setIsVideoPlaying(visible)
                                                                            handlePopoverVisibleChange(visible)
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            type="primary"
                                                                            onClick={() => {
                                                                                audioReboundRef.current.play()
                                                                                navigate("/login")
                                                                            }}
                                                                        >
                                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                                                                                <Icon icon="bx:log-out" style={{ color: "white", height: 18, width: 18, cursor: "pointer" }} />
                                                                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>เพิ่มลงตระกร้า</label>
                                                                            </div>
                                                                        </Button>
                                                                    </Popover>
                                                                </>
                                                                : []
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>

                                    <Row gutter={[12, 12]} style={{ paddingTop: 24 }}>
                                        {predictionSelected?.detail ?
                                            <Col xs={24} md={24} xl={24}>
                                                <div style={{ display: "grid", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                                    <label style={{ fontWeight: 600 }}>คำแนะนำ</label>
                                                    <label style={{}}>{predictionSelected?.detail ? parse(predictionSelected?.detail) : "-"}</label>
                                                </div>
                                            </Col>
                                            : []
                                        }

                                        {predictionSelected?.promotion ?
                                            <Col xs={24} md={24} xl={24}>
                                                <div style={{ display: "grid", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                                    <label style={{ fontWeight: 600 }} onClick={() => audioReboundRef.current.play()}>โปรโมชั่น</label>
                                                    <label style={{}}>{predictionSelected?.promotion ? parse(predictionSelected?.promotion) : "-"}</label>
                                                </div>
                                            </Col>
                                            : []
                                        }
                                    </Row>
                                </Col>

                                <Col xs={24} md={8} xl={6}>
                                    {predictionList?.map((item) => {
                                        return (
                                            <div style={{ paddingBottom: 24 }}>
                                                <div className='prediction-shop-card' >
                                                    <div style={{ backgroundColor: "#FF7F00", padding: 8, color: "white", borderTopLeftRadius: 8, borderTopRightRadius: 8 }}>
                                                        <label
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setPredictionSelected(item)
                                                                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                                                            }}
                                                        >{item.title}</label>
                                                    </div>
                                                    <div style={{ paddingTop: 0, paddingBottom: 6 }}>
                                                        <img
                                                            onClick={() => {
                                                                setPredictionSelected(item)
                                                                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                                                            }}
                                                            src={`${serverUrl}/${item.imgCoverURL}`}
                                                            style={{ width: "100%", top: 0, left: 0, borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between", padding: 8 }}>
                                                        <div
                                                            onClick={() => {
                                                                setPredictionSelected(item)
                                                                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                                                            }}
                                                        >
                                                            <label style={{ color: "#FF7F00", fontWeight: 600, textDecoration: "underline", cursor: "pointer" }} onClick={() => audioReboundRef.current.play()}>อ่านเพิ่มเติม</label>
                                                        </div>
                                                        <div
                                                            onClick={() => {
                                                                setModalPromotion({
                                                                    isShow: true,
                                                                    data: item,
                                                                })
                                                            }}
                                                        >
                                                            <label style={{ color: "#FF7F00", fontWeight: 600, textDecoration: "underline", cursor: "pointer" }} onClick={() => audioReboundRef.current.play()}>โปรโมชั่น</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </Col>
                            </Row>
                        </div>
                    </>
                    :
                    <LoadingPage loading={loading} />
                }
            </div>

            <TalkToUs />

            <Footer />

            <Modal
                maskClosable={false}
                title={<span>ดำเนินการชำระเงิน</span>}
                visible={modalBasket.isShow}
                width={400}
                onCancel={() => {
                    // close modal
                    setModalBasket({
                        isShow: false,
                        data: null,
                    })
                }}
                footer={[]}
            >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ paddingLeft: 6, paddingRight: 6 }}>
                        <Button
                            type="primary"
                            style={{ width: 120 }}
                            onClick={() => {
                                audioReboundRef.current.play()
                                setModalBasket({
                                    isShow: false,
                                    data: null,
                                })
                            }}
                        >
                            <label style={{ cursor: "pointer" }}>ช้อปปิ้งต่อ</label>
                        </Button>
                    </div>

                    <div style={{ paddingLeft: 6, paddingRight: 6 }}>
                        <Button
                            type="primary"
                            style={{ width: 120 }}
                            onClick={() => {
                                audioReboundRef.current.play()
                                navigate("/payment")
                            }}
                        >
                            <label style={{ cursor: "pointer" }}>ชำระเงิน</label>
                        </Button>
                    </div>
                </div>
            </Modal>

            <Modal
                maskClosable={false}
                title={<span>โปรโมชั่น</span>}
                visible={modalPromotion.isShow}
                width={590}
                onCancel={() => {
                    // close modal
                    setModalPromotion({
                        isShow: false,
                        data: null,
                    })
                }}
                footer={[]}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <label>{modalPromotion?.data?.title ? parse(modalPromotion.data.title) : []}</label>
                    </Col>

                    <Col span={24}>
                        <label>{modalPromotion?.data?.promotion ? parse(modalPromotion.data.promotion) : "-"}</label>
                    </Col>
                </Row>
            </Modal>

            {/* <Modal
                maskClosable={false}
                title={<span>คุณยังไม่ได้ยืนยันตัวตน</span>}
                visible={modalVerify.isShow}
                width={300}
                onCancel={() => {
                    // close modal
                    setModalVerify({ isShow: false, data: null })
                }}
                footer={[
                    <Button
                        type="primary"
                        onClick={() => {
                            audioReboundRef.current.play()
                            // default
                            navigate("/account?tab=verifyIdentity")
                            window.scrollTo(0, 0)
                        }}
                    >
                        ยืนยันตัวตน
                    </Button>
                ]}
            >
                <label>โปรดยืนยันตัวตน ก่อนชำระเงินซื้อพยากรณ์</label>
            </Modal> */}

            <audio ref={audioReboundRef}>
                <source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
        </>
    )
}
