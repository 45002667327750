import "./styles/App.css"
import "./styles/App.less"

import Home from "./modules/home"
import { ConfigProvider } from 'antd'
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom"
// import { ThemeProvider } from '@mui/material/styles'
// import themeMUI from "./styles/themeMUI"
import themeAntd from './styles/themeAntd'
import AboutMe from "./modules/aboutMe"
import Contract from "./modules/contract"
import Service from "./modules/service"
import Login from "./modules/login"
import Register from "./modules/register"
import LoginDetect from "./modules/login/components/loginDetect"
import Account from "./modules/account"
import LoginERP from "./modules/erp/manage/login"
import ERP from "./modules/erp"
import Course from "./modules/course"
import Payment from "./modules/payment"
import Classroom from "./modules/classroom"
import ClassroomStudy from "./modules/classroomStudy"
import Prediction from "./modules/prediction"
import PredictionRoom from "./modules/predictionRoom"
import PredictionOpen from "./modules/predictionOpen"
import EBook from "./modules/ebook"
import Exam from "./modules/exam"
import DisableRightClick from "./common/components/disableRightClick"

const App = ({ authorized, firebase, user }) => (
    <ConfigProvider theme={{ token: themeAntd }}>
        {/* <DisableRightClick /> */}
        <div style={{
            // userSelect: 'none',
            // MozUserSelect: 'none',
            // WebkitUserSelect: 'none',
            // msUserSelect: 'none'
        }}>
            <Router>
                <Routes>
                    <Route exact path="/" element={<Home authorized={authorized} firebase={firebase} user={user} />} />
                    <Route exact path="/about-me" element={<AboutMe authorized={authorized} firebase={firebase} user={user} />} />
                    <Route exact path="/contact" element={<Contract authorized={authorized} firebase={firebase} user={user} />} />
                    <Route exact path="/service" element={<Service authorized={authorized} firebase={firebase} user={user} />} />
                    <Route exact path="/login" Component={() => (<Login authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/login-detect" Component={() => (<LoginDetect authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/register" Component={Register} />
                    <Route exact path="/account" Component={() => (<Account authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/backoffice-login" Component={() => (<LoginERP />)} />
                    <Route exact path="/erp-manage" Component={ERP} />
                    <Route exact path="/course" element={<Course authorized={authorized} firebase={firebase} user={user} />} />
                    <Route exact path="/payment" Component={() => (<Payment authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/classroom" Component={() => (<Classroom authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/classroom-course-study" Component={() => (<ClassroomStudy authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/prediction" element={<Prediction authorized={authorized} firebase={firebase} user={user} />} />
                    <Route exact path="/prediction-room" element={<PredictionRoom authorized={authorized} firebase={firebase} user={user} />} />
                    <Route exact path="/prediction-open" element={<PredictionOpen authorized={authorized} firebase={firebase} user={user} />} />
                    <Route exact path="/ebook" element={<EBook authorized={authorized} firebase={firebase} user={user} />} />
                    <Route exact path="/exam" element={<Exam authorized={authorized} firebase={firebase} user={user} />} />

                    <Route path="/:id">Page not found!</Route>
                </Routes>
            </Router>
        </div>
    </ConfigProvider>
)

export default App
