export const HTTP_BASKET_ORDER_FETCHING = "HTTP_BASKET_ORDER_FETCHING";
export const HTTP_BASKET_ORDER_SUCCESS = "HTTP_BASKET_ORDER_SUCCESS";
export const HTTP_BASKET_ORDER_FAILED = "HTTP_BASKET_ORDER_FAILED";

///////////////////////// Localization Begin /////////////////////////
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK";
export const NETWORK_CONNECTION_MESSAGE = "NETWORK_CONNECTION_MESSAGE";

export const serverUrl = "https://cdn.witte-bangkok.com";
// export const serverUrl = "http://localhost:3001";

export const apiServerUrl = serverUrl + "/api/v1";

export const imgDefaultUrl = `./assets/images/default/df-img.png`;
export const videoDefaultUrl = `./assets/images/default/df-vdo.png`;
export const imgDefaltCardCourse = `./assets/images/default/df-card-course-img.png`;
export const img404notfound = `./assets/images/notfound/404notfound.jpg`;
export const inspectCertificateUrl = `https://www.toflyn.com/inspect-certificate`;

export const paginate = 10000; // limit 10 k
export const sortASC = "ASC";
export const sortDESC = "DESC";

export const YES = "YES";
export const NO = "NO";
export const OK = "ok";
export const NOK = "nok";

export const server = {
  // ------------------------ BANNER -------------------------------------------------------------
  GET_ANNOUNCE_ALL_URL: `announce/get-announce-all`,

  // ------------------------ SERVICE ------------------------------------------------------------
  GET_PAGE_SERVICE_URL: `service/get-page-service`,
  GET_SERVICE_ALL_URL: `service/get-service-all`,
  GET_SERVICE_ASTROLOGICAL_ALL_URL: `service/get-service-astrological-all`,

  UPDATE_PAGE_SERVICE_BY_ID_URL: `service/update-page-service-by-id`,

  // ------------------------ HIGHLIGHT ----------------------------------------------------------
  GET_HIGHLIGHT_ASTROLOGY_ALL_URL: `highlight/get-highlight-astrology-all`,

  // ------------------------ ABOUT ME -----------------------------------------------------------
  GET_PAGE_ABOUT_ME_URL: `about_me/get-page-about-me`,

  UPDATE_PAGE_ABOUT_US_ID_URL: `about_me/update-page-about-us-by-id`,

  // ------------------------ FAQ ----------------------------------------------------------------
  GET_PAGE_FAQ_URL: `faq/get-page-faq`,
  GET_FAQ_ALL_URL: `faq/get-faq-all`,

  UPDATE_PAGE_FAQ_BY_ID_URL: `faq/update-page-faq-by-id`,

  // ------------------------ PARTNER ------------------------------------------------------------
  GET_PARTNER_ALL_URL: `partner/get-partner-all`,

  // ------------------------ CONTACT ------------------------------------------------------------
  GET_PAGE_TALK_TO_US_URL: `contact/get-page-talk-to-us`,
  GET_PAGE_CONTACT_URL: `contact/get-page-contact`,
  GET_CONTACT_US_CHANNEL_ALL_URL: `contact/get-contact-us-channel-all`,
  GET_CONTACT_CHANNEL_ALL_URL: `contact/get-contact-channel-all`,

  INSERT_INQUIRY_FORM_URL: `contact/insert-inquiry-form`,
  INSERT_CONTACT_CHANNEL_URL: `contact/insert-contact-channel`,

  UPDATE_INQUIRY_FORM_BY_ID_URL: `contact/update-inquiry-form-by-id`,
  UPDATE_PAGE_TALK_TO_US_BY_ID_URL: `contact/update-page-talk-to-us-by-id`,
  UPDATE_PAGE_CONTACT_BY_ID_URL: `contact/update-page-contact-by-id`,
  UPDATE_CONTACT_CHANNEL_BY_ID_URL: `contact/update-contact-channel-by-id`,

  DELETE_CONTACT_CHANNEL_BY_ID_URL: `contact/delete-contact-channel-by-id`,

  // ------------------------ HOME ---------------------------------------------------------------
  GET_PAGE_HOME_URL: `home/get-page-home`,
  GET_ASTROLOGY_IMPORTANT_URL: `home/get-astrology-important`,
  GET_SERVICE_GROUP_ALL_URL: `service/get-service-group-all`,
  GET_USER_COMMENT_ALL_URL: `user/get-user-comment-all`,
  GET_KNOWLEDGE_SOURCE_ALL_URL: `knowledge_source/get-knowledge-source-all`,
  GET_BLOG_ALL_URL: `blog/get-blog-all`,
  GET_NEWS_ALL_URL: `home/get-news-all`,

  UPDATE_PAGE_HOME_BY_ID_URL: `home/update-page-home-by-id`,
  UPDATE_ASTROLOGY_IMPORTANT_BY_ID_URL: `home/update-astrology-important-by-id-url`,

  // ------------------------ AUTH ---------------------------------------------------------------
  GET_DETECT_USERNAME_DUPLICATE_URL: `auth/get-detect-username-duplicate`,
  GET_DETECT_EMAIL_DUPLICATE_URL: `auth/get-detect-email-duplicate`,
  GET_DETECT_PHONE_DUPLICATE_URL: `auth/get-detect-phone-duplicate`,
  GET_PROVINCES_ALL_URL: `auth/get-provinces-all`,
  GET_PREFIX_ALL_URL: `auth/get-prefix-all`,
  GET_LOGIN_DETECT_URL: `auth/get-login-detect`,
  GET_USER_BY_UID_URL: `auth/get-user-by-uid`,
  GET_EMAIL_BY_USERNAME_URL: `auth/get-email-by-username`,
  GET_EMAIL_PASSWORD_IS_ON_DB_URL: `auth/get-email-password-is-on-db`,
  // GET_CHECK_USER_ID_ON_DATABASE_URL: `auth/get_check_user_id_on_database`,

  INSERT_USERS_URL: `user/insert-users`,

  UPDATE_USERS: `user/update-users`,

  // ------------------------ ACCOUNT ------------------------------------------------------------
  GET_ACCOUNT_VERIFY_IDENTIFY_BY_ID_URL: `account/get-account-verify-identify-by-id`,

  INSERT_ACCOUNT_VERIFY_IDENTIFY_URL: `account/insert-account-verify-identify`,

  UPDATE_ACCOUNT_VERIFY_IDENTIFY_BY_ID_URL: `account/update-account-verify-identify-by-id`,

  // ------------------------ PAYMENT ------------------------------------------------------------
  GET_PAYMENT_ORDERS_BY_ID_URL: `payment/get-payment-orders-by-id`,
  GET_PAYMENT_ACCOUNT_ALL_URL: `payment/get-payment-account-all`,
  GET_BANK_ACCOUNT_ALL_URL: `payment/get-bank-account-all`,
  GET_DETECT_CLASSROOM_EXPIRED_BY_ID_URL: `payment/get-detect-classroom-expired-by-id`,
  GET_DETECT_PREDICTION_ROOM_EXPIRED_BY_ID_URL: `payment/get-detect-prediction-room-expired-by-id`,

  UPDATE_PAYMENT_ACCOUNT_BY_ID_URL: `payment/update-payment-account-by-id`,
  UPDATE_CLASSROOM_ACTIVE_BY_ID_URL: `payment/update-classroom-active-by-id`,
  UPDATE_PREDICTION_ROOM_ACTIVE_BY_ID_URL: `payment/update-prediction-room-active-by-id`,

  // ------------------------ ERP ----------------------------------------------------------------
  GET_ADMIN_BY_ID_URL: `erp/get-admin-by-id`,
  GET_ADMIN_ALL_URL: `erp/get-admin-all`,
  GET_ANNOUNCE_BY_SEARCH_URL: `announce/get-announce-by-search`,
  GET_USER_BY_SEARCH_URL: `user/get-user-by-search`,
  GET_NEWS_BY_SEARCH_URL: `news/get-news-by-search`,
  GET_FAQ_BY_SEARCH_URL: `faq/get-faq-by-search`,
  GET_USER_COMMENT_BY_SEARCH_URL: `user/get-user-comment-by-search`,
  GET_INQUIRY_FORM_BY_SEARCH_URL: `contact/get-inquiry-form-by-search`,
  GET_PARTNER_BY_SEARCH_URL: `partner/get-partner-by-search`,
  GET_HIGHLIGHT_ASTROLOGY_BY_SEARCH_URL: `highlight/get-highlight-astrology-by-search`,
  GET_SERVICE_BY_SEARCH_URL: `service/get-service-by-search`,
  GET_SERVICE_ASTROLOGICAL_BY_SEARCH_URL: `service/get-service-astrological-by-search`,
  GET_CONTACT_US_CHANNEL_BY_SEARCH_URL: `contact/get-contact-us-channel-by-search`,
  GET_ADMIN_BY_SEARCH_URL: `erp/get-admin-by-search`,
  INSPECT_NEW_USERNAME_DUPLICATE_URL: `erp/inspect-new-username-duplicate`,
  INSPECT_OLD_PASSWORD_MATCHES_URL: `erp/inspect-old-password-matches`,
  GET_PAYMENT_ORDER_BY_SEARCH_URL: `payment/get-payment-order-by-search`,
  GET_PAYMENT_STATUS_ALL_URL: `payment/get-payment-status-all`,
  GET_PAYMENT_TYPE_ALL_URL: `payment/get-payment-type-all`,
  GET_COURSE_BY_SEARCH_URL: `course/get-course-by-search`,
  GET_VIDEO_COURSE_BY_SEARCH_URL: `course/get-video-course-by-search`,
  GET_PREDICTION_BY_SEARCH_URL: `prediction/get-prediction-by-search`,
  GET_EBOOK_BY_SEARCH_URL: `ebook/get-ebook-by-search`,

  INSERT_ANNOUNCE_URL: `announce/insert-announce`,
  INSERT_KNOWLEDGE_SOURCE_URL: `knowledge_source/insert-knowledge-source`,
  INSERT_NEWS_URL: `news/insert-news`,
  INSERT_FAQ_URL: `faq/insert-faq`,
  INSERT_USER_COMMENT_URL: `user/insert-user-comment`,
  INSERT_PARTNER_URL: `partner/insert-partner`,
  INSERT_HIGHLIGHT_ASTROLOGY_URL: `highlight/insert-highlight-astrology`,
  INSERT_SERVICE_URL: `service/insert-service`,
  INSERT_SERVICE_ASTROLOGICAL_URL: `service/insert-service-astrological`,
  INSERT_CONTACT_US_CHANNEL_URL: `contact/insert-contact-us-channel`,
  INSERT_ADMIN_URL: `erp/insert-admin`,
  INSERT_PAYMENT_ORDER_URL: `payment/insert-payment-order`,
  INSERT_COURSE_URL: `course/insert-course`,
  INSERT_VIDEO_COURSE_URL: `course/insert-video-course`,
  INSERT_PREDICTION_URL: `prediction/insert-prediction`,
  INSERT_EBOOK_URL: `ebook/insert-ebook`,

  UPDATE_ANNOUNCE_BY_ID_URL: `announce/update-announce-by-id`,
  UPDATE_KNOWLEDGE_SOURCE_BY_ID_URL: `knowledge_source/update-knowledge-source-by-id`,
  UPDATE_NEWS_BY_ID_URL: `news/update-news-by-id`,
  UPDATE_FAQ_BY_ID_URL: `faq/update-faq-by-id`,
  UPDATE_USER_COMMENT_BY_ID_URL: `user/update-user-comment-by-id`,
  UPDATE_USER_INFO_BY_ID_URL: `user/update-user-info-by-id`,
  UPDATE_PARTNER_BY_ID_URL: `partner/update-partner-by-id`,
  UPDATE_HIGHLIGHT_ASTROLOGY_BY_ID_URL: `highlight/update-highlight-astrology-by-id`,
  UPDATE_SERVICE_BY_ID_URL: `service/update-service-by-id`,
  UPDATE_SERVICE_ASTROLOGICAL_BY_ID_URL: `service/update-service-astrological-by-id`,
  UPDATE_CONTACT_US_CHANNEL_BY_ID_URL: `contact/update-contact-us-channel-by-id`,
  UPDATE_ADMIN_BY_ID_URL: `erp/update-admin-by-id`,
  UPDATE_ADMIN_PASSWORD_BY_ID_URL: `erp/update-admin-password-by-id`,
  UPDATE_PAYMENT_ORDER_BY_ID_URL: `payment/update-payment-order-by-id`,
  UPDATE_COURSE_BY_ID_URL: `course/update-course-by-id`,
  UPDATE_VIDEO_COURSE_BY_ID_URL: `course/update-video-course-by-id`,
  UPDATE_PAYMENT_ORDER_COURSE_BY_ID_URL: `payment/update-payment-order-by-id`,
  UPDATE_PREDICTION_BY_ID_URL: `prediction/update-prediction-by-id`,
  UPDATE_EBOOK_BY_ID_URL: `ebook/update-ebook-by-id`,
  UPDATE_CLASSROOM_BY_ID: `user/update-classroom-by-id`,
  UPDATE_PREDICTION_ROOM_BY_ID: `user/update-prediction-room-by-id`,
  DELETE_CLASSROOM_BY_ID: `user/delete-user-classroom-by-id`,
  DELETE_PREDICTION_ROOM_BY_ID: `user/delete-user-prediction-room-by-id`,

  DELETE_ANNOUNCE_BY_ID_URL: `announce/delete-announce-by-id`,
  DELETE_KNOWLEDGE_SOURCE_BY_ID_URL: `knowledge_source/delete-knowledge-source-by-id`,
  DELETE_NEWS_BY_ID_URL: `news/delete-news-by-id`,
  DELETE_FAQ_BY_ID_URL: `faq/delete-faq-by-id`,
  DELETE_USER_COMMENT_BY_ID_URL: `user/delete-user-comment-by-id`,
  DELETE_PARTNER_BY_ID_URL: `partner/delete-partner-by-id`,
  DELETE_HIGHLIGHT_ASTROLOGY_BY_ID_URL: `highlight/delete-highlight-astrology-by-id`,
  DELETE_SERVICE_BY_ID_URL: `service/delete-service-by-id`,
  DELETE_SERVICE_ASTROLOGICAL_BY_ID_URL: `service/delete-service-astrological-by-id`,
  DELETE_CONTACT_US_CHANNEL_BY_ID_URL: `contact/delete-contact-us-channel-by-id`,
  DELETE_ADMIN_BY_ID_URL: `erp/delete-admin-by-id`,
  DELETE_PAYMENT_ORDER_BY_ID_URL: `payment/delete-payment-order-by-id`,
  DELETE_COURSE_BY_ID_URL: `course/delete-course-by-id`,
  DELETE_VIDEO_COURSE_BY_ID_URL: `course/delete-video-course-by-id`,
  DELETE_PREDICTION_BY_ID_URL: `prediction/delete-prediction-by-id`,
  DELETE_EBOOK_BY_ID_URL: `ebook/delete-ebook-by-id`,
  DELETE_USER_BY_ID_URL: `user/delete-user-by-id`,

  // ------------------------ COURSE -------------------------------------------------------------
  GET_COURSE_ALL_URL: `course/get-course-all`,
  GET_COURSE_WAIT_INSPECT_URL: `course/get-course-wait-inspect`,
  GET_COURSE_PURCHASED_AND_NOT_EXPIRED_URL: `course/get-course-purchased-and-not-expired`,

  // ------------------------ CLASSROOM ----------------------------------------------------------
  GET_CLASSROOM_BY_ID_URL: `classroom/get-classroom-by-id`,
  GET_COURSE_BY_ID_URL: `classroom/get-course-by-id`,
  GET_VIDEO_COURSE_BY_ID_URL: `classroom/get-video-course-by-id`,

  INSERT_CLASSROOM_URL: `classroom/insert-classroom`,

  // ------------------------ PLANETARY ASPECT ---------------------------------------------------
  GET_PLANETARY_ASPECT_BY_SEARCH_URL: `planetary_aspect/get-planetary-aspect-by-search`,

  INSERT_PLANETARY_ASPECT_ORDER_URL: `planetary_aspect/insert-planetary-aspect-order`,

  UPDATE_PLANETARY_ASPECT_BY_ID_URL: `planetary_aspect/update-planetary-aspect-by-id`,

  DELETE_PLANETARY_ASPECT_BY_ID_URL: `planetary_aspect/delete-planetary-aspect-by-id`,

  // ------------------------ MANDALA ------------------------------------------------------------
  GET_MANDALA_BY_SEARCH_URL: `mandala/get-mandala-by-search`,
  GET_MANDALA_BY_ID_URL: `mandala/get-mandala-by-id`,

  INSERT_MANDALA_ORDER_URL: `mandala/insert-mandala-order`,

  UPDATE_MANDALA_BY_ID_URL: `mandala/update-mandala-by-id`,

  DELETE_MANDALA_BY_ID_URL: `mandala/delete-mandala-by-id`,

  // ------------------------ KHUN HOR -----------------------------------------------------------
  GET_KHUN_HOR_BY_SEARCH_URL: `khun_hor/get-khun-hor-by-search`,
  GET_KHUN_HOR_BY_ID_URL: `khun_hor/get-khun-hor-by-id`,

  INSERT_KHUN_HOR_ORDER_URL: `khun_hor/insert-khun-hor-order`,

  UPDATE_KHUN_HOR_BY_ID_URL: `khun_hor/update-khun-hor-by-id`,

  DELETE_KHUN_HOR_BY_ID_URL: `khun_hor/delete-khun-hor-by-id`,

  // ------------------------ PREDICTION ---------------------------------------------------------
  GET_PREDICTION_ALL_URL: `prediction/get-prediction-all`,
  GET_PREDICTION_WAIT_INSPECT_URL: `prediction/get-prediction-wait-inspect`,
  GET_PREDICTION_PURCHASED_AND_NOT_EXPIRED_URL: `prediction/get-prediction-purchased-and-not-expired`,

  GET_FRTUNE_TELLER_QUESTIONS_MASTER_All:  `prediction/get-fortune-teller-questions-master-all`,
  GET_FRTUNE_TELLER_QUESTIONS_All:  `prediction/get-fortune-teller-questions-all`,
  GET_FRTUNE_TELLER_QUESTIONS_BY_ID:  `prediction/get-fortune-teller-questions-by-id`,

  // ------------------------ PREDICTION ROOM ----------------------------------------------------
  GET_PREDICTION_ROOM_BY_ID_URL: `prediction_room/get-prediction-room-by-id`,
  GET_PREDICTION_BY_ID_URL: `prediction_room/get-prediction-by-id`,

  INSERT_PREDICTION_ROOM_URL: `prediction_room/insert-prediction-room`,

  // ------------------------ PREDICTION OPEN ----------------------------------------------------
  GET_BIRTHDAY_USER_BY_ID_URL: `auth/get-birthday-user-by-id`,

  // ------------------------ EBOOK --------------------------------------------------------------
  GET_EBOOK_ALL_URL: `ebook/get-ebook-all`,

  // ------------------------ FEEDBACK -----------------------------------------------------------
  // ------------------------ BOOK INFO ----------------------------------------------------------
  GET_FEEDBACK_BY_ID_URL: `feedback/get-feedback-by-id`,
  GET_FEEDBACK_BY_SEARCH_URL: `feedback/get-feedback-by-search`,

  INSERT_FEEDBACK_URL: `feedback/insert-feedback`,

  // ------------------------ BOOK INFO ----------------------------------------------------------
  GET_BOOK_INFO_BY_SEARCH_URL: `book_info/get-book-info-by-search`,

  INSERT_BOOK_INFO_URL: `book_info/insert-book-info`,

  UPDATE_BOOK_INFO_BY_ID_URL: `book_info/update-book-info-by-id`,

  DELETE_BOOK_INFO_BY_ID_URL: `book_info/delete-book-info-by-id`,

  // ------------------------ LOADING ------------------------------------------------------------
  // ------------------------ USER ---------------------------------------------------------------
  GET_LOADING_ALL_URL: `loading/get-loading-all`,
  GET_LOADING_BY_SEARCH_URL: `loading/get-loading-by-search`,

  INSERT_LOADING_URL: `loading/insert-loading`,

  UPDATE_LOADING_BY_ID_URL: `loading/update-loading-by-id`,

  DELETE_LOADING_BY_ID_URL: `loading/delete-loading-by-id`,

  // ------------------------ USER ---------------------------------------------------------------
  GET_MY_CLASSROOM_BY_URL: `user/get-my-classroom-by-id`,
  GET_MY_PREDICTION_ROOM_BY_URL: `user/get-my-prediction-room-by-id`,
  GET_COUNTRY_ALL_URL: `auth/get-country-all`,

  // ------------------------ OTP ----------------------------------------------------------------
  GET_OTP_SMS_URL: `otp/get-otp-sms`,
  VERIFY_OTP_SMS_URL: `otp/verify-otp-sms`,

  // ------------------------ EXAM ---------------------------------------------------------------
  GET_EXAM_STATUS_ALL_URL: `exam/get-exam-status-all`,
  GET_EXAM_ROOM_STATUS_ALL_URL: `exam/get-exam-room-status-all`,
  GET_COURSE_TYPE_ALL_URL: `exam/get-course-type-all`,
  GET_EXAM_ROOM_BY_ID_URL: `exam/get-exam-room-by-id`,
  GET_EXAM_ROOM_ALL_URL: `exam/get-exam-room-all`,
  GET_PERMISSION_EXAM_BY_ID_URL: `exam/get-permission-exam-by-id`,
  GET_EXAM_BY_SEARCH_URL: `exam/get-exam-by-search`,
  GET_QUESTION_LIST_BY_ID_URL: `exam/get-question-list-by-id`,
  GET_ANSWER_LIST_BY_ID_URL: `exam/get-answer-list-by-id`,
  GET_EXAM_INSPECT_LOG_BY_ID: `exam/get-exam-inspect-log-by-id`,

  INSERT_EXAM_ROOM_URL: `exam/insert-exam-room`,
  INSERT_QUESTION_LIST_URL: `exam/insert-question-list`,
  INSERT_ANSWER_LIST_URL: `exam/insert-answer-list`,
  INSERT_EXAM_INSPECT_LOG_URL: `exam/insert-exam-inspect-log`,

  UPDATE_EXAM_ROOM_BY_ID_URL: `exam/update-exam-room-by-id`,
  UPDATE_EXAM_BY_ID_URL: `exam/update-exam-by-id`,
  UPDATE_QUESTION_LIST_BY_ID_URL: `exam/update-question-list-by-id`,
  UPDATE_ANSWER_LIST_BY_ID_URL: `exam/update-answer-list-by-id`,
  UPDATE_ANSWER_SCORE_BY_ID_URL: `exam/update-answer-score-by-id`,

  DELETE_QUESTION_LIST_BY_ID_URL: `exam/delete-question-list-by-id`,
  DELETE_ANSWER_LIST_BY_ID_URL: `exam/delete-answer-list-by-id`,

  // ------------------------ CERTIFICATE --------------------------------------------------------
  GET_CERTIFICATE_URL: `certificate/get-certificate`,
  GET_CERTIFICATE_TEMPLATE_URL: `certificate/get-certificate-template`,
  GET_CERTIFICATE_LEVEL_URL: `certificate/get-certificate-level`,

  UPDATE_CERTIFICATE_BY_ID_URL: `certificate/update-certificate-by-id`,

  // ------------------------ USAGE --------------------------------------------------------------
  GET_USAGE_BY_SEARCH_URL: `usage/get-usage-by-search`,
  GET_USAGE_BY_ID_URL: `usage/get-usage-by-id`,

  INSERT_USAGE_URL: `usage/insert-usage`,

  UPDATE_USAGE_BY_ID_URL: `usage/update-usage-by-id`,

  DELETE_USAGE_BY_ID_URL: `usage/delete-usage-by-id/:id`,
};
