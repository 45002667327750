/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import { Row, Col, Button, Modal, Pagination, Input } from 'antd'
import ReactPlayer from 'react-player/youtube'
import { getKnowledgeSourceAllFetch } from '../../API'
import { serverUrl } from '../../../../constants'
import "./css/index.css"
import { useDimensions } from '../../../../common/mamager/ScreenManager'
import { Icon } from '@iconify/react'
import LoadingPage from '../../../../common/components/loading/page'

export default function KnowledgeSource() {
    const [loading, setLoading] = useState(false)
    const { width } = useDimensions()

    const audioReboundRef = useRef()

    const [page, setPage] = useState({
        minValue: 0,
        maxValue: 5
    })

    const [search, setSearch] = useState({
        name: null,
        ep: null
    })

    const [knowledgeSourceAllData, setKnowledgeSourceAllData] = useState()

    const [modalWatchingMore, setModalWatchingMore] = useState({
        title: null,
        isShow: false,
        data: null
    })

    const getKnowledgeSourceAll = async (title, ep) => {
        let obj = {
            title: (title ? title : null),
            ep: (ep ? ep : null)
        }
        let result = await getKnowledgeSourceAllFetch(obj)
        setKnowledgeSourceAllData(result)
    }

    const getRequestBaseApi = async () => {
        setLoading(true)
        await getKnowledgeSourceAll(null, null)
        setLoading(false)
    }

    const handleChange = value => {
        if (value <= 1) {
            setPage({
                minValue: 0,
                maxValue: 5
            });
        } else {
            setPage({
                minValue: (value - 1) * 5,
                maxValue: value * 5
            })
        }
    }

    useEffect(() => {
        getRequestBaseApi()
    }, [])

    return (
        <>
            {!loading ?
                <div style={{}}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingBottom: 6 }}>
                        <div style={{ padding: 6 }}>
                            <Input placeholder='กรอกชื่อวิดีโอ' onChange={(e) => { setSearch({ name: e.target.value, ep: search.ep }) }} />
                        </div>

                        <div style={{ padding: 6 }}>
                            <Input placeholder="กรอก EP" onChange={(e) => { setSearch({ name: search.name, ep: e.target.value }) }} />
                        </div>

                        <div style={{ padding: 6 }}>
                            <Button
                                type="primary"
                                onClick={() => {
                                    audioReboundRef.current.play()
                                    // console.log("search : ", search)
                                    getKnowledgeSourceAll(search.name, search.ep)
                                }}
                            >
                                ค้นหา
                            </Button>
                        </div>
                    </div>

                    <Row>
                        <Col
                            span={4}
                            style={{
                                borderTop: "1px solid #FF7F00",
                                borderLeft: "1px solid #FF7F00",
                                // borderBottom: "1px solid #E1E1E1",
                                borderRight: "1px solid #E1E1E1",
                                borderTopLeftRadius: 8,
                                padding: 8,
                                backgroundColor: "#FF7F00",
                                color: "white"
                            }}
                        >
                            <label style={{ fontWeight: 600 }}>วิดีโอ</label>
                        </Col>
                        <Col
                            span={12}
                            style={{
                                borderTop: "1px solid #FF7F00",
                                // borderLeft: "1px solid #E1E1E1",
                                // borderBottom: "1px solid #E1E1E1",
                                borderRight: "1px solid #E1E1E1",
                                padding: 8,
                                backgroundColor: "#FF7F00",
                                color: "white"
                            }}
                        >
                            <label style={{ fontWeight: 600 }}>ชื่อวิดีโอ</label>
                        </Col>
                        <Col
                            span={4}
                            style={{
                                borderTop: "1px solid #FF7F00",
                                // borderLeft: "1px solid #E1E1E1",
                                // borderBottom: "1px solid #E1E1E1",
                                borderRight: "1px solid #E1E1E1",
                                padding: 8,
                                backgroundColor: "#FF7F00",
                                color: "white"
                            }}
                        >
                            <label style={{ fontWeight: 600 }}>EP</label>
                        </Col>
                        <Col
                            span={4}
                            style={{
                                borderTop: "1px solid #FF7F00",
                                // borderLeft: "1px solid #E1E1E1",
                                // borderBottom: "1px solid #E1E1E1",
                                borderRight: "1px solid #FF7F00",
                                borderTopRightRadius: 8,
                                padding: 8,
                                backgroundColor: "#FF7F00",
                                color: "white"
                            }}
                        >

                        </Col>

                        {knowledgeSourceAllData
                            ?.slice(page.minValue, page.maxValue)
                            ?.map((item, index) => {
                                return (
                                    <>
                                        <Col
                                            span={4}
                                            style={{
                                                borderTop: index === 0 ? "1px solid #FF7F00" : "",
                                                borderLeft: "1px solid #FF7F00",
                                                borderBottom: "1px solid #FF7F00",
                                                borderRight: "1px solid #FF7F00",
                                                padding: 4,
                                                position: "relative"
                                            }}
                                        >
                                            <img
                                                src={`${serverUrl}/${item?.imageCoverURL}`}
                                                style={{ width: "100%", top: 0, left: 0, borderRadius: 4 }}
                                            />
                                        </Col>
                                        <Col
                                            span={12}
                                            style={{
                                                borderTop: index === 0 ? "1px solid #FF7F00" : "",
                                                borderBottom: "1px solid #FF7F00",
                                                borderRight: "1px solid #FF7F00",
                                                textAlign: "left",
                                                padding: 6
                                            }}
                                        >
                                            <label style={{ textAlign: "left", paddingLeft: 12 }}>{item?.title}</label>
                                        </Col>
                                        <Col
                                            span={4}
                                            style={{
                                                borderTop: index === 0 ? "1px solid #FF7F00" : "",
                                                borderBottom: "1px solid #FF7F00",
                                                borderRight: "1px solid #FF7F00",
                                                padding: 6
                                            }}
                                        >
                                            <label>{item?.ep}</label>
                                        </Col>
                                        <Col
                                            span={4}
                                            style={{
                                                borderTop: index === 0 ? "1px solid #FF7F00" : "",
                                                borderBottom: "1px solid #FF7F00",
                                                borderRight: "1px solid #FF7F00",
                                                padding: 6
                                            }}
                                        >
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    audioReboundRef.current.play()
                                                    setModalWatchingMore({
                                                        title: item.title,
                                                        isShow: true,
                                                        data: item
                                                    })
                                                }}
                                            >
                                                {width > 450 ?
                                                    "คลิกดู"
                                                    :
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <Icon icon="ph:eye-bold" style={{ width: 18, height: 18, color: "white" }} />
                                                    </div>
                                                }
                                            </Button>
                                        </Col>
                                    </>
                                )
                            })}

                        <Col span={24} style={{ paddingTop: 10 }}>
                            <center>
                                <Pagination
                                    defaultCurrent={1}
                                    defaultPageSize={5}
                                    onChange={handleChange}
                                    total={knowledgeSourceAllData?.length ?? 0}
                                />
                            </center>
                        </Col>

                    </Row>
                </div>
                : 
                <LoadingPage loading={loading} />
            }

            <Modal
                maskClosable={false}
                title={<span>{modalWatchingMore.title}</span>}
                visible={modalWatchingMore.isShow}
                width={800}
                onCancel={() => {
                    // close modal
                    setModalWatchingMore({
                        title: null,
                        isShow: false,
                        data: null,
                    })
                }}
                footer={[]}
            >
                <div className="react-player-wrapper">
                    <ReactPlayer
                        className="react-player"
                        url={modalWatchingMore?.data?.linkVideo}
                        width='100%'
                        height={400}
                    />
                </div>
            </Modal>
            <audio ref={audioReboundRef}>
				<source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
				Your browser does not support the audio element.
			</audio>
        </>
    )
}
