import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getExamStatusAllFetch = async (value) => {
    try {
        const result = await httpClient.get(server.GET_EXAM_STATUS_ALL_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const getExamRoomStatusAllFetch = async (value) => {
    try {
        const result = await httpClient.get(server.GET_EXAM_ROOM_STATUS_ALL_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const getCourseTypeAllFetch = async (value) => {
    try {
        const result = await httpClient.get(server.GET_COURSE_TYPE_ALL_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const getExamRoomAllFetch = async (value) => {
    try {
        const result = await httpClient.post(server.GET_EXAM_ROOM_ALL_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const getExamRoomByIdFetch = async (value) => {
    try {
        const result = await httpClient.post(server.GET_EXAM_ROOM_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const getPermissionExamByIdFetch = async (value) => {
    try {
        const result = await httpClient.post(server.GET_PERMISSION_EXAM_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const getExamBySearchFetch = async (value) => {
    try {
        const result = await httpClient.post(server.GET_EXAM_BY_SEARCH_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const getQuestionListByIdFetch = async (examId) => {
    try {
        const result = await httpClient.post(server.GET_QUESTION_LIST_BY_ID_URL, { examId })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const getAnswerListByIdFetch = async (examId) => {
    try {
        const result = await httpClient.post(server.GET_ANSWER_LIST_BY_ID_URL, { examId })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const getExamInspectLogByIdFetch = async (examRoomId) => {
    try {
        const result = await httpClient.post(server.GET_EXAM_INSPECT_LOG_BY_ID, { examRoomId })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const insertExamRoomFetch = async (value) => {
    try {
        const result = await httpClient.post(server.INSERT_EXAM_ROOM_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) {}
}

const insertQuestionListFetch = async (value) => {
    try {
        const result = await httpClient.post(server.INSERT_QUESTION_LIST_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) {}
}

const insertAnswerListFetch = async (value) => {
    try {
        const result = await httpClient.post(server.INSERT_ANSWER_LIST_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) {}
}

const insertExamInspectLogFetch = async (value) => {
    try {
        const result = await httpClient.post(server.INSERT_EXAM_INSPECT_LOG_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) {}
}

const updateExamRoomByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_EXAM_ROOM_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) {}
}

const updateExamByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_EXAM_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) {}
}

const updateQuestionListByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_QUESTION_LIST_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) {}
}

const updateAnswerListByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_ANSWER_LIST_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) {}
}

const updateAnswerScoreByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_ANSWER_SCORE_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) {}
}

const deleteQuestionListByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(server.DELETE_QUESTION_LIST_BY_ID_URL + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteAnswerListByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(server.DELETE_ANSWER_LIST_BY_ID_URL + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getExamStatusAllFetch,
    getExamRoomStatusAllFetch,
    getCourseTypeAllFetch,
    getExamRoomByIdFetch,
    getExamRoomAllFetch,
    getPermissionExamByIdFetch,
    getExamBySearchFetch,
    getQuestionListByIdFetch,
    getAnswerListByIdFetch,
    getExamInspectLogByIdFetch,

    // insert
    insertExamRoomFetch,
    insertQuestionListFetch,
    insertAnswerListFetch,
    insertExamInspectLogFetch,

    // update
    updateExamRoomByIdFetch,
    updateExamByIdFetch,
    updateQuestionListByIdFetch,
    updateAnswerListByIdFetch,
    updateAnswerScoreByIdFetch,

    // delete
    deleteQuestionListByIdFetch,
    deleteAnswerListByIdFetch
}
