import { server } from "../../../../../constants";
import { httpClient } from "../../../../../utils/HttpClient";

const getUserBySearchFetch = async (value) => {
  // Done
  try {
    const result = await httpClient.post(server.GET_USER_BY_SEARCH_URL, {
      value,
    });
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {
    // status 404
    return null;
  }
};

const getUserCommentBySearchFetch = async (value) => {
  // Done
  try {
    const result = await httpClient.post(
      server.GET_USER_COMMENT_BY_SEARCH_URL,
      { value }
    );
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {
    // status 404
    return null;
  }
};

const getMyClassroomByIdFetch = async (uid) => {
  // Done
  try {
    const result = await httpClient.post(server.GET_MY_CLASSROOM_BY_URL, {
      uid,
    });
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {
    // status 404
    return null;
  }
};

const getMyPredictionRoomByIdFetch = async (uid) => {
  // Done
  try {
    const result = await httpClient.post(server.GET_MY_PREDICTION_ROOM_BY_URL, {
      uid,
    });
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {
    // status 404
    return null;
  }
};

const insertUserCommentFetch = async (value) => {
  // Done
  try {
    const result = await httpClient.post(server.INSERT_USER_COMMENT_URL, {
      value,
    });
    return result.data;
  } catch (err) {
    // status 404
    return null;
  }
};

const updateUserCommentByIdFetch = async (value) => {
  // Done
  try {
    const result = await httpClient.put(server.UPDATE_USER_COMMENT_BY_ID_URL, {
      value,
    });
    return result.data;
  } catch (err) {
    // status 404
    return null;
  }
};

const updateUserInfoByIdFetch = async (value) => {
  // Done
  try {
    const result = await httpClient.put(server.UPDATE_USER_INFO_BY_ID_URL, {
      value,
    });
    return result.data;
  } catch (err) {
    // status 404
    return null;
  }
};

const updateClassroomByIdFetch = async (value) => {
  // Done
  try {
    const result = await httpClient.put(server.UPDATE_CLASSROOM_BY_ID, {
      value,
    });
    return result.data;
  } catch (err) {
    // status 404
    return null;
  }
};

const updatePredictionRoomByIdFetch = async (value) => {
  // Done
  try {
    const result = await httpClient.put(server.UPDATE_PREDICTION_ROOM_BY_ID, {
      value,
    });
    return result.data;
  } catch (err) {
    // status 404
    return null;
  }
};

const deletePredictionRoomByIdFetch = async (id) => {
  // Done
  try {
    const result = await httpClient.delete(
      server.DELETE_PREDICTION_ROOM_BY_ID + `/${id}`
    );
    return result.data;
  } catch (err) {
    // status 404
    return null;
  }
};

const deleteClassroomByIdFetch = async (id) => {
  // Done
  try {
    const result = await httpClient.delete(
      server.DELETE_CLASSROOM_BY_ID + `/${id}`
    );
    return result.data;
  } catch (err) {
    // status 404
    return null;
  }
};

const deleteUserCommentByIdFetch = async (id) => {
  // Done
  try {
    const result = await httpClient.delete(
      server.DELETE_USER_COMMENT_BY_ID_URL + `/${id}`
    );
    return result.data;
  } catch (err) {
    // status 404
    return null;
  }
};

const deleteUserByIdFetch = async (id) => {
  // Done
  try {
    const result = await httpClient.delete(
      server.DELETE_USER_BY_ID_URL + `/${id}`
    );
    return result.data;
  } catch (err) {
    // status 404
    return null;
  }
};

export {
  // get
  getUserBySearchFetch,
  getUserCommentBySearchFetch,
  getMyClassroomByIdFetch,
  getMyPredictionRoomByIdFetch,

  // insert
  insertUserCommentFetch,

  // update
  updateUserCommentByIdFetch,
  updateUserInfoByIdFetch,
  updateClassroomByIdFetch,
  updatePredictionRoomByIdFetch,

  // delete
  deleteUserCommentByIdFetch,
  deleteUserByIdFetch,
  deleteClassroomByIdFetch,
  deletePredictionRoomByIdFetch,
};
