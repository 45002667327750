import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getClassroomByIdFetch = async (value) => {
    try {
        const result = await httpClient.post(server.GET_CLASSROOM_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const getDetectClassroomExpiredByIdFetch = async (value) => {
    try {
        const result = await httpClient.post(server.GET_DETECT_CLASSROOM_EXPIRED_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const insertClassroomFetch = async (value) => {
    try {
        const result = await httpClient.post(server.INSERT_CLASSROOM_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) {}
}

const updateClassroomActiveByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_CLASSROOM_ACTIVE_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) {}
}

export {
    // get
    getClassroomByIdFetch,
    getDetectClassroomExpiredByIdFetch,
    
    // insert
    insertClassroomFetch,

    // update
    updateClassroomActiveByIdFetch,

    // delete
    
}
