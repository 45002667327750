import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getOtpSmsFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.GET_OTP_SMS_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const verifyOtpSmsFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.VERIFY_OTP_SMS_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getAccountVerifyIdentifyByIdFetch = async (uid, language) => { // Done
    try {
        const result = await httpClient.post(server.GET_ACCOUNT_VERIFY_IDENTIFY_BY_ID_URL, { uid, language })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertAccountVerifyIdentifyFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_ACCOUNT_VERIFY_IDENTIFY_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateAccountVerifyIdentifyByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_ACCOUNT_VERIFY_IDENTIFY_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateUser = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_USERS, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getOtpSmsFetch,
    verifyOtpSmsFetch,
    getAccountVerifyIdentifyByIdFetch,

    // update
    updateAccountVerifyIdentifyByIdFetch,
    updateUser,

    // insert
    insertAccountVerifyIdentifyFetch,
}