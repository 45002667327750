import { server } from "../../../../../constants"
import { httpClient } from "../../../../../utils/HttpClient"

const getPredictionBySearchFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.GET_PREDICTION_BY_SEARCH_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertPredictionFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_PREDICTION_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updatePredictionByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_PREDICTION_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deletePredictionByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(server.DELETE_PREDICTION_BY_ID_URL + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getPredictionBySearchFetch,

    // insert
    insertPredictionFetch,

    // update
    updatePredictionByIdFetch,

    // delete
    deletePredictionByIdFetch,
}