/* eslint-disable jsx-a11y/alt-text */

import "./../css/index.css"
import { useState, useEffect, useRef } from "react"
import { useDimensions } from "../../../common/mamager/ScreenManager"
import {
    Row,
    Col,
    Flex,
    Modal,
    Button
} from 'antd'
import { getPageServiceFetch, getServiceAstrologicalAllFetch } from "../API"
import { serverUrl } from "../../../constants"
import parse from 'html-react-parser'
import LoadingPage from "../../../common/components/loading/page"

export default function ServiceAstrologicalBoard() {
    const [loading, setLoading] = useState(false)
    const { width } = useDimensions()

    const audioReboundRef = useRef()


    const [pageServiceData, setPageServiceData] = useState(null)
    const [serviceAstrologicalAllData, setServiceAstrologicalAllData] = useState(null)

    const [modalAstrologicalDetail, setModalAstrologicalDetail] = useState({
        isShow: false,
        data: null
    })

    const [modalAstrologicalContact, setModalAstrologicalContact] = useState({
        isShow: false,
        data: null
    })

    const getPageService = async () => {
        const result = await getPageServiceFetch()
        setPageServiceData(result)
    }

    const getServiceAstrologicalAll = async () => {
        const result = await getServiceAstrologicalAllFetch()
        setServiceAstrologicalAllData(result)
    }

    const baseAPI = async () => {
        setLoading(true)

        getPageService()
        getServiceAstrologicalAll()

        setLoading(false)
    }

    const genPrice = (data) => {
        if (data.isDiscount) {
            return data.price - data.discount
        } else {
            return data.price
        }
    }

    useEffect(() => {
        baseAPI()
    }, [])

    return (
        <>
            {!loading ?
                <div
                    style={{
                        display: "grid",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingLeft: width > 925 ? '15%' : 12,
                        paddingRight: width > 925 ? '15%' : 12,
                        paddingTop: 24,
                        paddingBottom: 24,
                        textAlign: "center",
                        backgroundColor: "#EEEEEE"
                    }}
                >
                    <label style={{ fontWeight: 600, fontSize: 24 }}>{pageServiceData?.serviceAstrologicalTitle}</label>
                    <label style={{ paddingBottom: 6 }}>{pageServiceData ? parse(pageServiceData?.serviceAstrologicalSubTitle) : []}</label>

                    <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "center", paddingTop: 0 }}>
                        <Row gutter={[8, 8]}>
                            {serviceAstrologicalAllData?.map((item) => {
                                return (
                                    <Col xs={24} md={12} xl={6}>
                                        <div
                                            // style={{
                                            //     border: "1px dotted #D1D1D1",
                                            //     backgroundColor: "white",
                                            //     // paddingTop: 12,
                                            //     borderRadius: 8,
                                            //     display: "grid"
                                            // }}
                                            className="service-astrology-card"
                                        >
                                            <Flex gap="small" align="start" vertical>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                                    <img
                                                        src={`${serverUrl}/${item.imageURL}`}
                                                        style={{ backgroundColor: "#EEEEEE", borderRadius: 4, width: "100%" }}
                                                        id="img-card"
                                                    />
                                                </div>

                                                <div style={{ textAlign: "center", minHeight: 100, width: "100%" }}>
                                                    <label style={{ paddingLeft: 6, paddingRight: 6, cursor: "pointer" }}>{item.title}</label>
                                                    <br />
                                                    <label style={{ paddingLeft: 6, paddingRight: 6, cursor: "pointer" }}>{item.titleENG}</label>
                                                </div>

                                                {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", cursor: "pointer" }}>
                                                    <label style={{ paddingLeft: 6, cursor: "pointer" }}>Charge :</label>
                                                    <label style={{ paddingRight: 6, color: "blue", cursor: "pointer" }}>THB {Intl.NumberFormat('en').format(Number(genPrice(item)))} /Job</label>
                                                </div> */}

                                                <Flex style={{ width: "100%" }} justify={"flex-start"} align={"flex-end"}>
                                                    <Row style={{ width: "100%" }}>
                                                        <Col
                                                            span={12}
                                                            className="footer-card-btn-left"
                                                            onClick={() => {
                                                                
                                                                setModalAstrologicalDetail({
                                                                    isShow: true,
                                                                    data: item,
                                                                })
                                                            }}
                                                        >
                                                            <label style={{ cursor: "pointer" }} onClick={() =>  audioReboundRef.current.play()}>อ่านเพิ่มเติม</label>
                                                        </Col>
                                                        <Col
                                                            span={12}
                                                            className="footer-card-btn-right"
                                                            onClick={() => {
                                                                setModalAstrologicalContact({
                                                                    isShow: true,
                                                                    data: item,
                                                                })
                                                            }}
                                                        >
                                                            <label style={{ cursor: "pointer" }} onClick={() =>  audioReboundRef.current.play()}>อีเมล์/โทร</label>
                                                        </Col>
                                                    </Row>
                                                </Flex>
                                            </Flex>
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                    </div>
                </div>
                :
                <LoadingPage loading={loading} />
            }


            <Modal
                maskClosable={false}
                title={<span>รายละเอียด</span>}
                visible={modalAstrologicalDetail.isShow}
                width={590}
                onCancel={() => {
                    // close modal
                    setModalAstrologicalDetail({
                        isShow: false,
                        data: null,
                    })
                }}
                footer={[
                    // <Button
                    //     type="primary"
                    //     onClick={() => {

                    //     }}
                    // >
                    //     ปิด
                    // </Button>
                ]}
            >
                <Row>
                    {modalAstrologicalDetail?.data?.detailImageURL ?
                        {/* <Col span={24}>
                            <img
                                src={`${serverUrl}/${modalAstrologicalContact?.data?.detailImageURL}`}
                                style={{ height: 40, backgroundColor: "#EEEEEE" }}
                            />
                        </Col> */}
                        : []
                    }

                    <Col span={24}>
                        <label>{modalAstrologicalDetail?.data?.detail ? parse(modalAstrologicalDetail?.data?.detail) : []}</label>
                    </Col>
                </Row>
            </Modal>

            <Modal
                maskClosable={false}
                title={<span>ช่องทางการติดต่อ</span>}
                visible={modalAstrologicalContact.isShow}
                width={590}
                onCancel={() => {
                    // close modal
                    setModalAstrologicalContact({
                        isShow: false,
                        data: null,
                    })
                }}
                footer={[
                    // <Button
                    //     type="primary"
                    //     onClick={() => {

                    //     }}
                    // >
                    //     ปิด
                    // </Button>
                ]}
            >
                <Row>
                    <Col span={24}>
                        <label>เบอร์โทร: <a href={"tel: " + modalAstrologicalContact?.data?.phone} style={{ color: "black" }}>{modalAstrologicalContact?.data?.phone}</a></label>
                    </Col>
                    <Col span={24}>
                        <label>อีเมล์: <a href={"mailto: " + modalAstrologicalContact?.data?.email} style={{ color: "black" }}>{modalAstrologicalContact?.data?.email}</a></label>
                    </Col>
                </Row>
            </Modal>
            <audio ref={audioReboundRef}>
				<source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
				Your browser does not support the audio element.
			</audio>
        </>
    )
}
