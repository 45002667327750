/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState,useRef } from 'react'
import {
    Row,
    Col,
    Button,
    Form,
    Input,
    Upload,
} from 'antd'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import { apiServerUrl, serverUrl } from '../../../../../constants'
import { Notification } from '../../../../../common/components/notification'
import { getPageFaqFetch, updatePageFaqByIdFetch } from '../../../../faq/API'
import LoadingPage from '../../../../../common/components/loading/page'

export default function FaqComponent() {
    const [form] = Form.useForm()

    const [loading, setLoading] = useState(true)

    const audioReboundRef = useRef()

    const [data, setData] = useState(null)

    // -------------------------------------------------------------------
    const [imageUrl, setImageUrl] = useState({
        loading: false,
        img: null
    })

    const optionImageUrl = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            name: "admin",
            path: "upload_file/image/page/faq"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    setImageUrl({
                        img: info.file.response.filePath,
                        loading: false,
                    })
                }
            } else {
                setImageUrl({
                    loading: true,
                    img: imageUrl.imageURL
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }
    // -------------------------------------------------------------------

    const getPageFaq = async () => {
        const result = await getPageFaqFetch()

        setImageUrl({
            loading: false,
            img: result.imageURL
        })

        setData(result)
    }

    const onFinish = async (values) => {
        let obj = {
            pageFaqId: values.pageFaqId ? values.pageFaqId : null,
            titleFAQ: values.titleFAQ ? values.titleFAQ : null,
            subtitleFAQ: values.subtitleFAQ ? values.subtitleFAQ : null,
            titleImage: values.titleImage ? values.titleImage : null,
            imageUrl: imageUrl.img ? imageUrl.img : null,
        }
        // console.log("obj : ", obj)
        const result = await updatePageFaqByIdFetch(obj)

        if (result.isSuccess) {
            Notification('success', 'เเก้ไขสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const getBaseApi = async () => {
        setLoading(true)
        await getPageFaq()
        setLoading(false)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    // console.log("data : ", data)

    return (
        <>
            {!loading ?
                <Row>
                    <Col span={24} style={{ paddingBottom: 20 }}>
                        <label>เพจ (เกี่ยวกับเรา)</label>
                    </Col>
                    <Col xs={24} md={24} xl={24}>
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                            initialValues={{
                                pageFaqId: data?.pageFaqId,
                                titleFAQ: data?.titleFAQ,
                                subtitleFAQ: data?.subtitleFAQ,
                                titleImage: data?.titleImage,
                            }}
                        >
                            <Row gutter={[16,16]}>
                                <Col span={24}>
                                    <Form.Item name="pageFaqId" style={{ display: "none" }}>
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        // label=""
                                        name="titleFAQ"
                                        style={{ width: '100%' }}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                {/* <Col span={24}>
                                    <Form.Item
                                        // label=""
                                        name="subtitleFAQ"
                                        style={{ width: '100%' }}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col> */}

                                <Col span={24}>
                                    <Form.Item
                                        // label=""
                                        name="titleImage"
                                        style={{ width: '100%' }}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <div style={{ display: "grid" }}>
                                        {/* <label style={{ paddingBottom: 6 }}></label> */}
                                        {imageUrl?.img ?
                                            <img
                                                style={{ borderRadius: 8, maxWidth: 300, border: "1px solid #EEEEEE" }}
                                                src={`${serverUrl}/${imageUrl.img}`}
                                            />
                                            :
                                            <img
                                                style={{ width: 207, borderRadius: 8 }}
                                                src={`./assets/images/default/df-img.png`}
                                            />
                                        }
                                        <div style={{ paddingTop: 12 }}>
                                            <Upload
                                                {...optionImageUrl}
                                                accept='image/jpeg, image/png, image/jfif'
                                                style={{ width: "100%" }}
                                                maxCount={1}
                                                showUploadList={{ showRemoveIcon: false }}
                                            >
                                                <Button
                                                    type="default"
                                                    style={{ width: "100%" }}
                                                    icon={imageUrl.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                    onClick={() => audioReboundRef.current.play()}
                                                >อัพโหลดรูปภาพ</Button>
                                            </Upload>
                                        </div>
                                    </div>
                                </Col>

                                <Col span={24}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Button
                                            style={{ float: 'right', width: 70 }}
                                            type="primary"
                                            onClick={() => {
                                                audioReboundRef.current.play()
                                                form.submit()
                                            }}
                                        >
                                            บันทึก
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                : 
                <LoadingPage loading={loading} />
            }
            <audio ref={audioReboundRef}>
				<source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
				Your browser does not support the audio element.
			</audio>
        </>
    )
}
