/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */

import { useState, useEffect, useRef } from 'react'
import {
    Button,
    Card,
    Checkbox,
    Col,
    Modal,
    Row,
    Spin,
    Input,
    Form,
    Select,
    TimePicker
} from 'antd'
import { Icon } from '@iconify/react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from "react-redux"
import Layout, { Content } from 'antd/lib/layout/layout'
import { BsInfoCircle } from "react-icons/bs"
import firebase, { auth } from "../../config/firebase-config"
import "../register/css/index.css"
import Footer from '../../common/components/footer'
import Header from '../../common/components/header'
import { Notification } from '../../common/components/notification'
import { useDimensions } from '../../common/mamager/ScreenManager'
import PrivacyPolicyItem from '../policy/components/privacyPolicy/PrivacyPolicyItem'
import {
    getCountryFetch,
    getPrefixAllFetch,
    getProvincesFetch,
    insertUserFetch
} from './API'
import moment from 'moment'
import locale from "antd/es/date-picker/locale/de_DE"
import LoadingPage from '../../common/components/loading/page'
import BreadcrumbPage from '../../common/components/breadcrumbPage'
import { detectEmail, detectFormatPassword, detectPhone, detectUsername } from '../../common/mamager/DetectManager'
import { insertAccountVerifyIdentifyFetch } from '../account/API'

const { Option } = Select

export default function Register(props) {
    const { width } = useDimensions()

    const navigate = useNavigate()
    const location = useLocation()

    const [form] = Form.useForm()
    const dispatch = useDispatch()

    const [agree, setAgree] = useState(false)
    const [modalActive, setModalActive] = useState(true)
    const [modalBusinessOperation, setModalBusinessOperation] = useState(false)

    const [loading, setLoading] = useState(false)
    
    const audioReboundRef = useRef()

    const [day, setDay] = useState([])
    const [month, setMonth] = useState([])
    const [year, setYear] = useState([])

    const [provincesData, setProvincesData] = useState([])
    const [countryData, setCountryData] = useState([])
    const [prefixData, setPrefixData] = useState([])

    const [loadingSend, setLoadingSend] = useState(false)

    const [selectBusinessOperation, setSelectBusinessOperation] = useState(null)

    const [isDayNull, setIsDayNull] = useState(false)
    const [isMonthNull, setIsMonthNull] = useState(false)
    const [isTimeNull, setIsTimeNull] = useState(false)

    const getDay = () => {
        let tmpDay = [];
        for (let i = 1; i <= 31; i++) {
            tmpDay.push({
                label: i,
                value: i
            })
        }
        setDay(tmpDay)
    }

    const getMonth = () => {
        let tmpMonth = [
            { label: "มกราคม", value: 1 },
            { label: "กุมภาพันธ์", value: 2 },
            { label: "มีนาคม", value: 3 },
            { label: "เมษายน", value: 4 },
            { label: "พฤษภาคม", value: 5 },
            { label: "มิถุนายน", value: 6 },
            { label: "กรกฎาคม", value: 7 },
            { label: "สิงหาคม", value: 8 },
            { label: "กันยายน", value: 9 },
            { label: "ตุลาคม", value: 10 },
            { label: "พฤศจิกายน", value: 11 },
            { label: "ธันวาคม", value: 12 },
        ]
        setMonth(tmpMonth)
    }

    const getYear = () => {
        let tmpYear = []
        let a = moment().format('YYYY')
        let yearCurrent = (Number(a) + 543) - 1
        for (let i = yearCurrent; i > (yearCurrent - 100); i--) {
            tmpYear.push({
                label: i + 1,
                value: i + 1
            })
        }
        setYear(tmpYear)
    }

    const onFinish = async (values) => {
        // console.log("values : ", values)

        let isInfoRady = true

        if (values.password === values.passwordAgain) {
            isInfoRady = true
        } else {
            isInfoRady = false
            Notification('warning', 'รหัสผ่านไม่ตรงกัน')
        }

        // console.log("isInfoRady : ", isInfoRady)

        if (isInfoRady) {
            setLoadingSend(true)

            let uid = null
            let isAccountHasBeenUsed = false

            let username = values.username ? values.username : null
            let password = values.password ? values.password : null
            let email = values.email ? values.email : null
            let phone = values.phone ? values.phone : null
            let firstname = values.firstname ? values.firstname : null
            let lastname = values.lastname ? values.lastname : null
            let prefixId = values.prefix ? values.prefix : null
            let countryId = values.country ? values.country : null
            let provinceId = values.province ? values.province : null
            let timeOfBirth = values.time ? moment(String(values.time)).format("HH:mm") : null
            let dateOfBirth = values.day ? values.day : null
            let monthOfBirth = values.month ? values.month : null
            let yearOfBirth = values.year ? values.year : null

            let obj = {
                username, password, email, phone, firstname, lastname, prefixId, countryId, provinceId, timeOfBirth, dateOfBirth, monthOfBirth, yearOfBirth
            }
            // console.log("obj : ", obj)

            const result = await auth.createUserWithEmailAndPassword(
                email,
                password
            ).then((user) => {
                return user
            }).catch((err) => {
                isAccountHasBeenUsed = true
                return null
            });
            uid = isAccountHasBeenUsed ? null : result.user.uid

            if (!isAccountHasBeenUsed) {
                // insert data to db [user]
                let objUser = {
                    username,
                    uid,
                    email,
                    phone,
                    firstname,
                    lastname,
                    prefixId,
                    countryId,
                    provinceId,
                    timeOfBirth,
                    dateOfBirth,
                    monthOfBirth,
                    yearOfBirth
                }
                // console.log("objUser : ", objUser)
                const resultUser = await insertUserFetch(objUser)

                // insert data to db [account_verified_identify]
                let objAccountVerifyIdCard = {
                    uid: uid ? uid : null
                }
                // console.log("objAccountVerifyIdCard : ", objAccountVerifyIdCard)
                const resultAccountVerifyIdentify = await insertAccountVerifyIdentifyFetch(objAccountVerifyIdCard)

                // console.log("resultUser : ", resultUser)
                // console.log("resultAccountVerifyIdentify : ", resultAccountVerifyIdentify)

                if (
                    resultUser.isSuccess &&
                    resultAccountVerifyIdentify.isSuccess
                ) {
                    navigate('/')
                    Notification('success', 'สมัครสมาชิกสำเร็จ')

                } else {
                    Notification('error', 'ไม่สามารถสมัครสมาชิกได้ กรุณาลองใหม่อีกครั้ง')
                    setLoadingSend(false)
                }
            } else {
                Notification('error', 'ไม่สามารถสมัครสมาชิกได้ เนื่องจากอีเมลที่ใช้สมัคร ถูกใช้งานเเล้ว')
                setLoadingSend(false)
            }
        }
    }

    const getProvinces = async () => {
        let result = await getProvincesFetch()
        setProvincesData(result)
    }

    const getPrefix = async () => {
        let result = await getPrefixAllFetch()
        setPrefixData(result)
    }

    const getCountry = async () => {
        let result = await getCountryFetch()
        setCountryData(result)
    }

    const getBaseApi = async () => {
        setLoading(true)

        getDay()
        getMonth()
        getYear()

        await getProvinces()
        await getPrefix()
        await getCountry()

        setLoading(false)
    }

    useEffect(() => {
        if (location.state === undefined) {
            navigate("/login")
        } else {
            getBaseApi()
        }
    }, [selectBusinessOperation])

    // console.log("prefixData : ", prefixData)

    return (
        <>
            <Layout className="layout">
                <Header btnAutnActive={false} />
                <BreadcrumbPage pageCurrentName={"สมัครสมาชิก"} />

                {!loading ?
                    <>
                        {/* {resultRegisterCurrentStep >= (selectBusinessOperation === 1 ? 2 : 1) ?
                                <Content>
                                    <div style={width >= 1500 ? { paddingLeft: '15%', paddingRight: '15%' } : ((width > 912 && width < 1500) ? { paddingLeft: '5%', paddingRight: '5%' } : { paddingLeft: 0, paddingRight: 0 })}>
                                        <div style={{ backgroundColor: 'white', padding: 30 }}>
                                            <div style={{ paddingBottom: 20 }}>
                                                <label>สรุปข้อมูล การกรอกสมัครสมาชิก</label>
                                            </div>
                                            <Row>
                                                <Col span={24}>
                                                    <Card title={<label style={{ fontWeight: 100 }}>ข้อมูลผู้ใช้</label>} style={{ marginBottom: 20 }}>
                                                        <Row gutter={[12, 12]}>
                                                            <Col xs={12} xl={6}>
                                                                <label style={{ float: "right" }}>ชื่อผู้ใช้งาน : </label>
                                                            </Col>
                                                            <Col xs={12} xl={18}>
                                                                <label>{resultRegisterInformation.userName}</label>
                                                            </Col>

                                                            {providerId === "email/password" ?
                                                                <>
                                                                    <Col xs={12} xl={6}>
                                                                        <label style={{ float: "right" }}>{selectBusinessOperation === 1 ? "อีเมล" : "อีเมลบริษัท"} : </label>
                                                                    </Col>
                                                                    <Col xs={12} xl={18}>
                                                                        <label>{resultRegisterInformation.email}</label>
                                                                    </Col>
                                                                </>
                                                                : []
                                                            }
                                                        </Row>
                                                    </Card>

                                                    {selectBusinessOperation === 1 ?
                                                        <>
                                                            <Card title={<label style={{ fontWeight: 100 }}>หมวดหมู่คอร์สที่ชอบ</label>}>
                                                                <Row gutter={[12, 12]}>
                                                                    <Col xs={12} xl={6}>
                                                                        <label style={{ float: "right" }}>หมวดหมู่คอร์สที่ชื่นชอบ อันดับ 1 : </label>
                                                                    </Col>
                                                                    <Col xs={12} xl={18}>
                                                                        <label>{courseCategoryRef.current?.find(e => resultCourseCategoryInterest.courseCategoryInterestedRank1 === e.value)?.label}</label>
                                                                    </Col>

                                                                    <Col xs={12} xl={6}>
                                                                        <label style={{ float: "right" }}>หมวดหมู่คอร์สที่ชื่นชอบ อันดับ 2 : </label>
                                                                    </Col>
                                                                    <Col xs={12} xl={18}>
                                                                        <label>{courseCategoryRef.current?.find(e => resultCourseCategoryInterest.courseCategoryInterestedRank2 === e.value)?.label}</label>
                                                                    </Col>

                                                                    <Col xs={12} xl={6}>
                                                                        <label style={{ float: "right" }}>หมวดหมู่คอร์สที่ชื่นชอบ อันดับ 3 : </label>
                                                                    </Col>
                                                                    <Col xs={12} xl={18}>
                                                                        <label>{courseCategoryRef.current?.find(e => resultCourseCategoryInterest.courseCategoryInterestedRank3 === e.value)?.label}</label>
                                                                    </Col>
                                                                </Row>
                                                            </Card>
                                                        </>
                                                        : []
                                                    }
                                                </Col>

                                                <Col span={24} style={{ paddingTop: 15 }}>
                                                    <Button
                                                        style={{ float: 'left', width: 130 }}
                                                        type="default"
                                                        onClick={() => dispatch(setRegisterCurrentStepStateToSuccess(Number(resultRegisterCurrentStep) - 1))}
                                                    >
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Icon icon="fluent:arrow-step-back-20-filled" />
                                                            <label style={{ paddingLeft: 10, cursor: "pointer" }}>ย้อนกลับ</label>
                                                        </div>
                                                    </Button>

                                                    <Button
                                                        loading={loadingSend}
                                                        style={{ float: 'right', width: 130 }}
                                                        type="primary"
                                                        onClick={() => handleInsertRegister()}
                                                    >
                                                        {!loadingSend ?
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <Icon icon="ic:baseline-app-registration" style={{ color: "white" }} />
                                                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>ยืนยัน</label>
                                                            </div>
                                                            : []
                                                        }
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Content>
                                : */}
                        <Content>
                            <div style={width >= 1500 ? { paddingLeft: '15%', paddingRight: '15%' } : ((width > 912 && width < 1500) ? { paddingLeft: '5%', paddingRight: '5%' } : { paddingLeft: 0, paddingRight: 0 })}>
                                <div style={{ backgroundColor: 'white', padding: 30 }}>

                                    <Row gutter={[24, 0]}>
                                        <Col xs={24} md={10} xl={10}>
                                            <Card>
                                                <Row gutter={[16, 0]}>
                                                    <Col span={24}>
                                                        <Form form={form} layout="vertical" onFinish={onFinish} >
                                                            <Form.Item
                                                                label="ชื่อผู้ใช้งาน"
                                                                name="username"
                                                                style={{ width: '100%' }}
                                                                rules={[{ required: true, validator: detectUsername }]}
                                                            >
                                                                <Input onBlur={(e) => form.setFieldsValue({ username: e.target.value })} />
                                                            </Form.Item>
                                                        </Form>
                                                    </Col>

                                                    <Col span={24}>
                                                        <Form form={form} layout="vertical" onFinish={onFinish} >
                                                            <Form.Item
                                                                label="รหัสผ่าน"
                                                                name="password"
                                                                style={{ width: '100%' }}
                                                                rules={[{ required: true, validator: detectFormatPassword }]}
                                                            >
                                                                <Input.Password onBlur={(e) => form.setFieldsValue({ password: e.target.value })} />
                                                            </Form.Item>
                                                        </Form>
                                                    </Col>

                                                    <Col span={24}>
                                                        <Form form={form} layout="vertical" onFinish={onFinish} >
                                                            <Form.Item
                                                                label="รหัสผ่านอีกครั้ง"
                                                                name="passwordAgain"
                                                                style={{ width: '100%' }}
                                                                rules={[{ required: true, validator: detectFormatPassword }]}
                                                            >
                                                                <Input.Password onBlur={(e) => form.setFieldsValue({ passwordAgain: e.target.value })} />
                                                            </Form.Item>
                                                        </Form>
                                                    </Col>

                                                    <Col xs={24} md={24} xl={24}>
                                                        <Form form={form} layout="vertical" onFinish={onFinish} >
                                                            <Form.Item
                                                                label={"อีเมล"}
                                                                name="email"
                                                                rules={[{ required: true, validator: detectEmail }]}
                                                            >
                                                                <Input style={{ width: '100%' }} />
                                                            </Form.Item>
                                                        </Form>
                                                    </Col>
                                                    <Col xs={24} md={24} xl={24}>
                                                        <Form form={form} layout="vertical" onFinish={onFinish} >
                                                            <Form.Item
                                                                label={"เบอร์โทร"}
                                                                name="phone"
                                                                rules={[{ required: true, validator: detectPhone }]}
                                                            >
                                                                <Input style={{ width: '100%' }} />
                                                            </Form.Item>
                                                        </Form>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>

                                        <Col xs={24} md={14} xl={14}>
                                            <Card>
                                                <Row gutter={[16, 0]}>
                                                    <Col xs={24} md={6} xl={6}>
                                                        <Form form={form} layout="vertical" onFinish={onFinish} >
                                                            <Form.Item
                                                                label="คำนำหน้า"
                                                                name="prefix"
                                                                style={{ width: '100%' }}
                                                                rules={[{ required: true, message: "กรุณาเลือกคำนำหน้า" }]}
                                                            >
                                                                {/* <Input onBlur={(e) => form.setFieldsValue({ prefix: e.target.value })} /> */}
                                                                <Select
                                                                    showSearch
                                                                    style={{ width: '100%' }}
                                                                    placeholder="คำนำหน้า"
                                                                    optionFilterProp="children"
                                                                    allowClear
                                                                >
                                                                    {prefixData.map((val, index) =>
                                                                        <Option key={index} value={val.prefixId}>{val.name}</Option>
                                                                    )}
                                                                </Select>
                                                            </Form.Item>
                                                        </Form>
                                                    </Col>

                                                    <Col xs={24} md={9} xl={9}>
                                                        <Form form={form} layout="vertical" onFinish={onFinish} >
                                                            <Form.Item
                                                                label="ชื่อจริง"
                                                                name="firstname"
                                                                style={{ width: '100%' }}
                                                                rules={[{ required: true, message: "กรุณากรอกชื่อจริง" }]}
                                                            >
                                                                <Input onBlur={(e) => form.setFieldsValue({ firstname: e.target.value })} />
                                                            </Form.Item>
                                                        </Form>
                                                    </Col>

                                                    <Col xs={24} md={9} xl={9}>
                                                        <Form form={form} layout="vertical" onFinish={onFinish} >
                                                            <Form.Item
                                                                label="นามสกุล"
                                                                name="lastname"
                                                                style={{ width: '100%' }}
                                                                rules={[{ required: true, message: "กรุณากรอกนามสกุล" }]}
                                                            >
                                                                <Input onBlur={(e) => form.setFieldsValue({ lastname: e.target.value })} />
                                                            </Form.Item>
                                                        </Form>
                                                    </Col>

                                                    <Col span={24} style={{ paddingBottom: 12 }}>
                                                        <Row gutter={[12, 0]}>
                                                            <Col span={24}><label>วันเกิด</label></Col>

                                                            <Col xs={24} sm={12} md={8} lg={5}>
                                                                <Form form={form} layout="vertical" onFinish={onFinish} >
                                                                    <Form.Item
                                                                        name="day"
                                                                        label="วัน"
                                                                        style={{ width: '100%' }}
                                                                        rules={[{ required: !isDayNull, message: 'กรุณาเลือกวัน' }]}
                                                                    >
                                                                        <Select
                                                                            showSearch
                                                                            style={{ width: '100%' }}
                                                                            placeholder="วัน"
                                                                            optionFilterProp="children"
                                                                            allowClear
                                                                            disabled={isDayNull}
                                                                        >
                                                                            {day.map((val, index) =>
                                                                                <Option key={index} value={val.value}>{val.label}</Option>
                                                                            )}
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Form>
                                                                <Checkbox
                                                                    onChange={() => {
                                                                        if (!isDayNull) {
                                                                            form.setFieldsValue({ day: 30 })
                                                                        } else {
                                                                            form.setFieldsValue({ day: undefined })
                                                                        }
                                                                        setIsDayNull(!isDayNull)
                                                                    }}
                                                                >ไม่ทราบวัน</Checkbox>
                                                            </Col>

                                                            <Col xs={24} sm={12} md={8} lg={7}>
                                                                <Form form={form} layout="vertical" onFinish={onFinish} >
                                                                    <Form.Item
                                                                        name="month"
                                                                        label="เดือน"
                                                                        style={{ width: '100%' }}
                                                                        rules={[{ required: !isMonthNull, message: 'กรุณาเลือกเดือน' }]}
                                                                    >
                                                                        <Select
                                                                            showSearch
                                                                            style={{ width: '100%' }}
                                                                            placeholder="เดือน"
                                                                            optionFilterProp="children"
                                                                            allowClear
                                                                            disabled={isMonthNull}
                                                                        >
                                                                            {month.map((val, index) =>
                                                                                <Option key={index} value={val.value}>{val.label}</Option>
                                                                            )}
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Form>
                                                                <Checkbox
                                                                    onChange={() => {
                                                                        if (!isMonthNull) {
                                                                            form.setFieldsValue({ month: 6 })
                                                                        } else {
                                                                            form.setFieldsValue({ month: undefined })
                                                                        }
                                                                        setIsMonthNull(!isMonthNull)
                                                                    }}
                                                                >ไม่ทราบเดือน</Checkbox>
                                                            </Col>

                                                            <Col xs={24} sm={12} md={8} lg={6}>
                                                                <Form form={form} layout="vertical" onFinish={onFinish} >
                                                                    <Form.Item
                                                                        name="year"
                                                                        label="ปี"
                                                                        style={{ width: '100%' }}
                                                                        rules={[{ required: true, message: 'กรุณาเลือกปี' }]}
                                                                    >
                                                                        <Select
                                                                            showSearch
                                                                            style={{ width: '100%' }}
                                                                            placeholder="ปี"
                                                                            optionFilterProp="children"
                                                                            allowClear
                                                                        >
                                                                            {year.map((val, index) =>
                                                                                <Option key={index} value={val.value}>{val.label}</Option>
                                                                            )}
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Form>
                                                            </Col>

                                                            <Col xs={24} sm={12} md={8} lg={6}>
                                                                <Form form={form} layout="vertical" onFinish={onFinish} >
                                                                    <Form.Item
                                                                        name="time"
                                                                        label="เวลา"
                                                                        style={{ width: '100%' }}
                                                                    // rules={[{ required: true, message: 'กรุณาเลือกเวลา' }]}
                                                                    >
                                                                        <TimePicker
                                                                            showNow={false}
                                                                            placeholder="เวลา"
                                                                            disabled={isTimeNull}
                                                                            style={{ width: '100%' }}
                                                                            locale={{
                                                                                ...locale,
                                                                                lang: {
                                                                                    ...locale.lang,
                                                                                    // now: "เวลาปัจจุบัน",
                                                                                    ok: "ตกลง",
                                                                                }
                                                                            }}
                                                                            format={"HH:mm"}
                                                                        />
                                                                    </Form.Item>
                                                                    <Checkbox onChange={() => {
                                                                        if (!isTimeNull) {
                                                                            form.setFieldsValue({ time: moment('12:00', 'HH:mm') })
                                                                        } else {
                                                                            form.setFieldsValue({ time: undefined })
                                                                        }
                                                                        setIsTimeNull(!isTimeNull)
                                                                    }}
                                                                    >ไม่ทราบเวลา</Checkbox>
                                                                </Form>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col xs={24} md={9} xl={9}>
                                                        <Form form={form} layout="vertical" onFinish={onFinish} >
                                                            <Form.Item
                                                                label="สถานที่ (จังหวัด)"
                                                                name="province"
                                                                style={{ width: '100%' }}
                                                                rules={[{ required: true, message: "กรุณาเลือกจังหวัด" }]}
                                                            >
                                                                <Select
                                                                    showSearch
                                                                    style={{ width: '100%' }}
                                                                    placeholder="เลือกจังหวัด"
                                                                    optionFilterProp="children"
                                                                    allowClear
                                                                >
                                                                    {provincesData.map((val, index) =>
                                                                        <Option key={index} value={val.provinceId}>{val.provinceNameTH}</Option>
                                                                    )}
                                                                </Select>
                                                            </Form.Item>
                                                        </Form>
                                                    </Col>

                                                    <Col xs={24} md={9} xl={9}>
                                                        <Form form={form} layout="vertical" onFinish={onFinish} >
                                                            <Form.Item
                                                                label="ประเทศ"
                                                                name="country"
                                                                style={{ width: '100%' }}
                                                                rules={[{ required: true, message: "กรุณาเลือกประเทศ" }]}
                                                            >
                                                                <Select
                                                                    showSearch
                                                                    style={{ width: '100%' }}
                                                                    placeholder="เลือกประเทศ"
                                                                    optionFilterProp="children"
                                                                    allowClear
                                                                >
                                                                    {countryData.map((val, index) =>
                                                                        <Option key={index} value={val.countryId}>{val.name}</Option>
                                                                    )}
                                                                </Select>
                                                            </Form.Item>
                                                        </Form>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>

                                        <Col className="gutter-row" span={24} style={{ paddingTop: 15 }}>
                                            <Button
                                                style={{ float: 'right', width: 130 }}
                                                type="primary"
                                                loading={loadingSend}
                                                onClick={() => {
                                                    audioReboundRef.current.play()
                                                    form.submit()
                                                }}
                                            >
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <label style={{ paddingLeft: 10, cursor: "pointer" }}>ดำเนินการสมัคร</label>
                                                </div>
                                            </Button>
                                        </Col>
                                    </Row>

                                </div>
                            </div>
                        </Content>
                        {/* } */}
                    </>
                    :
                    <LoadingPage loading={loading} />
                }

                <Footer />
            </Layout>

            <Modal
                maskClosable={false}
                closable={false}
                title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <BsInfoCircle style={{ marginRight: 10, width: 22, height: 22, color: '#FF7F00' }} />
                        <label style={{ fontWeight: 100 }}>นโยบายความเป็นส่วนตัว ภายใต้สถาบันโหราศาสตร์วิตเตอ-กรุงเทพ</label>
                    </div>
                }
                style={{ top: 50 }}
                visible={modalActive}
                onOk={() => {
                    setModalActive(false)
                    setModalBusinessOperation(true)
                }}
                onCancel={() => {
                    firebase.auth().signOut()
                    setTimeout(function () {
                        navigate("/")
                        
                    }, 1000)
                }}
                okText={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Icon icon="line-md:confirm" style={{ color: !agree ? "gray" : "white" }} />
                        <label style={{ paddingLeft: 10, cursor: 'pointer' }} onClick={() =>  audioReboundRef.current.play()}>ตกลง</label>
                    </div>
                }
                cancelText={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Icon icon="ant-design:home-outlined" style={{ color: "black" }} />
                        <label style={{ paddingLeft: 10, cursor: 'pointer' }} onClick={() =>  audioReboundRef.current.play()}>กลับไปหน้าเเรก</label>
                    </div>
                }
                width={800}
                okButtonProps={{ disabled: !agree }}
            >
                <Row>
                    <Col span={24} style={{ paddingBottom: 15 }}>
                        โปรดอ่านโดยละเอียด และยอมรับเพื่อดำเนินการใช้งานระบบ
                    </Col>
                    <Col span={24} style={{ paddingBottom: 15 }}>
                        <div
                            style={{
                                overflow: 'auto',
                                height: 330,
                                width: 'auto',
                                overflowX: 'hidden',
                                border: '1px solid #E0E0E0',
                                padding: 15
                            }}
                        >
                            <PrivacyPolicyItem />
                        </div>
                    </Col>
                    <Col span={24}>
                        <Checkbox
                            className='checkbox-custom'
                            onChange={() => setAgree(!agree)}
                        >ข้าพเจ้ายอมรับนโยบายความเป็นส่วนตัวข้างต้น</Checkbox>
                    </Col>
                </Row>
            </Modal>
            <audio ref={audioReboundRef}>
				<source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
				Your browser does not support the audio element.
			</audio>
        </>
    )
}