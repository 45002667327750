import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getCourseAllFetch = async () => {
    try {
        const result = await httpClient.get(server.GET_COURSE_ALL_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCourseWaitInspectFetch = async (uid, courseId) => {
    try {
        const result = await httpClient.post(server.GET_COURSE_WAIT_INSPECT_URL, { uid, courseId })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCoursePurchasedAndNotExpiredFetch = async (uid, courseId) => {
    try {
        const result = await httpClient.post(server.GET_COURSE_PURCHASED_AND_NOT_EXPIRED_URL, { uid, courseId })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getCourseAllFetch,
    getCourseWaitInspectFetch,
    getCoursePurchasedAndNotExpiredFetch
}