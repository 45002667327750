/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { useState, useEffect, useRef } from "react"

import Header from '../../common/components/header'
import Footer from '../../common/components/footer'
import LoadingPage from '../../common/components/loading/page'
import { useDimensions } from "../../common/mamager/ScreenManager"
import {
    Button,
    Row,
    Col,
    Divider,
    Form,
    Input,
    Upload,
} from 'antd'
import { getContactUsChannelAllFetch, getPageContactFetch, insertInquiryFormFetch } from "./API"
import { Icon } from '@iconify/react'
import parse from 'html-react-parser'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import { Notification } from "../../common/components/notification"
import { apiServerUrl, serverUrl } from "../../constants"
import Video from "../../common/components/video"
import TalkToUs from "./components/TalkToUs"
import BreadcrumbPage from "../../common/components/breadcrumbPage"

const { TextArea } = Input

export default function Contract({ authorized, firebase, user }) {
    const [loading, setLoading] = useState(false)
    const { width } = useDimensions()
    const [form] = Form.useForm()

    const [pageContactData, setPageContactData] = useState(null)
    const [contactUsChannelAllData, setContactUsChannelAllData] = useState(null)

    const audioReboundRef = useRef()

    const [inquiryVideo, setInquiryVideo] = useState({
        loading: false,
        videoUrl: null
    })

    const [inquiryImage, setInquiryImage] = useState({
        loading: false,
        imageUrl: null
    })

    const optionInquiryVideo = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            name: "my-user",
            path: "upload_file/video/inquiry"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    setInquiryVideo({
                        videoUrl: info.file.response.filePath,
                        loading: false,
                    })
                }
            } else {
                setInquiryVideo({
                    loading: true,
                    videoUrl: inquiryVideo.videoUrl
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดวิดีโอสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดวิดีโอไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const optionInquiryImage = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            name: "my-user",
            path: "upload_file/image/inquiry"
        },
        headers: {
            authorization: 'authorization-text'
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    setInquiryImage({
                        imageUrl: info.file.response.filePath,
                        loading: false,
                    })
                }
            } else {
                setInquiryImage({
                    loading: true,
                    imageUrl: inquiryImage.imageUrl
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const onFinish = async (values) => {
        let uid = user?.uid ?? null
        let detail = values.detail ? values.detail : null
        let videoURL = inquiryVideo.videoUrl ? inquiryVideo.videoUrl : null
        let imageURL = inquiryImage.imageUrl ? inquiryImage.imageUrl : null

        const obj = { uid, detail, imageURL, videoURL }

        const result = await insertInquiryFormFetch(obj)
        if (result.isSuccess) {
            Notification("success", "เเจ้งเตือน!", "ส่งเเบบสอบถามสำเร็จ")
            form.resetFields()
            setInquiryVideo({
                loading: false,
                videoUrl: null
            })
            setInquiryImage({
                loading: false,
                imageUrl: null
            })
        } else {
            Notification("error", "เเจ้งเตือน!", "ไม่สามารถส่งเเบบสอบถามได้ โปรดลองอีกครั้ง")
        }
    }

    const getHighlightAstrology = async () => {
        let result = await getPageContactFetch()
        setPageContactData(result)
    }

    const getPageAboutMe = async () => {
        let result = await getContactUsChannelAllFetch()
        setContactUsChannelAllData(result)
    }

    const baseApi = async () => {
        setLoading(true)
        await getHighlightAstrology()
        await getPageAboutMe()
        setLoading(false)
    }

    useEffect(() => {
        baseApi()
    }, [])

    return (
        <>
            <Header firebase={firebase} user={user} />
            <BreadcrumbPage pageCurrentName={"ติดต่อเรา"} />

            <div>
                {!loading ?
                    <>
                        <div
                            style={{
                                alignItems: "center",
                                justifyContent: "center",
                                paddingLeft: width > 925 ? '15%' : 12,
                                paddingRight: width > 925 ? '15%' : 12,
                                paddingTop: 24
                            }}
                        >
                            <div style={{ display: "grid", alignItems: "flex-start", textAlign: "center" }}>
                                <label style={{ fontSize: 24, fontWeight: 600, paddingBottom: 8 }}>{pageContactData?.title}</label>
                                <label style={{ paddingBottom: 20 }}>{pageContactData?.subTitle ? parse(pageContactData.subTitle) : []}</label>
                            </div>

                            <div style={{ paddingTop: 24, textAlign: "center", paddingBottom: 32 }}>
                                <Row gutter={[8, 8]}>
                                    {contactUsChannelAllData?.map((item) => {
                                        return (
                                            <Col xs={24} md={8} xl={8}>
                                                <div style={{ display: "grid", alignItems: "flex-start", border: "1px dotted #D1D1D1", borderRadius: 8, paddingTop: 12, paddingBottom: 12, height: 125 }}>
                                                    <center>
                                                        <Icon icon={item.imageURL} style={{ color: "#FF7F00", height: 28, width: 28 }} />
                                                    </center>
                                                    <label>{item?.title ? parse(item.title) : []}</label>
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </div>
                        </div>

                        {/* <div style={{ paddingTop: 24, paddingBottom: 24 }}>
                            <div
                                style={{
                                    backgroundColor: "black",
                                    display: "flex", alignItems: "center", justifyContent: "center",
                                }}
                            >
                                <Video
                                    url={""}
                                    title={""}
                                    height={200}
                                    width={"auto"}
                                />
                            </div>
                        </div> */}

                        <div
                            style={{
                                alignItems: "center",
                                justifyContent: "center",
                                paddingLeft: width > 925 ? '15%' : 12,
                                paddingRight: width > 925 ? '15%' : 12,
                                paddingTop: 24
                            }}
                        >
                            <div style={{ display: "grid", alignItems: "flex-start", paddingBottom: 32 }}>
                                <label style={{ fontSize: 24, fontWeight: 600, paddingBottom: 12, textAlign: 'center' }}>{pageContactData?.titleInquiryForm}</label>
                                <Form
                                    form={form}
                                    name="validateOnly"
                                    layout="vertical"
                                    autoComplete="off"
                                    onFinish={onFinish}
                                >
                                    <Row gutter={[8, 8]}>
                                        <Col span={24}>
                                            <Form.Item name="detail" label="ข้อความ/MESSAGE" rules={[{ required: true, message: "กรุณากรอกข้อความ" }]}>
                                                <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} md={12} xl={12}>
                                            <div style={{ display: "grid" }}>
                                                <label style={{ paddingBottom: 6 }}>เเนบวิดีโอ (ไม่บังคับ)</label>
                                                {inquiryVideo?.videoUrl ?
                                                    <div style={{ backgroundColor: "black", borderRadius: 8 }}>
                                                        <Video
                                                            url={inquiryVideo.videoUrl}
                                                            title={""}
                                                            height={"100%"}
                                                            width={"100%"}
                                                        />
                                                    </div>
                                                    :
                                                    <img
                                                        style={{ width: "100%", borderRadius: 8 }}
                                                        src={`./assets/images/default/df-vdo.png`}
                                                    />
                                                }
                                                <div style={{ paddingTop: 12 }}>
                                                    <Upload
                                                        {...optionInquiryVideo}
                                                        accept='.mp4'
                                                        style={{ width: "100%" }}
                                                        maxCount={1}
                                                        showUploadList={{ showRemoveIcon: false }}
                                                    >
                                                        <Button
                                                            type="default"
                                                            style={{ width: "100%" }}
                                                            icon={inquiryVideo.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                            onClick={() =>  audioReboundRef.current.play()}
                                                        >อัพโหลดวิดีโอ</Button>
                                                    </Upload>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xs={24} md={12} xl={12}>
                                            <div style={{ display: "grid" }}>
                                                <label style={{ paddingBottom: 6 }}>เเนบรูปภาพ (ไม่บังคับ)</label>
                                                {inquiryImage?.imageUrl ?
                                                    <img
                                                        style={{ borderRadius: 8, maxHeight: 207, border: "1px solid #EEEEEE" }}
                                                        src={`${serverUrl}/${inquiryImage.imageUrl}`}
                                                    />
                                                    :
                                                    <img
                                                        style={{ width: "100%", borderRadius: 8 }}
                                                        src={`./assets/images/default/df-img.png`}
                                                    />
                                                }
                                                <div style={{ paddingTop: 12 }}>
                                                    <Upload
                                                        {...optionInquiryImage}
                                                        accept='image/jpeg, image/png, image/jfif'
                                                        style={{ width: "100%" }}
                                                        maxCount={1}
                                                        showUploadList={{ showRemoveIcon: false }}
                                                    >
                                                        <Button
                                                            type="default"
                                                            style={{ width: "100%" }}
                                                            icon={inquiryImage.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                            onClick={() =>  audioReboundRef.current.play()}
                                                        >อัพโหลดรูปภาพ</Button>
                                                    </Upload>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col span={24}>
                                            <Divider style={{ marginTop: 12, marginBottom: 18 }} />
                                            <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "center" }}>
                                                <Button
                                                    type="primary"
                                                    size="middle"
                                                    onClick={() => {
                                                        audioReboundRef.current.play()
                                                        form.submit()
                                                    }}
                                                >
                                                    ส่งข้อมูล
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </div>

                        <TalkToUs />
                    </>
                    :
                    <LoadingPage loading={loading} />
                }
            </div>

            <Footer />
            <audio ref={audioReboundRef}>
				<source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
				Your browser does not support the audio element.
			</audio>
        </>
    )
}
