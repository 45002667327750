/* eslint-disable no-const-assign */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from "react"
import {
    Layout,
    Row,
    Col
} from 'antd'
import { useNavigate } from 'react-router-dom'
import { useLocation } from "react-router-dom"
import { useDimensions } from "./../../common/mamager/ScreenManager"
import CourseOverview from "./components/overview"
import CourseVideo from "./components/video"
import Header from "./../../common/components/header"
import Footer from "./../../common/components/footer"
import LoadingPage from "./../../common/components/loading/page"
import { getCourseByIdFetch, getVideoCourseByIdFetch } from "./API"
import Video from "../../common/components/video"
import parse from 'html-react-parser'
import { detectSignIn } from "../../common/mamager/DetectManager"
import { detectCourseReadyPayment } from "../course/components/detectOrder"

const { Content } = Layout

export default function ClassroomStudy(props) {

    const navigate = useNavigate()

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const courseId = params.get("course-id")

    const { width } = useDimensions()

    const [course, setCourse] = useState(null)
    const [videoCourse, setVideoCourse] = useState([])
    const [videoCourseCurrent, setVideoCourseCurrent] = useState()

    const [loading, setLoading] = useState(true)

    const handleVideoCourseCurrent = (value) => {
        setVideoCourseCurrent(value)
    }

    const genCourseOverview = () => {
        return (
            <CourseOverview
                course={course}
                courseOwner={course}
                user={props.user}
            />
        )
    }

    const genCourseVideoList = () => {
        return (
            <CourseVideo
                videoCourse={videoCourse}
                course={course}
                handleVideoCourseCurrent={handleVideoCourseCurrent}
            />
        )
    }

    const detectLogin = async () => {
        // detect login
        const isLogin = await detectSignIn()
        if (!isLogin) {
            navigate("/login")
        }
    }

    const  detectAccessToClassroom = async () => {
        const result = await detectCourseReadyPayment(props.user.uid, courseId)
        // console.log("detectCourseReadyPayment : ", result)
        if (result.isReady) {
            navigate("/login")
        }
    }

    const getCourseById = async () => {
        let result = await getCourseByIdFetch(courseId)
        setCourse(result ?? null)
    }

    const getVideoCourseById = async (isCurrent, isDefaultPercentWatch) => {
        let result = await getVideoCourseByIdFetch(courseId)
        setVideoCourseCurrent(result[0])
        setVideoCourse(result ?? [])
    }

    const getBaseApi = async () => {
        setLoading(true)

        await detectLogin()
        await detectAccessToClassroom()
        await getCourseById()
        await getVideoCourseById()

        setLoading(false)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <>
            <Header
                firebase={props.firebase}
                authorized={props.authorized}
                user={props.user}
            />

            <article>
                {!loading ?
                    <Content style={{ backgroundColor: "#F5F5F5" }}>
                        <div style={width >= 1500 ? { paddingLeft: '5%', paddingRight: '5%' } : { paddingLeft: '0%', paddingRight: '0%' }}>
                            <Row>
                                <Col xs={24} lg={16} style={{ backgroundColor: "#0c0c0c" }}>
                                    <div style={{ display: "grid", alignItems: "center", justifyContent: "flex-start" }}>
                                        <div>
                                            {width < 992 ?
                                                <div style={{ float: "none" }}>
                                                    {genCourseOverview()}
                                                </div>
                                                : []
                                            }
                                        </div>

                                        <div>
                                            <Video
                                                url={videoCourseCurrent?.videoURL}
                                                title={videoCourseCurrent?.title}
                                                height={"100%"}
                                                width={"100%"}
                                            />
                                        </div>

                                        <div>
                                            {width < 992 ?
                                                <div style={{ float: "none" }}>
                                                    {genCourseOverview()}
                                                </div>
                                                : []
                                            }
                                        </div>

                                        <div style={{ color: "white", padding: 12 }}>
                                            <label style={{ }}>รายละเอียดคอร์ส</label>
                                            {course?.detail ? parse(course?.detail) : []}
                                        </div>
                                    </div>
                                </Col>

                                <Col xs={24} lg={8} style={{ backgroundColor: "#444444" }}>
                                    <Row>
                                        <Col span={24}>
                                            {width >= 992 ?
                                                <div style={{ float: "none" }}>
                                                    {genCourseOverview()}
                                                </div>
                                                : []
                                            }
                                        </Col>

                                        <Col span={24}>
                                            {genCourseVideoList()}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Content>
                    :
                    <LoadingPage loading={loading} />
                }
            </article>

            <Footer />
        </>
    )
}