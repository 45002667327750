import React from 'react'

class DisableRightClick extends React.Component {
    componentDidMount() {
        document.addEventListener('contextmenu', this.handleRightClick)
    }

    componentWillUnmount() {
        document.removeEventListener('contextmenu', this.handleRightClick)
    }

    handleRightClick = (e) => {
        e.preventDefault()
    }

    render() {
        return (
            <div>
                {/* เนื้อหาหรือ Component ของคุณที่คุณต้องการป้องกันการคลิกขวา */}
            </div>
        )
    }
}

export default DisableRightClick
