/* eslint-disable no-array-constructor */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect,useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    DatePicker,
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { getFeedbackBySearchFetch } from '../../../feedback/API'

const { RangePicker } = DatePicker

const columns = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "5%",
    },
    {
        title: 'ชื่อผู้ใช้งาน',
        dataIndex: 'username',
        width: "20%",
    },
    {
        title: 'ข้อเสนอเเนะ',
        dataIndex: 'feedback',
        width: "50%",
    },
    {
        title: 'วันที่ส่ง',
        dataIndex: 'createdAt',
        align: "center",
        width: "15%",
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        fixed: 'right',
        align: "center",
        width: "10%"

    },
]

const formatDate = "DD/MM/YYYY"
const formatDateTime = "DD/MM/YYYY HH:mm"

export default function FeedbackInspect() {

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)

    const [form] = Form.useForm()
    const [formSearch] = Form.useForm()

    const audioReboundRef = useRef()


    const [pageCurrent, setPageCurrent] = useState(1)

    const [modal, setModal] = useState({
        isShow: false,
        title: null,
        data: null
    })

    const getFeedbackBySearch = async (username, feedback, startedDate, endDate) => {
        setLoading(true)

        let objSearch = {
            username, feedback, startedDate, endDate
        }
        const result = await getFeedbackBySearchFetch(objSearch)
        // console.log("result : ", result)

        let tempList = []
        result?.map((val, index) => {
            tempList.push({
                index: (index + 1),
                username: val?.user?.username,
                feedback: val.subTitle,
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            type="default"
                            style={{ width: 100 }}
                            onClick={() => {
                                audioReboundRef.current.play()
                                setModal({ isShow: true, title: "view", data: val })
                            }}
                        >
                            <div style={{}}>
                                ดูรายละเอียด
                            </div>
                        </Button>
                    </>
            })

        })
        setList(tempList)
        // console.log("tempList : ", tempList)

        setLoading(false)
    }

    const onSearchFinish = async (values) => {
        let username = values?.username ? values.username : null
        let feedback = values?.feedback ? values.feedback : null
        let startedDate = values?.dateRange ? values.dateRange[0] : null
        let endDate = values?.dateRange ? values.dateRange[1] : null
        await getFeedbackBySearch(username, feedback, startedDate, endDate)
    }

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const setFormDefault = () => {
        form.resetFields()

        setModal({
            isShow: false,
            title: null,
            data: null
        })
    }


    const getBaseApi = async () => {
        await getFeedbackBySearch(null, null, null, null)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <Row>
            <Col span={8}>
                <label>ตรวจสอบข้อเสนอเเนะ</label>
            </Col>
            <Col span={16} style={{ paddingBottom: 20 }}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ชื่อผู้ใช้งาน"
                                name="username"
                                style={{ width: '100%' }}
                            >
                                <Input />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ข้อเสนอเเนะ"
                                name="feedback"
                                style={{ width: '100%' }}
                            >
                                <Input />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ห้วงวันที่สร้าง" name="dateRange"
                                style={{ width: '100%' }}
                            >
                                <RangePicker />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10, marginTop: 3 }}>
                            <Button
                                style={{ float: 'right', width: 70 }}
                                type="primary"
                                onClick={() => {
                                    audioReboundRef.current.play()
                                    formSearch.submit()}}
                            >
                                ค้นหา
                            </Button>
                        </div>
                    </div>
                </Form>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrent,
                        pageSize: 10,
                        total: list.length
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modal.title === "view" ? "รายละเอียดข้อมูล" : ""}</label></strong>}
                visible={modal.isShow}
                onCancel={() => {

                    // default
                    setFormDefault()
                }}
                width={500}
                footer={[]}
            >

                <Row gutter={[24, 0]}>
                    <Col span={24} style={{ paddingBottom: 12 }}>
                        <Row gutter={[0, 8]}>
                            <Col xs={24} md={8} xl={6}>
                                <label>ชื่อผู้ใช้งาน</label>
                            </Col>
                            <Col xs={24} md={16} xl={18}>
                                <label>{modal.data?.user?.username}</label>
                            </Col>

                            <Col xs={24} md={8} xl={6}>
                                <label>ข้อเสนอเเนะ</label>
                            </Col>
                            <Col xs={24} md={16} xl={18}>
                                <label>{modal.data?.subTitle}</label>
                            </Col>

                            <Col xs={24} md={8} xl={6}>
                                <label>วันที่ส่ง</label>
                            </Col>
                            <Col xs={24} md={16} xl={18}>
                                <label>{moment(modal.data?.createdAt).format(formatDateTime)}</label>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal>
            <audio ref={audioReboundRef}>
				<source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
				Your browser does not support the audio element.
			</audio>
        </Row>
    )
}
