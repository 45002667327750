import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getPredictionRoomByIdFetch = async (value) => {
    try {
        const result = await httpClient.post(server.GET_PREDICTION_ROOM_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const getDetectPredictionRoomExpiredByIdFetch = async (value) => {
    try {
        const result = await httpClient.post(server.GET_DETECT_PREDICTION_ROOM_EXPIRED_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const insertPredictionRoomFetch = async (value) => {
    try {
        const result = await httpClient.post(server.INSERT_PREDICTION_ROOM_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) {}
}

const updatePredictionRoomActiveByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_PREDICTION_ROOM_ACTIVE_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) {}
}

export {
    // get
    getPredictionRoomByIdFetch,
    getDetectPredictionRoomExpiredByIdFetch,
    
    // insert
    insertPredictionRoomFetch,

    // update
    updatePredictionRoomActiveByIdFetch,

    // delete
    
}
