import { getCoursePurchasedAndNotExpiredFetch, getCourseWaitInspectFetch } from "../API"

export const detectCourseReadyPayment = async (uid, courseId) => {
    let result = {
        massage: "",
        isReady: true
    }
    const resultStep1 = await getCourseWaitInspectFetch(uid, courseId)
    // console.log("resultStep1 : ", resultStep1)
    if (resultStep1?.isCourseWaitInspect) {
        result = {
            massage: "WAITING_PAYMENT",
            isReady: false
        }
    }

    const resultStep2 = await getCoursePurchasedAndNotExpiredFetch(uid, courseId)
    let find = resultStep2?.filter(fill => fill.isCoursePurchasedAndNotExpired === false)
    // console.log("resultStep2 : ", resultStep2?.length, find?.length)
    if (resultStep2?.length !== find?.length) {
        result = {
            massage: "PURCHASED_AND_NOT_EXPIRED",
            isReady: false
        }
    }

    return result
}