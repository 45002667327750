/* eslint-disable jsx-a11y/alt-text */

import Header from '../../common/components/header'
import Footer from '../../common/components/footer'
import ServiceAstrologicalBoard from "./components/ServiceAstrologicalBoard"
import ServiceBoard from "./components/ServiceBoard"
import BreadcrumbPage from "../../common/components/breadcrumbPage"

export default function Service({ authorized, firebase, user }) {
    return (
        <>
            <Header firebase={firebase} user={user} />
            <BreadcrumbPage pageCurrentName={"บริการต่าง ๆ"} />

            <div>
                <ServiceBoard />
                <ServiceAstrologicalBoard />
            </div>

            <Footer />
        </>
    )
}
