import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getPageTalkToUsFetch = async () => {
    try {
        const result = await httpClient.get(server.GET_PAGE_TALK_TO_US_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getPageContactFetch = async () => {
    try {
        const result = await httpClient.get(server.GET_PAGE_CONTACT_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getContactChannelAllFetch = async () => {
    try {
        const result = await httpClient.get(server.GET_CONTACT_CHANNEL_ALL_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getContactUsChannelAllFetch = async () => {
    try {
        const result = await httpClient.get(server.GET_CONTACT_US_CHANNEL_ALL_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getInquiryFormBySearchFetch = async (value) => {
    try {
        const result = await httpClient.post(server.GET_INQUIRY_FORM_BY_SEARCH_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertInquiryFormFetch = async (value) => {
    try {
        const result = await httpClient.post(server.INSERT_INQUIRY_FORM_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertContactChannelFetch = async (value) => {
    try {
        const result = await httpClient.post(server.INSERT_CONTACT_CHANNEL_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updateInquiryFormByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_INQUIRY_FORM_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updatePageTalkToUsByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_PAGE_TALK_TO_US_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updatePageContactByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_PAGE_CONTACT_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updateContactChannelByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_CONTACT_CHANNEL_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const deleteContactChannelByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(server.DELETE_CONTACT_CHANNEL_BY_ID_URL + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getPageTalkToUsFetch,
    getPageContactFetch,
    getContactChannelAllFetch,
    getContactUsChannelAllFetch,
    getInquiryFormBySearchFetch,

    // insert
    insertInquiryFormFetch,
    insertContactChannelFetch,

    // update
    updateInquiryFormByIdFetch,
    updatePageTalkToUsByIdFetch,
    updatePageContactByIdFetch,
    updateContactChannelByIdFetch,

    // delete
    deleteContactChannelByIdFetch,
}