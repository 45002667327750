/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useRef } from "react"
import {
    Form,
    Button,
    Row,
    Col,
    Upload,
    Image,
    Divider,
    Select,
} from "antd"
import {
    LoadingOutlined,
    UploadOutlined
} from "@ant-design/icons"
import ImgCrop from "antd-img-crop"
import { Icon } from '@iconify/react'
import { AiOutlineUpload } from 'react-icons/ai'
import { useDimensions } from "../../../../common/mamager/ScreenManager"
import LoadingPage from "../../../../common/components/loading/page"
// import { apiStorageUrl } from "../../../../constants"
import { updateUser } from "../../API"
import { Notification } from "../../../../common/components/notification"
import { resizeImage } from "../../../../common/mamager/ImageMamager"
import moment from "moment"
import { apiServerUrl } from "../../../../constants"
// import { detectPhone } from "../../../../common/mamager/DetectManager"

const { Option } = Select

export default function Profile(props) {
    const [loading, setLoading] = useState(false)
    const { width } = useDimensions()
    const [form] = Form.useForm()
    const audioReboundRef = useRef()
    const [courseCoverImage, setCourseCoverImage] = useState({
        loading: false,
        imageProfile: props.user ? (props.user.imageProfile ? props.user.imageProfile : null) : null
    })

    const onFinish = async (values) => {
        setLoading(true)

        let objUser = {
            uid: props.user ? (props.user.uid ? props.user.uid : null) : null,
            provinceId: values.province ? values.province : null,
            countryId: values.country ? values.country : null,
            imageProfile: courseCoverImage.imageProfile ? courseCoverImage.imageProfile : null

        }
        // console.log("objUser : ", objUser)
        const result = await updateUser(objUser)

        if (result.isSuccess) {
            Notification("success", "เเจ้งเตือน!", "บันทึกสำเร็จ")
            props.handleReload()
        } else {
            Notification('error', 'ไม่สามารถบันทึกข้อมูลได้ กรุณาลองใหม่อีกครั้ง')
        }

        setLoading(false)
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader()
        reader.addEventListener("load", () => callback(reader.result))
        reader.readAsDataURL(img)
    }

    const handleChange = (info) => {
        if (info.file.status !== "uploading") {
            resizeImage(info.file.originFileObj, 300, 300).then((res) => {
                getBase64(res, (imageProfile) => {
                    setCourseCoverImage({
                        loading: false,
                        imageProfile
                    })
                })
            })
        } else {
            setCourseCoverImage({ loading: true })
        }

        if (info.file.status === "done") {
            Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")
        } else if (info.file.status === "error") {
            Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ")
        }
    }

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={{
                province: props.user.province?.provinceId,
                country: props.user.country?.countryId
            }}
            onFinish={onFinish}
        >
            {!loading ?
                <Row gutter={[16, 0]}>
                    <Col xs={24} xl={8}>
                        <Row>
                            <Col span={24}>
                                <center>
                                    {courseCoverImage.imageProfile ?
                                        <Image
                                            style={{ height: "160px", width: "160px", borderRadius: "50%" }}
                                            src={courseCoverImage.imageProfile}
                                            preview={false}
                                        />
                                        :
                                        <Image
                                            style={{ height: "160px", width: "160px", borderRadius: "50%" }}
                                            src={props.user.imageProfile !== null ? props.user.imageProfile : `./assets/images/profile/personDefault.jpg`}
                                            preview={false}
                                        />
                                    }
                                </center>
                            </Col>

                            <Col span={24} style={{ paddingTop: 15 }}>
                                <center>
                                    <Form.Item name="uploadCourseCoverImage">
                                        <ImgCrop
                                            modalTitle={<label style={{ fontWeight: 100 }}>ปรับเเต่งรูปภาพ</label>}
                                            modalOk={
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <AiOutlineUpload style={{ width: 20, height: 20 }} />
                                                    <label style={{ paddingLeft: 10, cursor: "pointer" }} onClick={() =>  audioReboundRef.current.play()}>อัพโหลด</label>
                                                </div>
                                            }
                                            modalCancel={
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Icon icon="mdi:cancel" style={{ width: 20, height: 20 }} />
                                                    <label style={{ paddingLeft: 10, cursor: "pointer" }} onClick={() =>  audioReboundRef.current.play()}>ยกเลิก</label>
                                                </div>
                                            }
                                            aspect={300 / 300}
                                        >
                                            <Upload
                                                accept=".jpeg, .jpg, .png"
                                                maxCount={1}
                                                showUploadList={{ showRemoveIcon: false }}
                                                action={`${apiServerUrl}/upload/active`}
                                                onChange={handleChange}
                                            >
                                                <Button
                                                    type="primary"
                                                    style={{ width: 200 }}
                                                    icon={courseCoverImage.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                    onClick={() =>  audioReboundRef.current.play()}
                                                >
                                                    อัพโหลด
                                                </Button>
                                            </Upload>
                                        </ImgCrop>
                                    </Form.Item>
                                </center>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} xl={16}>
                        <Row gutter={[16, 16]} style={{ paddingBottom: 16 }}>
                            <Col xs={24} md={6} xl={6} style={{ textAlign: (width > 808 ? "right" : "left") }}>
                                ชื่อผู้ใช้งาน
                            </Col>
                            <Col xs={24} md={18} xl={18} >
                                {props.user.username}
                            </Col>

                            <Col xs={24} md={6} xl={6} style={{ textAlign: (width > 808 ? "right" : "left") }}>
                                อีเมล
                            </Col>
                            <Col xs={24} md={18} xl={18} >
                                <a href={"mailto: " + props.user.email} style={{ color: "black" }}>{props.user.email}</a>
                            </Col>

                            <Col xs={24} md={6} xl={6} style={{ textAlign: (width > 808 ? "right" : "left") }}>
                                เบอร์โทร
                            </Col>
                            <Col xs={24} md={18} xl={18} >
                                {props.user.phone}
                            </Col>

                            <Divider style={{ marginTop: 1, marginBottom: 1 }} />

                            <Col xs={24} md={6} xl={6} style={{ textAlign: (width > 808 ? "right" : "left") }}>
                                ชื่อ
                            </Col>
                            <Col xs={24} md={18} xl={18} >
                                {props.user.firstname} {props.user.lastname}
                            </Col>

                            <Col xs={24} md={6} xl={6} style={{ textAlign: (width > 808 ? "right" : "left") }}>
                                วันเกิด
                            </Col>
                            <Col xs={24} md={18} xl={18} >
                                {(
                                    props.user.dateOfBirth + "/" +
                                    (props.user.monthOfBirth > 9 ? props.user.monthOfBirth : "0" + props.user.monthOfBirth) + "/" +
                                    props.user.yearOfBirth + " " +
                                    props.user.timeOfBirth
                                )}
                            </Col>

                            <Col xs={24} md={6} xl={6} style={{ textAlign: (width > 808 ? "right" : "left") }}>
                                เพศ
                            </Col>
                            <Col xs={24} md={18} xl={18} >
                                {props.user.prefix?.name}
                            </Col>

                            <Col xs={24} md={6} xl={6} style={{ textAlign: (width > 808 ? "right" : "left") }}>
                                สถานที่ (จังหวัด)
                            </Col>
                            <Col xs={24} md={18} xl={18} >
                                {/* {console.log("DD : ", props.user)} */}
                                {/* {props.user.province?.provinceNameTH} */}
                                <Form.Item
                                    // label="สถานที่ (จังหวัด)"
                                    name="province"
                                    style={{ width: '100%' }}
                                    rules={[{ required: true, message: "กรุณาเลือกจังหวัด" }]}
                                >
                                    <Select
                                        showSearch
                                        style={{ width: 180 }}
                                        placeholder="จังหวัด"
                                        optionFilterProp="children"
                                        allowClear
                                    >
                                        {props.provincesData.map((val, index) =>
                                            <Option key={index} value={val.provinceId}>{val.provinceNameTH}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={6} xl={6} style={{ textAlign: (width > 808 ? "right" : "left") }}>
                                ประเทศ
                            </Col>
                            <Col xs={24} md={18} xl={18} >
                                {/* {console.log("DD : ", props.user)} */}
                                {/* {props.user.province?.provinceNameTH} */}
                                <Form.Item
                                    // label="สถานที่ (จังหวัด)"
                                    name="country"
                                    style={{ width: '100%' }}
                                    rules={[{ required: true, message: "กรุณาเลือกประเทศ" }]}
                                >
                                    <Select
                                        showSearch
                                        style={{ width: 180 }}
                                        placeholder="ประเทศ"
                                        optionFilterProp="children"
                                        allowClear
                                    >
                                        {props.countryData.map((val, index) =>
                                            <Option key={index} value={val.countryId}>{val.name}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={24} style={{ textAlign: "center", borderTop: "1px solid #eeeeee" }}>
                        <div style={{ paddingTop: 15 }}>
                            <Button type="primary"
                                onClick={() => {
                                    audioReboundRef.current.play()
                                    form.submit()
                                }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Icon icon="bx:save" style={{ width: 19, height: 19, color: "white" }} />
                                    <label style={{ paddingLeft: 10, cursor: "pointer" }}>บันทึกข้อมูล</label>
                                </div>
                            </Button>
                        </div>
                    </Col>
                </Row>

                :
                <LoadingPage loading={loading} height={190} />
            }
            <audio ref={audioReboundRef}>
                <source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
        </Form>

    )
}
