/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import {
    Row,
    Col,
    Button,
    Form,
    Input,
    Upload,
} from 'antd'
import { getPageServiceFetch, updatePageServiceByIdFetch } from '../../../service/API'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { apiServerUrl } from '../../../../constants'
import Video from '../../../../common/components/video'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import { Notification } from '../../../../common/components/notification'
import LoadingPage from '../../../../common/components/loading/page'

export default function ServicePage() {
    const [form] = Form.useForm()

    const [loading, setLoading] = useState(true)

    const audioReboundRef = useRef()

    const [data, setData] = useState(null)

    // -------------------------------------------------------------------
    const [subTitle, setSubTitle] = useState()
    const subTitleRef = useRef()

    const [serviceAstrologicalSubTitle, setServiceAstrologicalSubTitle] = useState()
    const serviceAstrologicalSubTitlRef = useRef()

    const [videoIntroURL, setVideoIntroURL] = useState({
        loading: false,
        videoUrl: null
    })

    const optionVideoIntro = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            name: "admin",
            path: "upload_file/video/page/service/intro"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    // console.log("info.file.response.filePath : ", info.file.response.filePath)
                    setVideoIntroURL({
                        videoUrl: info.file.response.filePath,
                        loading: false,
                    })
                }
            } else {
                setVideoIntroURL({
                    loading: true,
                    videoUrl: videoIntroURL.videoUrl
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดวิดีโอสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดวิดีโอไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }
    // -------------------------------------------------------------------

    const getAboutUs = async () => {
        const result = await getPageServiceFetch()

        setSubTitle(result?.subtitle ?? "")
        subTitleRef.current = result?.subtitle ?? ""

        setServiceAstrologicalSubTitle(result?.serviceAstrologicalSubTitle ?? "")
        serviceAstrologicalSubTitlRef.current = result?.serviceAstrologicalSubTitle ?? ""

        setVideoIntroURL({
            loading: false,
            videoUrl: result?.videoIntroURL
        })

        setData(result)
    }

    const onFinish = async (values) => {
        let obj = {
            pageServiceId: data.pageServiceId ? data.pageServiceId : null,
            title: values.title ? values.title : null,
            subtitle: subTitleRef.current ? subTitleRef.current : null,
            titleMenuList: values.titleMenuList ? values.titleMenuList : null,
            subTitleMenuList: values.subTitleMenuList ? values.subTitleMenuList : null,
            serviceAstrologicalTitle: values.serviceAstrologicalTitle ? values.serviceAstrologicalTitle : null,
            serviceAstrologicalSubTitle: serviceAstrologicalSubTitlRef.current ? serviceAstrologicalSubTitlRef.current : null,
            videoIntroURL: videoIntroURL.videoUrl ? videoIntroURL.videoUrl : null,
        }
        // console.log("obj : ", obj)
        const result = await updatePageServiceByIdFetch(obj)

        if (result.isSuccess) {
            Notification('success', 'เเก้ไขสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const getBaseApi = async () => {
        setLoading(true)
        await getAboutUs()
        setLoading(false)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    // console.log("data : ", data)

    return (
        <>
            {!loading ?
                <Row>
                    <Col span={24} style={{ paddingBottom: 20 }}>
                        <label>เพจ (ติดต่อเรา)</label>
                    </Col>
                    <Col xs={24} md={24} xl={24}>
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                            initialValues={{
                                pageServiceId: data?.pageServiceId,
                                title: data?.title,
                                subtitle: data?.subtitle,
                                titleMenuList: data?.titleMenuList,
                                subTitleMenuList: data?.subTitleMenuList,
                                serviceAstrologicalTitle: data?.serviceAstrologicalTitle,
                                serviceAstrologicalSubTitle: data?.serviceAstrologicalSubTitle,
                            }}
                        >
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <Form.Item name="pageServiceId" style={{ display: "none" }}>
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        // label=""
                                        name="title"
                                        style={{ width: '100%' }}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={{
                                            toolbar: {
                                                items: [
                                                    'heading', '|',
                                                    'fontfamily', 'fontsize', '|',
                                                    'bold', 'italic', 'underline', '|',
                                                    'alignment', '|',
                                                    'fontColor', 'fontBackgroundColor', '|',
                                                    'bulletedList', 'numberedList', 'todoList', '|',
                                                    'code', 'codeBlock', '|',
                                                    'undo', 'redo'
                                                ],
                                                removeButtons: 'Subscript,Superscript',
                                                // height: 350,
                                            },
                                        }}
                                        data={subTitle}
                                        onBlur={(event, editor) => {
                                            const data = editor.getData()
                                            subTitleRef.current = data
                                        }}
                                    />
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        // label=""
                                        name="titleMenuList"
                                        style={{ width: '100%' }}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        // label=""
                                        name="subTitleMenuList"
                                        style={{ width: '100%' }}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        // label=""
                                        name="serviceAstrologicalTitle"
                                        style={{ width: '100%' }}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={{
                                            toolbar: {
                                                items: [
                                                    'heading', '|',
                                                    'fontfamily', 'fontsize', '|',
                                                    'bold', 'italic', 'underline', '|',
                                                    'alignment', '|',
                                                    'fontColor', 'fontBackgroundColor', '|',
                                                    'bulletedList', 'numberedList', 'todoList', '|',
                                                    'code', 'codeBlock', '|',
                                                    'undo', 'redo'
                                                ],
                                                removeButtons: 'Subscript,Superscript',
                                                // height: 350,
                                            },
                                        }}
                                        data={serviceAstrologicalSubTitle}
                                        onBlur={(event, editor) => {
                                            const data = editor.getData()
                                            serviceAstrologicalSubTitlRef.current = data
                                        }}
                                    />
                                </Col>

                                <Col span={12}>
                                    <div style={{ display: "grid" }}>
                                        {/* <label style={{ paddingBottom: 6 }}></label> */}
                                        {videoIntroURL?.videoUrl ?
                                            <div style={{ backgroundColor: "black", borderRadius: 8 }}>
                                                <Video
                                                    url={videoIntroURL.videoUrl}
                                                    title={""}
                                                    height={"100%"}
                                                    width={"100%"}
                                                />
                                            </div>
                                            :
                                            <img
                                                style={{ width: "100%", borderRadius: 8 }}
                                                src={`./assets/images/default/df-vdo.png`}
                                            />
                                        }
                                        <div style={{ paddingTop: 12 }}>
                                            <Upload
                                                {...optionVideoIntro}
                                                accept='.mp4'
                                                style={{ width: "100%" }}
                                                maxCount={1}
                                                showUploadList={{ showRemoveIcon: false }}
                                            >
                                                <Button
                                                    type="default"
                                                    style={{ width: "100%" }}
                                                    icon={videoIntroURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                    onClick={() => audioReboundRef.current.play()}
                                                >อัพโหลดวิดีโอ</Button>
                                            </Upload>
                                        </div>
                                    </div>
                                </Col>

                                <Col span={24}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Button
                                            style={{ float: 'right', width: 70 }}
                                            type="primary"
                                            onClick={() => {
                                                audioReboundRef.current.play()
                                                form.submit()
                                            }}
                                        >
                                            บันทึก
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                : 
                <LoadingPage loading={loading} />
            }
            <audio ref={audioReboundRef}>
				<source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
				Your browser does not support the audio element.
			</audio>
        </>
    )
}
