/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Pagination
} from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from '../../../../common/mamager/ScreenManager'
import { getUserCommentAllFetch } from '../../API'
import parse from 'html-react-parser'
import LoadingPage from '../../../../common/components/loading/page'

export default function UserComment() {

    const { width } = useDimensions()
    const [loading, setLoading] = useState(false)

    const [page, setPage] = useState({
        minValue: 0,
        maxValue: 2
    })

    const [userCommentData, setUserCommentData] = useState()

    const getUserCommentAll = async (title, ep) => {
        let result = await getUserCommentAllFetch()
        setUserCommentData(result)
    }

    const getRequestBaseApi = async () => {
        setLoading(true)
        await getUserCommentAll()
        setLoading(false)
    }

    const handleChange = value => {
        if (value <= 1) {
            setPage({
                minValue: 0,
                maxValue: 2
            });
        } else {
            setPage({
                minValue: (value - 1) * 2,
                maxValue: value * 2
            })
        }
    }

    useEffect(() => {
        getRequestBaseApi()
    }, [])

    // console.log("userCommentData : ", userCommentData)

    return (
        <>
            {!loading ?
                <div style={{ textAlign: "center" }}>
                    <div style={{ paddingBottom: 12 }}>
                        <label style={{ fontSize: 22, fontWeight: 600 }}>สิ่งที่ลูกค้ากล่าวถึงเรา</label>
                    </div>

                    <div
                        style={{
                            backgroundColor: "#EEEEEE",
                            padding: 12
                        }}
                    >
                        <Row
                            style={{
                                paddingLeft: width > 900 ? "10%" : 0,
                                paddingRight: width > 900 ? "10%" : 0
                            }}
                        >
                            {userCommentData
                                ?.slice(page.minValue, page.maxValue)
                                ?.map((item) => {
                                    return (
                                        <Col xs={24} md={12} xl={12} style={{ padding: 12 }}>
                                            <div style={{ backgroundColor: "white", padding: 12, borderRadius: 8 }}>
                                                <div
                                                    style={{
                                                        border: "2px dotted #D1D1D1",
                                                        borderRadius: 8,
                                                        paddingTop: 1, 
                                                        paddingBottom: 12, 
                                                        paddingRight: 12, 
                                                        paddingLeft: 12,
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <div style={{
                                                        display: "flex",
                                                        alignItems: "flex-start",
                                                        justifyContent: "center"
                                                    }}>
                                                        <div>
                                                            <Icon icon="oi:double-quote-serif-left" style={{ color: "#FF7F00", width: 20, height: 20, marginTop: 15 }} />
                                                        </div>
                                                        <label style={{ paddingLeft: 8, paddingRight: 8 }}>{parse(item?.massage)}</label>
                                                        <div>
                                                            <Icon icon="oi:double-quote-serif-right" style={{ color: "#FF7F00", width: 20, height: 20, marginTop: 15 }} />
                                                        </div>
                                                    </div>

                                                    <div style={{ paddingTop: 0 }}>
                                                        <img
                                                            src={`${item?.user?.imageProfile}`}
                                                            style={{ height: 60, borderRadius: 50 }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })}

                            <Col span={24} style={{ paddingTop: 10 }}>
                                <center>
                                    <Pagination
                                        defaultCurrent={1}
                                        defaultPageSize={2}
                                        onChange={handleChange}
                                        total={userCommentData?.length ?? 0}
                                    />
                                </center>
                            </Col>
                        </Row>
                    </div>
                </div>
                : 
                <LoadingPage loading={loading} />
            }
        </>
    )
}
