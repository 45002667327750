import './fonts.css'

const themeAntd = {
    colorPrimary: "#FF7F00",
    fontSize: 20,
    fontFamily: "THSarabunPSK, Roboto",

    rateStarColor: "white", // สีของดาวที่ยังไม่ได้เลือก
    rateStarSelectedColor: "#FF7F00", // สีของดาวที่ถูกเลือก
    rateStarSelectedBg: "transparent", // สีพื้นหลังของดาวที่ถูกเลือก
}

export default themeAntd