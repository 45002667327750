/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    DatePicker,
    Popconfirm,
    Upload
} from 'antd'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { apiServerUrl, serverUrl } from '../../../../constants'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import {
    deleteAnnounceByIdFetch,
    getAnnounceBySearchFetch,
    insertAnnounceFetch,
    updateAnnounceByIdFetch
} from '../../API'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'

const { RangePicker } = DatePicker;

const columns = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "10%",
    },
    {
        title: 'ภาพประกาศ',
        width: "15%",
        render: (text, record) => {
            return (
                <>
                    <img
                        style={{ maxHeight: 80, border: "1px solid #EEEEEE" }}
                        src={`${serverUrl}/${record.imgCoverURL}`}
                    />
                </>
            )
        }
    },
    {
        title: 'เรื่องประกาศ',
        dataIndex: 'title',
        width: "40%",
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "10%",
    },
    {
        title: 'วันที่เเก้ไข',
        dataIndex: 'updatedAt',
        width: "10%",
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "15%",
    },
]

const formatDate = "DD/MM/YYYY"

export default function AnnounceManage() {

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)

    const audioReboundRef = useRef()

    const [form] = Form.useForm()
    const [formSearch] = Form.useForm()

    // field external
    const [subTitle, setSubTitle] = useState()
    const subTitleRef = useRef()
    const [imgCoverURL, setImgCoverURL] = useState({
        loading: false,
        imageUrl: null
    })

    const [pageCurrent, setPageCurrent] = useState(1)

    const [modal, setModal] = useState({
        isShow: false,
        title: null
    })

    const optionAnnounceImage = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            name: "admin",
            path: "upload_file/image/announce"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    setImgCoverURL({
                        imageUrl: info.file.response.filePath,
                        loading: false,
                    })
                }
            } else {
                setImgCoverURL({
                    loading: true,
                    imageUrl: imgCoverURL.videoUrl
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const getAnnouncementAll = async (title, startedDate, endDate) => {
        setLoading(true)

        let objSearch = {
            title, startedDate, endDate
        }
        // console.log("getAnnouncementAll : ", objSearch)
        const result = await getAnnounceBySearchFetch(objSearch)
        // console.log("result : ", result)

        let tempList = [];
        result?.map((val, index) => {
            tempList.push({
                index: (index + 1),
                imgCoverURL: val.imgCoverURL,
                title: val.title,
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            style={{
                                width: 35,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: 'white',
                                borderRadius: 50
                            }}
                            onClick={() => {
                                audioReboundRef?.current?.play()
                                form.setFieldsValue({
                                    announceId: val.announceId,
                                    title: val.title,
                                    link: val.link,
                                    updatedAt: val.updatedAt,
                                    createdAt: val.createdAt,
                                })
                                setImgCoverURL({
                                    loading: false,
                                    imageUrl: val.imgCoverURL
                                })
                                setSubTitle(val.subTitle ?? "")
                                subTitleRef.current = val.subTitle ?? ""
                                setModal({ isShow: true, title: "edit" })
                            }}
                        >
                            <div style={{ marginTop: 0, marginLeft: -8 }}>
                                <Icon icon="typcn:edit" style={{ color: "white", width: 20, height: 20 }} />
                            </div>
                        </Button>{"  "}

                        <Popconfirm
                            title="คุณยืนยันลบหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                await handleAnnounceDelete(val.announceId)

                                // reload
                                await getAnnouncementAll(null, null, null)
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                danger
                                type="primary"
                                style={{
                                    width: 35,
                                    borderRadius: 50
                                }}
                                onClick={() => audioReboundRef?.current?.play()}
                            >
                                <div style={{ marginTop: 0, marginLeft: -8 }}>
                                    <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 20, height: 20 }} />
                                </div>
                            </Button>
                        </Popconfirm>
                    </>
            });
        });
        setList(tempList)

        setLoading(false)
    }

    const onFinish = async (values) => {
        let obj = {
            announceId: values.announceId ? values.announceId : null,
            title: values.title ? values.title : null,
            subTitle: subTitleRef.current ? subTitleRef.current : null,
            imgCoverURL: imgCoverURL.imageUrl ? imgCoverURL.imageUrl : null,
            link: values.link ? values.link : null,
        }
        // console.log("onFinish : ", obj)
        if (modal.title === "add") {
            const result = await insertAnnounceFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'สร้างสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง')
            }
        } else if (modal.title === "edit") {
            const result = await updateAnnounceByIdFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'เเก้ไขสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        getAnnouncementAll(null, null, null)

        // set default
        setFormDefault()
    }

    const onSearchFinish = async (values) => {
        let title = values?.title ? values.title : null
        let startedDate = values?.dateRange ? values.dateRange[0] : null
        let endDate = values?.dateRange ? values.dateRange[1] : null

        // let objSearch = {
        //     name, startDate, endDate
        // }
        // console.log("onSearchFinish : ", objSearch)
        await getAnnouncementAll(title, startedDate, endDate)
    }

    const handleAnnounceDelete = async (id) => {
        const result = await deleteAnnounceByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const setFormDefault = () => {
        form.resetFields()

        setSubTitle("")

        subTitleRef.current = ""

        setModal({
            isShow: false,
            title: null
        })
    }

    const detectSubTitle = (rules, value) => {
        if (subTitleRef.current === "") {
            return Promise.reject(new Error('กรุณากรอกรายละเอียดข่าวรประกาศ'))
        } else {
            return Promise.resolve()
        }
    }

    useEffect(() => {
        getAnnouncementAll(null, null, null)
    }, [])

    return (
        <Row>
            <Col span={12}>
                <label>จัดการประกาศ</label>
            </Col>
            <Col span={12} style={{ paddingBottom: 20 }}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ชื่อข่าวสาร"
                                name="title"
                                style={{ width: '100%' }}
                            >
                                <Input />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ห้วงวันที่สร้าง" name="dateRange"
                                style={{ width: '100%' }}
                            >
                                <RangePicker />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10, marginTop: 3 }}>
                            <Button
                                style={{ float: 'right', width: 70 }}
                                type="primary"
                                onClick={() => {
                                    audioReboundRef?.current?.play()
                                    formSearch.submit()
                                }}
                            >
                                ค้นหา
                            </Button>
                        </div>
                    </div>
                </Form>
            </Col>

            <Col span={24} style={{ paddingBottom: 20 }}>
                <Button
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={() => {
                        audioReboundRef?.current?.play()
                        form.resetFields()
                        setImgCoverURL({
                            loading: false,
                            imageUrl: null
                        })
                        setSubTitle("")
                        subTitleRef.current = ""

                        setModal({
                            isShow: true,
                            title: "add"
                        })
                    }}
                >เพิ่มรายการ</Button>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrent,
                        pageSize: 10,
                        total: list.length
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modal.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}</label></strong>}
                visible={modal.isShow}
                onCancel={() => {

                    // default
                    setFormDefault();
                }}
                width={1000}
                onOk={() => {
                    form.submit();
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }} onClick={() =>  audioReboundRef?.current?.play()}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }} onClick={() =>  audioReboundRef?.current?.play()}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <Row gutter={[24, 0]}>
                        <Col span={12}>
                            <Form.Item name="announceId" style={{ display: "none" }}>
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="title"
                                label="หัวข้อประกาศ"
                                rules={[{ required: true, message: 'กรุณากรอกหัวข้อประกาศ' }]}
                            >
                                <Input style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="link"
                                label="ลิงค์"
                            >
                                <Input.TextArea
                                    style={{ width: "100%" }}
                                    autoSize={{ minRows: 1, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="subTitle"
                                label="รายละเอียดข่าวรประกาศ"
                                rules={[{ required: true, validator: detectSubTitle }]}
                            >
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={{
                                        toolbar: {
                                            items: [
                                                'heading', '|',
                                                'fontfamily', 'fontsize', '|',
                                                'bold', 'italic', 'underline', '|',
                                                'alignment', '|',
                                                'fontColor', 'fontBackgroundColor', '|',
                                                'bulletedList', 'numberedList', 'todoList', '|',
                                                'code', 'codeBlock', '|',
                                                'undo', 'redo'
                                            ],
                                            removeButtons: 'Subscript,Superscript',
                                            // height: 350,
                                        },
                                    }}
                                    data={subTitle}
                                    onBlur={(event, editor) => {
                                        const data = editor.getData()
                                        subTitleRef.current = data
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div style={{ display: "grid" }}>
                                <label style={{ paddingBottom: 6 }}>ปกรูปภาพ</label>
                                {imgCoverURL?.imageUrl ?
                                    <img
                                        style={{ borderRadius: 8, maxHeight: 207, border: "1px solid #EEEEEE" }}
                                        src={`${serverUrl}/${imgCoverURL.imageUrl}`}
                                    />
                                    :
                                    <img
                                        style={{ width: 207, borderRadius: 8 }}
                                        src={`./assets/images/default/df-img.png`}
                                    />
                                }
                                <div style={{ paddingTop: 12 }}>
                                    <Upload
                                        {...optionAnnounceImage}
                                        accept='image/jpeg, image/png, image/jfif'
                                        style={{ width: "100%" }}
                                        maxCount={1}
                                        showUploadList={{ showRemoveIcon: false }}
                                    >
                                        <Button
                                            type="default"
                                            style={{ width: "100%" }}
                                            icon={imgCoverURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                            onClick={() => audioReboundRef?.current?.play()}
                                        // disabled={false}
                                        >อัพโหลดรูปภาพ</Button>
                                    </Upload>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <audio ref={audioReboundRef}>
				<source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
				Your browser does not support the audio element.
			</audio>
        </Row>
    )
}
