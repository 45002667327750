import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getPageAboutMeFetch = async () => {
    try {
        const result = await httpClient.get(server.GET_PAGE_ABOUT_ME_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updatePageAboutUsByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_PAGE_ABOUT_US_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getPageAboutMeFetch,

    // insert

    // update
    updatePageAboutUsByIdFetch,

    // delete

}
