import { server } from "../../../../../constants"
import { httpClient } from "../../../../../utils/HttpClient"

const getLoadingAllFetch = async (value) => { // Done
    try {
        const result = await httpClient.get(server.GET_LOADING_ALL_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getLoadingBySearchFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.GET_LOADING_BY_SEARCH_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertLoadingFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_LOADING_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateLoadingByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_LOADING_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteLoadingByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(server.DELETE_LOADING_BY_ID_URL + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getLoadingAllFetch,
    getLoadingBySearchFetch,

    // insert
    insertLoadingFetch,

    // update
    updateLoadingByIdFetch,

    // delete
    deleteLoadingByIdFetch,
}