import { server } from "../../../../../constants"
import { httpClient } from "../../../../../utils/HttpClient"

const getCertificateFetch = async (value) => {
    try {
        const result = await httpClient.get(server.GET_CERTIFICATE_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const getCertificateTemplateFetch = async (value) => {
    try {
        const result = await httpClient.get(server.GET_CERTIFICATE_TEMPLATE_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const getCertificateLevelFetch = async (value) => {
    try {
        const result = await httpClient.get(server.GET_CERTIFICATE_LEVEL_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const updateCertificateByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_CERTIFICATE_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) {}
}

export {
    // get
    getCertificateFetch,
    getCertificateTemplateFetch,
    getCertificateLevelFetch,

    // insert

    // update
    updateCertificateByIdFetch,

    // delete

}