/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    DatePicker,
    Popconfirm,
    Upload
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { apiServerUrl, serverUrl } from '../../../../constants'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import { deleteContactUsChannelByIdFetch, getContactUsChannelBySearchFetch, insertContactUsChannelFetch, updateContactUsChannelByIdFetch } from './API'
import parse from 'html-react-parser'
import { deleteContactChannelByIdFetch, getContactChannelAllFetch, insertContactChannelFetch, updateContactChannelByIdFetch } from '../../../contract/API'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'

const { RangePicker } = DatePicker;

const columns = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "10%",
    },
    {
        title: 'ภาพ Icon',
        width: "15%",
        render: (text, record) => {
            return (
                <>
                    <Icon icon={record.imageURL} style={{ color: "black", width: 40, height: 40 }} />
                </>
            )
        }
    },
    {
        title: 'ช่องทางการติดต่อ',
        dataIndex: 'title',
        width: "30%",
        render: (text, record) => {
            return (
                <>
                    <label>{record?.title ? parse(record.title) : []}</label>
                </>
            )
        }
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "15%",
    },
    {
        title: 'วันที่เเก้ไข',
        dataIndex: 'updatedAt',
        width: "15%",
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "15%",
    },
]

const columns2 = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "10%",
    },
    {
        title: 'ภาพ Icon',
        width: "15%",
        render: (text, record) => {
            return (
                <img
                    src={`${serverUrl}/${record.imageURL}`}
                    style={{ width: 60, height: 60 }}
                />
            )
        }
    },
    {
        title: 'ลิ้งค์ URL',
        dataIndex: 'title',
        width: "30%",
        render: (text, record) => {
            return (
                <>
                    <label>{record?.link}</label>
                </>
            )
        }
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "15%",
    },
    {
        title: 'วันที่เเก้ไข',
        dataIndex: 'updatedAt',
        width: "15%",
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "15%",
    },
]

const formatDate = "DD/MM/YYYY"

export default function ContactUsChannelManage() {
    const [list, setList] = useState([])
    const [list2, setList2] = useState([])

    const [loading, setLoading] = useState(true)
    const [loading2, setLoading2] = useState(true)

    const [form] = Form.useForm()
    const [form2] = Form.useForm()
    const [formSearch] = Form.useForm()

    // field external
    const [title, setTitle] = useState()
    const titleRef = useRef()

    const audioReboundRef = useRef()


    const [pageCurrent, setPageCurrent] = useState(1)

    const [iconSelect, setIconSelect] = useState("")

    const [modal, setModal] = useState({
        isShow: false,
        title: null
    })

    const [modal2, setModal2] = useState({
        isShow: false,
        title: null
    })

    const [imgURL, setImgURL] = useState({
        loading: false,
        imageUrl: null
    })

    const optionImage = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            name: "admin",
            path: "upload_file/image/contact_channel"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    setImgURL({
                        imageUrl: info.file.response.filePath,
                        loading: false,
                    })
                }
            } else {
                setImgURL({
                    loading: true,
                    imageUrl: imgURL.imageUrl
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const getContactUsChannelBySearch = async (title, startedDate, endDate) => {
        setLoading(true)

        let objSearch = {
            title, startedDate, endDate
        }
        // console.log("getContactUsChannelBySearchFetch : ", objSearch)
        const result = await getContactUsChannelBySearchFetch(objSearch)
        // console.log("result : ", result)

        let tempList = [];
        result?.map((val, index) => {
            tempList.push({
                index: (index + 1),
                title: val.title,
                imageURL: val.imageURL,
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            style={{
                                width: 35,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: 'white',
                                borderRadius: 50
                            }}
                            onClick={() => {
                                audioReboundRef?.current?.play()
                                form.setFieldsValue({
                                    contactUsChannelId: val.contactUsChannelId,
                                    link: val.link,
                                    imageURL: val.imageURL,
                                })
                                setTitle(val.title ?? "")
                                setIconSelect(val.imageURL ?? "")
                                titleRef.current = val.title ?? ""
                                setModal({ isShow: true, title: "edit" })
                            }}
                        >
                            <div style={{ marginTop: 0, marginLeft: -8 }}>
                                <Icon icon="typcn:edit" style={{ color: "white", width: 20, height: 20 }} />
                            </div>
                        </Button>{"  "}

                        <Popconfirm
                            title="คุณยืนยันลบหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                await handleContactUsChannelDelete(val.contactUsChannelId)

                                // reload
                                await getContactUsChannelBySearch(null, null, null)
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                danger
                                type="primary"
                                style={{
                                    width: 35,
                                    borderRadius: 50
                                }}
                                onClick={() => audioReboundRef?.current?.play()}
                            >
                                <div style={{ marginTop: 0, marginLeft: -8 }}>
                                    <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 20, height: 20 }} />
                                </div>
                            </Button>
                        </Popconfirm>
                    </>
            });
        });
        setList(tempList)

        setLoading(false)
    }

    const getContactChannelAll = async (title, startedDate, endDate) => {
        setLoading(true)

        let objSearch = {
            title, startedDate, endDate
        }
        // console.log("getContactChannelAllFetch : ", objSearch)
        const result = await getContactChannelAllFetch(objSearch)
        // console.log("result : ", result)

        let tempList2 = [];
        result?.map((val, index) => {
            tempList2.push({
                index: (index + 1),
                link: val.link,
                imageURL: val.imageURL,
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            style={{
                                width: 35,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: 'white',
                                borderRadius: 50
                            }}
                            onClick={() => {
                                audioReboundRef?.current?.play()
                                form2.setFieldsValue({
                                    contactChannelId: val.contactChannelId,
                                    link: val.link,
                                })

                                setImgURL({
                                    loading: false,
                                    imageUrl: val.imageURL
                                })

                                setModal2({ isShow: true, title: "edit" })
                            }}
                        >
                            <div style={{ marginTop: 0, marginLeft: -8 }}>
                                <Icon icon="typcn:edit" style={{ color: "white", width: 20, height: 20 }} />
                            </div>
                        </Button>{"  "}

                        <Popconfirm
                            title="คุณยืนยันลบหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                await handleContactChannelDelete(val.contactChannelId)

                                // reload
                                await getContactChannelAll()
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                danger
                                type="primary"
                                style={{
                                    width: 35,
                                    borderRadius: 50
                                }}
                                onClick={() => audioReboundRef?.current?.play()}
                            >
                                <div style={{ marginTop: 0, marginLeft: -8 }}>
                                    <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 20, height: 20 }} />
                                </div>
                            </Button>
                        </Popconfirm>
                    </>
            });
        });
        setList2(tempList2)

        setLoading(false)
    }

    const onFinish = async (values) => {
        let obj = {
            contactUsChannelId: values.contactUsChannelId ? values.contactUsChannelId : null,
            title: titleRef.current ? titleRef.current : null,
            link: values.link ? values.link : null,
            imageURL: values.imageURL ? values.imageURL : null,
        }
        // console.log("onFinish : ", obj)

        if (modal.title === "add") {
            const result = await insertContactUsChannelFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'สร้างสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง')
            }
        } else if (modal.title === "edit") {
            const result = await updateContactUsChannelByIdFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'เเก้ไขสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        getContactUsChannelBySearch(null, null, null)

        // set default
        setFormDefault()
    }

    const onFinish2 = async (values) => {
        let obj = {
            contactChannelId: values.contactChannelId ? values.contactChannelId : null,
            link: values.link ? values.link : null,
            imageURL: imgURL.imageUrl ? imgURL.imageUrl : null
        }
        // console.log("onFinish : ", obj)

        if (modal2.title === "add") {
            const result = await insertContactChannelFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'สร้างสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง')
            }
        } else if (modal2.title === "edit") {
            const result = await updateContactChannelByIdFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'เเก้ไขสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        getContactChannelAll()

        // set default
        setFormDefault2()
    }

    const onSearchFinish = async (values) => {
        let title = values?.title ? values.title : null
        let startedDate = values?.dateRange ? values.dateRange[0] : null
        let endDate = values?.dateRange ? values.dateRange[1] : null
        await getContactUsChannelBySearch(title, startedDate, endDate)
    }

    const handleContactUsChannelDelete = async (id) => {
        const result = await deleteContactUsChannelByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const handleContactChannelDelete = async (id) => {
        const result = await deleteContactChannelByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const setFormDefault = () => {
        form.resetFields()

        setTitle("")
        setIconSelect(null)

        titleRef.current = ""

        setModal({
            isShow: false,
            title: null
        })
    }

    const setFormDefault2 = () => {
        form2.resetFields()

        setImgURL({
            loading: false,
            imageUrl: null
        })

        setModal2({
            isShow: false,
            title: null
        })
    }

    const getBaseApi = async () => {
        await getContactUsChannelBySearch(null, null, null)
        await getContactChannelAll()
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <Row>
            <Col span={8}>
                <label>จัดการช่องทางการติดต่อ</label>
            </Col>
            <Col span={16} style={{ paddingBottom: 20 }}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ช่องทางการติดต่อ"
                                name="title"
                                style={{ width: '100%' }}
                            >
                                <Input />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ห้วงวันที่สร้าง" name="dateRange"
                                style={{ width: '100%' }}
                            >
                                <RangePicker />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10, marginTop: 3 }}>
                            <Button
                                style={{ float: 'right', width: 70 }}
                                type="primary"
                                onClick={() => {
                                    audioReboundRef?.current?.play()
                                    formSearch.submit()}}
                            >
                                ค้นหา
                            </Button>
                        </div>
                    </div>
                </Form>
            </Col>

            <Col span={24} style={{ paddingBottom: 20 }}>
                <Button
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={() => {
                        audioReboundRef?.current?.play()

                        form.resetFields()

                        setTitle("")
                        setIconSelect(null)
                        titleRef.current = ""

                        setModal({
                            isShow: true,
                            title: "add"
                        })
                    }}
                >เพิ่มรายการ</Button>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrent,
                        pageSize: 10,
                        total: list.length
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Col span={24} style={{ paddingBottom: 20, paddingTop: 40 }}>
                <Button
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={() => {
                        audioReboundRef?.current?.play()

                        form2.resetFields()

                        setModal2({
                            isShow: true,
                            title: "add"
                        })
                    }}
                >เพิ่มรายการ</Button>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns2}
                    dataSource={list2}
                    pagination={{
                        current: pageCurrent,
                        pageSize: 10,
                        total: list.length
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modal.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}</label></strong>}
                visible={modal.isShow}
                onCancel={() => {

                    // default
                    setFormDefault()
                }}
                width={700}
                onOk={() => {
                    form.submit()
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }} onClick={() =>  audioReboundRef?.current?.play()}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }} onClick={() =>  audioReboundRef?.current?.play()}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item name="contactUsChannelId" style={{ display: "none" }}>
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="title"
                                label="ช่องทางการติดต่อ"
                            >
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={{
                                        toolbar: {
                                            items: [
                                                'heading', '|',
                                                'fontfamily', 'fontsize', '|',
                                                'bold', 'italic', 'underline', '|',
                                                'alignment', '|',
                                                'fontColor', 'fontBackgroundColor', '|',
                                                'bulletedList', 'numberedList', 'todoList', '|',
                                                'code', 'codeBlock', '|',
                                                'undo', 'redo'
                                            ],
                                            removeButtons: 'Subscript,Superscript',
                                            height: 350,
                                        },
                                    }}
                                    data={title}
                                    onBlur={(event, editor) => {
                                        const data = editor.getData()
                                        titleRef.current = data
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        {/* <Col span={24}>
                            <Form.Item
                                name="link"
                                label="ลิงค์"
                            >
                                <Input style={{ width: "100%" }} />
                            </Form.Item>
                        </Col> */}

                        <Col span={24}>
                            <div style={{ display: "grid" }}>
                                <label style={{ paddingBottom: 6 }}>ภาพ Icon</label>
                                <div style={{ paddingTop: 12 }}>
                                    <Icon icon={iconSelect} style={{ color: "black", width: 60, height: 60 }} />
                                </div>
                                <Form.Item
                                    name="imageURL"
                                    label="คีย์ไอค่อน ( https://icon-sets.iconify.design/ )"
                                    rules={[{ required: true, message: 'กรุณากรอกคีย์ไอค่อน' }]}
                                >
                                    <Input style={{ width: "100%" }} onChange={(e) => setIconSelect(e.target.value)} />
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal
                title={<strong><label className="topic-color-bold">{modal2.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}</label></strong>}
                visible={modal2.isShow}
                onCancel={() => {

                    // default
                    setFormDefault2()
                }}
                width={700}
                onOk={() => {
                    form2.submit()
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }} onClick={() =>  audioReboundRef?.current?.play()}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }} onClick={() =>  audioReboundRef?.current?.play()}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={form2}
                    onFinish={onFinish2}
                >
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item name="contactChannelId" style={{ display: "none" }}>
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="link"
                                label="ลิงค์"
                            >
                                <Input style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <div style={{ display: "grid" }}>
                                <label style={{ paddingBottom: 6 }}></label>
                                {imgURL?.imageUrl ?
                                    <img
                                        style={{ borderRadius: 8, maxWidth: 300, border: "1px solid #EEEEEE" }}
                                        src={`${serverUrl}/${imgURL.imageUrl}`}
                                    />
                                    :
                                    <img
                                        style={{ width: 207, borderRadius: 8 }}
                                        src={`./assets/images/default/df-img.png`}
                                    />
                                }
                                <div style={{ paddingTop: 12 }}>
                                    <Upload
                                        {...optionImage}
                                        accept='image/jpeg, image/png, image/jfif'
                                        style={{ width: "100%" }}
                                        maxCount={1}
                                        showUploadList={{ showRemoveIcon: false }}
                                    >
                                        <Button
                                            type="default"
                                            style={{ width: "100%" }}
                                            icon={imgURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                            onClick={() => audioReboundRef?.current?.play()}
                                        >อัพโหลดรูปภาพ</Button>
                                    </Upload>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <audio ref={audioReboundRef}>
				<source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
				Your browser does not support the audio element.
			</audio>
        </Row>
    )
}
