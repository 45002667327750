/* eslint-disable no-array-constructor */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button,
    Card,
    Col,
    Image,
    List,
    Modal,
    Row,
    Select,
    // Spin,
    Form,
    TimePicker,
    Upload,
    Divider,
    Popover
} from 'antd'
import { useState, useEffect, useRef } from 'react'
import ReactPlayer from 'react-player'
import locale from "antd/es/date-picker/locale/de_DE"
import { Icon } from '@iconify/react'
import {
    useNavigate,
    // useLocation
} from 'react-router-dom'
import * as moment from 'moment'
import { Content } from 'antd/lib/layout/layout'
import {
    LoadingOutlined,
    UploadOutlined
} from '@ant-design/icons'
import firebase from 'firebase/app'
import '../payment/css/index.css'
import { apiServerUrl, imgDefaultUrl, serverUrl } from '../../constants'
import { useDimensions } from '../../common/mamager/ScreenManager'
import Header from '../../common/components/header'
import LoadingPage from '../../common/components/loading/page'
import Footer from '../../common/components/footer'
import { detectSignIn } from '../../common/mamager/DetectManager'
import { Notification } from '../../common/components/notification'
import { useDispatch, useSelector } from 'react-redux'
import { setBasketOrderStateToSuccess } from '../../actions/basketOrder.action'
import { getPaymentAccountFetch, insertPaymentOrderFetch } from './API'
import { v4 as uuidv4 } from 'uuid';
import Video from '../../common/components/video'
import { getUsageByIdFetch } from '../erp/manage/usage/API'

const { Option } = Select

export default function Payment(props) {
    const [form] = Form.useForm()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)

    const audioReboundRef = useRef()

    const { resultBasketOrder } = useSelector(({ basketOrderReducer }) => basketOrderReducer)

    const [paymentmethodLoading, setPaymentmethodLoading] = useState(false)

    const [imgTransferSlipURL, setImgTransferSlipURL] = useState({
        loading: false,
        imageUrl: null
    })

    // const location = useLocation()
    // const params = new URLSearchParams(location.search)
    // const courseId = params.get("course-id")

    const [isPopoverVisible, setIsPopoverVisible] = useState(false)
    const [isVideoPlaying, setIsVideoPlaying] = useState(false)

    useEffect(() => {
        if (!isPopoverVisible) {
            setIsVideoPlaying(false)
        }
    }, [isPopoverVisible])

    const handlePopoverVisibleChange = (visible) => {
        if (visible) {
            setIsPopoverVisible(true)
        } else {
            setTimeout(() => {
                setIsPopoverVisible(false)
            }, 500)
        }
    }

    const [pamentTransferModal, setPamentTransferModal] = useState({
        isShow: false,
        title: null,
    })

    // const [course, setCourse] = useState(null)
    const [paymentAccount, setPaymentAccount] = useState(null)

    const dispatch = useDispatch()

    const [day, setDay] = useState([])
    const [month, setMonth] = useState([])
    const [year, setYear] = useState([])

    const [uid, setUid] = useState(null)

    const optionTransferSlipImage = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            name: (uid ? uid : "user"),
            path: "upload_file/image/payment/transfer_slip"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    setImgTransferSlipURL({
                        imageUrl: info.file.response.filePath,
                        loading: false,
                    })
                }
            } else {
                setImgTransferSlipURL({
                    loading: true,
                    imageUrl: imgTransferSlipURL.imageUrl
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดหลักฐานการชำระเงินสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "ไม่สามารถอัพโหลดหลักฐานการชำระเงินได้ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const { width } = useDimensions()

    const genFormatPrice = (price) => {
        return Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(price))
    }

    const getCourseById = async () => {
        // const resultCourse = await getCourseByIdFetch(courseId, language); // params is courseId, language
        // setCourse(resultCourse)

        // if (resultCourse) {
        // calculateNetPrice(resultCourse)

        // const resultCourseOwner = await getCourseOwnerFetch(resultCourse.uid)
        // setCourseOwner(resultCourseOwner)

        // setTimeLeft(calculateDiscountTimeLeft(resultCourse.discountDateEnd))
        // }
    }

    const detectCourseHasBeenPurchased = async (uid) => {
        // let obj = {
        //     learnerId: uid,
        //     courseId: courseId,
        //     isActive: 1,
        // }
        // const resultDetectCourseHasBeenPurchased = await getDetectCourseHasBeenPurchasedFetch(obj)

        // const resultPaymentOrder = await getPaymentOrderDetectReBuyByUserId(uid, courseId)

        // if (resultDetectCourseHasBeenPurchased && resultPaymentOrder?.isPaid) {
        //     let calculateIsExpire = null
        //     if (resultDetectCourseHasBeenPurchased.endLearnDate) {
        //         calculateIsExpire = calculateDetectCourseExpire(resultDetectCourseHasBeenPurchased.endLearnDate)
        //         if (calculateIsExpire) {
        //             if (calculateIsExpire.isExpire) {
        //                 setIsExpire(true)
        //             } else {
        //                 redirectPageClassroomCourseStudy()
        //             }
        //         } else {
        //             redirectPageClassroomCourseStudy()
        //         }
        //     }

        //     if (!calculateIsExpire) {
        //         redirectPageClassroomCourseStudy()
        //     }
        // }
    }

    const getDay = () => {
        let tmpDay = [];
        for (let i = 1; i <= 31; i++) {
            tmpDay.push({
                label: i,
                value: i
            })
        }
        setDay(tmpDay)
    }

    const getMonth = () => {
        let tmpMonth = [
            { label: "มกราคม", value: 1 },
            { label: "กุมภาพันธ์", value: 2 },
            { label: "มีนาคม", value: 3 },
            { label: "เมษายน", value: 4 },
            { label: "พฤษภาคม", value: 5 },
            { label: "มิถุนายน", value: 6 },
            { label: "กรกฎาคม", value: 7 },
            { label: "สิงหาคม", value: 8 },
            { label: "กันยายน", value: 9 },
            { label: "ตุลาคม", value: 10 },
            { label: "พฤศจิกายน", value: 11 },
            { label: "ธันวาคม", value: 12 },
        ]
        setMonth(tmpMonth)
    }

    const getYear = () => {
        let tmpYear = []
        let a = moment().format('YYYY')
        let yearCurrent = (Number(a) + 543) - 1
        for (let i = yearCurrent; i > (yearCurrent - 100); i--) {
            tmpYear.push({
                label: i + 1,
                value: i + 1
            })
        }
        setYear(tmpYear)
    }

    const genSumCoursePrice = () => {
        let price = 0
        let netPrice = 0

        resultBasketOrder?.map((item) => {
            price = price + item?.price
        })

        return {
            price,
            netPrice: price + 0 // ...
        }
    }

    // console.log("props.users : ", props.user.uid)
    // console.log("resultBasketOrder : ", resultBasketOrder)

    const detectProductCategory = (item) => {
        if (item.courseId && item.courseTypeId === 1) { // course
            return 1
        }

        if (item.predictionId) {  // prediction
            return 2
        }

        if (item.courseId && item.courseTypeId === 2) { // exam certificate
            return 3
        }

        return null
    }

    const onFinish = async (values) => {
        let orderList = new Array()
        if (imgTransferSlipURL.imageUrl !== null) {
            let day = values.day
            let month = values.month
            let year = values.year
            let date = (Number(year) - 543) + "/" + month + "/" + day
            let time = moment(String(values.time)).format('HH:mm')
            let dateTimeTransfer = moment(date + " " + time).format('YYYY-MM-DD HH:mm:ss')
            let paymentOrderGroupId = uuidv4()

            resultBasketOrder?.map((item, index) => {
                if (index === 0) {
                    orderList.push({
                        orderName:
                            // (resultBasketOrder?.length > 0 ?
                            //     item.title
                            //     :
                            `คำสั่งซื้อจำนวน ${resultBasketOrder?.length} รายการ`,
                        // ),
                        uid: props.user.uid,
                        netPrice: Number(genSumCoursePrice().netPrice),
                        paymentStatusId: 2,
                        productCategoryId: null, // parent is don't have payment type
                        transferSlipImageURL: imgTransferSlipURL.imageUrl,
                        dateTimeTransfer,
                        isPaymentOrderParent: true,
                        paymentOrderGroupId,
                        courseId: null, // item.courseId,
                        predictionId: null, // item.predictionId,
                    })
                }

                orderList.push({
                    orderName: item.title,
                    uid: props.user.uid,
                    netPrice: item.price,
                    paymentStatusId: 2,
                    productCategoryId: detectProductCategory(item),
                    transferSlipImageURL: null,
                    dateTimeTransfer,
                    isPaymentOrderParent: false,
                    paymentOrderGroupId,
                    courseId: item.courseId,
                    predictionId: item.predictionId,
                })
            })
            // console.log("orderList : ", orderList)

            const result = await insertPaymentOrderFetch(orderList)
            if (result.isSuccess) {
                setFormDefault()
                dispatch(setBasketOrderStateToSuccess([]))
                Notification('success', 'ส่งหลักฐานการชำระเงินสำเร็จ')
                navigate("/account?tab=paymentTransaction")

            } else {
                Notification('error', 'ไม่สามารถส่งหลักฐานการชำระเงินสำเร็จได้ กรุณาลองใหม่อีกครั้ง')
            }
        } else {
            Notification("warning", "เเจ้งเตือน!", 'กรุณาอัพโหลด หลักฐานการโอนเงิน')
        }
    }

    const setFormDefault = (type) => {
        form.resetFields()

        // ser default image
        setPamentTransferModal({
            isShow: false,
            title: null,
        })
    }

    useEffect(() => {
        if (resultBasketOrder?.length <= 0) {
            navigate("/course")
        }
    }, [])


    const genOrderedList = () => {
        return (
            <Card
                bordered
                title={
                    <label style={{ paddingBottom: 0, marginBottom: 0, fontWeight: 100 }}>รายการสั่งซื้อ</label>
                }
                style={{
                    width: '100%',
                    borderRadius: 10,
                    boxShadow: "1px 1px 12px 5px rgba(208, 216, 243, 0.4)",
                    height: '100%'
                }}
            >
                <Row>
                    <Col span={24} style={{ paddingBottom: 9 }}>
                        {resultBasketOrder?.map((item, index) => {
                            return (
                                <Row>
                                    <Col
                                        xs={24} xl={6}
                                        style={{ display: 'flex', alignItems: "flex-start", justifyContent: width > 1400 ? 'left' : 'center', paddingBottom: 24 }}
                                    >
                                        <img
                                            src={`${serverUrl}/${item?.imgCoverURL}`}
                                            style={{ width: "100%", top: 0, left: 0, borderRadius: 8 }}
                                        />
                                    </Col>

                                    <Col xs={24} xl={18} style={{ paddingLeft: width < 1200 ? 0 : 24 }}>
                                        <Row>
                                            <Col span={24}>
                                                <label className='payment-order-title' style={{ fontWeight: 600 }}>{item?.title}</label>
                                            </Col>
                                            <Col span={24}>
                                                <label className='payment-order-subtitle' >{item?.subTitle}</label>
                                            </Col>
                                            <Col span={24}>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: 6 }}>
                                                    <label style={{}}>ราคา {Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format((item?.price))} บาท</label>

                                                    <Button
                                                        type="primary"
                                                        size="small"
                                                        onClick={() => {
                                                            audioReboundRef.current.play()
                                                            let tmpArr = resultBasketOrder.filter(fill => fill.courseId !== item.courseId)
                                                            dispatch(setBasketOrderStateToSuccess(tmpArr))
                                                            if (resultBasketOrder?.length === 1) {
                                                                navigate("/course")
                                                            }
                                                        }}
                                                    >
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                                                            <Icon icon="ph:trash-bold" style={{ color: "white", height: 16, width: 16, cursor: "pointer" }} />
                                                        </div>
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {resultBasketOrder?.length !== index + 1 ?
                                        <Col span={24}>
                                            <Divider style={{ marginTop: 11, marginBottom: 15 }} />
                                        </Col>
                                        : []
                                    }
                                </Row>
                            )
                        })}
                    </Col>

                    <Col span={12} style={{ borderTop: "2px solid #FF7F00" }}>
                        <div style={{ paddingTop: 15 }}>
                            <label style={{ paddingBottom: 0, marginBottom: 0, paddingRight: 10, fontSize: 18, float: "right" }}>
                                {resultBasketOrder.length > 0 ? "ราคาคอร์สทั้งหมด" : "ราคาคอร์ส"}
                            </label>
                        </div>
                    </Col>
                    <Col span={12} style={{ borderTop: "2px solid #FF7F00" }}>
                        <div style={{ paddingTop: 15 }}>
                            <label style={{ paddingBottom: 0, marginBottom: 0, fontSize: 18, fontWeight: "bold" }}>
                                {Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(genSumCoursePrice().price))}
                            </label>
                            <label style={{ paddingBottom: 0, marginBottom: 0, paddingLeft: 5, fontSize: 18 }}>บาท</label>
                        </div>
                    </Col>

                    <Col span={12}>
                        <div>
                            <label style={{ paddingBottom: 0, marginBottom: 0, paddingRight: 10, fontSize: 18, color: "#FF7F00", float: "right" }}>ราคาสุทธิ </label>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div>
                            <label style={{ paddingBottom: 0, marginBottom: 0, fontSize: 18, fontWeight: "bold", color: "#FF7F00" }}>
                                {Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(genSumCoursePrice().netPrice))}
                            </label>
                            <label style={{ paddingBottom: 0, marginBottom: 0, paddingLeft: 5, fontSize: 18, color: "#FF7F00" }}>บาท</label>
                        </div>
                    </Col>
                </Row>
            </Card>

        )
    }

    const getPaymentAccount = async () => {
        const result = await getPaymentAccountFetch()
        // console.log("getPaymentAccount : ", result)
        setPaymentAccount(result)
    }

    const genPaymentMethod = () => {
        return (

            <Card
                bordered
                title={<label style={{ paddingBottom: 0, marginBottom: 0, fontWeight: 100 }}>ช่องทางชำระเงิน</label>}
                style={{
                    width: '100%',
                    borderRadius: 10,
                    boxShadow: "1px 1px 16px 3px rgba(208, 216, 243, 0.6)",
                    height: '100%'
                }}
            >
                <center>
                    <List style={{ width: "80%" }}>
                        {/* โอนเงิน */}
                        <Popover
                            content={
                                <div style={{ display: "grid" }}>
                                    <div style={{ paddingBottom: 12 }}>
                                        <label>{usage?.detail}</label>
                                    </div>
                                    <div style={{ paddingBottom: 12 }}>
                                        <div style={{ backgroundColor: "black" }}>
                                            <ReactPlayer
                                                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                                onContextMenu={e => e.preventDefault()}
                                                url={`${serverUrl}/${usage?.videoUrl}`}
                                                controls
                                                playing={isVideoPlaying}
                                                width={"100%"}
                                                height={200}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            title={usage?.title}
                            visible={isPopoverVisible}
                            onVisibleChange={(visible) => {
                                setIsVideoPlaying(visible)
                                handlePopoverVisibleChange(visible)
                            }}
                        >
                            <List.Item
                                className={"payment-method"}
                                style={{
                                    height: width <= 577 ? 'auto' : 80,
                                    borderRadius: 10,
                                    borderLeft: '2px solid #FF7F00',
                                    borderRight: '2px solid #FF7F00',
                                    borderBottom: '2px solid #FF7F00',
                                    borderTop: '2px solid #FF7F00',
                                    boxShadow: "1px 1px 16px 3px rgba(208, 216, 243, 0.8)",
                                }}
                                onClick={async () => {
                                    const isLogin = await detectSignIn()
                                    if (isLogin) {

                                        // let result = await getPaymentOrderDetectReBuyByUserId(uid, courseId)
                                        // console.log("getPaymentOrderDetectReBuyByUserId : ", result)
                                        // if (result?.isPaid && !isExpire) {
                                        //     Notification("warning", "เเจ้งเตือน!", 'ไม่สามารถซื้อคอร์สนี้ได้ เนื่องจากท่านได้ซื้อคอร์สนี้แล้ว หรืออยู่ในช่วงตรวจสอบชำระเงินอยู่');
                                        // } else {
                                        //     if (!creditCardLoading) {
                                        //         setPaymentmethodLoading(true)

                                        setPamentTransferModal({
                                            isShow: true,
                                            title: "โอนเงินผ่านบัญชีธนาคาร",
                                        })

                                        form.resetFields();
                                        setImgTransferSlipURL({
                                            loading: false,
                                            imageUrl: null
                                        })

                                        //         setPaymentmethodLoading(false)
                                        //     } else {
                                        //         Notification("warning", "เเจ้งเตือน!", 'ขณะนี้ อยู่ระหว่างการดำเนินการชำระเงิน')
                                        //     }
                                        // }
                                    } else {
                                        Notification("warning", "เเจ้งเตือน!", 'กรุณาเข้าสู่ระบบก่อนทำการซื้อคอร์ส !')
                                    }
                                }}
                            >
                                <Row style={{ width: "100%", paddingLeft: 18, paddingRight: 18 }}>
                                    <Col
                                        span={width < 1400 ? 24 : 12}
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: width > 1400 ? 'left' : 'center' }}
                                    >
                                        <label style={{ paddingBottom: 0, marginBottom: 0, cursor: "pointer" }}>โอนเงิน</label>
                                    </Col>
                                    <Col
                                        span={width < 1400 ? 24 : 12}
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: width > 1400 ? 'right' : 'center' }}
                                    >
                                        <Image
                                            height={30}
                                            width={"auto"}
                                            style={{ borderRadius: 10 }}
                                            preview={false}
                                            src="error"
                                            fallback={`${serverUrl}/${paymentAccount?.bank_category?.bankLogoURL}`}
                                        />
                                    </Col>
                                </Row>
                            </List.Item>
                        </Popover>
                    </List>
                </center >
            </Card >

        )
    }

    const [usage, setUsage] = useState(false)
    const getUsageById = async () => {
        let objSearch = {
            type: "Payment"
        }
        const result = await getUsageByIdFetch(objSearch)
        // console.log("getUsageByIdFetch : ", result)
        setUsage(result)
    }

    const getBaseApi = async () => {
        setLoading(true)

        const isLogin = await detectSignIn()
        if (isLogin) {
            let uid = firebase.auth().currentUser.uid

            getDay()
            getMonth()
            getYear()
            setUid(uid)

            getPaymentAccount()

            await detectCourseHasBeenPurchased(uid)

            await getCourseById()
            await getUsageById()
        } else {
            navigate("/login")
        }

        setLoading(false)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <>
            <Header
                firebase={props.firebase}
                user={props.user}
            />

            <article>
                <Content style={{ backgroundColor: "#F5F5F5" }}>
                    <article>
                        {!loading ?
                            <div style={width >= 1500 ? { paddingLeft: '5%', paddingRight: '5%' } : ((width > 912 && width < 1500) ? { paddingLeft: '5%', paddingRight: '5%' } : { paddingLeft: '0%', paddingRight: '0%' })}>
                                <Row style={{ backgroundColor: "white", paddingTop: 30, paddingLeft: 15, paddingRight: 15 }}>
                                    <Col xs={24} xl={12} style={{ paddingLeft: 15, paddingRight: 15, paddingBottom: 30 }}>
                                        {genOrderedList()}
                                    </Col>

                                    <Col xs={24} lg={24} xl={12} style={{ paddingLeft: 15, paddingRight: 15, paddingBottom: 30 }}>
                                        {genPaymentMethod()}
                                    </Col>
                                </Row>

                                <Modal
                                    maskClosable={false}
                                    title={<label style={{ fontWeight: 100 }}>{pamentTransferModal.title}</label>}
                                    visible={pamentTransferModal.isShow}
                                    width={550}
                                    loading={paymentmethodLoading}
                                    onCancel={() => {
                                        // close modal
                                        setFormDefault();
                                    }}
                                    footer={[
                                        <Button
                                            type="primary"
                                            loading={paymentmethodLoading}
                                            onClick={() => {
                                                audioReboundRef.current.play()
                                                form.submit()
                                            }}
                                        >
                                            {!paymentmethodLoading ?
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <Icon icon="bi:send" style={{ color: "white" }} />
                                                    <label style={{ paddingLeft: 10, cursor: "pointer" }}>ส่งหลักฐานการชำระเงิน</label>
                                                </div>
                                                : []
                                            }
                                        </Button>
                                    ]}
                                >
                                    <Row gutter={[12, 12]}>
                                        <Col span={6}><label style={{ float: "right" }}>ธนาคาร</label></Col>
                                        <Col span={18}><label>{paymentAccount?.bank_category?.bankName ?? "-"}</label></Col>

                                        <Col span={6}><label style={{ float: "right" }}>ชื่อบัญชี</label></Col>
                                        <Col span={18}><label>{paymentAccount?.accountName ?? "-"}</label></Col>

                                        <Col span={6}><label style={{ float: "right" }}>เลขที่บัญชี</label></Col>
                                        <Col span={18}><label>{paymentAccount?.accountNumber ?? "-"}</label></Col>

                                        <Col span={6}><label style={{ float: "right" }}>ส่งหลักฐาน</label></Col>
                                        <Col span={18}>
                                            <Row gutter={[12, 0]}>
                                                <Col span={24}><label>วันเเละเวลาที่โอน (ดูในสลิปธนาคาร)</label></Col>

                                                <Col xs={24} sm={12} md={8} lg={5}>
                                                    <Form form={form} layout="vertical" onFinish={onFinish} >
                                                        <Form.Item
                                                            name="day"
                                                            label="วัน"
                                                            style={{ width: '100%' }}
                                                            rules={[{ required: true, message: 'กรุณาเลือกวัน' }]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                style={{ width: '100%' }}
                                                                placeholder="วัน"
                                                                optionFilterProp="children"
                                                                allowClear
                                                            >
                                                                {day.map((val, index) =>
                                                                    <Option key={index} value={val.value}>{val.label}</Option>
                                                                )}
                                                            </Select>
                                                        </Form.Item>
                                                    </Form>
                                                </Col>

                                                <Col xs={24} sm={12} md={8} lg={7}>
                                                    <Form form={form} layout="vertical" onFinish={onFinish} >
                                                        <Form.Item
                                                            name="month"
                                                            label="เดือน"
                                                            style={{ width: '100%' }}
                                                            rules={[{ required: true, message: 'กรุณาเลือกเดือน' }]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                style={{ width: '100%' }}
                                                                placeholder="เดือน"
                                                                optionFilterProp="children"
                                                                allowClear
                                                            >
                                                                {month.map((val, index) =>
                                                                    <Option key={index} value={val.value}>{val.label}</Option>
                                                                )}
                                                            </Select>
                                                        </Form.Item>
                                                    </Form>
                                                </Col>

                                                <Col xs={24} sm={12} md={8} lg={6}>
                                                    <Form form={form} layout="vertical" onFinish={onFinish} >
                                                        <Form.Item
                                                            name="year"
                                                            label="ปี"
                                                            style={{ width: '100%' }}
                                                            rules={[{ required: true, message: 'กรุณาเลือกปี' }]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                style={{ width: '100%' }}
                                                                placeholder="ปี"
                                                                optionFilterProp="children"
                                                                allowClear
                                                            >
                                                                {year.map((val, index) =>
                                                                    <Option key={index} value={val.value}>{val.label}</Option>
                                                                )}
                                                            </Select>
                                                        </Form.Item>
                                                    </Form>
                                                </Col>

                                                <Col xs={24} sm={12} md={8} lg={6}>
                                                    <Form form={form} layout="vertical" onFinish={onFinish} >
                                                        <Form.Item
                                                            name="time"
                                                            label="เวลา"
                                                            style={{ width: '100%' }}
                                                            rules={[{ required: true, message: 'กรุณาเลือกเวลา' }]}
                                                        >
                                                            <TimePicker
                                                                showNow={false}
                                                                placeholder="เวลา"
                                                                style={{ width: '100%' }}
                                                                locale={{
                                                                    ...locale,
                                                                    lang: {
                                                                        ...locale.lang,
                                                                        // now: "เวลาปัจจุบัน",
                                                                        ok: "ตกลง",
                                                                    }
                                                                }}
                                                                format={"HH:mm"}
                                                            />
                                                        </Form.Item>
                                                    </Form>
                                                </Col>

                                                <Col span={24} style={{ paddingBottom: 6 }}>
                                                    <Form form={form} layout="vertical" onFinish={onFinish} >
                                                        <Form.Item
                                                            name="imgTransferSlipURL"
                                                            label={
                                                                <>
                                                                    <label style={{ color: "red", margin: 0, padding: 0 }}>*</label>
                                                                    <label style={{ margin: 0, paddingBottom: 0, paddingLeft: 5 }}>หลักฐานการโอน (สลิป)</label>
                                                                </>
                                                            }
                                                        >
                                                            <div style={{ paddingBottom: 12 }}>
                                                                {imgTransferSlipURL.imageUrl ?
                                                                    <Image
                                                                        style={{ width: 250, border: "1px solid #e8e8e8", borderRadius: 8 }}
                                                                        alt="avatar"
                                                                        src={`${serverUrl}/${imgTransferSlipURL.imageUrl}`}
                                                                        preview={{
                                                                            src: `${serverUrl}/${imgTransferSlipURL.imageUrl}`,
                                                                        }}
                                                                    />
                                                                    :
                                                                    <Image
                                                                        style={{ width: 250, border: "1px solid #e8e8e8" }}
                                                                        src="error"
                                                                        fallback={`${imgDefaultUrl}`}
                                                                    />
                                                                }
                                                            </div>

                                                            <Upload
                                                                {...optionTransferSlipImage}
                                                                listType="picture"
                                                                accept='image/jpeg, image/png, image/jfif'
                                                                maxCount={1}
                                                                showUploadList={false}
                                                            >
                                                                <Button
                                                                    type="primary"
                                                                    disabled={props.isDisabled}
                                                                    style={{ width: 245 }}
                                                                    icon={imgTransferSlipURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                                    onClick={() => audioReboundRef.current.play()}
                                                                >อัพโหลด</Button>
                                                            </Upload>
                                                        </Form.Item>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </Col>

                                        {width >= 590 ? <Col xs={24} sm={24} md={4} lg={4}></Col> : []}
                                        <Col xs={24} sm={24} md={20} lg={20}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Icon icon="carbon:information" style={{ color: "#FF7F00", width: 20, height: 20 }} />
                                                <label style={{ paddingLeft: 5 }}>กรุณาเก็บหลักฐานการโอนเงินไว้</label>
                                            </div>
                                        </Col>

                                        {width >= 590 ? <Col xs={24} sm={24} md={4} lg={4}></Col> : []}
                                        <Col xs={24} sm={24} md={20} lg={20}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Icon icon="carbon:information" style={{ color: "#FF7F00", width: 20, height: 20 }} />
                                                <label style={{ paddingLeft: 5 }}>การตรวจสอบจะใช้เวลาไม่เกิน 24 ชม. เราจะดำเนินการให้เร็วที่สุด</label>
                                            </div>
                                        </Col>
                                    </Row>
                                </Modal>
                            </div>
                            :
                            <LoadingPage loading={loading} />
                        }
                    </article>
                </Content>
            </article>

            <Footer />
            <audio ref={audioReboundRef}>
                <source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
        </>

    )
}