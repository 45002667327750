import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getPartnerAllFetch = async () => {
    try {
        const result = await httpClient.get(server.GET_PARTNER_ALL_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getPartnerBySearchFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.GET_PARTNER_BY_SEARCH_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertPartnerFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_PARTNER_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updatePartnerByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_PARTNER_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deletePartnerByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(server.DELETE_PARTNER_BY_ID_URL + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getPartnerAllFetch,
    getPartnerBySearchFetch,

    // insert
    insertPartnerFetch,

    // update
    updatePartnerByIdFetch,

    // delete
    deletePartnerByIdFetch

}