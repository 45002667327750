/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import {
    Row,
    Col
} from 'antd'
import { serverUrl } from '../../../constants'
import { getContactChannelAllFetch, getPageTalkToUsFetch } from '../API'
import { useDimensions } from '../../../common/mamager/ScreenManager'
import LoadingPage from '../../../common/components/loading/page'

export default function TalkToUs() {

    const { width } = useDimensions()
    const [pageTalkToUsData, setPageTalkToUsData] = useState()
    const [contactChannelData, setContactChannelData] = useState()
    const [loading, setLoading] = useState(true)

    const getPageTalkToUs = async () => {
        const result = await getPageTalkToUsFetch()
        setPageTalkToUsData(result)
    }

    const getContactChannel = async () => {
        const result = await getContactChannelAllFetch()
        setContactChannelData(result)
    }

    const getBaseApi = async () => {
        setLoading(true)
        await getPageTalkToUs()
        await getContactChannel()
        setLoading(false)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    // console.log("pageTalkToUsData : ", pageTalkToUsData)
    // console.log("contactChannelData : ", contactChannelData)

    return (
        <>
            {!loading ?
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: 24, paddingBottom: 24, backgroundColor: "#EEEEEE" }}>
                    <Row>
                        <Col xs={24} md={12} xl={10}>
                            <div style={{ display: "grid", alignItems: "flex-start", justifyContent: "flex-start", paddingLeft: 12, paddingRight: 12 }}>
                                <label style={{ fontSize: 28 }}>{pageTalkToUsData?.title}</label>
                                <label style={{ paddingTop: 6 }}>{pageTalkToUsData?.subTitle}</label>
                            </div>
                        </Col>

                        <Col xs={24} md={12} xl={14}>
                            <Row>
                                {contactChannelData?.map(item => {
                                    return (
                                        <>
                                            <Col xs={12} md={4} xl={4} style={{ paddingLeft: width >= 760 ? 12 : 0 }}>
                                                <center>
                                                    <div
                                                        // style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            window.open(item.link, '_blank')
                                                        }}
                                                    >
                                                    <img
                                                        src={`${serverUrl}/${item.imageURL}`}
                                                        style={{ height: 60, width: 60, backgroundColor: "#EEEEEE", padding: 2, border: "1px solid white", borderRadius: 50, cursor: "pointer", filter: "grayscale(40%)" }}
                                                    />
                                                    </div>
                                                </center>
                                            </Col>
                                        </>
                                    )
                                })}
                            </Row>
                        </Col>
                    </Row>
                </div>
                : 
                <LoadingPage loading={loading} />
            }
        </>
    )
}
