import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getPaymentOrdersByIdFetch = async (uid) => {
    try {
        const result = await httpClient.post(server.GET_PAYMENT_ORDERS_BY_ID_URL, { uid })
        return result.data.formData
    } catch (err) { // status 404
        return null
    }
}

const getPaymentAccountFetch = async () => {
    try {
        const result = await httpClient.get(server.GET_PAYMENT_ACCOUNT_ALL_URL)
        return result.data.formData
    } catch (err) { // status 404
        return null
    }
}

const getBankAccountFetch = async () => {
    try {
        const result = await httpClient.get(server.GET_BANK_ACCOUNT_ALL_URL)
        return result.data.formData
    } catch (err) { // status 404
        return null
    }
}

const insertPaymentOrderFetch = async (value) => {
    try {
        const result = await httpClient.post(server.INSERT_PAYMENT_ORDER_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updatePaymentOrderByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_PAYMENT_ORDER_COURSE_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updatePaymentAccountByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_PAYMENT_ACCOUNT_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getPaymentOrdersByIdFetch,
    getPaymentAccountFetch,
    getBankAccountFetch,

    // insert
    insertPaymentOrderFetch,

    // update
    updatePaymentOrderByIdFetch,
    updatePaymentAccountByIdFetch,

    // delete
    
}