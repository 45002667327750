/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Header from "../../common/components/header"
import PredictionRoomBoard from "./PredictionRoomBoard"
import { getPredictionRoomByIdFetch } from "./API"
import { detectSignIn } from "../../common/mamager/DetectManager"
import LoadingPage from "../../common/components/loading/page"
import Footer from "../../common/components/footer"
import BreadcrumbPage from "../../common/components/breadcrumbPage"

export default function PredictionRoom(props) {
    const navigate = useNavigate()

    const [predictionRoom, setPredictionRoom] = useState([])
    const [loading, setLoading] = useState(false)

    const getPredictionRoomById = async () => {
        setLoading(true)

        if (props?.user?.uid) {
            const result = await getPredictionRoomByIdFetch({ uid: props?.user?.uid })
            // console.log("getPredictionRoomByIdFetch : ", result)
            setPredictionRoom(result)
        }

        setLoading(false)
    }

    const detectLogin = async () => {
        // detect login
        const isLogin = await detectSignIn()
        if (!isLogin) {
            navigate("/login")
        }
    }

    const getBaseApi = async () => {
        await detectLogin()
        await getPredictionRoomById()
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <>
            <Header firebase={props.firebase} authorized={props.authorized} user={props.user} />
            <BreadcrumbPage pageCurrentName={"ห้องโหร ออนไลน์ - Prediction Room"} />

            <article>
                <>
                    {!loading ?
                        <>
                            <PredictionRoomBoard predictionRoom={predictionRoom} user={props.user} />
                        </>
                        :
                        <LoadingPage loading={loading} />
                    }
                </>
            </article>

            <Footer />
        </>
    )
}
