/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    DatePicker,
    Popconfirm,
    Select,
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import { deleteAdminByIdFetch, getAdminBySearchFetch, insertAdminFetch, inspectNewUsernameDuplicateFetch, inspectOldPasswordMatchesFetch, updateAdminByIdFetch, updateAdminPasswordByIdFetch } from './API'

const { TextArea } = Input;
const { Option } = Select
const { RangePicker } = DatePicker

const columns = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "10%",
    },
    {
        title: 'ชื่อ Admin',
        dataIndex: 'username',
        width: "40%",
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "10%",
    },
    {
        title: 'วันที่เเก้ไข',
        dataIndex: 'updatedAt',
        width: "10%",
    },
    {
        title: 'สถานะ',
        width: "15%",
        render: (text, record) => {
            return (
                <>
                    {record.isActive ?
                        <label>เปิดการใช้งาน</label>
                        :
                        <label>ปิดการใช้งาน</label>
                    }
                </>
            )
        }
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "20%",
    },
]

const formatDate = "DD/MM/YYYY"

export default function AdminManage() {

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)

    const [form] = Form.useForm()
    const [formSearch] = Form.useForm()

    const audioReboundRef = useRef()

    const [isOpenPassword, setIsOpenPassword] = useState(false)

    const [pageCurrent, setPageCurrent] = useState(1)

    const [modal, setModal] = useState({
        isShow: false,
        title: null
    })

    const getAdminBySearch = async (username, startedDate, endDate) => {
        setLoading(true)

        let objSearch = {
            username, startedDate, endDate
        }
        // console.log("getAdminBySearch : ", objSearch)
        const result = await getAdminBySearchFetch(objSearch)
        // console.log("result : ", result)

        let tempList = [];
        result?.map((val, index) => {
            tempList.push({
                index: (index + 1),
                username: val.username,
                isActive: val.isActive,
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            style={{
                                width: 35,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: 'white',
                                borderRadius: 50
                            }}
                            onClick={() => {
                                audioReboundRef.current.play()
                                form.setFieldsValue({
                                    adminId: val.adminId,
                                    username: val.username,
                                    isActive: val.isActive,
                                })
                                setIsOpenPassword(false)
                                setModal({ isShow: true, title: "edit" })
                            }}
                        >
                            <div style={{ marginTop: 0, marginLeft: -8 }}>
                                <Icon icon="typcn:edit" style={{ color: "white", width: 20, height: 20 }} />
                            </div>
                        </Button>{"  "}

                        <Popconfirm
                            title="คุณยืนยันลบหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                await handleAdminDelete(val.adminId)

                                // reload
                                await getAdminBySearch(null, null, null)
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                danger
                                type="primary"
                                style={{
                                    width: 35,
                                    borderRadius: 50
                                }}
                                onClick={() => audioReboundRef.current.play()}
                            >
                                <div style={{ marginTop: 0, marginLeft: -8 }}>
                                    <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 20, height: 20 }} />
                                </div>
                            </Button>
                        </Popconfirm>
                    </>
            });
        });
        setList(tempList)

        setLoading(false)
    }

    const onFinish = async (values) => {
        let obj = {
            adminId: values.adminId ? values.adminId : null,
            username: values.username ? values.username : null,
            passwordOld: values.passwordOld ? values.passwordOld : null,
            passwordNew: values.passwordNew ? values.passwordNew : null,
            passwordNewAgain: values.passwordNewAgain ? values.passwordNewAgain : null,
            isActive: values.isActive
        }

        if (modal.title === "add") {

            // check new username duplicate
            const resultCheckUser = await inspectNewUsernameDuplicateFetch(obj.username)
            if (resultCheckUser?.isSuccess) {
                if (resultCheckUser?.formData?.status === "IS_NOT_REPEAT") {

                    if (obj.passwordNew === obj.passwordNewAgain) {
                        const result = await insertAdminFetch(obj)
                        if (result.isSuccess) {
                            Notification('success', 'สร้างสำเร็จ')

                            // reload
                            getAdminBySearch(null, null, null)

                            // set default
                            setFormDefault()
                        } else {
                            Notification('error', 'ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง')
                        }
                    } else {
                        Notification('error', 'รหัสผ่านไม่ตรงกัน')
                    }

                } else {
                    Notification('error', 'ชื่อซ้ำ กรุณาเปลี่ยนชื่อใหม่')
                }
            } else {
                Notification('error', 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')
            }
        } else if (modal.title === "edit") {

            if (!isOpenPassword) {
                const result = await updateAdminByIdFetch(obj)
                if (result.isSuccess) {
                    Notification('success', 'เเก้ไขสำเร็จ')

                    // reload
                    getAdminBySearch(null, null, null)

                    // set default
                    setFormDefault()
                } else {
                    Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
                }
            } else {
                // check old password matches
                const resultCheckUser = await inspectOldPasswordMatchesFetch(obj.passwordOld, obj.adminId)
                if (resultCheckUser.isSuccess) {
                    if (resultCheckUser?.formData?.status === "IS_MATCH") {
                        if (obj.passwordNew === obj.passwordNewAgain) {
                            const result = await updateAdminPasswordByIdFetch(obj)
                            if (result.isSuccess) {
                                Notification('success', 'เเก้ไขสำเร็จ')

                                // reload
                                getAdminBySearch(null, null, null)

                                // set default
                                setFormDefault()
                            } else {
                                Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
                            }
                        } else {
                            Notification('error', 'รหัสผ่านใหม่ ไม่ตรงกัน')
                        }
                    } else {
                        Notification('error', 'รหัสผ่านเก่าผิด กรุณาลองใหม่อีกครั้ง')
                    }
                } else {
                    Notification('error', 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')
                }
            }
        }
    }

    const onSearchFinish = async (values) => {
        let username = values?.username ? values.username : null
        let startedDate = values?.dateRange ? values.dateRange[0] : null
        let endDate = values?.dateRange ? values.dateRange[1] : null

        let objSearch = {
            username, startedDate, endDate
        }
        // console.log("onSearchFinish : ", objSearch)
        await getAdminBySearch(username, startedDate, endDate)
    }

    const handleAdminDelete = async (id) => {
        const result = await deleteAdminByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const setFormDefault = () => {
        form.resetFields()

        setIsOpenPassword(false)

        setModal({
            isShow: false,
            title: null,
        })
    }

    useEffect(() => {
        getAdminBySearch(null, null, null)
    }, [])

    return (
        <Row>
            <Col span={12}>
                <label>จัดการ Admin</label>
            </Col>
            <Col span={12} style={{ paddingBottom: 20 }}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ชื่อ Admin"
                                name="username"
                                style={{ width: '100%' }}
                            >
                                <Input />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ห้วงวันที่สร้าง" name="dateRange"
                                style={{ width: '100%' }}
                            >
                                <RangePicker />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10, marginTop: 3 }}>
                            <Button
                                style={{ float: 'right', width: 70 }}
                                type="primary"
                                onClick={() => {
                                    audioReboundRef.current.play()
                                    formSearch.submit()
                                }}
                            >
                                ค้นหา
                            </Button>
                        </div>
                    </div>
                </Form>
            </Col>

            <Col span={24} style={{ paddingBottom: 20 }}>
                <Button
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={() => {
                        audioReboundRef.current.play()
                        form.resetFields()
                        setIsOpenPassword(true)
                        setModal({
                            isShow: true,
                            title: "add"
                        })
                    }}
                >เพิ่มรายการ</Button>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrent,
                        pageSize: 10,
                        total: list.length
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modal.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}</label></strong>}
                visible={modal.isShow}
                onCancel={() => {

                    // default
                    setFormDefault()
                }}
                width={600}
                onOk={() => {
                    form.submit()
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }} onClick={() =>  audioReboundRef.current.play()}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }} onClick={() =>  audioReboundRef.current.play()}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item name="adminId" style={{ display: "none" }}>
                                <Input />
                            </Form.Item>

                            {modal.title === "add" ?
                                <Form.Item
                                    name="username"
                                    label="ชื่อ Admin"
                                    rules={[{ required: true, message: 'กรุณากรอกชื่อ Admin' }]}
                                >
                                    <Input />
                                </Form.Item>
                                : []
                            }
                        </Col>

                        {modal.title === "edit" ?
                            <>
                                <Col span={24} style={{ paddingBottom: 12 }}>
                                    <Button
                                        type="primary"
                                        // style={{ float: 'right' }}
                                        onClick={() => {
                                            audioReboundRef.current.play()
                                            setIsOpenPassword(!isOpenPassword)
                                        }}
                                    >{isOpenPassword ? "ยกเลิกเปลี่ยนรหัสผ่าน" : "เปลี่ยนรหัสผ่าน"}</Button>
                                </Col>
                                {isOpenPassword ?
                                    <Col span={24}>
                                        <Form.Item
                                            name="passwordOld"
                                            label="รหัสผ่านปัจจุบัน (ที่ใช้อยู่)"
                                            rules={[{ required: true, message: 'กรุณากรอกรหัสผ่านปัจจุบัน (ที่ใช้อยู่)' }]}
                                        >
                                            <Input.Password />
                                        </Form.Item>
                                    </Col>
                                    : []
                                }
                            </>
                            : []
                        }

                        {isOpenPassword ?
                            <>
                                <Col span={24}>
                                    <Form.Item
                                        name="passwordNew"
                                        label={modal.title === "add" ? "รหัสผ่าน" : "รหัสผ่านใหม่"}
                                        rules={[{ required: true, message: (modal.title === "add" ? "กรุณากรอกรหัสผ่าน" : 'กรุณากรอกรหัสผ่านใหม่') }]}
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name="passwordNewAgain"
                                        label={modal.title === "add" ? "รหัสผ่าน อีกครั้ง" : "รหัสผ่านใหม่ อีกครั้ง"}
                                        rules={[{ required: true, message: (modal.title === "add" ? "กรุณากรอกรหัสผ่าน อีกครั้ง" : 'กรุณากรอกรหัสผ่านใหม่ อีกครั้ง') }]}
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                </Col>
                            </>
                            : []
                        }

                        <Col span={24}>
                            <Form.Item
                                name="isActive"
                                label="สถานะการใช้งาน"
                                rules={[{ required: true, message: 'กรุณาเลือกสถานะการเเสดง' }]}
                            >
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                >
                                    <Option key={0} value={true}>เปิดการใช้งาน</Option>
                                    <Option key={1} value={false}>ปิดการใช้งาน</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <audio ref={audioReboundRef}>
                <source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
        </Row>
    )
}
