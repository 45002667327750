import { server } from "../../../../../constants"
import { httpClient } from "../../../../../utils/HttpClient"

const getMandalaBySearchFetch = async (value) => {
    try {
        const result = await httpClient.post(server.GET_MANDALA_BY_SEARCH_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertMandalaFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_MANDALA_ORDER_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateMandalaByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_MANDALA_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteMandalaByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(server.DELETE_MANDALA_BY_ID_URL + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getMandalaBySearchFetch,

    // insert
    insertMandalaFetch,

    // update
    updateMandalaByIdFetch,

    // 
    deleteMandalaByIdFetch,
}