import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getPageFaqFetch = async () => {
    try {
        const result = await httpClient.get(server.GET_PAGE_FAQ_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getFaqAllFetch = async () => {
    try {
        const result = await httpClient.get(server.GET_FAQ_ALL_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updatePageFaqByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_PAGE_FAQ_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getPageFaqFetch,
    getFaqAllFetch,

    // insert

    // update
    updatePageFaqByIdFetch,

    // delete

}