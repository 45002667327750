import { server } from "../../../../../constants"
import { httpClient } from "../../../../../utils/HttpClient"

const getContactUsChannelBySearchFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.GET_CONTACT_US_CHANNEL_BY_SEARCH_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertContactUsChannelFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_CONTACT_US_CHANNEL_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateContactUsChannelByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_CONTACT_US_CHANNEL_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteContactUsChannelByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(server.DELETE_CONTACT_US_CHANNEL_BY_ID_URL + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getContactUsChannelBySearchFetch,

    // insert
    insertContactUsChannelFetch,

    // update
    updateContactUsChannelByIdFetch,

    // delete
    deleteContactUsChannelByIdFetch,

}