import React, { useEffect, useState } from 'react'
import CarouselList from '../../../common/components/banner/CarouselList'
import { useDimensions } from '../../../common/mamager/ScreenManager'
import { getPartnerAllFetch } from '../../../common/API/partner'

export default function Partner() {

    const [loading, setLoading] = useState(false)
    const { width } = useDimensions()

    const [pageAboutMe, setPageAboutMe] = useState(null)

    const getPartnerAll = async () => {
        let result = await getPartnerAllFetch()
        setPageAboutMe(result)
    }

    useEffect(() => {
        getPartnerAll()
    }, [])

    // console.log("pageAboutMe : ", pageAboutMe)

    return (
        <div
            style={{
                paddingTop: 24,
                paddingBottom: 24
            }}
        >
            {!loading ?
                <CarouselList bannerData={pageAboutMe} />
                : []
            }
        </div>
    )
}
