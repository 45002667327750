/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import "../header/css/index.css"
import ReactPlayer from 'react-player'

import { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import {
	Drawer,
	Button,
	Avatar,
	Badge,
	Space,
	Popover,
	Divider,
	Row,
	Col,
	Modal
} from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from "../../mamager/ScreenManager"
import { detectSignIn } from "../../mamager/DetectManager"
import { getUserByUidFetch } from "./API"
import firebase from "./../../../config/firebase-config"
import { useDispatch, useSelector } from "react-redux"
import { setBasketOrderStateToSuccess } from "../../../actions/basketOrder.action"
import { serverUrl } from "../../../constants"
import LoadingPage from "../loading/page"
import { getUsageByIdFetch } from "../../../modules/erp/manage/usage/API"
import Video from "../video"

export default function Header(props) {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const [userInformation, setUserInformation] = useState()

	const [isSignIn, setIsSignIn] = useState(false)

	const { width } = useDimensions()

	const [loading, setLoading] = useState(false)

	const [state, setState] = useState({ current: 'mail', visible: false })

	const [open, setOpen] = useState(false)

	const { resultBasketOrder } = useSelector(({ basketOrderReducer }) => basketOrderReducer)

	const audioInterfaceRef = useRef()
	const audioDecidempRef = useRef()
	const audioReboundRef = useRef()

	// -------------------------------------------------
	const [isPopoverVisible, setIsPopoverVisible] = useState(false)
	const [isPopoverVisible2, setIsPopoverVisible2] = useState(false)
	const [isVideoPlaying, setIsVideoPlaying] = useState(false)
	const [isVideoPlaying2, setIsVideoPlaying2] = useState(false)

	useEffect(() => {
		if (!isPopoverVisible) {
			setIsVideoPlaying(false)
		}
	}, [isPopoverVisible])

	useEffect(() => {
		if (!isPopoverVisible2) {
			setIsVideoPlaying2(false)
		}
	}, [isPopoverVisible2])

	const handlePopoverVisibleChange = (visible) => {
		if (visible) {
			setIsPopoverVisible(true)
		} else {
			setTimeout(() => {
				setIsPopoverVisible(false)
			}, 500)
		}
	}

	const handlePopoverVisibleChange2 = (visible) => {
		if (visible) {
			setIsPopoverVisible2(true)
		} else {
			setTimeout(() => {
				setIsPopoverVisible2(false)
			}, 500)
		}
	}
	// -------------------------------------------------

	const handleOpenChange = (newOpen) => {
		setOpen(newOpen)
	}

	const showDrawer = () => {
		setState({
			visible: true
		})
	}

	const onClose = () => {
		setState({
			visible: false
		})
	}

	const genProfileDisplay = () => {
		return (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					cursor: "pointer"
				}}
				onClick={() => {
					if (isSignIn) {
						navigate("/account?tab=profile")
					}
				}}
			>
				<div style={{ display: 'block', float: "right", paddingRight: 18 }}>
					{userInformation?.username ? userInformation.username : ""}
				</div>

				<Avatar
					style={{ float: "right", marginRight: 20, border: "1px solid #E5E5E5" }}
					className="mt-1"
					size={40}
					src={userInformation?.imageProfile ? userInformation.imageProfile : `./assets/images/profile/personDefault.jpg`}
				/>

				{isSignIn ?
					<>
						{width < 926 ?
							<Space size={24} style={{ cursor: "pointer", paddingRight: 12 }}>
								<Popover
									content={genBasketList()}
									placement="bottomRight"
									trigger="click"
									open={open}
									onOpenChange={handleOpenChange}
								>
									<div style={{ paddingRight: 8 }}>
										<Badge count={resultBasketOrder?.length}>
											<Avatar
												shape="square"
												icon={
													<div style={{ marginTop: 4, marginLeft: -1 }}>
														<Icon icon="fa:shopping-basket" style={{ color: "white", width: 20, height: 20 }} />
													</div>
												} />
										</Badge>
									</div>
								</Popover>
							</Space>
							: []
						}
					</>
					: []
				}
			</div>
		)
	}

	const genBasketList = () => {
		return (
			<div style={{ width: 370, display: "grid", alignItems: "center" }}>
				{resultBasketOrder?.map((item) => {
					return (
						<>
							<div style={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
								<img
									src={`${serverUrl}/${item?.imgCoverURL}`}
									style={{ height: 60, paddingTop: 5, paddingBottom: 5, cursor: 'pointer', borderRadius: 8 }}
								/>
								<div style={{ display: "flex", alignItems: "center", paddingLeft: 10, width: "100%" }}>
									<div style={{ display: "grid", alignItems: "center", width: "100%", paddingRight: 10 }}>
										<label className="basket-order-title" style={{ fontWeight: "bold" }}>{item?.title}</label>
										<label className="basket-order-subtitle" >{item?.subTitle}</label>
										<label style={{ fontWeight: 600 }}>ราคา {Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format((item?.price))} บาท</label>
									</div>

									<Button
										type="primary"
										size="small"
										onClick={() => {
											audioReboundRef.current.play()
											let tmpArr = resultBasketOrder.filter(fill => fill.courseId !== item.courseId)
											dispatch(setBasketOrderStateToSuccess(tmpArr))
										}}
									>
										<div style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
											<Icon icon="ph:trash-bold" style={{ color: "white", height: 16, width: 16, cursor: "pointer" }} />
										</div>
									</Button>
								</div>
							</div>
							<Divider style={{ marginTop: 11, marginBottom: 11 }} />
						</>
					)
				})}

				<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
					<div style={{ display: "grid", alignItems: "center" }}>
						<Button
							type="primary"
							disabled={resultBasketOrder.length > 0 ? false : true}
							onClick={() => {
								audioReboundRef.current.play()
								navigate("/payment")
							}}
						>
							ชำระเงิน
						</Button>
					</div>
				</div>
			</div>
		)
	}

	const genMenuTop = () => {
		return (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					paddingLeft: width > 1142 ? '10%' : 0,
					paddingRight: width > 1142 ? '10%' : 14,
					backgroundColor: "#FF7F00",
					height: 40
				}}
			>
				<div style={{ display: "flex", alignItems: "center", }}>
					<div
						onClick={() => {
							audioReboundRef.current.play()
							setTimeout(function () {
								navigate("/")
								window.scrollTo(0, 0)
							}, 1000)
						}}
						className="divStyle"
					>
						<div style={{ borderRight: "1px solid white", color: "white", cursor: "pointer" }}>
							<label style={{ padding: 8, cursor: "pointer" }}>หน้าหลัก</label>
						</div>
					</div>

					<div
						onClick={() => {
							audioReboundRef.current.play()
							setTimeout(function () {
								navigate("/course")
								window.scrollTo(0, 0)
							}, 1000)
						}}
						className="divStyle"
					>
						<div style={{ borderRight: "1px solid white", color: "white", cursor: "pointer" }}>
							<label style={{ padding: 8, cursor: "pointer" }}>คอร์สเรียน</label>
						</div>
					</div>

					<div
						onClick={() => {
							audioReboundRef.current.play()
							setTimeout(function () {
								navigate("/prediction")
								window.scrollTo(0, 0)
							}, 1000)
						}}
						className="divStyle"
					>
						<div style={{ borderRight: "1px solid white", color: "white", cursor: "pointer" }}>
							<label style={{ padding: 8, cursor: "pointer" }}>ห้องโหร ออนไลน์</label>
						</div>
					</div>

					<div
						onClick={() => {
							audioReboundRef.current.play()
							setTimeout(function () {
								navigate("/ebook")
								window.scrollTo(0, 0)
							}, 1000)
						}}
						className="divStyle"
					>
						<div style={{ borderRight: "1px solid white", color: "white", cursor: "pointer" }}>
							<label style={{ padding: 8, cursor: "pointer" }}>e-Books</label>
						</div>
					</div>

					<div
						onClick={() => {
							audioReboundRef.current.play()
							setTimeout(function () {
								navigate("/service")
								window.scrollTo(0, 0)
							}, 1000)
						}}
						className="divStyle"
					>
						<div style={{ borderRight: "1px solid white", color: "white", cursor: "pointer" }}>
							<label style={{ padding: 8, cursor: "pointer" }}>บริการต่างๆ</label>
						</div>
					</div>

					<div
						onClick={() => {
							audioReboundRef.current.play()
							setTimeout(function () {
								navigate("/about-me")
								window.scrollTo(0, 0)
							}, 1000)
						}}
						className="divStyle"
					>
						<div style={{ borderRight: "1px solid white", color: "white", cursor: "pointer" }}>
							<label style={{ padding: 8, cursor: "pointer" }}>เกี่ยวกับเรา</label>
						</div>
					</div>

					<div
						onClick={() => {
							audioReboundRef.current.play()
							setTimeout(function () {
								navigate("/contact")
								window.scrollTo(0, 0)
							}, 1000)
						}}
						className="divStyle"
					>
						<div style={{ borderRight: (firebase.auth().currentUser?.uid ? "1px solid white" : ""), color: "white", cursor: "pointer" }}>
							<label style={{ padding: 8, cursor: "pointer" }}>ติดต่อเรา</label>
						</div>
					</div>

					{firebase.auth().currentUser?.uid ?
						<div
							onClick={() => {
								audioReboundRef.current.play()
								setTimeout(function () {
									props.firebase.auth().signOut()
									navigate("/login")
									window.scrollTo(0, 0)
								}, 1000)
							}}
							className="divStyle"
						>
							<div style={{ color: "white", cursor: "pointer" }}>
								<label style={{ padding: 8, cursor: "pointer" }}>ออกจากระบบ</label>
							</div>
						</div>
						: []
					}
				</div>

				<div style={{ display: "flex", alignItems: "center", }}>
					<Popover
						content={
							<div style={{ display: "grid" }}>
								<div style={{ paddingBottom: 12 }}>
									<label>{usage2?.detail}</label>
								</div>
								<div style={{ paddingBottom: 12 }}>
									<div style={{ backgroundColor: "black" }}>
										<ReactPlayer
											config={{ file: { attributes: { controlsList: 'nodownload' } } }}
											onContextMenu={e => e.preventDefault()}
											url={`${serverUrl}/${usage2?.videoUrl}`}
											controls
											playing={isVideoPlaying2}
											width={"100%"}
											height={200}
										/>
									</div>
								</div>
							</div>
						}
						title={usage2?.title}
						visible={isPopoverVisible2}
						onVisibleChange={(visible) => {
							setIsVideoPlaying2(visible)
							handlePopoverVisibleChange2(visible)
						}}
					>
						<Space size={24} style={{ cursor: "pointer" }}>
							<Popover
								content={genBasketList()}
								placement="bottomRight"
								trigger="click"
								open={open}
								onOpenChange={handleOpenChange}
							>
								<div style={{ paddingRight: 8 }}>
									<Badge count={resultBasketOrder?.length}>
										<Avatar
											shape="square"
											icon={
												<div style={{ marginTop: 4, marginLeft: -1 }}>
													<Icon icon="fa:shopping-basket" style={{ color: "white", width: 20, height: 20 }} />
												</div>
											} />
									</Badge>
								</div>
							</Popover>
						</Space>
					</Popover>
				</div>
			</div>
		)
	}

	const genMenuRight = () => {
		return (
			<div
				style={{
					display: "grid",
					alignItems: "center",
					justifyContent: "space-between",
					paddingLeft: 0,
					paddingRight: 14,
					backgroundColor: "#FF7F00",
					height: "auto"
				}}
			>
				<div style={{ display: "grid", alignItems: "center", width: 370 }}>
					<div
						onClick={() => {
							audioReboundRef.current.play()
							setTimeout(function () {
								navigate("/")
								window.scrollTo(0, 0)
							}, 1000)
						}}
						className="sidebar-item"
					>
						<div style={{ color: "white", cursor: "pointer" }}>
							<label style={{ padding: 8, cursor: "pointer" }}>หน้าหลัก</label>
						</div>
					</div>

					<div
						onClick={() => {
							audioReboundRef.current.play()
							setTimeout(function () {
								navigate("/course")
								window.scrollTo(0, 0)
							}, 1000)
						}}
						className="sidebar-item"
					>
						<div style={{ color: "white", cursor: "pointer" }}>
							<label style={{ padding: 8, cursor: "pointer" }}>คอร์สเรียน</label>
						</div>
					</div>

					<div
						onClick={() => {
							audioReboundRef.current.play()
							setTimeout(function () {
								navigate("/prediction")
								window.scrollTo(0, 0)
							}, 1000)
						}}
						className="sidebar-item"
					>
						<div style={{ color: "white", cursor: "pointer" }}>
							<label style={{ padding: 8, cursor: "pointer" }}>ห้องโหร ออนไลน์</label>
						</div>
					</div>

					{/* <div
						onClick={() => {
							navigate("/classroom")
							window.scrollTo(0, 0)
						}}
						className="sidebar-item"
					>
						<div style={{ color: "white", cursor: "pointer" }}>
							<label style={{ padding: 8, cursor: "pointer" }}>คอร์สของฉัน</label>
						</div>
					</div> */}

					{/* <div
						onClick={() => {
							navigate("/prediction-room")
							window.scrollTo(0, 0)
						}}
						className="sidebar-item"
					>
						<div style={{ color: "white", cursor: "pointer" }}>
							<label style={{ padding: 8, cursor: "pointer" }}>ห้องโหร ออนไลน์</label>
						</div>
					</div> */}

					<div
						onClick={() => {
							audioReboundRef.current.play()
							setTimeout(function () {
								navigate("/ebook")
								window.scrollTo(0, 0)
							}, 1000)
						}}
						className="sidebar-item"
					>
						<div style={{ color: "white", cursor: "pointer" }}>
							<label style={{ padding: 8, cursor: "pointer" }}>e-Books</label>
						</div>
					</div>

					<div
						onClick={() => {
							audioReboundRef.current.play()
							setTimeout(function () {
								navigate("/service")
								window.scrollTo(0, 0)
							}, 1000)
						}}
						className="sidebar-item"
					>
						<div style={{ color: "white", cursor: "pointer" }}>
							<label style={{ padding: 8, cursor: "pointer" }}>บริการต่างๆ</label>
						</div>
					</div>

					<div
						onClick={() => {
							audioReboundRef.current.play()
							setTimeout(function () {
								navigate("/about-me")
								window.scrollTo(0, 0)
							}, 1000)
						}}
						className="sidebar-item"
					>
						<div style={{ color: "white", cursor: "pointer" }}>
							<label style={{ padding: 8, cursor: "pointer" }}>เกี่ยวกับเรา</label>
						</div>
					</div>

					<div
						onClick={() => {
							audioReboundRef.current.play()
							setTimeout(function () {
								navigate("/contact")
								window.scrollTo(0, 0)
							}, 1000)
						}}
						className="sidebar-item"
					>
						<div style={{ color: "white", cursor: "pointer" }}>
							<label style={{ padding: 8, cursor: "pointer" }}>ติดต่อเรา</label>
						</div>
					</div>

					{firebase.auth().currentUser?.uid ?
						<div
							onClick={() => {
								audioReboundRef.current.play()
								setTimeout(function () {
									props.firebase.auth().signOut()
									navigate("/login")
									window.scrollTo(0, 0)
								}, 1000)
							}}
							className="sidebar-item"
						>
							<div style={{ color: "white", cursor: "pointer" }}>
								<label style={{ padding: 8, cursor: "pointer" }}>ออกจากระบบ</label>
							</div>
						</div>
						: []
					}
				</div>
			</div>
		)
	}

	const getUserInformation = async () => {
		if (firebase.auth().currentUser?.uid) {
			const result = await getUserByUidFetch(firebase?.auth()?.currentUser?.uid)
			setUserInformation(result)
		} else {
			setUserInformation(null)
		}
	}

	const [usage, setUsage] = useState(false)
	const [usage2, setUsage2] = useState(false)
	const getUsageById = async () => {
		let objSearch = {
			type: "How to Login-Register ?"
		}
		const result = await getUsageByIdFetch(objSearch)
		// console.log("getUsageByIdFetch : ", result)
		setUsage(result)

		let objSearch2 = {
			type: "Payment"
		}
		const result2 = await getUsageByIdFetch(objSearch2)
		// console.log("getUsageByIdFetch : ", result2)
		setUsage2(result2)
	}

	const getBaseApi = async () => {
		setLoading(true)

		const isLogin = await detectSignIn()
		setIsSignIn(isLogin)

		await getUserInformation()
		await getUsageById()

		setLoading(false)
	}

	useEffect(() => {
		getBaseApi()
	}, [])

	return (
		<>
			<header style={{ height: width < 925 ? 70.4 : 110 }}>
				<div
					style={{
						position: 'fixed',
						zIndex: 2,
						width: '100%',
						backgroundColor: '#fff',
						boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)'
					}}
				>
					{!loading ?
						<>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between"
								}}
							>
								<div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", paddingLeft: width > 925 ? '10%' : 0 }}>
									<img
										src={width > 730 ? `./assets/images/logo/Du-SolarChart-Name.png` : `./assets/images/logo/Du-SolarChart.png`}
										style={{ height: 60, paddingTop: 5, paddingBottom: 5, paddingLeft: width > 925 ? 0 : 5, cursor: 'pointer' }}
										onClick={() => {
											navigate("/")
										}}
									/>

									{width > 1176 ?
										<div style={{ display: "flex", alignItems: "center" }}>
											<div style={{ padding: 12 }}>
												<Icon icon="fa-solid:home" style={{ color: "#FF7F00", height: 28, width: 28 }} />
											</div>
											<div style={{ display: "grid", alignItems: "center" }}>
												<label>สถานที่ตั้ง</label>
												<label>Uranian Group Thailand</label>
											</div>
										</div>
										: []
									}

									{width > 1176 ?
										<div style={{ display: "flex", alignItems: "center" }}>
											<div style={{ padding: 12 }}>
												<Icon icon="entypo-social:facebook" style={{ color: "#FF7F00", height: 28, width: 28 }} />
											</div>
											<div style={{ display: "grid", alignItems: "center" }}>
												<label>เพจสถาบัน</label>
												<Button
													size="middle"
													type="dashed"
													onClick={() => {
														window.open("https://www.facebook.com/explore78")
													}}
												>
													อ่านกรรมจากผังดาว
												</Button>
												{/* <a style={{ textDecoration: "none" }} href="https://www.facebook.com/explore78"><span style={{ color: "black" }}>อ่านกรรมจากผังดาว</span></a> */}
											</div>
										</div>
										: []
									}
								</div>

								<div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
									{!isSignIn ?
										<Popover
											content={
												<div style={{ display: "grid" }}>
													<div style={{ paddingBottom: 12 }}>
														<label>{usage?.detail}</label>
													</div>
													<div style={{ paddingBottom: 12 }}>
														<div style={{ backgroundColor: "black" }}>
															<ReactPlayer
																config={{ file: { attributes: { controlsList: 'nodownload' } } }}
																onContextMenu={e => e.preventDefault()}
																url={`${serverUrl}/${usage?.videoUrl}`}
																controls
																playing={isVideoPlaying}
																width={"100%"}
																height={200}
															/>
														</div>
													</div>
												</div>
											}
											title={usage?.title}
											visible={isPopoverVisible}
											onVisibleChange={(visible) => {
												setIsVideoPlaying(visible)
												handlePopoverVisibleChange(visible)
											}}
										>
											<Button
												size="large"
												onClick={() => {
													audioReboundRef.current.play()
													setTimeout(function () {
														navigate("/login")
													}, 1000)
												}}
											>
												สมัครสมาชิก/ล็อกอิน
											</Button>
										</Popover>
										: []
									}
									{genProfileDisplay()}

									<Drawer
										title={
											<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
												<label style={{ fontWeight: 100 }}>เมนู</label>
											</div>
										}
										placement="right"
										maskClosable={true}
										onClose={onClose}
										visible={state?.visible}
										bodyStyle={{ padding: '0px' }}
										size="default"
									>
										{genMenuRight()}
									</Drawer>

									{width < 925 ?
										<Button
											type="primary"
											onClick={() => {
												audioReboundRef.current.play()
												showDrawer()
											}}
											style={{
												height: 70,
												width: 70,
												borderRadius: 0
											}}
										>
											<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
												<Icon
													icon="charm:menu-hamburger"
													style={{
														fontSize: '1.9em',
														// marginTop: 4,
														color: "white"
													}}
												/>
											</div>
										</Button>
										: []
									}
								</div>
							</div>

							{width > 925 ?
								genMenuTop()
								: []
							}
						</>
						:
						<LoadingPage loading={loading} />
					}
				</div>
			</header>

			<audio ref={audioInterfaceRef}>
				<source src="./assets/audio/interface.mp3" type="audio/mpeg" />
			</audio>
			<audio ref={audioReboundRef}>
				<source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
				Your browser does not support the audio element.
			</audio>
			<audio ref={audioDecidempRef}>
				<source src="./assets/audio/decidemp.mp3" type="audio/mpeg" />
			</audio>
		</>
	)
}