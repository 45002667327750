import { server } from "../../../../../constants"
import { httpClient } from "../../../../../utils/HttpClient"

const getFaqBySearchFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.GET_FAQ_BY_SEARCH_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertFaqFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_FAQ_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateFaqByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_FAQ_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteFaqByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(server.DELETE_FAQ_BY_ID_URL + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getFaqBySearchFetch,

    // insert
    insertFaqFetch,

    // update
    updateFaqByIdFetch,

    // delete
    deleteFaqByIdFetch
}