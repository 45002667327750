/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    DatePicker,
    Select,
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import { getInquiryFormBySearchFetch, updateInquiryFormByIdFetch } from '../../../contract/API'
import Video from '../../../../common/components/video'
import { serverUrl } from '../../../../constants'

const { Option } = Select
const { RangePicker } = DatePicker

const columns = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "10%",
    },
    {
        title: 'ผู้ส่งเเบบสอบถาม',
        dataIndex: 'username',
        width: "15%",
    },
    {
        title: 'ข้อความ',
        dataIndex: 'detail',
        width: "35%",
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "10%",
    },
    // {
    //     title: 'วันที่เเก้ไข',
    //     dataIndex: 'updatedAt',
    //     width: "10%",
    // },
    {
        title: 'สถานะ',
        width: "20%",
        render: (text, record) => {
            return (
                <>
                    {record.isSendAssistance ?
                        <label>ช่วยเหลือเเล้ว</label>
                        :
                        <label>ยังไม่ได้ช่วยเหลือ</label>
                    }
                </>
            )
        }
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "10%",
    },
]

const formatDate = "DD/MM/YYYY"

export default function InquiryFormInspect() {

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)

    const [form] = Form.useForm()
    const [formSearch] = Form.useForm()

    const audioReboundRef = useRef()

    const [pageCurrent, setPageCurrent] = useState(1)

    const [modal, setModal] = useState({
        isShow: false,
        data: null
    })

    const getNewsAll = async (username, isSendAssistance, startedDate, endDate) => {
        setLoading(true)

        let objSearch = {
            username, isSendAssistance, startedDate, endDate
        }
        // console.log("getNewsAll : ", objSearch)
        const result = await getInquiryFormBySearchFetch(objSearch)
        // console.log("result : ", result)

        let tempList = [];
        result?.map((val, index) => {
            tempList.push({
                index: (index + 1),
                username: val.user?.username ?? "-",
                detail: val.detail,
                isSendAssistance: val.isSendAssistance,
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            type="primary"
                            style={{
                                width: 35
                            }}
                            onClick={() => {
                                audioReboundRef.current.play()
                                form.setFieldsValue({
                                    inquiryFormId: val.inquiryFormId,
                                    isSendAssistance: val.isSendAssistance
                                })
                                setModal({ isShow: true, data: val })
                            }}
                        >
                            <div style={{ marginTop: 0, marginLeft: -8 }}>
                                <Icon icon="lets-icons:eye" style={{ color: "white", width: 20, height: 20 }} />
                            </div>
                        </Button>
                    </>
            });
        });
        setList(tempList)

        setLoading(false)
    }

    const onFinish = async (values) => {
        let obj = {
            inquiryFormId: values.inquiryFormId ? values.inquiryFormId : null,
            isSendAssistance: values.isSendAssistance ? values.isSendAssistance : null
        }

        const result = await updateInquiryFormByIdFetch(obj)
        if (result.isSuccess) {
            Notification('success', 'บันทึกสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถบันทึกได้ กรุณาลองใหม่อีกครั้ง')
        }

        // reload
        getNewsAll(null, null, null, null)

        // set default
        setFormDefault()
    }

    const onSearchFinish = async (values) => {
        let username = values?.username ? values.username : null
        let isSendAssistance = values?.isSendAssistance ? values.isSendAssistance : null
        let startedDate = values?.dateRange ? values.dateRange[0] : null
        let endDate = values?.dateRange ? values.dateRange[1] : null

        await getNewsAll(username, isSendAssistance, startedDate, endDate)
    }

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const setFormDefault = () => {
        form.setFieldsValue({
            newsId: undefined,
            title: undefined,
            isActive: undefined,
        })

        setModal({
            isShow: false,
            data: null,
        })
    }

    useEffect(() => {
        getNewsAll(null, null, null, null)
    }, [])

    return (
        <Row>
            <Col span={24}>
                <label>จัดการผู้ส่งเเบบสอบถาม</label>
            </Col>
            <Col span={24} style={{ paddingBottom: 20 }}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ชื่อผู้ส่งเเบบสอบถาม"
                                name="username"
                                style={{ width: '100%' }}
                            >
                                <Input />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="สถานะ"
                                name="isSendAssistance"
                                style={{ width: '100%' }}
                            >
                                <Select
                                    showSearch
                                    style={{ width: 120 }}
                                    optionFilterProp="children"
                                    allowClear
                                >
                                    <Option key={0} value={true}>ช่วยเเล้ว</Option>
                                    <Option key={1} value={false}>ยังไม่ช่วย</Option>
                                </Select>
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ห้วงวันที่สร้าง" name="dateRange"
                                style={{ width: '100%' }}
                            >
                                <RangePicker />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10, marginTop: 3 }}>
                            <Button
                                style={{ float: 'right', width: 70 }}
                                type="primary"
                                onClick={() => {
                                    audioReboundRef.current.play()
                                    formSearch.submit()
                                }}
                            >
                                ค้นหา
                            </Button>
                        </div>
                    </div>
                </Form>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrent,
                        pageSize: 10,
                        total: list.length
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">ข้อมูลการสอบถาม</label></strong>}
                visible={modal.isShow}
                onCancel={() => {

                    // default
                    setFormDefault()
                }}
                width={600}
                onOk={() => {
                    form.submit()
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }} onClick={() =>  audioReboundRef.current.play()}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }} onClick={() =>  audioReboundRef.current.play()}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Row gutter={[6, 6]}>
                                <Col xs={24} md={8} xl={4} >
                                    <label>ชื่อ.สกุล</label>
                                </Col>
                                <Col xs={24} md={16} xl={20} >
                                    {modal.data?.user?.username}
                                </Col>

                                <Col xs={24} md={8} xl={4} >
                                    <label>uid</label>
                                </Col>
                                <Col xs={24} md={16} xl={20} >
                                    {modal.data?.user?.uid}
                                </Col>

                                <Col xs={24} md={8} xl={4} >
                                    <label>อีเมล์</label>
                                </Col>
                                <Col xs={24} md={16} xl={20} >
                                    <a href={"mailto: " + modal.data?.user?.email} style={{ color: "black" }}>{modal.data?.user?.email}</a>
                                </Col>

                                <Col xs={24} md={8} xl={4} >
                                    <label>เบอร์โทร</label>
                                </Col>
                                <Col xs={24} md={16} xl={20} >
                                    {modal.data?.user?.phone}
                                </Col>

                                <Col xs={24} md={8} xl={4} >
                                    <label>ข้อความ</label>
                                </Col>
                                <Col xs={24} md={16} xl={20} >
                                    {modal.data?.detail}
                                </Col>

                                {modal.data?.videoURL ?
                                    <>
                                        <Col xs={24} md={8} xl={4} >
                                            <label>เเนบวิดีโอ</label>
                                        </Col>
                                        <Col xs={24} md={16} xl={20} >
                                            <div style={{ backgroundColor: "black" }}>
                                                <Video
                                                    url={modal.data?.videoURL}
                                                    title={""}
                                                    height={200}
                                                    width={"auto"}
                                                />
                                            </div>
                                        </Col>
                                    </>
                                    : []
                                }

                                {modal.data?.imageURL ?
                                    <>
                                        <Col xs={24} md={8} xl={4} >
                                            <label>เเนบรูปภาพ</label>
                                        </Col>
                                        <Col xs={24} md={16} xl={20} >
                                            <img
                                                src={`${serverUrl}/${modal.data?.imageURL}`}
                                                style={{ maxHeight: 306 }}
                                            />
                                        </Col>
                                    </>
                                    : []
                                }
                            </Row>
                        </Col>

                        <Col span={24}>
                            <Form.Item name="inquiryFormId" style={{ display: "none" }}>
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="isSendAssistance"
                                label="ช่วยเหลือเเล้วใช่ไหม ?"
                                rules={[{ required: true, message: 'กรุณาเลือกสถานะการเเสดง' }]}
                            >
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                >
                                    <Option key={0} value={true}>ช่วยเเล้ว</Option>
                                    <Option key={1} value={false}>ยังไม่ช่วย</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <audio ref={audioReboundRef}>
				<source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
				Your browser does not support the audio element.
			</audio>
        </Row>
    )
}
