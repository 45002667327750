/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react"

import Header from '../../common/components/header'
import Footer from '../../common/components/footer'
import LoadingPage from '../../common/components/loading/page'

import {
    Row,
    Col,
    Modal
} from 'antd'
// import { Icon } from '@iconify/react'
import { useDimensions } from "../../common/mamager/ScreenManager"
import ServiceBoard from "../service/components/ServiceBoard"
import BreadcrumbPage from "../../common/components/breadcrumbPage"
import { serverUrl } from "../../constants"
import { getHighlightAstrologyAllFetch } from "../../common/API/highlight"
import { getPageAboutMeFetch } from "./API"
import parse from 'html-react-parser'
import FaqBoard from "../faq/components/FaqBoard"
import Video from "../../common/components/video"
import ServiceAstrologicalBoard from "../service/components/ServiceAstrologicalBoard"
import Partner from "./components/Partner"
import TalkToUs from "../contract/components/TalkToUs"

export default function AboutMe({ authorized, firebase, user }) {
    const [loading, setLoading] = useState(false)
    const { width } = useDimensions()

    const [highlightAstrology, setHighlightAstrology] = useState(null)
    const [pageAboutMe, setPageAboutMe] = useState(null)

    const getHighlightAstrology = async () => {
        let result = await getHighlightAstrologyAllFetch()
        setHighlightAstrology(result)
    }

    const getPageAboutMe = async () => {
        let result = await getPageAboutMeFetch()
        setPageAboutMe(result)
    }

    const [modalReadMore, setModalReadMore] = useState({
        title: null,
        isShow: false,
        data: null
    })

    const baseApi = async () => {
        setLoading(true)
        await getHighlightAstrology()
        await getPageAboutMe()
        setLoading(false)
    }

    useEffect(() => {
        baseApi()
    }, [])

    return (
        <>
            <Header firebase={firebase} user={user} />
            <BreadcrumbPage pageCurrentName={pageAboutMe?.pageName} />
            <div>
                {!loading ?
                    <>
                        <div
                            style={{
                                display: width > 590 ? "flex" : "grid",
                                alignItems: "flex-start",
                                justifyContent: "center",
                                paddingLeft: width > 925 ? '15%' : 12,
                                paddingRight: width > 925 ? '15%' : 12,
                                paddingTop: 24
                            }}
                        >
                            <img
                                src={`${serverUrl}/${pageAboutMe?.imageIntroURL}`}
                                style={{ height: (width > 925 ? 200 : 130), width: (width > 925 ? 200 : 130) }}
                            />
                            <div style={{ display: "grid", alignItems: "flex-start", paddingLeft: 20, paddingTop: width > 590 ? 0 : 12 }}>
                                <label style={{ fontSize: 24, fontWeight: 600 }}>{pageAboutMe?.title}</label>
                                <label style={{}}>{pageAboutMe ? parse(pageAboutMe?.subtitle) : []}</label>

                                {/* <label style={{ fontSize: 20, fontWeight: 600, paddingBottom: 8 }}>ติดต่อเรา</label>
                                <label style={{ fontSize: 40, paddingBottom: 8, color: "#FF7F00" }}>+66-2-374-xxxx</label>
                                <label style={{ fontSize: 40, paddingBottom: 8, color: "#FF7F00" }}>+66-88-860-9785</label>

                                <label
                                    onClick={() => {
                                        setModalReadMore({
                                            title: "ข้อมูลเพิ่มเติม",
                                            isShow: true,
                                            data: {
                                                detail: "-"
                                            }
                                        })
                                    }}
                                    style={{ textDecoration: "underline", cursor: "pointer" }}
                                >อ่านเพิ่มเติม</label> */}
                            </div>
                        </div>

                        <div style={{ paddingTop: 24, paddingBottom: 24, textAlign: "center" }}>
                            <Row gutter={[8, 8]}>
                                {highlightAstrology?.map(item => {
                                    return (
                                        <Col xs={24} md={8} xl={4}>
                                            <div style={{ border: "1px dotted #D1D1D1", paddingTop: 12, paddingBottom: 20, height: 98 }}>
                                                <center>
                                                    <img
                                                        src={`${serverUrl}/${item?.imageURL}`}
                                                        style={{ height: 40, width: 40, backgroundColor: "#EEEEEE", borderRadius: 6, padding: 4 }}
                                                    />
                                                </center>
                                                <label>{item.title}</label>
                                            </div>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </div>

                        <FaqBoard user={user} />

                        {/* <div style={{ paddingTop: 24, paddingBottom: 24 }}>
                            <div style={{ backgroundColor: "black", display: "flex", alignItems: "center", justifyContent: "center", }}>
                                <Video
                                    url={""}
                                    title={""}
                                    height={200}
                                    width={"auto"}
                                />
                            </div>
                        </div> */}

                        <ServiceBoard />

                        <ServiceAstrologicalBoard />

                        <Partner />

                        <TalkToUs />
                    </>
                    :
                    <LoadingPage loading={loading} />
                }
            </div>

            <Footer />

            <Modal
                maskClosable={false}
                title={<span>{modalReadMore.title}</span>}
                visible={modalReadMore.isShow}
                width={590}
                onCancel={() => {
                    // close modal
                    setModalReadMore({
                        isShow: false,
                        data: null,
                    })
                }}
                footer={[
                    // <Button
                    //     type="primary"
                    //     onClick={() => {

                    //     }}
                    // >
                    //     ปิด
                    // </Button>
                ]}
            >
                <Row>
                    {modalReadMore?.data?.detailImageURL ?
                        {/* <Col span={24}>
                            <img
                                src={`${serverUrl}/${modalAstrologicalContact?.data?.detailImageURL}`}
                                style={{ height: 40, backgroundColor: "#EEEEEE" }}
                            />
                        </Col> */}
                        : []
                    }

                    <Col span={24}>
                        <label>{modalReadMore?.data?.detail ? parse(modalReadMore?.data?.detail) : []}</label>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}
