/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useNavigate } from 'react-router-dom'
import { Row, Col, Button, Space, List, Modal } from "antd"
import moment from 'moment'
import { useDimensions } from '../../../../common/mamager/ScreenManager'
import { serverUrl } from '../../../../constants'
import { useEffect, useState, useRef } from 'react'
import { getExamInspectLogByIdFetch } from '../../API'

const dateTimeFormat = 'DD/MM/YYYY HH:mm'

export default function ExamRoomItem(props) {
    const navigate = useNavigate()
    const { width } = useDimensions()

    const [loading, setLoading] = useState(false)

    const audioReboundRef = useRef()


    const [examInspectLog, setExamInspectLog] = useState()

    const [modal, setModal] = useState({
        isShow: false
    })

    const data = props.item

    async function handleDownloadClick(url, username) {
        // const url = 'your_pdf_file.pdf'; // URL ของไฟล์ PDF

        try {
            const response = await fetch(url); // ดาวน์โหลดไฟล์จาก URL
            const blob = await response.blob(); // สร้าง Blob จากข้อมูลที่ดาวน์โหลด

            // สร้าง URL สำหรับ Blob
            const blobURL = URL.createObjectURL(blob);

            // สร้างลิงก์สำหรับการดาวน์โหลดไฟล์
            const a = document.createElement('a');
            a.href = blobURL;
            a.download = `Certificate-${username}.pdf`; // กำหนดชื่อไฟล์ที่ต้องการให้ดาวน์โหลด

            // คลิกที่ลิงก์เพื่อดาวน์โหลดไฟล์
            document.body.appendChild(a);
            a.click();

            // ลบลิงก์และ URL ที่สร้างขึ้น
            window.URL.revokeObjectURL(blobURL);
            document.body.removeChild(a);
        } catch (error) {
            // console.error('Error downloading PDF:', error);
        }
    }

    const getExamInspectLogById = async () => {
        setLoading(true)

        if (props?.user?.uid) {
            const result = await getExamInspectLogByIdFetch(data?.examRoomId)
            // console.log("getExamInspectLogByIdFetch : ", result)
            setExamInspectLog(result)
        }

        setLoading(false)
    }

    const getBaseApi = async () => {
        await getExamInspectLogById()
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    // console.log("data : ", data)

    return (
        <>
            <Col xs={24} lg={12} style={width > 991 ? (props.index % 2 !== 0 ? { paddingBottom: 14, paddingRight: 7.5 } : { paddingBottom: 14, paddingLeft: 7.5 }) : { paddingBottom: 14 }} >
                <List
                    header={<label style={{ paddingLeft: 10, fontWeight: 600 }}>{data?.course?.title ?? "-"}</label>}
                    footer={
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end"
                            }}
                        >
                            <Space size={20}>
                                {[1, 2, 3, 4].includes(data?.exam_room_status?.examRoomStatusId) ?
                                    <>
                                        {examInspectLog?.length > 0 ?
                                            <Button
                                                type="primary"
                                                onClick={async () => {
                                                    audioReboundRef.current.play()
                                                    await getExamInspectLogById()
                                                    setModal({ isShow: true })
                                                }}
                                                style={{}}
                                            >
                                                <label style={{ cursor: "pointer" }}>ดูประวัติสอบ</label>
                                            </Button>
                                            : []
                                        }

                                        <Button
                                            type="primary"
                                            disabled={[2, 3].includes(data?.exam_room_status?.examRoomStatusId) ? false : true}
                                            onClick={() => {
                                                audioReboundRef.current.play()
                                                navigate("/exam?examRoomId=" + data?.examRoomId)
                                            }}
                                            style={{}}
                                        >
                                            <label style={{ cursor: "pointer" }}>เข้าสอบ</label>
                                        </Button>
                                    </>
                                    : []
                                }

                                {data?.exam_room_status?.examRoomStatusId === 5 ?
                                    <Button
                                        type="primary"
                                        onClick={async () => {
                                            audioReboundRef.current.play()
                                            // console.log("data : ", `${serverUrl}/${data?.certificate?.pdfUrl}`)
                                            await handleDownloadClick(
                                                `${serverUrl}/${data?.certificate?.pdfUrl}`,
                                                data?.user?.username
                                            )
                                        }}
                                        style={{}}
                                    >
                                        <label style={{ cursor: "pointer" }}>ดาวน์โหลดใบประกาศนียบัตร</label>
                                    </Button>
                                    : []
                                }

                                {data?.exam_room_status?.examRoomStatusId === 6 ?
                                    <>
                                        <Button
                                            type="primary"
                                            onClick={async () => {
                                                audioReboundRef.current.play()
                                                await getExamInspectLogById()
                                                setModal({ isShow: true })
                                            }}
                                            style={{}}
                                        >
                                            <label style={{ cursor: "pointer" }}>ดูประวัติสอบ</label>
                                        </Button>

                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                audioReboundRef.current.play()
                                                navigate("/exam?examRoomId=" + data?.examRoomId)
                                            }}
                                            style={{}}
                                        >
                                            <label style={{ cursor: "pointer" }}>เข้าสอบอีกครั้ง</label>
                                        </Button>
                                    </>
                                    : []
                                }

                                {data?.exam_room_status?.examRoomStatusId === 7 ?
                                    <>
                                        <Button
                                            type="primary"
                                            onClick={async () => {
                                                audioReboundRef.current.play()
                                                await getExamInspectLogById()
                                                setModal({ isShow: true })
                                            }}
                                            style={{}}
                                        >
                                            <label style={{ cursor: "pointer" }}>ดูประวัติสอบ</label>
                                        </Button>
                                    </>
                                    : []
                                }
                            </Space>
                        </div>
                    }
                    dataSource={[""]}
                    style={{ backgroundColor: 'white', paddingLeft: 15, paddingRight: 15, borderRadius: 15 }}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                style={{ height: 100 }}
                                description={
                                    <Row>
                                        <Col span={16}>
                                            <Row style={{ paddingLeft: 10 }} gutter={[0, 8]}>
                                                <Col span={24}>
                                                    <label style={{ color: 'black' }}>วันที่ซื้อสิทธิการสอบ {moment(data?.createdAt).format(dateTimeFormat)} ({Number(moment(item.createdAt).format("YYYY")) + 543})</label>
                                                </Col>
                                                <Col span={24}>
                                                    <label style={{ color: 'black' }}>สถานะการสอบ {data?.exam_room_status?.name}</label>
                                                </Col>
                                                <Col span={24}>
                                                    <label style={{ color: 'black' }}>จำนวนครั้งที่สอบ {examInspectLog?.length ?? 0} ครั้ง <span style={{ color: "red" }}>(สอบได้สูงสุด 3 ครั้ง)</span></label>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={8} style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-end" }}>
                                            <img
                                                src={`${serverUrl}/${data?.course?.imgCoverURL}`}
                                                style={{ maxHeight: 100, borderRadius: 8 }}
                                            />
                                        </Col>
                                    </Row>
                                }
                            />
                        </List.Item>
                    )}
                />
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">ประวัติการสอบ</label></strong>}
                visible={modal.isShow}
                onCancel={() => {
                    setModal({ isShow: false })
                }}
                footer={[]}
            >
                <Row gutter={[24, 0]}>
                    <Col span={8} style={{ backgroundColor: "#D9D9D9", padding: 6, textAlign: "center" }}>
                        <label style={{ fontWeight: 600, }}>วันที่ประกาศผลสอบ</label>
                    </Col>
                    <Col span={8} style={{ backgroundColor: "#D9D9D9", padding: 6, textAlign: "center" }}>
                        <label style={{ fontWeight: 600 }}>ผลสอบ</label>
                    </Col>
                    <Col span={8} style={{ backgroundColor: "#D9D9D9", padding: 6, textAlign: "center" }}>
                        <label style={{ fontWeight: 600 }}>คะเเนนรวม</label>
                    </Col>

                    {examInspectLog?.map(item => {
                        return (
                            <>
                                <Col span={8} style={{ padding: 6, textAlign: "center", borderBottom: '1px solid #DDDDDD' }}>
                                    <label style={{}}>
                                        {moment(data?.createdAt).format(dateTimeFormat)} ({Number(moment(item.createdAt).format("YYYY")) + 543})
                                    </label>
                                </Col>
                                <Col span={8} style={{ padding: 6, textAlign: "center", borderBottom: '1px solid #DDDDDD' }}>
                                    <label style={{}}>{item?.exam_room_status?.name ?? "-"}</label>
                                </Col>
                                <Col span={8} style={{ padding: 6, textAlign: "center", borderBottom: '1px solid #DDDDDD' }}>
                                    <label style={{}}>{item.score} / {item.fullScore}</label>
                                </Col>
                            </>
                        )
                    })}
                </Row>
            </Modal>
            <audio ref={audioReboundRef}>
                <source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
        </>

    )
}