import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getFetchUid = async (firebase) => { // language -> th, eng //move
    try {
        const result = await httpClient.get(server.GET_LOGIN_DETECT_URL + `/${firebase}`);
        if (result.data.isSuccess) {
            return result.data.formData;
        } else {
            return null;
        }
    } catch (err) { // status 404
        return null;
    }
}

// const getCheckUserIdOnDBFetch = async (uid) => {
//     try {
//         const result = await httpClient.post(server.GET_CHECK_USER_ID_ON_DATABASE_URL, { uid })
//         if (result.data.isSuccess) {
//             return result.data.formData
//         } else {
//             return null
//         }
//     } catch (err) { // status 404
//         return null
//     }
// }

const getEmailByUsernameFetch = async (value) => { // value[ email ] : 
    try {
        const result = await httpClient.post(server.GET_EMAIL_BY_USERNAME_URL, { value });
        if (result.data.isSuccess) {
            return result.data.formData;
        } else {
            return [];
        }
    } catch (err) { // status 404
        return null;
    }
}

const getEmailPasswordIsOnDbFetch = async (value) => { // value[ email ] : 
    try {
        const result = await httpClient.post(server.GET_EMAIL_PASSWORD_IS_ON_DB_URL, { value });
        if (result.data.isSuccess) {
            return result.data.formData;
        } else {
            return [];
        }
    } catch (err) { // status 404
        return null;
    }
}

export {
    // get
    getFetchUid,
    // getCheckUserIdOnDBFetch
    getEmailByUsernameFetch,
    getEmailPasswordIsOnDbFetch
    
    // update

    // insert

    // delete

}