/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */


import { useNavigate } from 'react-router-dom'
import { Row, Col, Button, Space, List } from "antd"
import moment from 'moment'
import { useDimensions } from '../../../common/mamager/ScreenManager'
import { serverUrl } from '../../../constants'
import { useDispatch, useSelector } from 'react-redux'
import { setBasketOrderStateToSuccess } from '../../../actions/basketOrder.action'
import { getCourseByIdFetch } from '../../classroomStudy/API'
import { useRef } from 'react'


const dateTimeFormat = 'DD/MM/YYYY HH:mm'

export default function ClassroomItem(props) {

    const navigate = useNavigate()
    const data = [""]
    const { width } = useDimensions()

    const dispatch = useDispatch()

    const audioReboundRef = useRef()

    const { resultBasketOrder } = useSelector(({ basketOrderReducer }) => basketOrderReducer)

    const isCourseExpired = (expireDate) => {
        let currentDate = new Date()
        let expirationDate = new Date(expireDate)

        if (currentDate > expirationDate) {
            return true
        } else {
            return false
        }
    }

    return (
        <Col xs={24} lg={12} style={width > 991 ? (props.index % 2 !== 0 ? { paddingBottom: 14, paddingRight: 7.5 } : { paddingBottom: 14, paddingLeft: 7.5 }) : { paddingBottom: 14 }} >
            <List
                header={<label style={{ paddingLeft: 10, fontWeight: 600 }}>{props?.item?.course?.title ?? "-"}</label>}
                footer={
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: (isCourseExpired(props?.item?.expireDate) ? "space-between" : "flex-end")
                        }}
                    >
                        {isCourseExpired(props?.item?.expireDate) ?
                            <>
                                <label>หมดอายุเเล้ว! โปรดต่ออายุ เพื่อเข้าเรียนอีกครั้ง</label>
                                <Space size={20}>
                                    <Button
                                        type="primary"
                                        onClick={async () => {
                                            audioReboundRef.current.play()
                                            if (isCourseExpired(props?.item?.expireDate)) { // หมดอายุเเล้ว
                                                let findCourseId = resultBasketOrder?.find(fnd => fnd.courseId === props?.item?.courseId)
                                                if (!findCourseId) {
                                                    let result = await getCourseByIdFetch(props?.item?.courseId)
                                                    let tmpArr = [...resultBasketOrder, ...[result]]
                                                    dispatch(setBasketOrderStateToSuccess(tmpArr))
                                                    navigate("/payment")
                                                }
                                            }
                                        }}
                                        style={{ width: 100 }}
                                    >
                                        <label style={{ cursor: "pointer" }}>ต่ออายุ</label>
                                    </Button>
                                </Space>
                            </>
                            :
                            <Space size={20}>
                                <Button
                                    type="primary"
                                    disabled={isCourseExpired(props?.item?.expireDate)}
                                    onClick={() => {
                                        audioReboundRef.current.play()
                                        if (!isCourseExpired(props?.item?.expireDate)) { // ยังไม่หมดอายุ
                                            navigate("/classroom-course-study?course-id=" + props.item.course.courseId)
                                        }
                                    }}
                                    style={{ width: 100 }}
                                >
                                    <label style={{ cursor: "pointer" }}>เข้าเรียน</label>
                                </Button>
                            </Space>
                        }

                    </div>
                }
                dataSource={data}
                style={{ backgroundColor: (isCourseExpired(props?.item?.expireDate) ? '#DDDDDD' : 'white'), paddingLeft: 15, paddingRight: 15, borderRadius: 15 }}
                renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta
                            style={{ height: 100 }}
                            description={
                                <Row>
                                    <Col span={16}>
                                        <Row style={{ paddingLeft: 10 }} gutter={[0, 8]}>
                                            <Col span={24}>
                                                <label style={{ color: 'black' }}>วันที่ซื้อคอร์ส {moment(props?.item?.createdAt).format(dateTimeFormat)} ({Number(moment(item.createdAt).format("YYYY")) + 543})</label>
                                            </Col>
                                            <Col span={24}>
                                                <label style={{ color: 'black' }}>วันที่หมดอายุ {moment(props?.item?.expireDate).format(dateTimeFormat)} ({Number(moment(item.createdAt).format("YYYY")) + 543})</label>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={8} style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-end" }}>
                                        <img
                                            src={`${serverUrl}/${props.item?.course?.imgCoverURL}`}
                                            style={{ maxHeight: 100, borderRadius: 8, filter: isCourseExpired(props?.item?.expireDate) ? "grayscale(100%)" : "" }}
                                        />
                                    </Col>
                                </Row>
                            }
                        />
                    </List.Item>
                )}
            />
            <audio ref={audioReboundRef}>
				<source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
				Your browser does not support the audio element.
			</audio>
        </Col>
        
    )
}