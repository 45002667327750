export default function PrivacyPolicyItem(props) {
    return (
        <>
            <p style={{ textAlign: "center", fontSize: props.headerSize }}><strong>นโยบายความเป็นส่วนตัว</strong></p>

            <p><span style={{}}>ขอต้อนรับสู่ Witte-bangkok แพลตฟอร์มที่ดำเนินการโดยสถาบันโหราศาสตร์วิตเตอ-กรุงเทพ โดย ดร.ดวง (ยูเรเนียนกรุ๊ปประเทศไทย 1978) </span></p>
            <p><span style={{}}>Witte-bangkok มีหน้าที่รับผิดชอบภายใต้กฎหมาย และข้อบังคับความเป็นส่วนตัวที่บังคับใช้ (กฎหมายคุ้มครองข้อมูลส่วนบุคคล) อย่างจริงจัง และยึดมั่นต่อการเคารพสิทธิ และให้ความสำคัญเกี่ยวกับความเป็นส่วนตัวของผู้ใช้เว็บไซต์ และแอปพลิเคชันบนมือถือ Witte-bangkok ทั้งหมด (เรียกรวมกันว่า แพลตฟอร์ม)&nbsp; เราตระหนักถึงความสำคัญของข้อมูลส่วนบุคคลที่คุณมอบให้แก่เรา ที่มีหน้าที่รับผิดชอบในการจัดการ ปกป้อง และดำเนินการข้อมูลส่วนบุคคลของคุณอย่างเหมาะสม&nbsp;</span></p>
            <p><span style={{}}>นโยบายความเป็นส่วนตัวนี้ ได้รับการออกแบบมาเพื่อช่วยให้คุณเข้าใจเกี่ยวกับวิธีที่เราเก็บรวบรวม ใช้ เปิดเผย หรือดำเนินการข้อมูลส่วนบุคคลที่คุณมอบให้แก่เรา หรือข้อมูลของคุณที่เราครอบครองไม่ว่าจะในปัจจุบันหรืออนาคต รวมทั้งยังเป็นข้อมูลประกอบการตัดสินใจก่อนที่คุณจะให้ข้อมูลส่วนตัวใดๆ แก่เรา โปรดอ่านนโยบายความเป็นส่วนตัวนี้โดยละเอียด หากคุณมีข้อสงสัยเกี่ยวกับข้อมูลเหล่านี้หรือข้อปฏิบัติว่าด้วยความเป็นส่วนตัวของเรา โปรดดูส่วนของหัวข้อ มีคำถาม ข้อกังวล หรือคำร้องเรียน โปรดติดต่อเรา ในตอนท้ายของนโยบายความเป็นส่วนตัวนี้</span></p>

            <span style={{ fontWeight: "bold" }}>1. ข้อมูลส่วนบุคคลที่เราเก็บรวบรวมจากท่าน</span>
            <p><span style={{}}>ข้อมูลส่วนบุคคลหมายถึงข้อมูลใด ๆ ที่เกี่ยวกับตัวบุคคล ไม่ว่าจะเป็นรูปธรรมหรือไม่ และไม่ว่าจริงหรือเท็จ ซึ่งสามารถใช้เป็นข้อมูลที่ระบุตัวตนของบุคคลจากข้อมูลนั้น (ไม่ว่าทางตรงหรือทางอ้อม) หรือจากข้อมูลนั้น และข้อมูลอื่นใดที่เราได้เข้าถึงหรืออาจเข้าถึง</span></p>

            <span style={{ fontWeight: "bold" }}>2. ระหว่างที่ท่านใช้แพลตฟอร์มและบริการใดของ Witte-bangkok เราอาจเก็บรวบรวมข้อมูลส่วนบุคคลเกี่ยวกับท่าน ดังนี้</span>
            <p><span style={{}}>ผู้เยี่ยมชมทุกคนสามารถเข้าถึงเนื้อหาต่างๆที่เผยแพร่บนเว็บไซต์ของเราได้ อย่างไรก็ตาม คุณลักษณะและบริการบางอย่างอาจต้องมีการลงทะเบียน ผู้ใช้สามารถลงทะเบียน (“เข้าสู่ระบบ”) ได้ที่หน้าเว็บไซต์ Witte-bangkok โดยท่านสามารถเพิ่มสิทธิการเข้าถึงบริการอื่น ๆ ได้โดยการทำตามกระบวนการที่เหมาะสม เช่น การชำระเงินเพื่อเข้าเรียนคอร์ส ตามเงื่อนไขที่ทางเรากำหนด ข้อมูลส่วนบุคคลที่เราเก็บรวบรวมจากท่าน ได้แก่ ข้อมูลใด ๆ ซึ่งสามารถใช้ในการระบุตัวตนของท่านได้ไม่ว่าโดยตรงหรือโดยอ้อม ซึ่งรวมถึงแต่ไม่จำกัดเพียงข้อมูลดังต่อไปนี้</span></p>
            <p style={{ paddingBottom: 0, marginBottom: 0 }}><span>2.1. หลักฐาน ข้อมูลระบุตัวตน เช่น ชื่อ-นามสกุล อายุ เพศ วันเดือนปีเกิด</span></p>
            <p style={{ paddingBottom: 0, marginBottom: 0 }}><span>2.2. ข้อมูลการติดต่อ เช่น หมายเลขโทรศัพท์ อีเมล/จีเมล</span></p>
            <p style={{ paddingBottom: 0, marginBottom: 0 }}><span>2.3. ข้อมูลบัญชี เช่น ชื่อผู้ใช้งานในระบบ บัญชีผู้ใช้งาน รหัสผ่าน ประวัติการใช้งาน</span></p>
            <p style={{ paddingBottom: 0, marginBottom: 0 }}><span>2.4. ข้อมูลการทำธุรกรรมและการเงิน เช่น ประวัติการสั่งซื้อข้อมูลการชำระเงิน บัญชีธนาคาร วีดีโอ</span></p>
            <p style={{ paddingBottom: 0, marginBottom: 0 }}><span>2.5. ขอข้อมูลมัลติมีเดีย เช่น เสียง รูปภาพ วีดีโอ ข้อมูลการแชต</span></p>
            <p style={{}}><span>2.6. ขอข้อมูลอื่นๆเช่น คอร์สที่ชื่นชอบ การศึกษา อาชีพ</span></p>

            {/* <p><span style={{}}></span></p>
            <p style={{ paddingBottom: 0, marginBottom: 0 }}><span>2.1. </span></p> */}

            <span style={{ fontWeight: "bold" }}>3. Witte-bangkok จะเก็บรวบรวมข้อมูลส่วนบุคคลเมื่อใด ?</span><br />
            <span style={{}}>เราจะเก็บรวบรวมข้อมูลส่วนบุคคลของคุณ ได้เเก่</span><br />
            <p style={{ paddingBottom: 0, marginBottom: 0 }}><span>3.1. เมื่อคุณได้เข้าถึง ใช้บริการแพลตฟอร์มของเรา หรือลงทะเบียนสมัครบัญชีผู้ใช้กับเรา</span></p>
            <p style={{ paddingBottom: 0, marginBottom: 0 }}><span>3.2. เมื่อคุณทำข้อตกลงใด ๆ ให้เอกสาร หรือข้อมูลอื่นใดที่เกี่ยวข้องกับการติดต่อระหว่างคุณกับเราหรือเมื่อคุณใช้บริการของเรา</span></p>
            <p style={{ paddingBottom: 0, marginBottom: 0 }}><span>3.3. เมื่อคุณดำเนินธุรกรรมผ่านบริการของเรา</span></p>
            <p style={{ paddingBottom: 0, marginBottom: 0 }}><span>3.4. เมื่อคุณบันทึกเนื้อหาที่สร้างขึ้นซึ่งอัปโหลดบนแพลตฟอร์มของเรา</span></p>
            <p style={{ paddingBottom: 0, marginBottom: 0 }}><span>3.5. เมื่อคุณใช้ระบบการเเชท หรือพูดคุยบนแฟลตฟอร์ม</span></p>
            <p style={{  }}><span>3.7. เมื่อคุณลงทะเบียนหรือสมัคร เพื่อรับโปรโมชั่น ส่วนลดคอร์สหรืออื่น ๆ จากเรา</span></p>

            <span style={{ fontWeight: "bold" }}>4. วัตถุประสงค์</span>
            <p style={{ paddingBottom: 0, marginBottom: 0 }}><span>4.1. เพื่อตรวจสอบตัวตนของผู้ใช้บริการ</span></p>
            <p style={{ paddingBottom: 0, marginBottom: 0 }}><span>4.2. เพื่ออำนวยความสะดวกให้แก่คุณในการใช้บริการ หรือเข้าใช้แพลตฟอร์ม</span></p>
            <p style={{ paddingBottom: 0, marginBottom: 0 }}><span>4.3. เพื่อทำความเข้าใจประสบการณ์ รีวิว เเละฟีดแบคการใช้บริการบนแพลตฟอร์ม</span></p>
            <p style={{ paddingBottom: 0, marginBottom: 0 }}><span>4.4. เพื่อรักษาความปลอดภัยต่อบัญชีผู้ใช้บริการ</span></p>
            <p style={{ paddingBottom: 0, marginBottom: 0 }}><span>4.5. เพื่อการวิเคราะห์ข้อมูล ทดสอบ ค้นคว้า ตรวจสอบ วิเคราะห์การใช้งาน และการพัฒนา</span></p>
            <p style={{ paddingBottom: 0, marginBottom: 0 }}><span>4.6. เพื่อเป็นช่องทางการติดต่อกับผู้ใช้บริการในด้านต่าง ๆ เช่นการช่วยสอนใช้งานบนแพลตฟอร์ม</span></p>
            <p style={{ paddingBottom: 0, marginBottom: 0 }}><span>4.7. เพื่อเป็นข้อมูลยืนยันการผ่านการทดสอบ หรือจบหลักสูตรวิชาที่ได้เรียน</span></p>
            <p style={{ paddingBottom: 0, marginBottom: 0 }}><span>4.8. เพื่อคัดสรรค์เนื้อหาคอร์สเรียนให้ตรงกับที่คุณต้องการมากที่สุด</span></p>
            <p style={{  }}><span>4.10. เพื่อทำการเก็บสถิติและทำการวิจัยในด้านการตลาด (Marketing)</span></p>

            <span style={{ fontWeight: "bold" }}>5. การรับรองมาตรการในการรักษาความปลอดภัย ข้อมูลส่วนบุคคล</span>
            <p><span style={{}}>เรานำมาตรการด้านความปลอดภัยต่างๆ ที่หลากหลายมาใช้ด้วยความพยายามอย่างดีที่สุด </span><span style={{}}>ข้อมูลส่วนบุคคลของผู้ใช้บริการจะถูกนำมาประมวลผลโดยจำกัดเท่าที่จำเป็น และจะเป็นไปโดยชอบด้วยกฎหมายคุ้มครองข้อมูลส่วนบุคคล ป้องกันมิให้ข้อมูลสูญหาย การเข้าถึง ทำลาย ใช้ ดัดแปลง แก้ไข หรือเปิดเผยข้อมูลส่วนบุคคลโดยไม่ได้รับอนุญาต มีความเป็นธรรม โปร่งใส และจะไม่ถูกประมวลผลต่อไปในลักษณะที่ไม่สอดคล้องกับวัตถุประสงค์ที่ได้กล่าวไว้ จำกัดการเข้าถึงซึ่งข้อมูลส่วนบุคคล เฉพาะบุคคลที่จำเป็นต้องเข้าถึงเท่านั้น&nbsp;โดยที่บุคคลเหล่านั้นต้องปฏิบัติตามข้อกำหนดการรักษาข้อมูลที่เป็นความลับอย่างเข้มงวด และตรวจสอบอย่างตลอดเวลา</span></p>

            <span style={{ fontWeight: "bold" }}>6. การเพิกถอนให้ความยินยอมต่อการใช้ เปิดเผย เก็บ หรือประมวลผลข้อมูลส่วนบุคคลของผู้ใช้งาน สามารถติดต่อมาทาง Witte-bangkok ตามที่ระบุด้านล่างนี้ ทั้งนี้ภายใต้เงื่อนไข ข้อจำกัดที่กำหนดภายใต้กฎหมาย หรือกฎเกณฑ์</span>
            {/* <ul /> */}
            <span style={{}}>โปรดควรรับทราบว่าหากคุณแจ้งการเพิกถอนการให้ความยินยอมของคุณต่อการใช้ เปิดเผย เก็บ หรือ ประมวลผลข้อมูลส่วนบุคคลของคุณโดยเรา ในการใช้งานแพลตฟอร์ม Witte-bangkok ตามวัตถุประสงค์และตามลักษณะที่ระบุด้านบน หรือใช้สิทธิของท่านภายใต้กฎหมายที่เกี่ยวข้อง เราอาจจะไม่อยู่ในสถานะให้บริการแก่ท่าน และเราจะไม่ต้องรับผิดในกรณีที่เราหยุดให้บริการแก่คุณ หรือหยุดปฏิบัติตามสัญญาที่มีกับคุณ เราขอสงวนสิทธิและรักษาสิทธิในการได้รับการเยียวยาใดๆ ที่เรามีภายใต้กฎหมายในสถานการณ์ดังกล่าว&nbsp;</span>
            <ul />
            
            <span style={{ fontWeight: "bold" }}>7. ผู้เยาว์</span>
            <p><span style={{}}>หากท่านมีอายุต่ำกว่า 20 ปี หรือมีข้อจำกัดความสามารถตามกฎหมาย ในการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน เราอาจจำเป็นต้องให้ผู้ปกครองหรือผู้มีอำนาจปกครองตามกฎหมายของท่านให้ความยินยอมแก่เราก่อน หากเราทราบว่ามีการเก็บรวบรวมข้อมูลส่วนบุคคลจากผู้มีอายุต่ำกว่า 20 ปีโดยไม่ได้รับความยินยอมจากผู้ปกครองหรือผู้มีอำนาจปกครองตามกฎหมาย เราจะดำเนินการลบข้อมูลนั้นออกจากเซิร์ฟเวอร์ของเราโดยไม่ชักช้า</span></p>

            <span style={{ fontWeight: "bold" }}>8. ระยะเวลาจัดเก็บข้อมูลส่วนบุคคล</span>
            <p><span style={{}}>เราจะเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้ตามระยะเวลาที่จำเป็นในระหว่างที่ท่านเป็นลูกค้าหรือมีความสัมพันธ์อยู่กับเรา หรือ ตลอดระยะเวลาที่จำเป็นเพื่อให้บรรลุวัตถุประสงค์ที่เกี่ยวข้องกับนโยบายความเป็นส่วนตัวฉบับนี้ ซึ่งอาจจำเป็นต้องเก็บรักษาไว้ต่อไปภายหลังจากนั้นหากมีกฎหมายกำหนดไว้ เราจะลบ ทำลาย หรือทำให้เป็นข้อมูลที่ไม่สามารถระบุตัวตนของท่านได้ เมื่อหมดความจำเป็นหรือสิ้นสุดระยะเวลาดังกล่าว</span></p>

            <span style={{ fontWeight: "bold" }}>9. วิธีการเก็บรักษาข้อมูลส่วนบุคคล</span>
            <p><span style={{}}>เราจะเก็บรักษาข้อมูลส่วนบุคคลของท่านในรูปแบบเอกสารและรูปแบบอิเล็กทรอนิกส์</span></p>

            <span style={{ fontWeight: "bold" }}>10. การส่งหรือโอนข้อมูลส่วนบุคคลไปต่างประเทศ</span>
            <p><span style={{}}>เรามีการโอนข้อมูลส่วนบุคคลของท่านไปยังเซิร์ฟเวอร์ที่มีการดูเเลเป็นอย่างดี เเละควบคุมอย่างเข็มงวด เราจะดำเนินการตามมาตรการต่าง ๆ เพื่อให้มั่นใจว่าการโอนข้อมูลส่วนบุคคลของท่านไปยังประเทศปลายทางนั้นมีมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลอย่างเพียงพอและเป็นไปตามที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลกำหนดไว้</span></p>

            <span style={{ fontWeight: "bold" }}>11. การเปลี่ยนแปลงนโยบายความเป็นส่วนตัว</span>

            <p><span style={{}}>เราอาจแก้ไขนโยบายนี้เป็นครั้งคราว การเปลี่ยนแปลงดังกล่าวจะถูกเน้นเมื่อเข้าถึงบริการและคุณสามารถเข้าถึงนโยบายเวอร์ชั่นล่าสุดของเราบนเเพลตฟอร์ม Witte-bangkok นี้</span></p>

            <p><span style={{}}>หากมีคำถามเพิ่มเติม สามารถติดต่อเราได้ที่ </span><a href="mailto: wittebangkok@gmail.com">wittebangkok@gmail.com</a><span style={{}}> หรือ</span><span style={{}}>หรือโทร 02-114-3464</span></p>

            <p><span style={{}}>ฉบับวันที่ 11 กุมภาพันธ์ 2567</span></p>
        </>
    )
}
