import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getFeedbackByIdFetch = async (uid, feedbackCategoryId) => {
    try {
        const result = await httpClient.post(server.GET_FEEDBACK_BY_ID_URL, { uid, feedbackCategoryId })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getFeedbackBySearchFetch = async (value) => {
    try {
        const result = await httpClient.post(server.GET_FEEDBACK_BY_SEARCH_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertFeedbackFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_FEEDBACK_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getFeedbackByIdFetch,
    getFeedbackBySearchFetch,

    // insert
    insertFeedbackFetch

    // update

    // delete
    
}