import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/firestore'

const firebaseConfig = {
    // apiKey: "AIzaSyCK-aSnFPLEiFt4OyxwolYIJmVTp-sP51c",
    // authDomain: "cloud.toflyn.com",
    // projectId: "cloud-toflyn",
    // storageBucket: "cloud-toflyn.appspot.com",
    // messagingSenderId: "667374851841",
    // appId: "1:667374851841:web:172fa5a6f3f729cbcf6d00",
    // measurementId: "G-JY8KE32XNT"

    apiKey: "AIzaSyDdQnSIEAFU_NAp-VRITxOp4ao8XDQcf98",
    authDomain: "astrology-220be.firebaseapp.com",
    projectId: "astrology-220be",
    storageBucket: "astrology-220be.appspot.com",
    messagingSenderId: "615535190898",
    appId: "1:615535190898:web:dad1f9f74e098e6226f3eb",
    measurementId: "G-BK1C96PYZZ"
}

firebase.initializeApp(firebaseConfig)
const auth = firebase.auth()
firebase.analytics()
export { auth }
export default firebase
