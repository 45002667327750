import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getHighlightAstrologyAllFetch = async () => {
    try {
        const result = await httpClient.get(server.GET_HIGHLIGHT_ASTROLOGY_ALL_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getHighlightAstrologyBySearchFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.GET_HIGHLIGHT_ASTROLOGY_BY_SEARCH_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertHighlightAstrologyFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_HIGHLIGHT_ASTROLOGY_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateHighlightAstrologyByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_HIGHLIGHT_ASTROLOGY_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteHighlightAstrologyByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(server.DELETE_HIGHLIGHT_ASTROLOGY_BY_ID_URL + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getHighlightAstrologyAllFetch,
    getHighlightAstrologyBySearchFetch,

    // insert
    insertHighlightAstrologyFetch,

    // update
    updateHighlightAstrologyByIdFetch,

    // delete
    deleteHighlightAstrologyByIdFetch
}
