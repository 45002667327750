/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Col,
  Form,
  Row,
  DatePicker,
  Input,
  Button,
  Table,
  Image,
  Modal,
  Select,
  Checkbox,
  TimePicker,
  Popconfirm,
} from "antd";
import { useEffect, useState, useRef } from "react";
import moment from "moment";
import {
  deleteClassroomByIdFetch,
  deletePredictionRoomByIdFetch,
  deleteUserByIdFetch,
  getMyClassroomByIdFetch,
  getMyPredictionRoomByIdFetch,
  getUserBySearchFetch,
  updateClassroomByIdFetch,
  updatePredictionRoomByIdFetch,
  updateUserInfoByIdFetch,
} from "./API";
import LoadingPage from "../../../../common/components/loading/page";
import { FaCog } from "react-icons/fa";
import locale from "antd/es/date-picker/locale/de_DE";
import {
  detectEmail,
  detectPhone,
} from "../../../../common/mamager/DetectManager";
import { Notification } from "../../../../common/components/notification";
import { Icon } from "@iconify/react";
import { getCountryFetch, getProvincesFetch } from "../../../register/API";

const { RangePicker } = DatePicker;
const { Option } = Select;

// const formatDate = "DD/MM/YYYY"
const formatDateTime = "DD/MM/YYYY HH:mm";

export default function UserManage(props) {
  const columnsฺ = [
    {
      title: "No",
      dataIndex: "index",
      width: "5%",
    },
    {
      title: "ภาพโปรไฟล์",
      dataIndex: "imageProfile",
      align: "center",
      width: "10%",
      render: (text, record) => {
        return (
          <>
            {record.imageProfile ? (
              <Image
                style={{
                  height: "60px",
                  width: "60px",
                  borderRadius: "5%",
                }}
                src={record.imageProfile}
              />
            ) : (
              <Image
                style={{
                  height: "60px",
                  width: "60px",
                  borderRadius: "5%",
                }}
                src={`./assets/images/profile/personDefault.jpg`}
              />
            )}
          </>
        );
      },
    },
    {
      title: "ชื่อผู้ใช้งาน",
      dataIndex: "username",
      width: "25%",
    },
    {
      title: "UID",
      dataIndex: "uid",
      width: "30%",
    },
    {
      title: "วันที่สร้าง",
      dataIndex: "createdAt",
      align: "center",
      width: "10%",
      render: (text, record) => {
        return <div>{record.createdAt}</div>;
      },
    },
    {
      title: <FaCog />,
      dataIndex: "operator",
      align: "center",
      width: "20%",
    },
  ];

  const [formSearch] = Form.useForm();
  const [form] = Form.useForm();

  const audioReboundRef = useRef();

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [classroom, setClassroom] = useState();
  const [predictRoom, setPredictRoom] = useState();

  const [selectUser, setSelectUser] = useState();

  // console.log("cuUser", selectUser);

  const [modal, setModal] = useState({
    isShow: false,
    title: null,
    data: null,
  });

  const dateExpireSelectedRef = useRef(null);

  const [modalExpireDate, setModalExpireDate] = useState({
    isShow: false,
    title: null,
    data: null,
    type: null,
  });

  const [day, setDay] = useState([]);
  const [month, setMonth] = useState([]);
  const [year, setYear] = useState([]);

  const [pageCurrent, setPageCurrent] = useState(1);

  const [provincesData, setProvincesData] = useState([]);
  const [countryData, setCountryData] = useState([]);

  const getProvinces = async () => {
    let result = await getProvincesFetch();
    setProvincesData(result);
  };

  const getCountry = async () => {
    let result = await getCountryFetch();
    setCountryData(result);
  };

  const onPagine = (n) => {
    setPageCurrent(n.current);
  };

  const getDay = () => {
    let tmpDay = [];
    for (let i = 1; i <= 31; i++) {
      tmpDay.push({
        label: i,
        value: i,
      });
    }
    setDay(tmpDay);
  };

  const getMonth = () => {
    let tmpMonth = [
      { label: "มกราคม", value: 1 },
      { label: "กุมภาพันธ์", value: 2 },
      { label: "มีนาคม", value: 3 },
      { label: "เมษายน", value: 4 },
      { label: "พฤษภาคม", value: 5 },
      { label: "มิถุนายน", value: 6 },
      { label: "กรกฎาคม", value: 7 },
      { label: "สิงหาคม", value: 8 },
      { label: "กันยายน", value: 9 },
      { label: "ตุลาคม", value: 10 },
      { label: "พฤศจิกายน", value: 11 },
      { label: "ธันวาคม", value: 12 },
    ];
    setMonth(tmpMonth);
  };

  const getYear = () => {
    let tmpYear = [];
    let a = moment().format("YYYY");
    let yearCurrent = Number(a) + 543 - 1;
    for (let i = yearCurrent; i > yearCurrent - 100; i--) {
      tmpYear.push({
        label: i + 1,
        value: i + 1,
      });
    }
    setYear(tmpYear);
  };

  const onSearchFinish = async (value) => {
    setLoading(true);

    let username = value.username ?? null;
    let startedDate = value.dateRange
      ? moment(value.dateRange[0]).format(formatDateTime)
      : null;
    let endDate = value.dateRange
      ? moment(value.dateRange[1]).format(formatDateTime)
      : null;

    let obj = {
      username,
      startedDate,
      endDate,
    };

    await getUserManageAll(obj);
    setLoading(false);
  };

  const getUserManageAll = async (value) => {
    try {
      setLoading(true);

      const result = await getUserBySearchFetch(value);
      // console.log("getUserManageAllFetch : ", result);

      let tempList = [];
      result?.map((val, index) => {
        tempList.push({
          index: index + 1,
          username: val.username,
          uid: val.uid,
          imageProfile: val.imageProfile,
          createdAt: val.createdAt
            ? moment(val.createdAt).format(formatDateTime)
            : "-",
          updatedAt: val.updatedAt
            ? moment(val.updatedAt).format(formatDateTime)
            : "-",
          operator: (
            <>
              <Button
                type="default"
                style={{ width: 100 }}
                onClick={async () => {
                  audioReboundRef?.current?.play();

                  const resultClassroom = await getMyClassroomByIdFetch(
                    val.uid
                  );
                  // console.log(resultClassroom);
                  setClassroom(resultClassroom);

                  const resultPredictRoom = await getMyPredictionRoomByIdFetch(
                    val.uid
                  );
                  // console.log(resultPredictRoom);

                  setPredictRoom(resultPredictRoom);

                  form.setFieldsValue({
                    phone: val.phone,
                    firstname: val.firstname,
                    lastname: val.lastname,
                    dateOfBirth: val.dateOfBirth,
                    monthOfBirth: val.monthOfBirth,
                    yearOfBirth: val.yearOfBirth,
                    province: val.provinceId,
                    country: val.countryId,
                    timeOfBirth: moment(val.timeOfBirth, "HH:mm"),
                    uid: val.uid,
                  });
                  // console.log(val.province, val.countryId);

                  setSelectUser(val.uid);

                  setModal({
                    isShow: true,
                    title: "รายละเอียดข้อมูล",
                    data: val,
                  });
                }}
              >
                <div style={{}}>ดูรายละเอียด</div>
              </Button>
              {"  "}

              <Popconfirm
                title="คุณยืนยันลบหรือไม่ ?"
                okText={<span style={{ width: 50 }}>ใช่</span>}
                onConfirm={async () => {
                  await handleUserDelete(val.uid);

                  // reload
                  await getUserManageAll({
                    username: null,
                    startedDate: null,
                    endDate: null,
                  });
                }}
                cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
              >
                <Button
                  danger
                  type="primary"
                  style={{
                    width: 35,
                    borderRadius: 50,
                  }}
                  onClick={() => audioReboundRef?.current?.play()}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{}}>
                      <Icon
                        icon="fluent:delete-16-regular"
                        style={{ color: "white", width: 20, height: 20 }}
                      />
                    </div>
                  </div>
                </Button>
              </Popconfirm>
            </>
          ),
        });
      });
      // console.log("tempList : ", tempList)
      setList(tempList);

      setLoading(false);
    } catch (err) {
      // ...
    }
  };

  const onFinish = async (value) => {
    let obj = {
      phone: value.phone ?? null,
      firstname: value.firstname ?? null,
      lastname: value.lastname ?? null,
      dateOfBirth: value.dateOfBirth ?? 0,
      monthOfBirth: value.monthOfBirth ?? 0,
      yearOfBirth: value.yearOfBirth ?? 0,
      provinceId: value.province ?? null,
      countryId: value.country ?? null,
      timeOfBirth: moment(String(value.timeOfBirth)).format("HH:mm"),
      uid: value.uid,
    };

    // console.log("obj update user : ", obj);
    const result = await updateUserInfoByIdFetch(obj);
    if (result.isSuccess) {
      Notification("success", "บันทึกสำเร็จ");

      //REFRESH USER INFO
      await getUserManageAll({
        username: null,
        startedDate: null,
        endDate: null,
      });
    } else {
      Notification("error", "ไม่สามารถบันทึกได้ กรุณาลองใหม่อีกครั้ง");
    }
  };

  const handleUserDelete = async (uid) => {
    const result = await deleteUserByIdFetch(uid);
    if (result.isSuccess) {
      Notification("success", "ลบสำเร็จ");
    } else {
      Notification("error", "ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง");
    }
  };

  const getBaseApi = async () => {
    try {
      getDay();
      getMonth();
      getYear();
      getProvinces();
      getCountry();

      await getUserManageAll({
        username: null,
        startedDate: null,
        endDate: null,
      });
    } catch (err) {
      // ...
    }
  };

  const deletePredictionRoom = async (id) => {
    const result = await deletePredictionRoomByIdFetch(id);

    if (result.isSuccess) {
      const resultPredictRoom = await getMyPredictionRoomByIdFetch(selectUser);
      setPredictRoom(resultPredictRoom);

      Notification("success", "ลบพยากรณ์สำเร็จ");
    } else {
      Notification("error", "ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง");
    }
  };

  const deleteClassroom = async (id) => {
    const result = await deleteClassroomByIdFetch(id);

    if (result.isSuccess) {
      const resultClassroom = await getMyClassroomByIdFetch(selectUser);
      setClassroom(resultClassroom);

      Notification("success", "ลบคอร์สเรียนสำเร็จ");
    } else {
      Notification("error", "ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง");
    }
  };

  useEffect(() => {
    getBaseApi();
  }, []);

  // console.log("classroom : ", classroom)
  // console.log("predictRoom : ", predictRoom)
  // console.log("modal.data : ", modal.data)
  // console.log("------------------------------------")

  return (
    <>
      <Row>
        <Col span={5}>
          <label>จัดการสมาชิก</label>
        </Col>

        <Col span={19} style={{ paddingBottom: 20 }}>
          <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div style={{ paddingLeft: 10 }}>
                <Form.Item
                  label="ชื่อผู้ใช้"
                  name="username"
                  style={{ width: "100%" }}
                >
                  <Input />
                </Form.Item>
              </div>

              <div style={{ paddingLeft: 10 }}>
                <Form.Item
                  label="ห้วงวันที่สร้าง"
                  name="dateRange"
                  style={{ width: "100%" }}
                >
                  <RangePicker />
                </Form.Item>
              </div>

              <div style={{ paddingLeft: 10, marginTop: 3 }}>
                <Button
                  style={{ float: "right", width: 70 }}
                  type="primary"
                  onClick={() => {
                    audioReboundRef.current.play();
                    formSearch.submit();
                  }}
                >
                  ค้นหา
                </Button>
              </div>
            </div>
          </Form>
        </Col>

        <Col span={24}>
          {!loading ? (
            <Table
              loading={loading}
              columns={columnsฺ}
              dataSource={list}
              pagination={{
                current: pageCurrent,
                pageSize: 10,
                total: list.length,
              }}
              onChange={(n) => onPagine(n)}
            ></Table>
          ) : (
            <LoadingPage loading={loading} />
          )}
        </Col>
      </Row>

      <Modal
        title={
          <strong>
            <label className="topic-color-bold">{modal.title}</label>
          </strong>
        }
        visible={modal.isShow}
        onCancel={() => {
          setSelectUser(null);
          setModal({ isShow: false, title: null, data: null });
        }}
        width={600}
        footer={[]}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row gutter={[0, 8]}>
            <Col xs={24} md={8} xl={6}>
              <label>ชื่อผู้ใช้งาน</label>
            </Col>
            <Col xs={24} md={16} xl={18}>
              <label>{modal.data?.username}</label>
            </Col>

            <Col xs={24} md={8} xl={6}>
              <label>uid</label>
            </Col>
            <Col xs={24} md={16} xl={18}>
              <label>{modal.data?.uid}</label>
              <Form.Item name="uid" style={{ display: "none" }}>
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} md={8} xl={6}>
              <label>อีเมล</label>
            </Col>
            <Col xs={24} md={16} xl={18}>
              <a
                href={"mailto: " + modal.data?.email}
                style={{ color: "black" }}
              >
                {modal.data?.email}
              </a>
            </Col>

            <Col xs={24} md={8} xl={6}>
              <label>เบอร์โทร</label>
            </Col>
            <Col xs={24} md={16} xl={18}>
              {/* <label>{modal.data?.phone}</label> */}
              <Form.Item
                // label={"เบอร์โทร"}
                name="phone"
                rules={[{ required: true, validator: detectPhone }]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            {/* <Col xs={24} md={8} xl={6}>
                            <label>วันเกิด</label>
                        </Col>
                        <Col xs={24} md={16} xl={18}>
                            <label>{moment(`${modal.data?.dateOfBirth}/${modal.data?.monthOfBirth}/${modal.data?.yearOfBirth} ${modal.data?.timeOfBirth}`).format(formatDateTime)}</label>
                        </Col> */}

            <Col span={24}>
              <div style={{ backgroundColor: "#EEEEEE", padding: 12 }}>
                <Row gutter={[12, 0]}>
                  <Col span={24}>
                    <label>วันเกิด</label>
                  </Col>

                  <Col xs={24} sm={12} md={8} lg={5}>
                    <Form.Item
                      name="dateOfBirth"
                      label="วัน"
                      style={{ width: "100%" }}
                      rules={[{ required: true, message: "กรุณาเลือกวัน" }]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="วัน"
                        optionFilterProp="children"
                        allowClear
                      >
                        {day.map((val, index) => (
                          <Option key={index} value={val.value}>
                            {val.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12} md={8} lg={7}>
                    <Form.Item
                      name="monthOfBirth"
                      label="เดือน"
                      style={{ width: "100%" }}
                      rules={[{ required: true, message: "กรุณาเลือกเดือน" }]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="เดือน"
                        optionFilterProp="children"
                        allowClear
                      >
                        {month.map((val, index) => (
                          <Option key={index} value={val.value}>
                            {val.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                      name="yearOfBirth"
                      label="ปี"
                      style={{ width: "100%" }}
                      rules={[{ required: true, message: "กรุณาเลือกปี" }]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="ปี"
                        optionFilterProp="children"
                        allowClear
                      >
                        {year.map((val, index) => (
                          <Option key={index} value={val.value}>
                            {val.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                      name="timeOfBirth"
                      label="เวลา"
                      style={{ width: "100%" }}
                      // rules={[{ required: true, message: 'กรุณาเลือกเวลา' }]}
                    >
                      <TimePicker
                        showNow={false}
                        placeholder="เวลา"
                        style={{ width: "100%" }}
                        locale={{
                          ...locale,
                          lang: {
                            ...locale.lang,
                            // now: "เวลาปัจจุบัน",
                            ok: "ตกลง",
                          },
                        }}
                        format={"HH:mm"}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col xs={24} md={8} xl={6}>
              <label>ชื่อจริง</label>
            </Col>
            <Col xs={24} md={16} xl={18}>
              {/* <label>{modal.data?.firstname}  {modal.data?.lastname}</label> */}
              <Form.Item
                // label="ชื่อจริง"
                name="firstname"
                style={{ width: "100%" }}
                rules={[{ required: true, message: "กรุณากรอกชื่อจริง" }]}
              >
                <Input
                  onBlur={(e) =>
                    form.setFieldsValue({ firstname: e.target.value })
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={8} xl={6}>
              <label>นามสกุล</label>
            </Col>
            <Col xs={24} md={16} xl={18}>
              {/* <label>{modal.data?.firstname}  {modal.data?.lastname}</label> */}
              <Form.Item
                // label="นามสกุล"
                name="lastname"
                style={{ width: "100%" }}
                rules={[{ required: true, message: "กรุณากรอกนามสกุล" }]}
              >
                <Input
                  onBlur={(e) =>
                    form.setFieldsValue({ lastname: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8} xl={6}>
              <label>สถานที่ (จังหวัด)</label>
            </Col>
            <Col xs={24} md={16} xl={18}>
              <Form.Item
                // label="สถานที่ (จังหวัด)"
                name="province"
                style={{ width: "100%" }}
                rules={[{ required: true, message: "กรุณาเลือกจังหวัด" }]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="เลือกจังหวัด"
                  optionFilterProp="children"
                  allowClear
                >
                  {provincesData.map((val, index) => (
                    <Option key={index} value={val.provinceId}>
                      {val.provinceNameTH}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8} xl={6}>
              <label>ประเทศ</label>
            </Col>
            <Col xs={24} md={16} xl={18}>
              <Form.Item
                // label="ประเทศ"
                name="country"
                style={{ width: "100%" }}
                rules={[{ required: true, message: "กรุณาเลือกประเทศ" }]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="เลือกประเทศ"
                  optionFilterProp="children"
                  allowClear
                >
                  {countryData.map((val, index) => (
                    <Option key={index} value={val.countryId}>
                      {val.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} md={8} xl={6}>
              <label>วันที่สร้าง</label>
            </Col>
            <Col xs={24} md={16} xl={18}>
              <label>
                {moment(modal.data?.createdAt).format(formatDateTime)}
              </label>
            </Col>

            <Col span={24} style={{ paddingBottom: 24 }}>
              <Button
                type="primary"
                onClick={() => {
                  form.submit();
                }}
              >
                บันทึกการปรับเเก้ User
              </Button>
            </Col>
          </Row>
        </Form>

        <Row>
          <Col span={24}>
            <label>คอร์สเรียนที่ซื้อ</label>
            <Row>
              <Col
                span={3}
                style={{
                  borderTop: "1px solid gray",
                  borderLeft: "1px solid gray",
                  backgroundColor: "#EEEEEE",
                }}
              >
                <div style={{ padding: 8, textAlign: "center" }}>
                  <label>No.</label>
                </div>
              </Col>
              <Col
                span={11}
                style={{
                  borderTop: "1px solid gray",
                  borderLeft: "1px solid gray",
                  borderRight: "1px solid gray",
                  backgroundColor: "#EEEEEE",
                }}
              >
                <div style={{ padding: 8 }}>
                  <label>ชื่อคอร์ส</label>
                </div>
              </Col>
              <Col
                span={6}
                style={{
                  borderTop: "1px solid gray",
                  borderRight: "1px solid gray",
                  backgroundColor: "#EEEEEE",
                }}
              >
                <div style={{ padding: 8, textAlign: "center" }}>
                  <label>วันหมดอายุ</label>
                </div>
              </Col>
              <Col
                span={4}
                style={{
                  borderTop: "1px solid gray",
                  borderRight: "1px solid gray",
                  backgroundColor: "#EEEEEE",
                }}
              >
                <div style={{ padding: 8, textAlign: "center" }}>
                  <Icon icon="eva:options-2-fill" />
                </div>
              </Col>
            </Row>

            {classroom?.length === 0 ? (
              <Row>
                <Col
                  span={24}
                  style={{
                    borderTop: "1px solid gray",
                    borderBottom: "1px solid gray",
                    borderLeft: "1px solid gray",
                    borderRight: "1px solid gray",
                  }}
                >
                  <div style={{ padding: 8, textAlign: "center" }}>
                    <label>ไม่มีการสั่งซื้อ</label>
                  </div>
                </Col>
              </Row>
            ) : (
              classroom?.map((item, index) => {
                return (
                  <>
                    <Row>
                      <Col
                        span={3}
                        style={{
                          borderTop: index === 0 ? "1px solid gray" : "",
                          borderBottom: "1px solid gray",
                          borderLeft: "1px solid gray",
                        }}
                      >
                        <div style={{ padding: 8, textAlign: "center" }}>
                          <label>{index + 1}</label>
                        </div>
                      </Col>

                      <Col
                        span={11}
                        style={{
                          borderTop: index === 0 ? "1px solid gray" : "",
                          borderBottom: "1px solid gray",
                          borderLeft: "1px solid gray",
                          borderRight: "1px solid gray",
                        }}
                      >
                        <div style={{ padding: 8 }}>
                          <label>{item.course.title}</label>
                        </div>
                      </Col>

                      <Col
                        span={6}
                        style={{
                          borderTop: index === 0 ? "1px solid gray" : "",
                          borderBottom: "1px solid gray",
                          borderRight: "1px solid gray",
                        }}
                      >
                        <div style={{ padding: 8, textAlign: "center" }}>
                          <label>
                            {moment(item.expireDate).format(formatDateTime)}
                          </label>
                          {/* <DatePicker
                                                        value={moment(item.expireDate)}
                                                        showTime
                                                        onChange={(value, dateString) => {
                                                            // console.log('Selected Time: ', value)
                                                            // console.log('Formatted Selected Time: ', dateString)
                                                        }}
                                                        onOk={(value) => {
                                                            // console.log('onOk: ', value)
                                                            let tmpArr = classroom?.map(val => val.classroomId === item.classroomId ? {
                                                                classroomId: item.classroomId,
                                                                expireDate: value,
                                                                paymentOrderId: item.paymentOrderId,
                                                                courseId: item.courseId,
                                                                course: item.course
                                                            } : val)
                                                            setClassroom(tmpArr)
                                                        }}
                                                    /> */}
                        </div>
                      </Col>

                      <Col
                        span={4}
                        style={{
                          borderTop: index === 0 ? "1px solid gray" : "",
                          borderBottom: "1px solid gray",
                          borderRight: "1px solid gray",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            padding: 8,
                            display: "flex",
                            gap: 8,
                          }}
                        >
                          <Button
                            type="primary"
                            size="small"
                            onClick={() => {
                              setModalExpireDate({
                                isShow: true,
                                title: "เเก้ไขวันที่หมดอายุ ของคอร์สเรียน",
                                data: {
                                  classroomId: item.classroomId,
                                  expireDate: item.expireDate,
                                },
                                type: "classroom",
                              });
                            }}
                          >
                            เเก้ไข
                          </Button>
                          <Popconfirm
                            title="ต้องการลบคอร์สเรียนนี้?"
                            description=""
                            onConfirm={() => deleteClassroom(item.classroomId)}
                            onCancel={() => {}}
                            okText="ลบ"
                            cancelText="ยกเลิก"
                          >
                            <Button
                              danger
                              type="primary"
                              size="small"
                              icon={
                                <Icon
                                  icon="fluent:delete-16-regular"
                                  style={{
                                    color: "white",
                                    width: 20,
                                    height: 20,
                                  }}
                                />
                              }
                            ></Button>
                          </Popconfirm>
                        </div>
                      </Col>
                    </Row>
                  </>
                );
              })
            )}
          </Col>

          <Col span={24}>
            <label>พยากรณ์ที่ซื้อ</label>
            <Row>
              <Col
                span={3}
                style={{
                  borderTop: "1px solid gray",
                  borderLeft: "1px solid gray",
                  backgroundColor: "#EEEEEE",
                }}
              >
                <div style={{ padding: 8, textAlign: "center" }}>
                  <label>No.</label>
                </div>
              </Col>
              <Col
                span={11}
                style={{
                  borderTop: "1px solid gray",
                  borderLeft: "1px solid gray",
                  borderRight: "1px solid gray",
                  backgroundColor: "#EEEEEE",
                }}
              >
                <div style={{ padding: 8 }}>
                  <label>ชื่อพยากรณ์</label>
                </div>
              </Col>
              <Col
                span={6}
                style={{
                  borderTop: "1px solid gray",
                  borderRight: "1px solid gray",
                  backgroundColor: "#EEEEEE",
                }}
              >
                <div style={{ padding: 8, textAlign: "center" }}>
                  <label>วันหมดอายุ</label>
                </div>
              </Col>
              <Col
                span={4}
                style={{
                  borderTop: "1px solid gray",
                  borderRight: "1px solid gray",
                  backgroundColor: "#EEEEEE",
                }}
              >
                <div style={{ padding: 8, textAlign: "center" }}>
                  <Icon icon="eva:options-2-fill" />
                </div>
              </Col>
            </Row>

            {predictRoom?.length === 0 ? (
              <Row>
                <Col
                  span={24}
                  style={{
                    borderTop: "1px solid gray",
                    borderBottom: "1px solid gray",
                    borderLeft: "1px solid gray",
                    borderRight: "1px solid gray",
                  }}
                >
                  <div style={{ padding: 8, textAlign: "center" }}>
                    <label>ไม่มีการสั่งซื้อ</label>
                  </div>
                </Col>
              </Row>
            ) : (
              predictRoom?.map((item, index) => {
                return (
                  <>
                    <Row>
                      <Col
                        span={3}
                        style={{
                          borderTop: index === 0 ? "1px solid gray" : "",
                          borderBottom: "1px solid gray",
                          borderLeft: "1px solid gray",
                        }}
                      >
                        <div style={{ padding: 8, textAlign: "center" }}>
                          <label>{index + 1}</label>
                        </div>
                      </Col>

                      <Col
                        span={11}
                        style={{
                          borderTop: index === 0 ? "1px solid gray" : "",
                          borderBottom: "1px solid gray",
                          borderLeft: "1px solid gray",
                          borderRight: "1px solid gray",
                        }}
                      >
                        <div style={{ padding: 8 }}>
                          <label>{item.prediction.title}</label>
                        </div>
                      </Col>

                      <Col
                        span={6}
                        style={{
                          borderTop: index === 0 ? "1px solid gray" : "",
                          borderBottom: "1px solid gray",
                          borderRight: "1px solid gray",
                        }}
                      >
                        <div style={{ padding: 8, textAlign: "center" }}>
                          <label>
                            {moment(item.expireDate).format(formatDateTime)}
                          </label>
                          {/* <DatePicker
                                                        value={moment(item.expireDate)}
                                                        showTime
                                                        onChange={(value, dateString) => {
                                                            // console.log('Selected Time: ', value)
                                                            // console.log('Formatted Selected Time: ', dateString)
                                                        }}
                                                        onOk={(value) => {
                                                            // console.log('onOk: ', value)
                                                            let tmpArr = predictRoom?.map(val => val.predictionRoomId === item.predictionRoomId ? {
                                                                predictionRoomId: item.predictionRoomId,
                                                                expireDate: value,
                                                                paymentOrderId: item.paymentOrderId,
                                                                predictionId: item.predictionId,
                                                                prediction: item.prediction
                                                            } : val)
                                                            setPredictRoom(tmpArr)
                                                        }}
                                                    /> */}
                        </div>
                      </Col>

                      <Col
                        span={4}
                        style={{
                          borderTop: index === 0 ? "1px solid gray" : "",
                          borderBottom: "1px solid gray",
                          borderRight: "1px solid gray",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            padding: 8,
                            display: "flex",
                            gap: 8,
                          }}
                        >
                          <Button
                            type="primary"
                            size="small"
                            onClick={() => {
                              // setModalExpireDate({ isShow: true, title: "เเก้ไขวันที่หมดอายุ ของพยากรณ์", data: item, type: "prediction" })
                              setModalExpireDate({
                                isShow: true,
                                title: "เเก้ไขวันที่หมดอายุ ของพยากรณ์",
                                data: {
                                  predictionRoomId: item.predictionRoomId,
                                  expireDate: item.expireDate,
                                },
                                type: "prediction-room",
                              });
                            }}
                          >
                            เเก้ไข
                          </Button>
                          <Popconfirm
                            title="ต้องการลบพยากรณ์นี้?"
                            description=""
                            onConfirm={() =>
                              deletePredictionRoom(item.predictionRoomId)
                            }
                            onCancel={() => {}}
                            okText="ลบ"
                            cancelText="ยกเลิก"
                          >
                            <Button
                              danger
                              type="primary"
                              size="small"
                              icon={
                                <Icon
                                  icon="fluent:delete-16-regular"
                                  style={{
                                    color: "white",
                                    width: 20,
                                    height: 20,
                                  }}
                                />
                              }
                            ></Button>
                          </Popconfirm>
                        </div>
                      </Col>
                    </Row>
                  </>
                );
              })
            )}
          </Col>
        </Row>

        <audio ref={audioReboundRef}>
          <source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </Modal>

      <Modal
        title={
          <strong>
            <label className="topic-color-bold">{modalExpireDate.title}</label>
          </strong>
        }
        visible={modalExpireDate.isShow}
        onCancel={() => {
          setModalExpireDate({
            isShow: false,
            title: null,
            data: null,
            tyoe: null,
          });
        }}
        width={400}
        footer={[]}
      >
        <Row>
          <Col span={12}>
            <label>เก่า</label>
            <br />
            <label>
              {moment(modalExpireDate?.data?.expireDate).format(formatDateTime)}
            </label>
          </Col>

          <Col span={12}>
            <label>ใหม่</label>
            <br />
            <DatePicker
              showTime
              onChange={(value, dateString) => {}}
              onOk={(value, dateString) => {
                // console.log(
                //   "onOk: ",
                //   moment(String(value)).format("YYYY-MM-DD HH:mm:ss")
                // );
                dateExpireSelectedRef.current = moment(String(value)).format(
                  "YYYY-MM-DD HH:mm:ss"
                );
              }}
            />
          </Col>

          <Col span={24}>
            <div style={{ float: "right", paddingTop: 12 }}>
              <Button
                type="primary"
                onClick={async () => {
                  // console.log("X --- : ", modalExpireDate?.data)

                  if (modalExpireDate?.type === "classroom") {
                    let obj = {
                      classroomId: modalExpireDate?.data?.classroomId ?? null,
                      expireDate: dateExpireSelectedRef.current ?? null,
                    };

                    // console.log("obj classroom : ", obj);
                    const result = await updateClassroomByIdFetch(obj);
                    if (result.isSuccess) {
                      Notification("success", "บันทึกสำเร็จ");
                      setModalExpireDate({
                        isShow: false,
                        title: null,
                        data: null,
                        tyoe: null,
                      });
                      setModal({ isShow: false, title: null, data: null });
                      getBaseApi();
                    } else {
                      Notification(
                        "error",
                        "ไม่สามารถบันทึกได้ กรุณาลองใหม่อีกครั้ง"
                      );
                    }
                  }

                  if (modalExpireDate?.type === "prediction-room") {
                    let obj = {
                      predictionRoomId:
                        modalExpireDate?.data?.predictionRoomId ?? null,
                      expireDate: dateExpireSelectedRef.current,
                    };

                    // console.log("obj prediction-room : ", obj);
                    const result = await updatePredictionRoomByIdFetch(obj);
                    if (result.isSuccess) {
                      Notification("success", "บันทึกสำเร็จ");
                      setModalExpireDate({
                        isShow: false,
                        title: null,
                        data: null,
                        tyoe: null,
                      });
                      setModal({ isShow: false, title: null, data: null });
                      getBaseApi();
                    } else {
                      Notification(
                        "error",
                        "ไม่สามารถบันทึกได้ กรุณาลองใหม่อีกครั้ง"
                      );
                    }
                  }
                }}
              >
                บัททึก
              </Button>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
