import { server } from "./../../../constants"
import { httpClient } from "./../../../utils/HttpClient"

const getAdminByIdFetch = async (username, password) => { // Done
    try {
        const result = await httpClient.post(server.GET_ADMIN_BY_ID_URL, { username, password })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getAdminAllFetch = async () => { // Done
    try {
        const result = await httpClient.get(server.GET_ADMIN_ALL_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getAnnounceBySearchFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.GET_ANNOUNCE_BY_SEARCH_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertAnnounceFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_ANNOUNCE_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const insertKnowledgeSourceFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_KNOWLEDGE_SOURCE_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateAnnounceByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_ANNOUNCE_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateKnowledgeSourceByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_KNOWLEDGE_SOURCE_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteAnnounceByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(server.DELETE_ANNOUNCE_BY_ID_URL + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteKnowledgeSourceByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(server.DELETE_KNOWLEDGE_SOURCE_BY_ID_URL + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getAdminByIdFetch,
    getAdminAllFetch,
    getAnnounceBySearchFetch,

    // insert
    insertAnnounceFetch,
    insertKnowledgeSourceFetch,

    // update
    updateAnnounceByIdFetch,
    updateKnowledgeSourceByIdFetch,

    // delete
    deleteAnnounceByIdFetch,
    deleteKnowledgeSourceByIdFetch,
}