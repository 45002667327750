/* eslint-disable react-hooks/exhaustive-deps */

import {
    Col,
    Row,
    Table
} from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { formatCodeUnit } from '../../../../common/mamager/IdManager'
import { getPaymentOrdersByIdFetch } from '../../API'
import LoadingPage from '../../../../common/components/loading/page'

const dateTime = 'DD/MM/YYYY HH:mm'

export default function Transaction(props) {

    const columns = [
        {
            title: 'No',
            // dataIndex: 'index',
            width: "2%",
            render: (text, record, index) => {
                return (
                    <div>{index+1}.</div>
                )
            }
        },
        {
            title: 'เลขที่ชำระเงิน',
            dataIndex: 'paymentOrderId',
            width: "4%",
            render: (text, record) => {
                return (
                    <div>{formatCodeUnit(record.paymentOrderId)}</div>
                )
            }
        },
        {
            title: 'สถานะ',
            dataIndex: 'paymentStatusId',
            width: "8%",
            render: (text, record) => {
                if (record.paymentStatusId === 1) {
                    return <div style={{ color: "green" }}>{record.payment_status.name}</div>
                } else if (record.paymentStatusId === 2) {
                    return <div style={{ color: "orange" }}>{record.payment_status.name}</div>
                } else if (record.paymentStatusId === 3) {
                    return <div style={{ color: "red" }}>{record.payment_status.name}</div>
                } else if (record.paymentStatusId === 4) {
                    return <div>{record.payment_status.name}</div>
                }
            }
        },
        {
            title: 'รายการ',
            dataIndex: 'orderName',
            width: "10%",
        },
        {
            title: 'ราคาสุทธิ',
            dataIndex: 'netPrice',
            width: "5%",
            render: (text, record) => {
                return (
                    <>
                        <label>{Intl.NumberFormat('en').format(Number(record.netPrice))} บาท</label>
                    </>
                )
            }
        },
        {
            title: 'วันที่ชำระ',
            dataIndex: 'createdAt',
            width: "4%",
            render: (text, record) => {
                return (
                    <div>{moment(record.createdAt).format(dateTime)} ({Number(moment(record.createdAt).format("YYYY")) + 543})</div>
                )
            }
        }
    ];

    const [order, setOrder] = useState([])
    const [loading, setLoading] = useState(false) // true

    const [pageOrderCurrent, setPageOrderCurrent] = useState(1)

    const onOrderPagine = (n) => {
        setPageOrderCurrent(n.current)
    }

    const getPaymentOrdersById = async () => {
        const result = await getPaymentOrdersByIdFetch(props.user.uid)
        // console.log("getPaymentOrdersByIdFetch : ", result)
        setOrder(result)
    }

    const getBaseApi = async () => {
        setLoading(true)
        await getPaymentOrdersById()
        setLoading(false)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <label>ธุรกรรมการชำระเงิน</label>
                </Col>
                <Col span={24}>
                    {!loading ?
                        <Table
                            scroll={{ x: 900 }}
                            loading={loading}
                            columns={columns}
                            dataSource={order}
                            pagination={{
                                current: pageOrderCurrent,
                                pageSize: 10,
                                total: order?.length
                            }}
                            onChange={(n) => onOrderPagine(n)}
                        >
                        </Table>
                        : 
                        <LoadingPage loading={loading} />
                    }
                </Col>
            </Row>
        </>
    )
}
