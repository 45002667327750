import { server } from "../../../../../constants"
import { httpClient } from "../../../../../utils/HttpClient"

const getKhunHorBySearchFetch = async (value) => {
    try {
        const result = await httpClient.post(server.GET_KHUN_HOR_BY_SEARCH_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertKhunHorFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_KHUN_HOR_ORDER_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateKhunHorByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_KHUN_HOR_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteKhunHorByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(server.DELETE_KHUN_HOR_BY_ID_URL + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}


export {
    // get
    getKhunHorBySearchFetch,

    // insert
    insertKhunHorFetch,

    // update
    updateKhunHorByIdFetch,

    // 
    deleteKhunHorByIdFetch,
}