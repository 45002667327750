/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-distracting-elements */

import { useState, useEffect } from 'react'
// import { useNavigate } from "react-router-dom"
import Marquee from 'react-fast-marquee'
// import { Button } from 'antd'
// import { Icon } from '@iconify/react'
import { getNewsAllFetch } from './API'

export default function Marquees() {

    // const navigate = useNavigate()

    const [news, setNews] = useState()

    const getNews = async () => {
        const result = await getNewsAllFetch()
        setNews(result)
    }

    useEffect(() => {
        getNews()
    }, [])

    // console.log("news : ", news)

    return (
        <div style={{ backgroundColor: "#1A1A1A", height: 50 }}>
            <Marquee
                speed='30'
                gradient={false}
                pauseOnHover={true}
                pauseOnClick={true}
            >
                {news?.map((val, index) =>
                    <div style={{ paddingRight: 12 }}>
                        <div
                            style={{
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'center',
                                paddingLeft: 10,
                                paddingRight: 10,
                                color: 'white',
                                fontSize: 18,
                                cursor: "pointer",
                                borderRadius: 10,
                                // backgroundColor: '#23B14D',
                                height: 50
                            }}
                            onClick={() => {
                                // navigate("/announce?announcement-id=" + val.announceNewsId)
                            }}
                        >
                            <label style={{ fontSize: 28 }}>{val.title} {news.length !== index+1 ? "," : ""}</label>
                        </div>
                    </div>
                )}
            </Marquee>
        </div>
    )
}