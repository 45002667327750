/* eslint-disable no-array-constructor */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect,useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    DatePicker,
    Popconfirm,
    Select,
    Image
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { formatCodeUnit } from '../../../../../common/mamager/IdManager'
import {
    deletePaymentOrderByIdFetch,
    getPaymentOrderBySearchFetch,
    getPaymentStatusAllFetch,
    updatePaymentOrderByIdFetch
} from '../API'
import { Notification } from '../../../../../common/components/notification'
import { serverUrl } from '../../../../../constants'
import { getDetectClassroomExpiredByIdFetch, insertClassroomFetch, updateClassroomActiveByIdFetch } from '../../../../classroom/API'
import { getDetectPredictionRoomExpiredByIdFetch, insertPredictionRoomFetch, updatePredictionRoomActiveByIdFetch } from '../../../../predictionRoom/API'
import { insertExamRoomFetch } from '../../../../exam/API'

const { Option } = Select
const { RangePicker } = DatePicker

const columns = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "2%",
    },
    {
        title: 'เลขที่ชำระ',
        dataIndex: 'paymentOrderId',
        width: "5%",
        render: (text, record) => {
            return (
                <label>{formatCodeUnit(record.paymentOrderId)}</label>
            )
        }
    },
    {
        title: 'รายการ',
        dataIndex: 'orderName',
        width: "9%",
    },
    {
        title: 'ชื่อผู้ใช้งาน',
        width: "6%",
        render: (text, record) => {
            return (
                <label>{record?.user?.username ?? "-"}</label>
            )
        }
    },
    {
        title: 'สถานะชำระเงิน',
        width: "7%",
        render: (text, record) => {
            return (
                <label>{(record.payment_status.name)}</label>
            )
        }
    },
    {
        title: 'ราคาสุทธิ',
        dataIndex: 'netPrice',
        width: "6%",
        render: (text, record) => {
            return (
                <div>{Intl.NumberFormat('en').format(Number(record.netPrice))} บาท</div>
            )
        }
    },
    {
        title: 'วันที่ชำระ',
        // dataIndex: 'dateTimeTransfer',
        align: "center",
        width: "3%",
        render: (text, record) => {
            return (
                <div>{record.dateTimeTransfer}</div>
            )
        }
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        fixed: 'right',
        align: "center",
        width: "8%"

    },
]

const formatDate = "DD/MM/YYYY"
const formatDateTime = "DD/MM/YYYY HH:mm"

export default function PaymentOrderInspect() {

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)

    const [form] = Form.useForm()
    const [formSearch] = Form.useForm()

    const audioReboundRef = useRef()


    const [paymentOrder, setPaymentOrder] = useState([])
    const [paymentStatus, setPaymentStatus] = useState([])

    const [pageCurrent, setPageCurrent] = useState(1)

    const [modal, setModal] = useState({
        isShow: false,
        title: null,
        data: null
    })

    const getPaymentOrderBySearch = async (title, paymentStatusId, startedDate, endDate) => {
        setLoading(true)

        let objSearch = {
            title, paymentStatusId, startedDate, endDate
        }
        const result = await getPaymentOrderBySearchFetch(objSearch)
        setPaymentOrder(result)
        // console.log("result : ", result)

        let tempList = []
        result?.map((val, index) => {
            if (val?.isPaymentOrderParent) {
                tempList.push({
                    index: (index + 1),
                    paymentOrderId: val.paymentOrderId,
                    orderName: val.orderName,
                    user: val.user,
                    netPrice: val.netPrice,
                    payment_status: val.payment_status,
                    dateTimeTransfer: val.dateTimeTransfer ? moment(val.dateTimeTransfer).format(formatDateTime) : "-",
                    createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                    updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                    operator:
                        <>
                            <div style={{ padding: 4 }}>
                                {val.paymentStatusId === 2 ?
                                    <Button
                                        type="primary"
                                        style={{ width: 100 }}
                                        onClick={() => {
                                            audioReboundRef.current.play()
                                            form.setFieldsValue({
                                                paymentOrderId: val.paymentOrderId,
                                                paymentOrderGroupId: val.paymentOrderGroupId,
                                                paymentStatusId: val.paymentStatusId
                                            })
                                            setModal({ isShow: true, title: "edit", data: val })
                                        }}
                                    >
                                        <div style={{}}>
                                            ตรวจสอบ
                                        </div>
                                    </Button>
                                    :
                                    <Button
                                        type="default"
                                        style={{ width: 100 }}
                                        onClick={() => {
                                            audioReboundRef.current.play()
                                            setModal({ isShow: true, title: "view", data: val })
                                        }}
                                    >
                                        <div style={{}}>
                                            ดูรายละเอียด
                                        </div>
                                    </Button>
                                }
                            </div>

                            <div style={{ padding: 4 }}>
                                <Popconfirm
                                    title="คุณยืนยันลบหรือไม่ ?"
                                    okText={<span style={{ width: 50 }}>ใช่</span>}
                                    onConfirm={async () => {
                                        await handlePaymentOrderDelete(val.paymentOrderGroupId)

                                        // reload
                                        await getPaymentOrderBySearch(null, null, null, null)
                                    }}
                                    cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                >
                                    <Button
                                        style={{ width: 100 }}
                                        type="default"
                                        onClick={() => audioReboundRef.current.play()}
                                    >
                                        ลบ Order
                                    </Button>
                                </Popconfirm>
                            </div>
                        </>
                })
            }

        })
        setList(tempList)

        setLoading(false)
    }

    const detectProductCategory = (item) => {
        if (item.courseId && item?.course?.courseTypeId === 1) { // course
            return 1
        }

        if (item.predictionId) {  // prediction
            return 2
        }

        if (item.courseId && item?.course?.courseTypeId === 2) { // exam certificate
            return 3
        }

        return null
    }

    const detectExpired = (data) => {
        let isExpired
        if (data) {
            let currentDate = new Date()
            let expirationDate = new Date(data?.expireDate)
            if (currentDate > expirationDate) {
                // console.log("EXPIRED")
                isExpired = true // "EXPIRED" (ซื้อเเล้ว เเต่หมดอายุเเล้ว)
            } else {
                // console.log("NOT_YET_EXPIRED")
                isExpired = false // "NOT_YET_EXPIRED" (ซื้อเเล้ว เเละยังไม่หมดอายุเเล้ว)
            }
        } else {
            // console.log("NOT_FOUND")
            isExpired = true // "NOT_FOUND" // ไม่พบในห้องเรียน
        }
        // console.log("isExpired : ", isExpired, data)
        return isExpired
    }
    const onFinish = async (values) => {

        // console.log("paymentOrder : ", paymentOrder)

        let obj = {
            paymentStatusId: values.paymentStatusId ? values.paymentStatusId : null,
            paymentOrderGroupId: values.paymentOrderGroupId ? values.paymentOrderGroupId : null,
        }
        const resultUpdatePaymentOrderById = await updatePaymentOrderByIdFetch(obj)

        let resultInsertClassroom = { isSuccess: true }
        let resultInsertPredictionRoom = { isSuccess: true }
        let resultInsertExamRoom = { isSuccess: true }

        if (values.paymentStatusId === 1) {

            let classroom = new Array()
            let predictionRoom = new Array()
            let examRoom = new Array()

            // classroom
            let paymentOrderChildClassroom = paymentOrder?.filter(fill =>
                fill.isPaymentOrderParent === false &&
                detectExpired(fill.classroom) &&
                fill?.course?.courseTypeId === 1
            )
            paymentOrderChildClassroom?.map((val) => {
                if (val.courseId) {
                    classroom.push({
                        uid: val.uid,
                        courseId: val.courseId,
                        productCategoryId: detectProductCategory(val),
                        paymentOrderId: val.paymentOrderId,
                        paymentStatusId: val.paymentStatusId
                    })
                }
            })

            // prediction room
            let paymentOrderChildPredictionRoom = paymentOrder?.filter(fill =>
                fill.isPaymentOrderParent === false &&
                detectExpired(fill.prediction_room)
            )
            paymentOrderChildPredictionRoom?.map((val) => {
                if (val.predictionId) {
                    predictionRoom.push({
                        uid: val.uid,
                        predictionId: val.predictionId,
                        productCategoryId: detectProductCategory(val),
                        paymentOrderId: val.paymentOrderId,
                        paymentStatusId: val.paymentStatusId
                    })
                }
            })

            // exam room
            let paymentOrderChildExamRoom = paymentOrder?.filter(fill =>
                fill.isPaymentOrderParent === false &&
                fill?.course?.courseTypeId === 2
            )
            paymentOrderChildExamRoom?.map((val) => {
                if (val.courseId) {
                    examRoom.push({
                        examRoomStatusId: 1,
                        courseId: val.courseId,
                        uid: val.uid,
                        paymentOrderId: val.paymentOrderId,
                        paymentStatusId: val.paymentStatusId
                    })
                }
            })

            // CLASSROOM OR EEAM =====================================================================================

            const classroomForInsert = classroom?.filter(fill => (fill.paymentStatusId === 2) && (fill?.uid === modal.data?.uid)) // insert data only waiting for payment and of owner
            resultInsertClassroom = await insertClassroomFetch(classroomForInsert)
            if (resultInsertClassroom.isSuccess) {
                for (let i = 0; i < classroom?.length; i++) {
                    const resultDetectClassroomExpired = await getDetectClassroomExpiredByIdFetch({
                        courseId: classroom[i]?.courseId,
                        uid: modal.data?.uid
                    })
                    if (resultDetectClassroomExpired?.length > 0) {
                        let tmpArr = new Array()
                        resultDetectClassroomExpired?.map(val => {
                            // console.log("Already exists : ", classroomForInsert?.filter(fill => fill.courseId === classroom[i]?.courseId))
                            if (classroomForInsert?.filter(fill => fill.courseId === classroom[i]?.courseId)?.length > 0) { // old course is expired -> for close
                                tmpArr.push({
                                    classroomId: val.classroomId,
                                    isActive: false
                                })
                            }
                        })
                        // console.log("tmpArr : ", tmpArr, classroom[i]?.courseId)
                        await updateClassroomActiveByIdFetch(tmpArr)
                    }
                }
            }

            // ASTRO CONSULT ROOM ============================================================================
            const predictionRoomForInsert = predictionRoom?.filter(fill => (fill.paymentStatusId === 2) && (fill?.uid === modal.data?.uid)) // insert data only waiting for payment and of owner
            resultInsertPredictionRoom = await insertPredictionRoomFetch(predictionRoomForInsert)
            if (resultInsertPredictionRoom.isSuccess) {
                for (let i = 0; i < predictionRoom?.length; i++) {
                    const resultDetectPredictionRoomExpired = await getDetectPredictionRoomExpiredByIdFetch({
                        predictionId: predictionRoom[i]?.predictionId,
                        uid: modal.data?.uid
                    })
                    if (resultDetectPredictionRoomExpired?.length > 0) {
                        let tmpArr = new Array()
                        resultDetectPredictionRoomExpired?.map(val => {
                            // console.log("Already exists : ", predictionRoomForInsert?.filter(fill => fill.predictionId === predictionRoom[i]?.predictionId))
                            if (predictionRoomForInsert?.filter(fill => fill.predictionId === predictionRoom[i]?.predictionId)?.length > 0) { // old prediction is expired -> for close
                                tmpArr.push({
                                    predictionRoomId: val.predictionRoomId,
                                    uid: modal.data?.uid,
                                    isActive: false
                                })
                            }
                        })

                        // console.log("tmpArr : ", tmpArr, predictionRoom[i]?.predictionId)
                        await updatePredictionRoomActiveByIdFetch(tmpArr)
                    }
                }
            }

            // EXAM CERTIFICATE
            
            const examRoomForInsert = examRoom?.filter(fill => (fill.paymentStatusId === 2) && (fill?.uid === modal.data?.uid)) // insert data only waiting for payment and of owner
            resultInsertExamRoom = await insertExamRoomFetch(examRoomForInsert)
            // console.log("examRoomForInsert : ", examRoomForInsert)
            // console.log("resultInsertExamRoom : ", resultInsertExamRoom)
        }

        if (
            resultUpdatePaymentOrderById.isSuccess &&
            resultInsertClassroom.isSuccess &&
            resultInsertPredictionRoom.isSuccess &&
            resultInsertExamRoom.isSuccess
        ) {
            Notification('success', 'เเก้ไขสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
        }

        // reload
        getPaymentOrderBySearch(null, null, null, null)

        // set default
        setFormDefault()
    }

    const onSearchFinish = async (values) => {
        let title = values?.title ? values.title : null
        let paymentStatusId = values?.paymentStatusId ? values.paymentStatusId : null
        let startedDate = values?.dateRange ? values.dateRange[0] : null
        let endDate = values?.dateRange ? values.dateRange[1] : null
        await getPaymentOrderBySearch(title, paymentStatusId, startedDate, endDate)
    }

    const handlePaymentOrderDelete = async (id) => {
        const result = await deletePaymentOrderByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const setFormDefault = () => {
        form.resetFields()

        setModal({
            isShow: false,
            title: null,
            data: null
        })
    }

    const genFormatPrice = (price) => {
        return Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(price))
    }

    const getPaymentStatusAll = async () => {
        setLoading(true)

        const result = await getPaymentStatusAllFetch()
        // console.log("result : ", result)
        setPaymentStatus(result)

        setLoading(false)
    }

    const getBaseApi = async () => {
        await getPaymentOrderBySearch(null, null, null, null)
        await getPaymentStatusAll()
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <Row>
            <Col span={8}>
                <label>จัดการรายการชำระเงิน</label>
            </Col>
            <Col span={16} style={{ paddingBottom: 20 }}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="รายการ"
                                name="orderName"
                                style={{ width: '100%' }}
                            >
                                <Input />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="สถานะชำระเงิน"
                                name="paymentStatusId"
                                style={{ width: '100%' }}
                            >
                                <Select
                                    showSearch
                                    style={{ width: 150 }}
                                    optionFilterProp="children"
                                    allowClear
                                >
                                    {paymentStatus?.map((val, index) =>
                                        <Option key={index} value={val.paymentStatusId}>{val.name}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ห้วงวันที่สร้าง" name="dateRange"
                                style={{ width: '100%' }}
                            >
                                <RangePicker />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10, marginTop: 3 }}>
                            <Button
                                style={{ float: 'right', width: 70 }}
                                type="primary"
                                onClick={() => {
                                    audioReboundRef.current.play()
                                    formSearch.submit()}}
                            >
                                ค้นหา
                            </Button>
                        </div>
                    </div>
                </Form>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrent,
                        pageSize: 10,
                        total: list.length
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modal.title === "view" ? "รายละเอียดข้อมูล" : "ตรวจสอบข้อมูล"}</label></strong>}
                visible={modal.isShow}
                onCancel={() => {

                    // default
                    setFormDefault()
                }}
                // width={500}
                // onOk={() => {
                //     form.submit()
                // }}
                // okText={<label style={{ width: 50, cursor: 'pointer' }}>บันทึก</label>}
                // cancelText={<label style={{ width: 50, cursor: 'pointer' }}>ยกเลิก</label>}

                footer={
                    modal.title === "edit" ? [
                        <Button
                            type="default"
                            onClick={() => {
                                audioReboundRef.current.play()
                                // default
                                setFormDefault()
                            }}
                        >
                            ยกเลิก
                        </Button>,
                        <Button
                            type="primary"
                            onClick={() => {
                                audioReboundRef.current.play()
                                form.submit()
                            }}
                        >
                            บันทึก
                        </Button>,
                    ] : []
                }

            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item name="paymentOrderId" style={{ display: "none" }}>
                                <Input />
                            </Form.Item>

                            <Form.Item name="paymentOrderGroupId" style={{ display: "none" }}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24} style={{ paddingBottom: 12 }}>
                            <Row gutter={[0, 8]}>
                                <Col xs={24} md={8} xl={6}>
                                    <label>เลข Order</label>
                                </Col>
                                <Col xs={24} md={16} xl={18}>
                                    <label>{formatCodeUnit(modal.data?.paymentOrderId)}</label>
                                </Col>

                                <Col xs={24} md={8} xl={6}>
                                    <label>ชื่อ Order</label>
                                </Col>
                                <Col xs={24} md={16} xl={18}>
                                    <div style={{ display: "grid", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                        <label>{modal.data?.orderName}</label>
                                        {paymentOrder?.filter(fill => (
                                            fill.paymentOrderGroupId === modal.data?.paymentOrderGroupId &&
                                            fill.isPaymentOrderParent === false
                                        ))?.map((val, index) => {
                                            return (
                                                <Row>
                                                    <Col
                                                        span={16}
                                                        style={{
                                                            borderTop: (index === 0 ? "1px solid gray" : ""),
                                                            borderBottom: "1px solid gray",
                                                            borderLeft: "1px solid gray",
                                                            borderRight: "1px solid gray",
                                                        }}
                                                    >
                                                        <div style={{ padding: 8 }}>
                                                            <label>{index + 1}) {val.orderName}</label>
                                                        </div>
                                                    </Col>
                                                    <Col
                                                        span={8}
                                                        style={{
                                                            borderTop: (index === 0 ? "1px solid gray" : ""),
                                                            borderBottom: "1px solid gray",
                                                            borderRight: "1px solid gray",
                                                        }}
                                                    >
                                                        <div style={{ padding: 8 }}>
                                                            <label>{genFormatPrice(val.netPrice)} บาท</label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )
                                        })}
                                    </div>
                                </Col>

                                <Col xs={24} md={8} xl={6}>
                                    <label>สถานะตรวจสอบ</label>
                                </Col>
                                <Col xs={24} md={16} xl={18}>
                                    <label>{modal.data?.payment_status?.name}</label>
                                </Col>

                                {/* <Col xs={24} md={8} xl={6}>
                                    <label>ประเภท Order</label>
                                </Col>
                                <Col xs={24} md={16} xl={18}>
                                    <label>{modal.data?.payment_type?.name}</label>
                                </Col> */}

                                <Col xs={24} md={8} xl={6}>
                                    <label>ราคาสุทธิ</label>
                                </Col>
                                <Col xs={24} md={16} xl={18}>
                                    <label>{genFormatPrice(modal.data?.netPrice)} บาท</label>
                                </Col>

                                <Col xs={24} md={8} xl={6}>
                                    <label>ชื่อผู้ใช้งาน</label>
                                </Col>
                                <Col xs={24} md={16} xl={18}>
                                    <label>{modal.data?.user?.username}</label>
                                </Col>

                                <Col xs={24} md={8} xl={6}>
                                    <label>อีเมลผู้ใช้งาน</label>
                                </Col>
                                <Col xs={24} md={16} xl={18}>
                                    <a href={"mailto: " + modal.data?.user?.email} style={{ color: "black" }}>{modal.data?.user?.email}</a>
                                </Col>

                                <Col xs={24} md={8} xl={6}>
                                    <label>เบอร์โทรผู้ใช้งาน</label>
                                </Col>
                                <Col xs={24} md={16} xl={18}>
                                    <label>{modal.data?.user?.phone}</label>
                                </Col>

                                {/* <Col xs={24} md={8} xl={6}>
                                    <label>รหัสผู้ซื้อ</label>
                                </Col>
                                <Col xs={24} md={16} xl={18}>
                                    <label>{modal.data?.uid}</label>
                                </Col> */}

                                <Col xs={24} md={8} xl={6}>
                                    <label>วันที่ชำระ</label>
                                </Col>
                                <Col xs={24} md={16} xl={18}>
                                    <label>{modal.data?.dateTimeTransfer ? moment(modal.data?.dateTimeTransfer).format(formatDateTime) : "-"}</label>
                                </Col>

                                <Col xs={24} md={8} xl={6}>
                                    <label>สลิปโอนเงิน</label>
                                </Col>
                                <Col xs={24} md={16} xl={18}>
                                    <Image
                                        width={200}
                                        src="error"
                                        fallback={`${serverUrl}/${modal.data?.transferSlipImageURL}`}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        {modal.title === "edit" ?
                            <Col span={24}>
                                <Form.Item
                                    name="paymentStatusId"
                                    label="สถานะชำระเงิน"
                                    rules={[{ required: true, message: 'กรุณาเลือกสถานะ' }]}
                                >
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        optionFilterProp="children"
                                        allowClear
                                    >
                                        {paymentStatus?.map((val, index) =>
                                            <Option key={index} value={val.paymentStatusId}>{val.name}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            : []
                        }
                    </Row>
                </Form>
            </Modal>
            <audio ref={audioReboundRef}>
				<source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
				Your browser does not support the audio element.
			</audio>
        </Row>
    )
}
