/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import { Notification } from '../../../../common/components/notification'
import { getAccountVerifyIdentifyByIdFetch, getOtpSmsFetch, updateAccountVerifyIdentifyByIdFetch, verifyOtpSmsFetch } from '../../API'
// import { OTPapikey, OTPapisecret, OtpUrl } from '../../../../constants'
import {
    Button,
    Col,
    Row,
    Statistic
} from 'antd'
import OTPInput from '../../../../common/components/otp'
import { Icon } from '@iconify/react'
import { getUserByUidFetch } from '../../../../common/components/header/API'

const { Countdown } = Statistic

export default function VerifyIdentity(props) {

    const [userInfo, setUserInfo] = useState({
        phone: null
    })

    const [deadline, setDeadline] = useState() // 600000

    const audioReboundRef = useRef()

    const [pin, setPin] = useState()
    const [refno, setRefno] = useState()
    const [token, setToken] = useState()
    const [isCorrect, setIsCorrect] = useState({
        status: true,
        text: ""
    })

    const [isRequestOTP, setIsRequestOTP] = useState()
    const [switchVerify, setSwitchVerify] = useState(true)

    const [accountVerifyIdentifyData, setAccountVerifyIdentifyData] = useState()

    const onOutTime = async () => {
        setIsRequestOTP(false)

        setIsCorrect({
            status: false,
            text: "หมดเวลาการยืนยันตัวตนเเล้ว กรุณาส่งรหัสใหม่อีกครั้ง!"
        })
    }

    const getAccountVerifyIdentifyById = async () => {
        let result = await getAccountVerifyIdentifyByIdFetch(props.user ? (props.user.uid ? props.user.uid : null) : null)
        setAccountVerifyIdentifyData(result)
    }

    const updateAccountVerifyIdentifyById = async () => {
        let obj = {
            uid: props.user ? (props.user.uid ? props.user.uid : null) : null,
            isIdentityVerificationSuccessful: true
        }
        let result = await updateAccountVerifyIdentifyByIdFetch(obj)
        if (result.isSuccess) {
            await getAccountVerifyIdentifyById()
            Notification('success', 'ยืนยันตัวตนสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถยืนยันตัวตนได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const getOtpSms = async () => {
        let obj = {
            msisdn: userInfo.phone
        }
        const result = await getOtpSmsFetch(obj)
        // console.log("result : ", result)
        if (result === "Rate Limit Exceed") {
            // 
        } else {
            setToken(result.token)
            setRefno(result.refno)
        }
    }

    const verifyOtpSms = async () => {
        let obj = {
            token,
            pin
        }
        const result = await verifyOtpSmsFetch(obj)
        // console.log("result1 : ", result)
        if (result === "Rate Limit Exceed") {
            setIsCorrect({
                status: false,
                text: "พบข้อผิดพลาดในการยืนยันตัวตน โปรดลองใหม่อีกครั้ง!"
            })
        } else {

            if (result?.status === "success") {
                // case 1
                await updateAccountVerifyIdentifyById()
            } else {
                // case 2
                // console.log("result2 : ", result?.errors[0]?.message)
                let err = result?.errors[0]?.message ?? null
                if (err === "Code is invalid.") {
                    setIsCorrect({
                        status: false,
                        text: "รหัส OTP ไม่ถูกต้อง โปรดลองใหม่อีกครั้ง"
                    })
                }

                if (err === "Code used already") {
                    setIsCorrect({
                        status: false,
                        text: "รหัส OTP นี้ถูกนำไปใช้งานเเล้ว!"
                    })
                }

            }
        }
    }

    const getUserByUid = async () => {
        const result = await getUserByUidFetch(props.user ? (props.user.uid ? props.user.uid : null) : null)
        // console.log("result : ", result.phone)
        setUserInfo(
            { phone: result.phone }
        )
    }

    const getBaseApi = async () => {
        await getAccountVerifyIdentifyById()
        await getUserByUid()
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    // console.log("accountVerifyIdentifyData : ", accountVerifyIdentifyData)

    return (
        <>
            {accountVerifyIdentifyData?.isIdentityVerificationSuccessful ?
                <div>
                    <div style={{ padding: 30, backgroundColor: "#EEEEEE", borderRadius: 8, display: "flex", alignItems: "center" }}>
                        <Icon icon="ic:round-gpp-good" width="40" height="40" style={{ color: "#11ff00" }} />
                        <label style={{ paddingLeft: 10 }}>ยืนยันตัวตนสำเร็จเเล้ว!</label>
                    </div>
                </div>
                :
                <>
                    {!isRequestOTP ?
                        <div>
                            <Row gutter={[0, 6]}>
                                <Col span={24} style={{ textAlign: "center" }}>
                                    <label>ยืนยันตัวต้น</label>
                                </Col>
                                <Col span={24} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Button
                                        type="primary"
                                        // style={{ width: 170 }}
                                        onClick={() => {
                                            audioReboundRef.current.play()
                                            setIsCorrect({ status: true, text: null })
                                            setDeadline(Date.now() + 900000) // 900000
                                            getOtpSms()
                                            setIsRequestOTP(true)
                                        }}
                                    >
                                        คลิกเพื่อรับรหัส OTP
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                        :
                        <div>
                            <Row gutter={[0, 6]}>
                                <Col span={24} style={{ textAlign: "center" }}>
                                    <label>เราส่งรหัส OTP ทาง SMS เพื่อยืนยันการเข้าใช้งานไปที่เบอร์โทร ******{userInfo.phone.substring(6, 10)}</label>
                                </Col>
                                <Col span={24} style={{ textAlign: "center" }}>
                                    <label style={{ fontSize: 28, fontWeight: 600 }}>กรอกรหัส OTP</label>
                                </Col>
                                <Col span={24}>
                                    <center>
                                        <OTPInput
                                            reset={switchVerify}
                                            autoFocus
                                            isNumberInput
                                            length={6}
                                            className="otpContainer"
                                            inputClassName="otpInput"
                                            onChangeOTP={(otp) => {
                                                setPin(otp);
                                            }}
                                            onPaste={(otp) => setPin(otp)}
                                        />
                                    </center>
                                </Col>

                                <Col span={24} style={{ textAlign: "center" }}>
                                    <label>รหัสอ้างอิง <span style={{ fontWeight: 600 }}>{refno}</span></label>
                                </Col>

                                <Col span={24} style={{ textAlign: "center" }}>
                                    <Countdown value={deadline} onFinish={onOutTime} />
                                </Col>

                                <Col span={24} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Button
                                        style={{ width: 170 }}
                                        type="primary"
                                        onClick={() => {
                                            audioReboundRef.current.play()
                                            // console.log("String(pin) : ", pin?.length)
                                            if (pin?.length === 6) {
                                                verifyOtpSms()
                                            } else {
                                                setIsCorrect({
                                                    status: false,
                                                    text: "กรุณากรอกเลข OTP ให้ครบ 6 หลัก"
                                                })
                                            }
                                        }}
                                    >
                                        คลิกยืนยัน OTP
                                    </Button>
                                </Col>
                                <Col span={24} style={{ textAlign: "center" }}>
                                    <label
                                        style={{ cursor: "pointer", textDecoration: "underline" }}
                                        onClick={() => {
                                            getOtpSms()
                                            setSwitchVerify(!switchVerify)
                                        }}
                                    >ขอรหัส OTP ใหม่</label>
                                </Col>
                            </Row>
                        </div>
                    }

                    <Row>
                        <Col span={24} style={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
                            {isCorrect.status ?
                                ""
                                :
                                <label style={{ color: "red", paddingTop: 12 }}>
                                    {isCorrect.text}
                                </label>
                            }
                        </Col>
                    </Row>
                    <audio ref={audioReboundRef}>
                        <source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
                        Your browser does not support the audio element.
                    </audio>
                </>
            }
        </>
    )
}
