/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import "./css/index.css"
import { useMemo, useState } from 'react'

export default function LoadingPage(props) {

    const [randomData, setRandomData] = useState(null)
    const [randomData2, setRandomData2] = useState(null)

    const genRandom = (sizeRandom) => {
        return Math.floor(Math.random() * sizeRandom) + 1;
    }

    useMemo(() => {
        if (!randomData) {
            let ran = genRandom(8)
            setRandomData(ran)

            let ran2 = genRandom(8)
            setRandomData2(ran2)
        }
    }, [])

    return (
        <div class="loading-screen">
            {/* <div class="loading-circle"> */}
                <img
                    style={{ width: 95, borderRadius: 8 }}
                    // src={`./assets/images/loading/LD-${randomData}.png`}
                    src={`./assets/images/loading/LD.gif`}
                />
            {/* </div> */}
        </div>
    )
}