import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getCourseByIdFetch = async (courseId) => {
    try {
        const result = await httpClient.post(server.GET_COURSE_BY_ID_URL, { courseId })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getVideoCourseByIdFetch = async (courseId) => {
    try {
        const result = await httpClient.post(server.GET_VIDEO_COURSE_BY_ID_URL, { courseId })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getCourseByIdFetch,
    getVideoCourseByIdFetch
}