import { server } from "../../../../../constants"
import { httpClient } from "../../../../../utils/HttpClient"

const getUsageBySearchFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.GET_USAGE_BY_SEARCH_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getUsageByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.GET_USAGE_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertUsageFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_USAGE_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateUsageByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_USAGE_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteUsageByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(server.DELETE_USAGE_BY_ID_URL + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getUsageBySearchFetch,
    getUsageByIdFetch,

    // insert
    insertUsageFetch,

    // update
    updateUsageByIdFetch,

    // delete
    deleteUsageByIdFetch
}