/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import {
    Row,
    Col,
    Button,
    Form,
    Input,
    Upload,
} from 'antd'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { apiServerUrl, serverUrl } from '../../../../constants'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import { getPageHomeFetch, updatePageHomeByIdFetch } from '../../../home/API'
import { Notification } from '../../../../common/components/notification'
import LoadingPage from '../../../../common/components/loading/page'

export default function HomePage() {
    const [form] = Form.useForm()

    const [loading, setLoading] = useState(true)

    const audioReboundRef = useRef()


    const [data, setData] = useState(null)

    // -------------------------------------------------------------------
    const [titleIntro, setTitleIntro] = useState()
    const titleIntroRef = useRef()

    const [titleIntroFooter, setTitleIntroFooter] = useState()
    const titleIntroFooterRef = useRef()

    const [imageIntro1URL, setImageIntro1URL] = useState({
        loading: false,
        imageUrl: null
    })

    const [imageIntro2URL, setImageIntro2URL] = useState({
        loading: false,
        imageUrl: null
    })

    const [imageIntro2BgUrl, setImageIntro2BgURL] = useState({
        loading: false,
        imageUrl: null
    })
    // -------------------------------------------------------------------

    const optionImageIntro1 = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            name: "admin",
            path: "upload_file/image/page/home/intro1"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    setImageIntro1URL({
                        imageUrl: info.file.response.filePath,
                        loading: false,
                    })
                }
            } else {
                setImageIntro1URL({
                    loading: true,
                    imageUrl: imageIntro1URL.imageUrl
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const optionImageIntro2 = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            name: "admin",
            path: "upload_file/image/page/home/intro2"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    setImageIntro2URL({
                        imageUrl: info.file.response.filePath,
                        loading: false,
                    })
                }
            } else {
                setImageIntro2URL({
                    loading: true,
                    imageUrl: imageIntro2URL.imageUrl
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const optionImageIntro2Bg = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            name: "admin",
            path: "upload_file/image/page/home/intro2_bg"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    setImageIntro2BgURL({
                        imageUrl: info.file.response.filePath,
                        loading: false,
                    })
                }
            } else {
                setImageIntro2BgURL({
                    loading: true,
                    imageUrl: imageIntro2BgUrl.imageUrl
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    // -------------------------------------------------------------------

    const getAboutUs = async () => {
        const result = await getPageHomeFetch()

        setTitleIntro(result?.titleIntro ?? "")
        titleIntroRef.current = result?.titleIntro ?? ""

        setTitleIntroFooter(result?.titleIntroFooter ?? "")
        titleIntroFooterRef.current = result?.titleIntroFooter ?? ""
        

        setImageIntro1URL({
            loading: false,
            imageUrl: result.imageIntro1Url
        })

        setImageIntro2URL({
            loading: false,
            imageUrl: result.imageIntro2Url
        })

        setImageIntro2BgURL({
            loading: false,
            imageUrl: result.imageIntro2BgUrl
        })

        setData(result)
    }

    const onFinish = async (values) => {
        let obj = {
            pageHomeId: data.pageHomeId ? data.pageHomeId : null,
            pageName: values.pageName ? values.pageName : null,
            titleIntro: titleIntroRef.current ? titleIntroRef.current : null,
            subTitleIntro: data.subTitleIntro ? data.subTitleIntro : null,
            titleknowledge: values.titleknowledge ? values.titleknowledge : null,
            subTitleknowledge: values.subTitleknowledge ? values.subTitleknowledge : null,
            titleIntroFooter: titleIntroFooterRef.current ? titleIntroFooterRef.current : null,
            imageIntro1Url: imageIntro1URL.imageUrl ? imageIntro1URL.imageUrl : null,
            imageIntro2Url: imageIntro2URL.imageUrl ? imageIntro2URL.imageUrl : null,
            imageIntro2BgUrl: imageIntro2BgUrl.imageUrl ? imageIntro2BgUrl.imageUrl : null,
        }
        // console.log("หobj : ", obj)
        const result = await updatePageHomeByIdFetch(obj)
        if (result.isSuccess) {
            Notification('success', 'เเก้ไขสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const getBaseApi = async () => {
        setLoading(true)
        await getAboutUs()
        setLoading(false)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    // console.log("data : ", data)

    return (
        <>
            {!loading ?
                <Row>
                    <Col span={24} style={{ paddingBottom: 20 }}>
                        <label>เพจ (หน้าหลัก)</label>
                    </Col>
                    <Col xs={24} md={24} xl={24}>
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                            initialValues={{
                                pageHomeId: data?.pageHomeId,
                                pageName: data?.pageName,
                                subTitleIntro: data?.subTitleIntro,
                                titleknowledge: data?.titleknowledge,
                                subTitleknowledge: data?.subTitleknowledge,
                            }}
                        >
                            <Row gutter={[16,16]}>
                                <Col span={24}>
                                    <Form.Item name="pageHomeId" style={{ display: "none" }}>
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        // label=""
                                        name="pageName"
                                        style={{ width: '100%' }}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={{
                                            toolbar: {
                                                items: [
                                                    'heading', '|',
                                                    'fontfamily', 'fontsize', '|',
                                                    'bold', 'italic', 'underline', '|',
                                                    'alignment', '|',
                                                    'fontColor', 'fontBackgroundColor', '|',
                                                    'bulletedList', 'numberedList', 'todoList', '|',
                                                    'code', 'codeBlock', '|',
                                                    'undo', 'redo'
                                                ],
                                                removeButtons: 'Subscript,Superscript',
                                                // height: 350,
                                            },
                                        }}
                                        data={titleIntro}
                                        onBlur={(event, editor) => {
                                            const data = editor.getData()
                                            titleIntroRef.current = data
                                        }}
                                    />
                                </Col>

                                {/* <Col span={24}>
                                    <Form.Item
                                        // label=""
                                        name="subTitleIntro"
                                        style={{ width: '100%' }}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col> */}

                                <Col span={24}>
                                    <Form.Item
                                        // label=""
                                        name="titleknowledge"
                                        style={{ width: '100%' }}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                {/* <Col span={24}>
                                    <Form.Item
                                        // label=""
                                        name="subTitleknowledge"
                                        style={{ width: '100%' }}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col> */}

                                <Col span={24}>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={{
                                            toolbar: {
                                                items: [
                                                    'heading', '|',
                                                    'fontfamily', 'fontsize', '|',
                                                    'bold', 'italic', 'underline', '|',
                                                    'alignment', '|',
                                                    'fontColor', 'fontBackgroundColor', '|',
                                                    'bulletedList', 'numberedList', 'todoList', '|',
                                                    'code', 'codeBlock', '|',
                                                    'undo', 'redo'
                                                ],
                                                removeButtons: 'Subscript,Superscript',
                                                // height: 350,
                                            },
                                        }}
                                        data={titleIntroFooter}
                                        onBlur={(event, editor) => {
                                            const data = editor.getData()
                                            titleIntroFooterRef.current = data
                                        }}
                                    />
                                </Col>

                                <Col span={12}>
                                    <div style={{ display: "grid" }}>
                                        {/* <label style={{ paddingBottom: 6 }}></label> */}
                                        {imageIntro1URL?.imageUrl ?
                                            <img
                                                style={{ borderRadius: 8, maxWidth: 300, border: "1px solid #EEEEEE" }}
                                                src={`${serverUrl}/${imageIntro1URL.imageUrl}`}
                                            />
                                            :
                                            <img
                                                style={{ width: 207, borderRadius: 8 }}
                                                src={`./assets/images/default/df-img.png`}
                                            />
                                        }
                                        <div style={{ paddingTop: 12 }}>
                                            <Upload
                                                {...optionImageIntro1}
                                                accept='image/jpeg, image/png, image/jfif'
                                                style={{ width: "100%" }}
                                                maxCount={1}
                                                showUploadList={{ showRemoveIcon: false }}
                                            >
                                                <Button
                                                    type="default"
                                                    style={{ width: "100%" }}
                                                    icon={imageIntro1URL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                    onClick={() => audioReboundRef.current.play()}
                                                >อัพโหลดรูปภาพ</Button>
                                            </Upload>
                                        </div>
                                    </div>
                                </Col>

                                <Col span={12}>
                                    <div style={{ display: "grid" }}>
                                        {/* <label style={{ paddingBottom: 6 }}></label> */}
                                        {imageIntro2URL?.imageUrl ?
                                            <img
                                                style={{ borderRadius: 8, maxWidth: 300, border: "1px solid #EEEEEE" }}
                                                src={`${serverUrl}/${imageIntro2URL.imageUrl}`}
                                            />
                                            :
                                            <img
                                                style={{ width: 207, borderRadius: 8 }}
                                                src={`./assets/images/default/df-img.png`}
                                            />
                                        }
                                        <div style={{ paddingTop: 12 }}>
                                            <Upload
                                                {...optionImageIntro2}
                                                accept='image/jpeg, image/png, image/jfif'
                                                style={{ width: "100%" }}
                                                maxCount={1}
                                                showUploadList={{ showRemoveIcon: false }}
                                            >
                                                <Button
                                                    type="default"
                                                    style={{ width: "100%" }}
                                                    icon={imageIntro2URL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                    onClick={() => audioReboundRef.current.play()}
                                                >อัพโหลดรูปภาพ</Button>
                                            </Upload>
                                        </div>
                                    </div>
                                </Col>

                                <Col span={12}>
                                    <div style={{ display: "grid" }}>
                                        {/* <label style={{ paddingBottom: 6 }}></label> */}
                                        {imageIntro2BgUrl?.imageUrl ?
                                            <img
                                                style={{ borderRadius: 8, maxWidth: 300, border: "1px solid #EEEEEE" }}
                                                src={`${serverUrl}/${imageIntro2BgUrl.imageUrl}`}
                                            />
                                            :
                                            <img
                                                style={{ width: 207, borderRadius: 8 }}
                                                src={`./assets/images/default/df-img.png`}
                                            />
                                        }
                                        <div style={{ paddingTop: 12 }}>
                                            <Upload
                                                {...optionImageIntro2Bg}
                                                accept='image/jpeg, image/png, image/jfif'
                                                style={{ width: "100%" }}
                                                maxCount={1}
                                                showUploadList={{ showRemoveIcon: false }}
                                            >
                                                <Button
                                                    type="default"
                                                    style={{ width: "100%" }}
                                                    icon={imageIntro2BgUrl.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                    onClick={() => audioReboundRef.current.play()}
                                                >อัพโหลดรูปภาพ</Button>
                                            </Upload>
                                        </div>
                                    </div>
                                </Col>

                                <Col span={24}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Button
                                            style={{ float: 'right', width: 70 }}
                                            type="primary"
                                            onClick={() => {
                                                audioReboundRef.current.play()
                                                form.submit()}}
                                        >
                                            บันทึก
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                : 
                <LoadingPage loading={loading} />
            }
            <audio ref={audioReboundRef}>
				<source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
				Your browser does not support the audio element.
			</audio>
        </>
    )
}
