import { useEffect } from "react"
import {
    Image,
    Card,
    Row,
    Col
} from 'antd'
import Footer from "../../common/components/footer"
import Header from "../../common/components/header"
import { useDimensions } from "../../common/mamager/ScreenManager"
import LoginItem from "./LoginItem"
import "../login/css/index.css"

export default function Login({ authorized, firebase, user }) {
    const { width } = useDimensions()

    useEffect(() => {

    }, [authorized])

    return (
        <>
            <Header firebase={firebase} authorized={authorized} user={user} btnAutnActive={false} />

            <article>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: 24, paddingBottom: 24 }}>
                    <Card
                        style={{
                            minWidth: 270,
                            maxWidth: 440,
                            borderRadius: 20,
                            boxShadow: "2px 2px 16px 5px rgba(208, 216, 243, 1)",
                        }}
                    >
                        <Row gutter={[10, 10]} style={{ paddingTop: 24, paddingBottom: 24 }}>
                            <Col span={width >= 1500 ? 3 : ((width > 912 && width < 1500) ? 2 : 1)}></Col>
                            <Col span={width >= 1500 ? 18 : ((width > 912 && width < 1500) ? 20 : 22)}>
                                <LoginItem firebase={firebase} authorized={authorized} />
                            </Col>
                            <Col span={width >= 1500 ? 3 : ((width > 912 && width < 1500) ? 2 : 1)}></Col>
                        </Row>
                    </Card>
                </div>
            </article>

            <Footer />
        </>
    )
}

