/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    DatePicker,
    Popconfirm,
    Upload
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { apiServerUrl, serverUrl } from '../../../../constants'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import { getKnowledgeSourceAllFetch } from '../../../home/API'
import { deleteKnowledgeSourceByIdFetch, insertKnowledgeSourceFetch, updateKnowledgeSourceByIdFetch } from '../../API'

const { RangePicker } = DatePicker;

const columns = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "10%",
    },
    {
        title: 'ภาพปก',
        width: "15%",
        render: (text, record) => {
            return (
                <>
                    <img
                        style={{ maxHeight: 80, border: "1px solid #EEEEEE" }}
                        src={`${serverUrl}/${record.imageCoverURL}`}
                    />
                </>
            )
        }
    },
    {
        title: 'รายการ',
        dataIndex: 'title',
        width: "40%",
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "10%",
    },
    {
        title: 'วันที่เเก้ไข',
        dataIndex: 'updatedAt',
        width: "10%",
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "15%",
    },
]

const formatDate = "DD/MM/YYYY"

export default function KnowledgeSourceManage() {

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)

    const [form] = Form.useForm()
    const [formSearch] = Form.useForm()

    // field external
    const [imageCoverURL, setImageCoverURL] = useState({
        loading: false,
        imageUrl: null
    })

    const audioReboundRef = useRef()


    const [pageCurrent, setPageCurrent] = useState(1)

    const [modal, setModal] = useState({
        isShow: false,
        title: null
    })

    const optionKnowledgeSourceImage = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            name: "my-user",
            path: "upload_file/image/knowledge_source"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    setImageCoverURL({
                        imageUrl: info.file.response.filePath,
                        loading: false
                    })
                }
            } else {
                setImageCoverURL({
                    loading: true,
                    imageUrl: imageCoverURL.imageUrl
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`
        }
    }

    const getKnowledgeSourceAll = async (title, startedDate, endDate) => {
        setLoading(true)

        let objSearch = {
            title, startedDate, endDate
        }
        // console.log("getKnowledgeSourceAll : ", objSearch)
        const result = await getKnowledgeSourceAllFetch(objSearch)
        // console.log("result : ", result)

        let tempList = [];
        result?.map((val, index) => {
            tempList.push({
                index: (index + 1),
                imageCoverURL: val.imageCoverURL,
                title: val.title,
                linkVideo: val.linkVideo,
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            style={{
                                width: 35,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: 'white',
                                borderRadius: 50
                            }}
                            onClick={() => {
                                audioReboundRef?.current?.play()
                                form.setFieldsValue({
                                    knowledgeSourceId: val.knowledgeSourceId,
                                    title: val.title,
                                    ep: val.ep,
                                    linkVideo: val.linkVideo,
                                    updatedAt: val.updatedAt,
                                    createdAt: val.createdAt
                                })
                                setImageCoverURL({
                                    loading: false,
                                    imageUrl: val.imageCoverURL
                                })
                                setModal({ isShow: true, title: "edit" })
                            }}
                        >
                            <div style={{ marginTop: 0, marginLeft: -8 }}>
                                <Icon icon="typcn:edit" style={{ color: "white", width: 20, height: 20 }} />
                            </div>
                        </Button>{"  "}

                        <Popconfirm
                            title="คุณยืนยันลบหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                await handleKnowledgeSourceDelete(val.knowledgeSourceId)

                                // reload
                                await getKnowledgeSourceAll(null, null, null)
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                danger
                                type="primary"
                                style={{
                                    width: 35,
                                    borderRadius: 50
                                }}
                                onClick={() => audioReboundRef?.current?.play()}
                            >
                                <div style={{ marginTop: 0, marginLeft: -8 }}>
                                    <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 20, height: 20 }} />
                                </div>
                            </Button>
                        </Popconfirm>
                    </>
            });
        });
        setList(tempList)

        setLoading(false)
    }

    const onFinish = async (values) => {
        let obj = {
            knowledgeSourceId: values.knowledgeSourceId ? values.knowledgeSourceId : null,
            title: values.title ? values.title : null,
            ep: values.ep ? values.ep : null,
            imageCoverURL: imageCoverURL.imageUrl ? imageCoverURL.imageUrl : null,
            linkVideo: values.linkVideo ? values.linkVideo : null,
        }
        // console.log("onFinish : ", obj)
        if (modal.title === "add") {
            const result = await insertKnowledgeSourceFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'สร้างสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง')
            }
        } else if (modal.title === "edit") {
            const result = await updateKnowledgeSourceByIdFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'เเก้ไขสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        getKnowledgeSourceAll(null, null, null)

        // set default
        setFormDefault()
    }

    const onSearchFinish = async (values) => {
        let title = values?.title ? values.title : null
        let startedDate = values?.dateRange ? values.dateRange[0] : null
        let endDate = values?.dateRange ? values.dateRange[1] : null

        // let objSearch = {
        //     name, startDate, endDate
        // }
        // console.log("onSearchFinish : ", objSearch)
        await getKnowledgeSourceAll(title, startedDate, endDate)
    }

    const handleKnowledgeSourceDelete = async (id) => {
        const result = await deleteKnowledgeSourceByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const setFormDefault = () => {
        form.resetFields()

        setModal({
            isShow: false,
            title: null,
        })
    }

    useEffect(() => {
        getKnowledgeSourceAll(null, null, null)
    }, [])

    return (
        <Row>
            <Col span={12}>
                <label>จัดการเเหล่งความรู้</label>
            </Col>
            <Col span={12} style={{ paddingBottom: 20 }}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ชื่อประกาศ"
                                name="title"
                                style={{ width: '100%' }}
                            >
                                <Input />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="EP"
                                name="ep"
                                style={{ width: '100%' }}
                            >
                                <Input />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ห้วงวันที่สร้าง" name="dateRange"
                                style={{ width: '100%' }}
                            >
                                <RangePicker />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10, marginTop: 3 }}>
                            <Button
                                style={{ float: 'right', width: 70 }}
                                type="primary"
                                onClick={() => {
                                    audioReboundRef?.current?.play()
                                    formSearch.submit()
                                }}
                            >
                                ค้นหา
                            </Button>
                        </div>
                    </div>
                </Form>
            </Col>

            <Col span={24} style={{ paddingBottom: 20 }}>
                <Button
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={() => {
                        audioReboundRef?.current?.play()
                        form.resetFields()
                        setImageCoverURL({
                            loading: false,
                            imageUrl: null
                        })

                        setModal({
                            isShow: true,
                            title: "add"
                        })
                    }}
                >เพิ่มรายการ</Button>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrent,
                        pageSize: 10,
                        total: list.length
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modal.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}</label></strong>}
                visible={modal.isShow}
                onCancel={() => {

                    // default
                    setFormDefault();
                }}
                width={1000}
                onOk={() => {
                    form.submit();
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }} onClick={() => audioReboundRef?.current?.play()}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }} onClick={() => audioReboundRef?.current?.play()}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <Row gutter={[24, 0]}>
                        <Col span={12}>
                            <Form.Item name="knowledgeSourceId" style={{ display: "none" }}>
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="title"
                                label="รายการ"
                                rules={[{ required: true, message: 'กรุณากรอกรายการ' }]}
                            >
                                <Input.TextArea
                                    style={{ width: "100%" }}
                                // autoSize={{ minRows: 6, maxRows: 6 }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="ep"
                                label="EP"
                                rules={[{ required: true, message: 'กรุณากรอก EP' }]}
                            >
                                <Input style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="linkVideo"
                                label="ลิงค์วิดีโอ"
                            >
                                <Input style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div style={{ display: "grid" }}>
                                <label style={{ paddingBottom: 6 }}>ปกรูปภาพ</label>
                                {imageCoverURL?.imageUrl ?
                                    <img
                                        style={{ borderRadius: 8, maxHeight: 207, border: "1px solid #EEEEEE" }}
                                        src={`${serverUrl}/${imageCoverURL.imageUrl}`}
                                    />
                                    :
                                    <img
                                        style={{ width: 207, borderRadius: 8 }}
                                        src={`./assets/images/default/df-img.png`}
                                    />
                                }
                                <div style={{ paddingTop: 12 }}>
                                    <Upload
                                        {...optionKnowledgeSourceImage}
                                        accept='image/jpeg, image/png, image/jfif'
                                        style={{ width: "100%" }}
                                        maxCount={1}
                                        showUploadList={false}
                                    >
                                        <Button
                                            type="default"
                                            style={{ width: "100%" }}
                                            icon={imageCoverURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                            onClick={() => audioReboundRef?.current?.play()}
                                        // disabled={false}
                                        >อัพโหลดรูปภาพ</Button>
                                    </Upload>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <audio ref={audioReboundRef}>
                <source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
        </Row>
    )
}
