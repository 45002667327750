/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */

import { useState, useEffect, useRef } from "react"
import { useDimensions } from "../../../common/mamager/ScreenManager"
import {
    Collapse, Button, Row, Col
} from 'antd'
import { getFaqAllFetch, getPageFaqFetch } from "../API"
import { serverUrl } from "../../../constants"
import parse from 'html-react-parser'
import { CaretRightOutlined } from '@ant-design/icons';
import "./../css/FaqBoard.css"
import Video from "../../../common/components/video"
import firebase from 'firebase/app'
import LoadingPage from "../../../common/components/loading/page"

export default function FaqBoard({ user }) {
    const [loading, setLoading] = useState(false)
    const { width } = useDimensions()

    const audioReboundRef = useRef()


    const [pageFaq, setPageFaqData] = useState(null)
    const [faqAll, setFaqAllData] = useState(null)

    const getPageFaq = async () => {
        const result = await getPageFaqFetch()
        setPageFaqData(result)
    }

    const detectOnlyMember = (isOnlyMember) => {
        // console.log(firebase?.auth()?.currentUser?.uid)
        if (isOnlyMember) {
            if (firebase?.auth()?.currentUser?.uid) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }

    const getFaqAll = async () => {
        const result = await getFaqAllFetch()
        setFaqAllData(result)
    }

    const baseAPI = async () => {
        getPageFaq()
        getFaqAll()
    }

    useEffect(() => {
        baseAPI()
    }, [])

    return (
        <>
            {!loading ?
                <div
                    style={{
                        display: (width > 768 ? "flex" : "grid"),
                        alignItems: "flex-start",
                        justifyContent: "center",
                        // paddingLeft: width > 925 ? '15%' : 12,
                        // paddingRight: width > 925 ? '15%' : 12,
                        paddingTop: 24,
                        paddingBottom: 24
                    }}
                >
                    <div style={{ display: "grid", alignItems: "flex-start", justifyContent: (width > 768 ? "flex-start" : "center"), paddingBottom: width > 768 ? 0 : 24 }}>
                        <label style={{ fontSize: 20, fontWeight: 600, paddingBottom: 12 }}>{pageFaq?.titleImage}</label>
                        <img
                            src={`${serverUrl}/${pageFaq?.imageURL}`}
                            style={{ height: 200, width: 300, borderRadius: 8 }}
                        />
                    </div>
                    <div style={{ display: "grid", alignItems: "flex-start", paddingLeft: 12 }}>
                        <label style={{ fontSize: 20, fontWeight: 600, paddingBottom: 12 }}>{pageFaq?.titleFAQ}</label>
                        <div>
                            {faqAll?.map((item, index) => {
                                return (
                                    <>
                                        {detectOnlyMember(item.isOnlyMember) ?
                                            <div style={{ paddingBottom: 12 }}>
                                                <Collapse
                                                    style={{ minWidth: 400, color: "black" }}
                                                    // expandIcon={({ isActive }) => <CaretRightOutlined style={{ color: "black" }} rotate={isActive ? 90 : 0} />}
                                                    showArrow={true}
                                                    items={[{
                                                        key: (index + 1),
                                                        label:
                                                            <>
                                                                <label>{item.title}</label>
                                                            </>,
                                                        children:
                                                            <div style={{ maxWidth: 450 }}>
                                                                <Row gutter={[0, 16]}>
                                                                    {item.videoURL ?
                                                                        <Col xs={24} md={24} xl={24}>
                                                                            <div style={{ backgroundColor: "black", borderRadius: 8 }}>
                                                                                <Video
                                                                                    url={item?.videoURL}
                                                                                    title={""}
                                                                                    height={"100%"}
                                                                                    width={"100%"}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        : []
                                                                    }

                                                                    {item.imageURL ?
                                                                        <Col xs={24} md={24} xl={24}>
                                                                            <img
                                                                                src={`${serverUrl}/${item?.imageURL}`}
                                                                                style={{ width: "100%", borderRadius: 8 }}
                                                                            />
                                                                        </Col>
                                                                        : []
                                                                    }

                                                                    {item.detail ?
                                                                        <Col xs={24} md={24} xl={24}>
                                                                            <label>{item.detail ? parse(item.detail) : []}</label>
                                                                        </Col>
                                                                        : []
                                                                    }
                                                                    {item.link ?
                                                                        <Col xs={24} md={24} xl={24}>

                                                                            <div style={{ paddingTop: item.subTitle ? 12 : 0 }}>
                                                                                <Button
                                                                                    style={{ width: 120 }}
                                                                                    onClick={() => {
                                                                                        audioReboundRef.current.play()
                                                                                        window.open(item.link)
                                                                                    }}
                                                                                >คลิกที่นี่!</Button>
                                                                            </div>

                                                                        </Col>
                                                                        : []
                                                                    }
                                                                </Row>
                                                            </div>
                                                    }]}
                                                />
                                            </div>
                                            : []
                                        }
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </div>
                : 
                <LoadingPage loading={loading} />
            }
            <audio ref={audioReboundRef}>
				<source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
				Your browser does not support the audio element.
			</audio>
        </>
    )
}
