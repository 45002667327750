import { server } from "../../../../../constants"
import { httpClient } from "../../../../../utils/HttpClient"

const getAdminBySearchFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.GET_ADMIN_BY_SEARCH_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const inspectNewUsernameDuplicateFetch = async (username) => { // Done
    try {
        const result = await httpClient.post(server.INSPECT_NEW_USERNAME_DUPLICATE_URL, { username })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const inspectOldPasswordMatchesFetch = async (password, adminId) => { // Done
    try {
        const result = await httpClient.post(server.INSPECT_OLD_PASSWORD_MATCHES_URL, { password, adminId })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertAdminFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_ADMIN_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateAdminByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_ADMIN_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateAdminPasswordByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_ADMIN_PASSWORD_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteAdminByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(server.DELETE_ADMIN_BY_ID_URL + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getAdminBySearchFetch,
    inspectNewUsernameDuplicateFetch,
    inspectOldPasswordMatchesFetch,

    // insert
    insertAdminFetch,

    // update
    updateAdminByIdFetch,
    updateAdminPasswordByIdFetch,

    // delete
    deleteAdminByIdFetch
}
