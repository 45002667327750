import { server } from "../../../../../constants"
import { httpClient } from "../../../../../utils/HttpClient"

const getPaymentOrderBySearchFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.GET_PAYMENT_ORDER_BY_SEARCH_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getPaymentStatusAllFetch = async () => { // Done
    try {
        const result = await httpClient.get(server.GET_PAYMENT_STATUS_ALL_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getPaymentTypeAllFetch = async () => { // Done
    try {
        const result = await httpClient.get(server.GET_PAYMENT_TYPE_ALL_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updatePaymentOrderByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_PAYMENT_ORDER_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deletePaymentOrderByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(server.DELETE_PAYMENT_ORDER_BY_ID_URL + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getPaymentOrderBySearchFetch,
    getPaymentStatusAllFetch,
    getPaymentTypeAllFetch,

    // insert

    // update
    updatePaymentOrderByIdFetch,

    // delete
    deletePaymentOrderByIdFetch
}