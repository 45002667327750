import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getPredictionAllFetch = async () => {
    try {
        const result = await httpClient.get(server.GET_PREDICTION_ALL_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getPredictionWaitInspectFetch = async (uid, predictionId) => {
    try {
        const result = await httpClient.post(server.GET_PREDICTION_WAIT_INSPECT_URL, { uid, predictionId })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getPredictionPurchasedAndNotExpiredFetch = async (uid, predictionId) => {
    try {
        const result = await httpClient.post(server.GET_PREDICTION_PURCHASED_AND_NOT_EXPIRED_URL, { uid, predictionId })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getPredictionAllFetch,
    getPredictionWaitInspectFetch,
    getPredictionPurchasedAndNotExpiredFetch
}