/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import { useEffect, useState, useRef } from "react"
import "../footer/css/index.css"
import { BackTop, Col, Image, Row, Tooltip, Input } from "antd"
import { useLocation, useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import firebase from 'firebase/app'

import { getContactChannelAllFetch } from "../../../modules/contract/API"
import { serverUrl } from "../../../constants"

// import Complaint from "../../../modules/helpAndResources/complaint"
// import ContractUs from "../contractUs"
// import { detectSignIn } from "../../mamager/DetectManager"
// import { Notification } from "../notification"

function Footer() {
    // let uid = firebase?.auth()?.currentUser?.uid
    const navigate = useNavigate()

    // const location = useLocation()
    // const [isModalContractUsVisible, setIsModalContractUsVisible] = useState(false)

    // const [isModalComplaintActive, setIsModalComplaintActive] = useState(false)

    // const showContractUsModal = () => {
    //     // setIsModalContractUsVisible(true)
    // }

    // const handleContractUsCancel = () => {
    //     // setIsModalContractUsVisible(false)
    // }

    // const handleModalComplaintActive = () => {
    //     // setIsModalComplaintActive(!isModalComplaintActive)
    // }

    const [contactChannelData, setContactChannelData] = useState()
    const [loading, setLoading] = useState(true)

    const audioReboundRef = useRef()

    const getContactChannel = async () => {
        const result = await getContactChannelAllFetch()
        setContactChannelData(result)
    }

    const getBaseApi = async () => {
        setLoading(true)
        await getContactChannel()
        setLoading(false)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <>
            {!loading ?
                <footer>
                    <nav style={{ backgroundColor: "#262626", color: "white", paddingLeft: '9%', paddingRight: '9%', paddingTop: 50, paddingBottom: 50 }}>
                        <Row>
                            <Col xs={24} xl={8}>
                                <Row gutter={15} style={{ paddingRight: 15 }}>
                                    <Col span={24}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Image
                                                fallback="./assets/images/logo/Du-SolarChart-Footer.png"
                                                src="error"
                                                preview={false}
                                                // width={50}
                                                height={110}
                                            />
                                            {/* <label style={{ paddingLeft: 10 }}>Toflyn</label> */}
                                        </div>
                                    </Col>
                                    <Col span={24} style={{ paddingTop: 7.5 }}>
                                        <div style={{ display: 'flex' }}>
                                            <label style={{ paddingLeft: 8 }}>สถาบันโหราศาสตร์ วิตเตอ-กรุงเทพ โดย ดร.ดวง (ยูเรเนียนกรุ๊ปประเทศไทย 1978)</label>
                                        </div>
                                    </Col>
                                    <Col span={24} style={{ paddingTop: 7.5 }}>
                                        <div style={{ display: 'flex' }}>
                                            <label style={{ paddingLeft: 10, color: "gray" }}>Das Witte-Bangkok  Astrologie-Forschungslabor</label>
                                        </div>
                                    </Col>
                                    <Col span={24} style={{ paddingBottom: 20 }}>
                                        <div style={{ display: 'flex' }}>
                                            <label style={{ paddingLeft: 10, color: "gray" }}>von Dr. Duang (Uranian Group Thailand 1978)</label>
                                        </div>
                                    </Col>

                                    {/* <Col span={24} style={{ textAlign: "center" }}>
                                <a
                                    href="#"
                                    style={{ color: "white", textDecoration: "underline" }}
                                    onClick={() => showContractUsModal()}
                                >อ่านต่อ</a>
                            </Col> */}

                                    <Col span={24} style={{ paddingTop: 15, paddingBottom: 15 }}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                                            {contactChannelData?.map((item) => {
                                                return (
                                                    <a className="nav-link" style={{ paddingLeft: 0, paddingRight: 10, float: "left" }} href={item.link}>
                                                        <img
                                                            src={`${serverUrl}/${item.imageURL}`}
                                                            style={{ width: 40, height: 40, filter: "grayscale(40%)", cursor: "pointer" }}
                                                        />
                                                    </a>
                                                )
                                            })}
                                            {/* <a className="nav-link" style={{ paddingLeft: 0, paddingRight: 10, float: "left" }} onClick={() => alert("Line official กำลังเปิดใช้งานเร็ว ๆ นี้")}>
                                                <Icon icon="cib:line" style={{ color: "white", width: 30, height: 30 }} />
                                            </a>

                                            <a className="nav-link" style={{ paddingLeft: 0, paddingRight: 10, float: "left" }} href="#" target="_blank">
                                                <Icon icon="akar-icons:facebook-fill" style={{ color: "white", width: 30, height: 30 }} />
                                            </a>

                                            <a className="nav-link" style={{ paddingLeft: 0, paddingRight: 10, float: "left" }} onClick={() => alert("Youtube official กำลังเปิดใช้งานเร็ว ๆ นี้")}>
                                                <Icon icon="entypo-social:youtube-with-circle" style={{ color: "white", width: 30, height: 30 }} />
                                            </a> */}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs={24} xl={8}>
                                <div style={{ height: '100%', display: "grid", alignItems: "flex-start", paddingBottom: 24, paddingTop: 12 }}>
                                    <div style={{ display: "grid", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                                        <div>
                                            <label style={{ color: "white" }}>ต้องการคำเสนอแนะ</label>
                                        </div>
                                        <div style={{ paddingTop: 12 }}>
                                            <label style={{ color: "gray" }}>แนะนำบริการ</label>
                                        </div>
                                        <div style={{ paddingTop: 4 }}>
                                            <label style={{ color: "gray" }}>ปรึกษาฯโปรดติดต่อมาหาเรา</label>
                                        </div>
                                        <div style={{ paddingTop: 4 }}>
                                            <label style={{ color: "gray" }}>หรือติดตามบทความในคลิปของเรา</label>
                                        </div>
                                        <div style={{ paddingTop: 18 }} onClick={() => {
                                             audioReboundRef.current.play()
                                             setTimeout(function () {
                                               navigate("/contact")
                                               window.scrollTo(0, 630)
                                           }, 1000)
                                        }}>
                                            <div style={{ paddingLeft: 10 }}>
                                                <div className="btn-consult">
                                                    <label style={{ cursor: "pointer" }} onClick={() =>  audioReboundRef.current.play()}>ฟรี!</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col xs={24} xl={8}>
                                <div style={{ height: '100%', display: "grid", alignItems: "flex-start", paddingBottom: 24, paddingTop: 12 }}>
                                    <div style={{ display: "grid", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                                        <div>
                                            <label style={{ color: "white" }}>ติดต่อหาเราวันนี้</label>
                                        </div>
                                        <div style={{ paddingTop: 12 }}>
                                            <a href="tel:021362116" style={{ color: "gray" }}>โทรศัพท์: <span style={{ color: "#FF7F00" }}>02-136-2116</span></a>
                                        </div>
                                        <div style={{ paddingTop: 4 }}>
                                            <a href="mailto: wittebangkok@gmail.com" style={{ color: "gray" }}>อีเมล์: <span style={{ color: "#FF7F00" }}>wittebangkok@gmail.com</span></a>
                                        </div>

                                        {/* <div style={{ paddingTop: 32 }}>
                                            <Input
                                                size="large"
                                                placeholder="Email Address..."
                                                // prefix={<UserOutlined className="site-form-item-icon" />}
                                                suffix={
                                                    <Icon icon="ic:outline-email" style={{ color: "#FF7F00", width: 24, height: 24 }} />
                                                }
                                            />
                                        </div> */}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </nav>

                    <nav style={{ backgroundColor: "#1A1A1A", color: "white", paddingLeft: '5%', paddingRight: '5%', paddingTop: 15, paddingBottom: 15, textAlign: 'center' }}>
                        <Row>
                            <Col span={24}>
                                <label>@Contents Copyright 2024 by  Das Witte- Bangkok  Astrologie-Forschungslabor</label>
                            </Col>
                            <Col span={24}>
                                <label>@เนื้อหา ลิขสิทธิ์ 2024 โดย สถาบันโหราศาสตร์วิตเตอ-กรุงเทพ - ดร.ดวง (ยูเรเนียนกรุ๊ปประเทศไทย 1978)</label>
                            </Col>
                        </Row>
                    </nav>
                </footer>
                : []
            }
            <audio ref={audioReboundRef}>
				<source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
				Your browser does not support the audio element.
			</audio>
        </>
    )
}

export default Footer
