/* eslint-disable no-duplicate-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState, useEffect, useRef } from 'react'
import {
    Button,
    Layout,
    Menu
} from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { useNavigate, useLocation } from 'react-router-dom'
import AnnounceManage from './manage/announce'
import UserManage from './manage/user'
import { getAdminByIdFetch } from './API'
import KnowledgeSourceManage from './manage/knowledgeSource'
import NewsManage from './manage/news'
import FaqManage from './manage/faq'
import UserCommentManage from './manage/user/userComment'
import InquiryFormInspect from './inspect/inquiryForm'
import PartnerManage from './manage/partner'
import HighlightAstrologyManage from './manage/highlightAstrology'
import ServiceManage from './manage/service'
import ServiceAstrologicalManage from './manage/service/ServiceAstrological'
import ContactUsChannelManage from './manage/contactUsChannel'
import AdminManage from './manage/admin'
import CourseManage from './manage/course'
import PaymentAccountManage from './manage/payment/paymentAccount'
import AboutUsPage from './page/aboutUs'
import ContactUsPage from './page/contactUs'
import ServicePage from './page/service'
import HomePage from './page/home'
import TalkToUsComponent from './page/components/talkToUs'
import ImportanceAstrologyComponent from './page/components/importanceAstrology'
import FaqComponent from './page/components/faq'
import PlanetaryAspect from './warehouse/planetaryAspect'
import KhunHor from './warehouse/khunHor'
import Mandala from './warehouse/mandala'
import PredictionManage from './manage/prediction'
import PaymentOrderInspect from './inspect/payment/paymentOrder'
import EBookManage from './manage/ebook'
import FeedbackInspect from './inspect/feedback'
import BookInfo from './warehouse/bookInfo'
import LoadingPageManage from './manage/loadingPage'
import LoadingPage from '../../common/components/loading/page'
import InspectExam from './inspect/exam'
import UsageManage from './manage/usage'

const { Sider } = Layout
const { SubMenu } = Menu

export default function ERP(props) {
    const navigate = useNavigate()
    const location = useLocation()

    const [loading, setLoading] = useState(true)
    
    const audioReboundRef = useRef()


    let username = location.state ? location.state.username : null
    let password = location.state ? location.state.password : null

    const [selectedMenuItem, setSelectedMenuItem] = useState("manage-user")

    const componentsSwitch = (type) => {
        switch (type) {
            // ---------------------------------------
            case "inspect-inquiry-form":
                return <InquiryFormInspect />
            case "inspect-payment":
                return <PaymentOrderInspect adminLogin={location.state} />
            case "inspect-feedback":
                return <FeedbackInspect />
            case "inspect-exam":
                return <InspectExam />

            // ---------------------------------------
            case "manage-course":
                return <CourseManage />
            case "manage-prediction":
                return <PredictionManage />
            case "manage-ebook":
                return <EBookManage />
            case "manage-payment-account":
                return <PaymentAccountManage />
            case "manage-user":
                return <UserManage />
            case "manage-admin":
                return <AdminManage />
            case "manage-knowledge-source":
                return <KnowledgeSourceManage />
            case "manage-announce":
                return <AnnounceManage />
            case "manage-news":
                return <NewsManage />
            case "manage-faq":
                return <FaqManage />
            case "manage-user-comment":
                return <UserCommentManage />
            case "manage-partner":
                return <PartnerManage />
            case "manage-highlight-astrology":
                return <HighlightAstrologyManage />
            case "manage-service":
                return <ServiceManage />
            case "manage-service-astrological":
                return <ServiceAstrologicalManage />
            case "manage-contact-us-channel":
                return <ContactUsChannelManage />
            case "manage-loading-page":
                return <LoadingPageManage />
            case "manage-usage":
                return <UsageManage />

            // ---------------------------------------

            case "warehouse-planetary-aspect":
                return <PlanetaryAspect />
            case "warehouse-mandala":
                return <Mandala />
            case "warehouse-khun-hor":
                return <KhunHor />
            case "warehouse-book-info":
                return <BookInfo />

            // ---------------------------------------
            case "page-home":
                return <HomePage />
            case "page-service":
                return <ServicePage />
            case "page-about-us":
                return <AboutUsPage />
            case "page-contact-us":
                return <ContactUsPage />
            case "component-talk-to-us":
                return <TalkToUsComponent />
            case "component-importance-astrology":
                return <ImportanceAstrologyComponent />
            case "component-faq":
                return <FaqComponent />

            default:
                return null
        }
    }

    const detectLogin = async () => {
        return await getAdminByIdFetch(username, password) // & where isActive = 1
    }

    const getBaseApi = async () => {
        // console.log(username, password)
        // setInterval(async () => {
        setLoading(true)
        let result = await detectLogin()
        // console.log("detectLogin : ", result)
        if (!result?.isLogin) {
            navigate("/backoffice-login")
            // Notification("warning", "เเจ้งเตือน", "เกิดข้อผิดพลาดในการ Detect login !")
        }
        setLoading(false)
        // }, 10000)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <>
            {!loading ?
                <div style={{ backgroundColor: '#ECECEC' }}>
                    <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <PageHeader
                            title={
                                <>
                                    <label style={{ paddingBottom: 0, marginBottom: 0, color: "white" }}>Administrator</label><br />
                                    <label style={{ paddingBottom: 0, marginBottom: 0, color: "white", fontWeight: "initial" }}>ผู้ใช้งาน : {location.state ? location.state.username : "-"}</label>
                                </>
                            }
                            extra={[
                                <Button
                                    type='default'
                                    onClick={() => {
                                        audioReboundRef.current.play()
                                        navigate(
                                            "/erp-manage",
                                            { state: null }
                                        )

                                        navigate("/backoffice-login")
                                    }}
                                    style={{ position: 'absolute', marginTop: 5, marginLeft: -105 }}
                                >ออกจากระบบ</Button>
                            ]}
                            style={{ backgroundColor: 'black' }}
                        >
                        </PageHeader>
                        <Layout>
                            <Sider
                                width={300}
                                className="site-layout-background"
                            >
                                <Menu
                                    defaultSelectedKeys={['1']}
                                    defaultOpenKeys={['mamage-user']}
                                    mode="inline"
                                    theme="dark"
                                    selectedKeys={selectedMenuItem}
                                    onClick={async (e) => {
                                        setSelectedMenuItem(e.key)

                                        // let resultDetectLogin = await detectLogin(); // & where isActive = 1
                                        // // console.log("detectLogin 2 : ", resultDetectLogin)

                                        // // ฝ่ายตรวจสอบ
                                        // if (resultDetectLogin.adminTypeId === 1) { // location.state.adminTypeId
                                        //     if (e.key === "menu0" || e.key === "menu1" || e.key === "menu2" || e.key === "menu3" || e.key === "menu4") {
                                        //         setSelectedMenuItem(e.key)
                                        //     } else {
                                        //         Notification("warning", "เเจ้งเตือน!", "คุณไม่มีสิทธิเข้าถึง")
                                        //     }
                                        // }

                                        // // ฝ่ายข่าวสาร
                                        // if (resultDetectLogin.adminTypeId === 2) {
                                        //     if (e.key === "menu0" || e.key === "menu5") {
                                        //         setSelectedMenuItem(e.key)
                                        //     } else {
                                        //         Notification("warning", "เเจ้งเตือน!", "คุณไม่มีสิทธิเข้าถึง")
                                        //     }
                                        // }

                                        // // ธานอส
                                        // if (resultDetectLogin.adminTypeId === 3) {
                                        //    setSelectedMenuItem(e.key)
                                        // }
                                    }}
                                    style={{ height: '100%' }}
                                >


                                    <SubMenu key="group-menu-1" title="การตรวจสอบ">
                                        <Menu.Item key="inspect-inquiry-form">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>ตรวจสอบเเบบสอบถาม</label>
                                        </Menu.Item>

                                        <Menu.Item key="inspect-payment">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>ตรวจสอบการชำระเงิน</label>
                                        </Menu.Item>

                                        <Menu.Item key="inspect-feedback">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>ตรวจสอบข้อเสนอเเนะ</label>
                                        </Menu.Item>

                                        <Menu.Item key="inspect-exam">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>ตรวจข้อสอบ (ขอใบประกาศนียบัตร)</label>
                                        </Menu.Item>
                                    </SubMenu>

                                    <SubMenu key="group-menu-2" title="การจัดการ">
                                        
                                        <Menu.Item key="manage-user">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการสมาชิก</label>
                                        </Menu.Item>

                                        <Menu.Item key="manage-usage">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการ สอนการใช้งาน</label>
                                        </Menu.Item>

                                        <Menu.Item key="manage-course">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการคอร์ส</label>
                                        </Menu.Item>

                                        <Menu.Item key="manage-prediction">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการพยากรณ์</label>
                                        </Menu.Item>

                                        <Menu.Item key="manage-ebook">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการ eBook</label>
                                        </Menu.Item>

                                        <Menu.Item key="manage-payment-account">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการช่องทางชำระเงิน</label>
                                        </Menu.Item>

                                        <Menu.Item key="manage-admin">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการ Admin</label>
                                        </Menu.Item>

                                        <Menu.Item key="manage-knowledge-source">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการเเหล่งความรู้</label>
                                        </Menu.Item>

                                        <Menu.Item key="manage-announce">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการประกาศ</label>
                                        </Menu.Item>

                                        <Menu.Item key="manage-news">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการข่าวสาร</label>
                                        </Menu.Item>

                                        <Menu.Item key="manage-faq">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการคำถามที่พบบ่อย (FAQ’S)</label>
                                        </Menu.Item>

                                        <Menu.Item key="manage-user-comment">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการสิ่งที่ผู้ใช้กล่าวถึงเรา</label>
                                        </Menu.Item>

                                        <Menu.Item key="manage-partner">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการ Partner</label>
                                        </Menu.Item>

                                        <Menu.Item key="manage-highlight-astrology">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการโหราศาสตร์ไฮไลท์ (จุดเด่น)</label>
                                        </Menu.Item>

                                        <Menu.Item key="manage-service">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดรายละเอียดการบริการต่างๆ</label>
                                        </Menu.Item>

                                        <Menu.Item key="manage-service-astrological">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดรายละเอียดบริการทางโหราศาสตร์</label>
                                        </Menu.Item>

                                        <Menu.Item key="manage-contact-us-channel">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการช่องทางติดต่อ</label>
                                        </Menu.Item>

                                        <Menu.Item key="manage-loading-page">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการ Loading page</label>
                                        </Menu.Item>
                                    </SubMenu>

                                    <SubMenu key="group-menu-3" title="เพจเว็บไซต์">
                                        <Menu.Item key="page-home">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>Page หน้าหลัก</label>
                                        </Menu.Item>

                                        <Menu.Item key="page-service">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>Page บริการต่างๆ</label>
                                        </Menu.Item>

                                        <Menu.Item key="page-about-us">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>Page เกี่ยวกับเรา</label>
                                        </Menu.Item>

                                        <Menu.Item key="page-contact-us">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>Page ติดต่อเรา</label>
                                        </Menu.Item>

                                        <Menu.Item key="component-talk-to-us">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>Sub page พูดคุยกับเรา</label>
                                        </Menu.Item>

                                        <Menu.Item key="component-importance-astrology">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>Sub page สำคัญของโหราศาสตร์</label>
                                        </Menu.Item>

                                        <Menu.Item key="component-faq">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>Sub page FAQ’S</label>
                                        </Menu.Item>
                                    </SubMenu>

                                    <SubMenu key="group-menu-4" title="คลังข้อมูล">
                                        <Menu.Item key="warehouse-planetary-aspect">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>ข้อมูลคอมพิวเตอร์โหร</label>
                                        </Menu.Item>

                                        <Menu.Item key="warehouse-mandala">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>ข้อมูลดวงกาลจักร</label>
                                        </Menu.Item>

                                        <Menu.Item key="warehouse-khun-hor">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>ข้อมูลขุนโหร</label>
                                        </Menu.Item>

                                        <Menu.Item key="warehouse-book-info">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>ข้อมูลหนังสือ</label>
                                        </Menu.Item>
                                    </SubMenu>
                                </Menu>
                            </Sider>
                            <article style={{ backgroundColor: 'white', width: '100%', height: '100%' }}>
                                <div style={{ padding: 20 }}>
                                    {componentsSwitch(selectedMenuItem)}
                                </div>
                            </article>
                        </Layout>
                    </div>
                </div>
                :
                <LoadingPage loading={loading} />
            }
            <audio ref={audioReboundRef}>
				<source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
				Your browser does not support the audio element.
			</audio>
        </>
    )
}
