import { useEffect, useState, useRef } from 'react'
import { getBankAccountFetch, getPaymentAccountFetch, updatePaymentAccountByIdFetch } from '../../../../payment/API'
import {
    Row,
    Col,
    Button,
    Form,
    Input,
    Select
} from 'antd'
import { Notification } from '../../../../../common/components/notification'
import LoadingPage from '../../../../../common/components/loading/page'


const { Option } = Select

export default function PaymentAccountManage() {
    const [form] = Form.useForm()

    const [loading, setLoading] = useState(true)

    const audioReboundRef = useRef()

    const [paymentAccount, setPaymentAccount] = useState(null)
    const [bankAccount, setBankAccount] = useState(null)

    const getPaymentAccount = async () => {
        const result = await getPaymentAccountFetch()
        setPaymentAccount(result)

    }

    const getBankAccount = async () => {
        const result = await getBankAccountFetch()
        setBankAccount(result)
    }

    const onFinish = async (values) => {
        let obj = {
            paymentAccountId: paymentAccount.paymentAccountId ? paymentAccount.paymentAccountId : null,
            bankCategoryId: values.bankCategoryId ? values.bankCategoryId : null,
            accountName: values.accountName ? values.accountName : null,
            accountNumber: values.accountNumber ? values.accountNumber : null
        }
        // console.log("obj : ", obj)
        const result = await updatePaymentAccountByIdFetch(obj)

        if (result.isSuccess) {
            Notification('success', 'เเก้ไขสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const getBaseApi = async () => {
        setLoading(true)
        await getPaymentAccount()
        await getBankAccount()
        setLoading(false)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    // console.log("paymentAccount : ", paymentAccount)
    // console.log("bankAccount : ", bankAccount)

    return (
        <>
            {!loading ?
                <Row>
                    <Col span={24} style={{ paddingBottom: 20 }}>
                        <label>จัดการประกาศ</label>
                    </Col>
                    <Col xs={24} md={12} xl={12}>
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                            initialValues={{
                                bankCategoryId: paymentAccount?.bankCategoryId,
                                accountName: paymentAccount?.accountName,
                                accountNumber: paymentAccount?.accountNumber
                            }}
                        >
                            <Row>
                                <Col span={24}>
                                    <Form.Item name="paymentAccountId" style={{ display: "none" }}>
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        label="ธนาคาร" name="bankCategoryId"
                                        style={{ width: '100%' }}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            allowClear
                                        >
                                            {bankAccount?.map((val, index) =>
                                                <Option key={index} value={val.bankCategoryId}>{val.bankName}</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="ชื่อบัญชีธนาคาร"
                                        name="accountName"
                                        style={{ width: '100%' }}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="เลขบัญชีธนาคาร"
                                        name="accountNumber"
                                        style={{ width: '100%' }}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Button
                                            style={{ float: 'right', width: 70 }}
                                            type="primary"
                                            onClick={() => {
                                                audioReboundRef.current.play()
                                                form.submit()
                                            }}
                                        >
                                            ค้นหา
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                : 
                <LoadingPage loading={loading} />
            }
            <audio ref={audioReboundRef}>
				<source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
				Your browser does not support the audio element.
			</audio>
        </>
    )
}
