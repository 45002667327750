import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getPredictionByIdFetch = async (predictionId) => {
    try {
        const result = await httpClient.post(server.GET_PREDICTION_BY_ID_URL, { predictionId })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getMandalaByIdFetch = async (key) => {
    try {
        const result = await httpClient.post(server.GET_MANDALA_BY_ID_URL, { key })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getKhunHorByIdFetch = async (key) => {
    try {
        const result = await httpClient.post(server.GET_KHUN_HOR_BY_ID_URL, { key })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getBirthdayUserByIdFetch = async (uid) => {
    try {
        const result = await httpClient.post(server.GET_BIRTHDAY_USER_BY_ID_URL, { uid })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}
const getFrtuneTellerQuestionsMasterAllFetch = async () => {
    try {
        const result = await httpClient.get(server.GET_FRTUNE_TELLER_QUESTIONS_MASTER_All)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getFrtuneTellerQuestionsAllFetch = async () => {
    try {
        const result = await httpClient.get(server.GET_FRTUNE_TELLER_QUESTIONS_All)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getFrtuneTellerQuestionsByIdFetch = async (masterId) => {
    try {
        const result = await httpClient.post(server.GET_FRTUNE_TELLER_QUESTIONS_BY_ID, { masterId })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getFrtuneTellerQuestionsMasterAllFetch,
    getFrtuneTellerQuestionsAllFetch,
    getFrtuneTellerQuestionsByIdFetch,

    getPredictionByIdFetch,
    getMandalaByIdFetch,    
    getKhunHorByIdFetch,
    getBirthdayUserByIdFetch
}