import { server } from "../../../../constants";
import { httpClient } from "../../../../utils/HttpClient";

const getNewsAllFetch = async () => { // Done
    try {
        const result = await httpClient.get( server.GET_NEWS_ALL_URL );
        if (result.data.isSuccess) {
            return result.data.formData;
        } else {
            return null;
        }
    } catch (err) { // status 404
        return null;
    }
}

// const getAnnounceByIdFetch = async (id) => { // Done
//     try {                                                                                 
//         const result = await httpClient.post( server.GET_ANNCUNCE_BY_ID_URL, { id } );
//         if (result.data.isSuccess) {
//             return result.data.formData;
//         } else {
//             return null;
//         }
//     } catch (err) { // status 404
//         return null;
//     }
// }

export {
    // get
    getNewsAllFetch,
    // getAnnounceByIdFetch
}