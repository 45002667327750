/* eslint-disable array-callback-return */
/* eslint-disable no-array-constructor */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { detectSignIn } from '../../common/mamager/DetectManager'
import { getExamRoomByIdFetch, getQuestionListByIdFetch, updateAnswerListByIdFetch, updateExamRoomByIdFetch } from './API'
import LoadingPage from '../../common/components/loading/page'
import BreadcrumbPage from '../../common/components/breadcrumbPage'
import Header from '../../common/components/header'
import { useDimensions } from '../../common/mamager/ScreenManager'
import { Button, Col, Row, Upload } from 'antd'
import { apiServerUrl, serverUrl } from '../../constants'
import Video from '../../common/components/video'
import parse from 'html-react-parser'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import "./css/index.css"
import { Notification } from '../../common/components/notification'

export default function Exam({ authorized, firebase, user }) {
    const navigate = useNavigate()
    const location = useLocation()
    const params = new URLSearchParams(location.search + location.hash)
    const examRoomId = params.get("examRoomId")

    const { width } = useDimensions()

    const [examRoom, setExamRoom] = useState([])
    const [loading, setLoading] = useState(true)

    const [questionList, setQuestionList] = useState([])

    const audioReboundRef = useRef()


    const [answerList, setAnswerList] = useState([])
    const [answerListIdSelected, setAnswerListIdSelected] = useState(null)

    const [sumFullScore, setSumFullScore] = useState([])
    const [sumMinimumPassScore, setSumMinimumPassScore] = useState([])

    const optionAnswerImage = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            name: "admin",
            path: "upload_file/image/exam/answer"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    let tmpAnswerList = answerList?.map(val => answerListIdSelected === val.questionListId ?
                        {
                            questionListId: val.questionListId,
                            answerListId: val.answerListId,
                            examId: val.examId,
                            answer: val.answer,
                            imageUrl: info.file.response.filePath,
                            imageLoading: false,
                            videoUrl: val.videoUrl,
                            videoLoading: val.videoLoading
                        }
                        : val)
                    setAnswerList(tmpAnswerList)
                }
            } else {
                let tmpAnswerList = answerList?.map(val => answerListIdSelected === val.questionListId ?
                    {
                        questionListId: val.questionListId,
                        answerListId: val.answerListId,
                        examId: val.examId,
                        answer: val.answer,
                        imageUrl: val.imageUrl,
                        imageLoading: true,
                        videoUrl: val.videoUrl,
                        videoLoading: val.videoLoading
                    }
                    : val)
                setAnswerList(tmpAnswerList)
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const optionAnswerVideo = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            name: "admin",
            path: "upload_file/video/exam/answer"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    // setVideoQuestionURL({
                    //     videoUrl: info.file.response.filePath,
                    //     loading: false,
                    // })
                    let tmpAnswerList = answerList?.map(val => answerListIdSelected === val.questionListId ?
                        {
                            questionListId: val.questionListId,
                            answerListId: val.answerListId,
                            examId: val.examId,
                            answer: val.answer,
                            imageUrl: val.imageUrl,
                            imageLoading: val.imageLoading,
                            videoUrl: info.file.response.filePath,
                            videoLoading: false
                        }
                        : val)
                    setAnswerList(tmpAnswerList)
                }
            } else {
                // setVideoQuestionURL({
                //     loading: true,
                //     videoUrl: videoQuestionURL.videoUrl
                // })
                let tmpAnswerList = answerList?.map(val => answerListIdSelected === val.questionListId ?
                    {
                        questionListId: val.questionListId,
                        answerListId: val.answerListId,
                        examId: val.examId,
                        answer: val.answer,
                        imageUrl: val.imageUrl,
                        imageLoading: val.imageLoading,
                        videoUrl: val.videoUrl,
                        videoLoading: true
                    }
                    : val)
                setAnswerList(tmpAnswerList)
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดวิดีโอสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดวิดีโอไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const detectLogin = async () => {
        // detect login
        const isLogin = await detectSignIn()
        if (!isLogin) {
            navigate("/login")
        }
    }

    const getExamAll = async () => {
        if (user?.uid) {
            let objExamRoom = { uid: user?.uid, examRoomId }
            // console.log("objExamRoom : ", objExamRoom)
            const resultExamRoom = await getExamRoomByIdFetch(objExamRoom)
            // console.log("getExamRoomByIdFetch : ", resultExamRoom)
            if (resultExamRoom) {

                if ([2, 3, 6].includes(resultExamRoom.examRoomStatusId)) {
                    setExamRoom(resultExamRoom)

                    if (resultExamRoom.examRoomStatusId !== 3) {
                        await updateExamRoomById(3) // กำลังสอบ
                    }

                    const resultQuestionList = await getQuestionListByIdFetch(resultExamRoom?.exam?.examId)
                    // console.log("getQuestionListByIdFetch : ", resultQuestionList)
                    setQuestionList(resultQuestionList)


                    let tmpSumFullScore = 0
                    let tmpSumMinimumPassScore = 0
                    resultQuestionList?.map((item, index) => {
                        tmpSumFullScore += item?.fullScore
                        tmpSumMinimumPassScore += item?.minimumPassScore
                    })
                    setSumFullScore(tmpSumFullScore)
                    setSumMinimumPassScore(tmpSumMinimumPassScore)

                    let tmpAnswerList = new Array()
                    resultQuestionList.map((val, index) => {
                        tmpAnswerList.push({
                            questionListId: val.questionListId,
                            answerListId: val.answer_list.answerListId,
                            examId: val.answer_list.examId,

                            answer: null, // : val.answer_list.answer,

                            imageUrl: null, // val.answer_list.imageUrl,
                            imageLoading: false,

                            videoUrl: null, // : val.answer_list.videoUrl,
                            videoLoading: false
                        })
                    })
                    setAnswerList(tmpAnswerList)
                    // console.log("tmpAnswer : ", tmpAnswerList)
                } else {
                    navigate("/classroom")
                }
            } else {
                navigate("/classroom")
            }
        }
    }

    const updateExamRoomById = async (id) => {
        let objExamRoom = {
            examRoomStatusId: id,
            examRoomId
        }
        await updateExamRoomByIdFetch(objExamRoom)
    }

    const getBaseApi = async () => {
        setLoading(true)

        await detectLogin()
        await getExamAll()

        setLoading(false)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <>
            <Header firebase={firebase} user={user} />
            <BreadcrumbPage pageCurrentName={"ห้องสอบ"} />

            {!loading ?
                <div
                    style={{
                        // display: width > 590 ? "flex" : "grid",
                        // alignItems: "flex-start",
                        // justifyContent: "center",
                        paddingLeft: width > 925 ? '15%' : 12,
                        paddingRight: width > 925 ? '15%' : 12,
                        paddingTop: 12
                    }}
                >
                    <Row gutter={[0, 12]} style={{}}>
                        <Col span={24}>
                            <div style={{ backgroundColor: "#EEEEEE", paddingLeft: 12, paddingRight: 12, paddingTop: 4, paddingBlock: 4, borderRadius: 8 }}>
                                <Row gutter={[0, 0]} style={{}}>
                                    <Col xs={24} md={12} xl={18} style={{}}>
                                        <div style={{ display: "grid", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                            <label style={{ fontSize: 22, fontWeight: 600 }}>{examRoom?.course?.title}</label>
                                            <label style={{ fontSize: 22 }}>สถานะการสอบ : {examRoom?.exam_room_status?.name}</label>
                                        </div>
                                    </Col>
                                    <Col xs={24} md={12} xl={6} style={{}}>
                                        <div style={{ display: "grid", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                            <label style={{}}>จำนวนข้อสอบ {questionList?.length} ข้อ</label>
                                            <label style={{}}>คะเเนนเต็มรวม {sumFullScore ?? 0} คะเเนน</label>
                                            <label style={{}}>เกณฑ์คะเเนนที่ผ่านรวม {sumMinimumPassScore ?? 0} คะเเนน</label>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                        <Col span={24}>
                            {questionList?.map((item, index) => {
                                return (
                                    <>
                                        <div style={{ backgroundColor: "#EEEEEE", paddingLeft: 12, paddingRight: 12, paddingTop: 4, paddingBottom: 12, borderRadius: 8 }}>
                                            <Row gutter={[12, 6]} style={{}}>
                                                <Col xs={24} md={12} xl={18} style={{}}>
                                                    <div style={{ display: "grid", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                                        <label>โจทย์คำถาม ข้อที่ {index + 1}</label>
                                                        <label style={{ fontWeight: 600 }}>{item.title}</label>
                                                        <label className='detail-box'>{item?.detail ? parse(item.detail) : "-"}</label>
                                                    </div>
                                                </Col>
                                                <Col xs={24} md={12} xl={6} style={{}}>
                                                    <label></label>
                                                </Col>

                                                {item?.imageUrl ?
                                                    <Col xs={24} md={12} xl={12} style={{}}>
                                                        <img
                                                            style={{ maxWidth: "100%", border: "1px solid white" }}
                                                            src={`${serverUrl}/${item.imageUrl}`}
                                                        />
                                                    </Col>
                                                    : []
                                                }

                                                {item?.videoUrl ?
                                                    <Col xs={24} md={12} xl={12} style={{}}>
                                                        <div style={{ backgroundColor: "black", borderRadius: 8 }}>
                                                            <Video
                                                                url={item.videoUrl}
                                                                title={""}
                                                                height={"100%"}
                                                                width={"100%"}
                                                            />
                                                        </div>
                                                    </Col>
                                                    : []
                                                }

                                                <Col span={24} style={{}}>
                                                    <div style={{ padding: 12, backgroundColor: "white", borderBottomRightRadius: 8, borderBottomLeftRadius: 8 }}>
                                                        <Row gutter={[12, 0]}>
                                                            <Col xs={24} md={24} xl={24}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    config={{
                                                                        toolbar: {
                                                                            items: [
                                                                                'heading', '|',
                                                                                'fontfamily', 'fontsize', '|',
                                                                                'bold', 'italic', 'underline', '|',
                                                                                'alignment', '|',
                                                                                'fontColor', 'fontBackgroundColor', '|',
                                                                                'bulletedList', 'numberedList', 'todoList', '|',
                                                                                'code', 'codeBlock', '|',
                                                                                'undo', 'redo'
                                                                            ],
                                                                            removeButtons: 'Subscript,Superscript',
                                                                            height: 350
                                                                        }
                                                                    }}
                                                                    data={answerList.filter(fill => item.questionListId === fill.questionListId)[0].answer}
                                                                    onBlur={(event, editor) => {
                                                                        const data = editor.getData()
                                                                        let tmpAnswerList = answerList?.map(val => item.questionListId === val.questionListId ?
                                                                            {
                                                                                questionListId: val.questionListId,
                                                                                answerListId: val.answerListId,
                                                                                examId: val.examId,
                                                                                answer: data,
                                                                                imageUrl: val.imageUrl,
                                                                                imageLoading: val.imageLoading,
                                                                                videoUrl: val.videoUrl,
                                                                                videoLoading: val.videoLoading
                                                                            }
                                                                            : val)
                                                                        setAnswerList(tmpAnswerList)
                                                                    }}
                                                                />
                                                            </Col>

                                                            <Col xs={24} md={12} xl={12}>
                                                                <div style={{ display: "grid" }}>
                                                                    <label style={{ paddingBottom: 6, paddingTop: 6 }}>รูปภาพคำตอบเพื่ออธิบายเพิ่มเติม (ไม่บังคับ)</label>
                                                                    {answerList.filter(fill => item.questionListId === fill.questionListId)[0]?.imageUrl ?
                                                                        <img
                                                                            style={{ borderRadius: 8, maxWidth: "100%", border: "1px solid #EEEEEE" }}
                                                                            src={`${serverUrl}/${answerList.filter(fill => item.questionListId === fill.questionListId)[0].imageUrl}`}
                                                                        />
                                                                        :
                                                                        <img
                                                                            style={{ width: "100%", borderRadius: 8 }}
                                                                            src={`./assets/images/default/df-img.png`}
                                                                        />
                                                                    }
                                                                    <div style={{ paddingTop: 12 }}>
                                                                        <Upload
                                                                            {...optionAnswerImage}
                                                                            accept='image/jpeg, image/png, image/jfif'
                                                                            style={{ width: "100%" }}
                                                                            maxCount={1}
                                                                            showUploadList={{ showRemoveIcon: false }}
                                                                        >
                                                                            <Button
                                                                                type="default"
                                                                                style={{ width: "100%" }}
                                                                                onClick={() => {
                                                                                    audioReboundRef.current.play()
                                                                                    setAnswerListIdSelected(item.questionListId)
                                                                                }}
                                                                                icon={answerList.filter(fill => item.questionListId === fill.questionListId)[0].imageLoading ? <LoadingOutlined /> : <UploadOutlined />}
                                                                            >อัพโหลดรูปภาพ</Button>
                                                                        </Upload>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col xs={24} md={12} xl={12}>
                                                                <div style={{ display: "grid" }}>
                                                                    <label style={{ paddingBottom: 6, paddingTop: 6 }}>วิดีโอคำตอบเพื่ออธิบายเพิ่มเติม (ไม่บังคับ)</label>
                                                                    {answerList.filter(fill => item.questionListId === fill.questionListId)[0]?.videoUrl ?
                                                                        <div style={{ backgroundColor: "black", borderRadius: 8 }}>
                                                                            <Video
                                                                                url={answerList.filter(fill => item.questionListId === fill.questionListId)[0].videoUrl}
                                                                                title={""}
                                                                                height={"100%"}
                                                                                width={"100%"}
                                                                            />
                                                                        </div>
                                                                        :
                                                                        <img
                                                                            style={{ width: "100%", borderRadius: 8 }}
                                                                            src={`./assets/images/default/df-vdo.png`}
                                                                        />
                                                                    }
                                                                    <div style={{ paddingTop: 12 }}>
                                                                        <Upload
                                                                            {...optionAnswerVideo}
                                                                            accept='.mp4'
                                                                            style={{ width: "100%" }}
                                                                            maxCount={1}
                                                                            showUploadList={{ showRemoveIcon: false }}
                                                                        >
                                                                            <Button
                                                                                type="default"
                                                                                style={{ width: "100%" }}
                                                                                onClick={() => {
                                                                                    audioReboundRef.current.play()
                                                                                    setAnswerListIdSelected(item.questionListId)
                                                                                }}
                                                                                icon={answerList.filter(fill => item.questionListId === fill.questionListId)[0].videoLoading ? <LoadingOutlined /> : <UploadOutlined />}
                                                                            >อัพโหลดวิดีโอ</Button>
                                                                        </Upload>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </>
                                )
                            })}
                        </Col>

                        <Col span={24} style={{}}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Button
                                    style={{ float: 'right', width: 100 }}
                                    type="primary"
                                    onClick={async () => {
                                        audioReboundRef.current.play()
                                        // console.log("X---- : ", answerList)

                                        let isAnswerHaveEmpty = false
                                        answerList?.map(val => {
                                            if (val.answer === null || val.answer === "" || val.answer === undefined) {
                                                isAnswerHaveEmpty = true
                                            }
                                        })
                                        // console.log("isAnswerHaveEmpty : ", isAnswerHaveEmpty)

                                        if (isAnswerHaveEmpty) {
                                            Notification("warning", "เเจ้งเตือน!", "กรุณากรอบคำตอบให้ครบ")
                                        } else {
                                            const resultAnswerList = await updateAnswerListByIdFetch(answerList)

                                            let objExamRoom = {
                                                examRoomStatusId: 4,
                                                examRoomId
                                            }
                                            const resultExamRoom = await updateExamRoomByIdFetch(objExamRoom)

                                            if (resultAnswerList.isSuccess && resultExamRoom.isSuccess) {
                                                Notification('success', 'ส่งผลสอบสำเร็จ')
                                                navigate("/classroom")
                                                window.scrollTo(0, 0)
                                            } else {
                                                Notification('error', 'ไม่สามารถส่งผลสอบได้ กรุณาลองใหม่อีกครั้ง')
                                            }
                                        }
                                    }}
                                >
                                    ส่งผลสอบ
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
                :
                <article style={{ backgroundColor: "#262626" }}>
                    <LoadingPage loading={loading} />
                </article>
            }
            <audio ref={audioReboundRef}>
				<source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
				Your browser does not support the audio element.
			</audio>
        </>
    )
}
