/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    DatePicker,
    Popconfirm,
    Upload
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { apiServerUrl, serverUrl } from '../../../../constants'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'

import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import { deletePartnerByIdFetch, getPartnerBySearchFetch, insertPartnerFetch, updatePartnerByIdFetch } from '../../../../common/API/partner'

const { RangePicker } = DatePicker;

const columns = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "10%",
    },
    {
        title: 'ภาพ Banner',
        width: "15%",
        render: (text, record) => {
            return (
                <>
                    <img
                        style={{ maxHeight: 80, border: "1px solid #EEEEEE" }}
                        src={`${serverUrl}/${record.imageURL}`}
                    />
                </>
            )
        }
    },
    {
        title: 'ชื่อ Banner',
        dataIndex: 'name',
        width: "30%",
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "15%",
    },
    {
        title: 'วันที่เเก้ไข',
        dataIndex: 'updatedAt',
        width: "15%",
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "15%",
    },
]

const formatDate = "DD/MM/YYYY"

export default function PartnerManage() {

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)

    const [form] = Form.useForm()
    const [formSearch] = Form.useForm()

    // field external
    const [imgCoverURL, setImgCoverURL] = useState({
        loading: false,
        imageUrl: null
    })

    const audioReboundRef = useRef()


    const [pageCurrent, setPageCurrent] = useState(1)

    const [modal, setModal] = useState({
        isShow: false,
        title: null
    })

    const optionPartnerImage = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            name: "admin",
            path: "upload_file/image/partner"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    setImgCoverURL({
                        imageUrl: info.file.response.filePath,
                        loading: false,
                    })
                }
            } else {
                setImgCoverURL({
                    loading: true,
                    imageUrl: imgCoverURL.videoUrl
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const getPartnerBySearch = async (name, startedDate, endDate) => {
        setLoading(true)

        let objSearch = {
            name, startedDate, endDate
        }
        // console.log("getPartnerBySearchFetch : ", objSearch)
        const result = await getPartnerBySearchFetch(objSearch)
        // console.log("result : ", result)

        let tempList = [];
        result?.map((val, index) => {
            tempList.push({
                index: (index + 1),
                link: val.link,
                name: val.name,
                imageURL: val.imageURL,
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            style={{
                                width: 35,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: 'white',
                                borderRadius: 50
                            }}
                            onClick={() => {
                                audioReboundRef?.current?.play()
                                form.setFieldsValue({
                                    partnerId: val.partnerId,
                                    name: val.name,
                                    link: val.link
                                })
                                setImgCoverURL({
                                    loading: false,
                                    imageUrl: val.imageURL
                                })
                                setModal({ isShow: true, title: "edit" })
                            }}
                        >
                            <div style={{ marginTop: 0, marginLeft: -8 }}>
                                <Icon icon="typcn:edit" style={{ color: "white", width: 20, height: 20 }} />
                            </div>
                        </Button>{"  "}

                        <Popconfirm
                            title="คุณยืนยันลบหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                await handlePartnerDelete(val.partnerId)

                                // reload
                                await getPartnerBySearch(null, null, null)
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                danger
                                type="primary"
                                style={{
                                    width: 35,
                                    borderRadius: 50
                                }}
                                onClick={() => audioReboundRef?.current?.play()}
                            >
                                <div style={{ marginTop: 0, marginLeft: -8 }}>
                                    <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 20, height: 20 }} />
                                </div>
                            </Button>
                        </Popconfirm>
                    </>
            });
        });
        setList(tempList)

        setLoading(false)
    }

    const onFinish = async (values) => {
        let obj = {
            partnerId: values.partnerId ? values.partnerId : null,
            name: values.name ? values.name : null,
            imageURL: imgCoverURL.imageUrl ? imgCoverURL.imageUrl : null,
            link: values.link ? values.link : null,
        }
        // console.log("onFinish : ", obj)
        if (modal.title === "add") {
            const result = await insertPartnerFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'สร้างสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง')
            }
        } else if (modal.title === "edit") {
            const result = await updatePartnerByIdFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'เเก้ไขสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        getPartnerBySearch(null, null, null)

        // set default
        setFormDefault()
    }

    const onSearchFinish = async (values) => {
        let name = values?.name ? values.name : null
        let startedDate = values?.dateRange ? values.dateRange[0] : null
        let endDate = values?.dateRange ? values.dateRange[1] : null
        await getPartnerBySearch(name, startedDate, endDate)
    }

    const handlePartnerDelete = async (id) => {
        const result = await deletePartnerByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const setFormDefault = () => {
        form.resetFields()

        setModal({
            isShow: false,
            title: null
        })
    }

    useEffect(() => {
        getPartnerBySearch(null, null, null)
    }, [])

    return (
        <Row>
            <Col span={12}>
                <label>จัดการประกาศ</label>
            </Col>
            <Col span={12} style={{ paddingBottom: 20 }}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ชื่อ Banner"
                                name="name"
                                style={{ width: '100%' }}
                            >
                                <Input />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ห้วงวันที่สร้าง" name="dateRange"
                                style={{ width: '100%' }}
                            >
                                <RangePicker />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10, marginTop: 3 }}>
                            <Button
                                style={{ float: 'right', width: 70 }}
                                type="primary"
                                onClick={() => {
                                    audioReboundRef?.current?.play()
                                    formSearch.submit()}}
                            >
                                ค้นหา
                            </Button>
                        </div>
                    </div>
                </Form>
            </Col>

            <Col span={24} style={{ paddingBottom: 20 }}>
                <Button
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={() => {
                        audioReboundRef?.current?.play()
                        form.resetFields()
                        setImgCoverURL({
                            loading: false,
                            imageUrl: null
                        })

                        setModal({
                            isShow: true,
                            title: "add"
                        })
                    }}
                >เพิ่มรายการ</Button>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrent,
                        pageSize: 10,
                        total: list.length
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modal.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}</label></strong>}
                visible={modal.isShow}
                onCancel={() => {

                    // default
                    setFormDefault();
                }}
                width={700}
                onOk={() => {
                    form.submit();
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }} onClick={() =>  audioReboundRef?.current?.play()}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }} onClick={() =>  audioReboundRef?.current?.play()}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item name="partnerId" style={{ display: "none" }}>
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="name"
                                label="ชื่อ Banner"
                                rules={[{ required: true, message: 'กรุณากรอกชื่อ Banner' }]}
                            >
                                <Input style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>

                        {/* <Col span={12}>
                            <Form.Item
                                name="link"
                                label="ลิงค์"
                            >
                                <Input.TextArea
                                    style={{ width: "100%" }}
                                    autoSize={{ minRows: 1, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col> */}

                        <Col span={24}>
                            <div style={{ display: "grid" }}>
                                <label style={{ paddingBottom: 6 }}>ปกรูปภาพ</label>
                                {imgCoverURL?.imageUrl ?
                                    <img
                                        style={{ borderRadius: 8, maxHeight: 207, border: "1px solid #EEEEEE" }}
                                        src={`${serverUrl}/${imgCoverURL.imageUrl}`}
                                    />
                                    :
                                    <img
                                        style={{ width: 207, borderRadius: 8 }}
                                        src={`./assets/images/default/df-img.png`}
                                    />
                                }
                                <div style={{ paddingTop: 12 }}>
                                    <Upload
                                        {...optionPartnerImage}
                                        accept='image/jpeg, image/png, image/jfif'
                                        style={{ width: "100%" }}
                                        maxCount={1}
                                        showUploadList={{ showRemoveIcon: false }}
                                    >
                                        <Button
                                            type="default"
                                            style={{ width: "100%" }}
                                            icon={imgCoverURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                            onClick={() => audioReboundRef?.current?.play()}
                                        >อัพโหลดรูปภาพ</Button>
                                    </Upload>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <audio ref={audioReboundRef}>
                <source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
        </Row>
    )
}
