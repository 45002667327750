/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Input,
    DatePicker,
    Upload,
    Popconfirm
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { apiServerUrl, serverUrl } from '../../../../constants'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import { deleteEBookByIdFetch, getEBookBySearchFetch, insertEBookFetch, updateEBookByIdFetch } from './API'

const { RangePicker } = DatePicker;

const columnsEBook = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "10%",
    },
    {
        title: 'ภาพปก',
        width: "15%",
        render: (text, record) => {
            return (
                <>
                    <img
                        style={{ borderRadius: 8, maxHeight: 70 }}
                        src={`${serverUrl}/${record.imgCoverURL}`}
                    />
                </>
            )
        }
    },
    {
        title: 'ชื่อ eBook',
        dataIndex: 'title',
        width: "30%",
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "15%",
    },
    {
        title: 'วันที่เเก้ไข',
        dataIndex: 'updatedAt',
        width: "15%",
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "15%",
    },
]

const formatDate = "DD/MM/YYYY"

export default function EBookManage() {

    const [eBookList, setEBookList] = useState([])
    const [eBookLoading, setEBookLoading] = useState(true)

    const [formEBook] = Form.useForm()
    const [formSearch] = Form.useForm()

    const [imgCoverEBookURL, setImgCoverEBookURL] = useState({
        loading: false,
        imageUrl: null
    })


    const [pdfEBookURL, setPdfEBookURL] = useState({
        loading: false,
        pdfUrl: null
    })

    // const [subTitle, setSubTitle] = useState()
    const [detail, setDetail] = useState()
    const [promotion, setPromotion] = useState()

    // const subTitleRef = useRef()
    const detailRef = useRef()
    const promotionRef = useRef()

    const audioReboundRef = useRef()


    const [eBookIdSelected, setEBookIdSelected] = useState(null)

    const [pageEBookCurrent, setPageEBookCurrent] = useState(1)

    const [operation, setOperation] = useState({
        type: null,
        isOpen: false
    })

    const optionCoverEBookImage = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            name: "admin",
            path: "upload_file/image/eBook/cover_ebook"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    setImgCoverEBookURL({
                        imageUrl: info.file.response.filePath,
                        loading: false,
                    })
                }
            } else {
                setImgCoverEBookURL({
                    loading: true,
                    imageUrl: imgCoverEBookURL.imageUrl
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const optionPdfEBookImage = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            name: "admin",
            path: "upload_file/image/eBook/pdf"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    setPdfEBookURL({
                        pdfUrl: info.file.response.filePath,
                        loading: false,
                    })
                }
            } else {
                setPdfEBookURL({
                    loading: true,
                    pdfUrl: pdfEBookURL.pdfUrl
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดไฟล์ Certificate สำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดไฟล์ Certificate ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const getEBookBySearch = async (title, startedDate, endDate) => {
        setEBookLoading(true)

        let objSearch = {
            title, startedDate, endDate
        }
        // console.log("getEBookBySearchFetch : ", objSearch)
        const result = await getEBookBySearchFetch(objSearch)
        // console.log("result : ", result)

        let tempList = [];
        result?.map((val, index) => {
            tempList.push({
                index: (index + 1),
                title: val.title,
                imgCoverURL: val.imgCoverURL,
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            style={{
                                width: 35,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: 'white',
                                borderRadius: 50
                            }}
                            onClick={async () => {
                                audioReboundRef.current.play()
                                formEBook.setFieldsValue({
                                    ebookId: val.ebookId,
                                    title: val.title,
                                    subTitle: val.subTitle,
                                    // price: val.price,
                                    link: val.link
                                })

                                setImgCoverEBookURL({
                                    loading: false,
                                    imageUrl: val.imgCoverURL
                                })

                                setPdfEBookURL({
                                    loading: false,
                                    pdfUrl: val.pdfURL
                                })

                                // setSubTitle(val.subTitle ?? "")
                                setDetail(val.detail ?? "")
                                setPromotion(val.promotion ?? "")

                                // subTitleRef.current = val.subTitle ?? ""
                                detailRef.current = val.detail ?? ""
                                promotionRef.current = val.promotion ?? ""

                                setEBookIdSelected(val.ebookId)
                                setOperation({ type: "edit", isOpen: true })
                            }}
                        >
                            <div style={{ marginTop: 0, marginLeft: -8 }}>
                                <Icon icon="typcn:edit" style={{ color: "white", width: 20, height: 20 }} />
                            </div>
                        </Button>{"  "}

                        <Popconfirm
                            title="คุณยืนยันลบหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                await handleEBookDelete(val.ebookId)

                                // reload
                                await getEBookBySearch(null, null, null)
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                danger
                                type="primary"
                                style={{
                                    width: 35,
                                    borderRadius: 50
                                }}
                                onClick={() => audioReboundRef.current.play()}
                            >
                                <div style={{ marginTop: 0, marginLeft: -8 }}>
                                    <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 20, height: 20 }} />
                                </div>
                            </Button>
                        </Popconfirm>
                    </>
            });
        });
        setEBookList(tempList)

        setEBookLoading(false)
    }

    const onEBookFinish = async (values) => {
        let obj = {
            ebookId: values.ebookId ? values.ebookId : null,
            title: values.title ? values.title : null,
            price: null, // values.price ? values.price : null,
            subTitle: values.subTitle ? values.subTitle : null,
            detail: detailRef.current ? detailRef.current : null,
            promotion: promotionRef.current ? promotionRef.current : null,
            imgCoverURL: imgCoverEBookURL.imageUrl ? imgCoverEBookURL.imageUrl : null,
            link: values.link ? values.link : null,
            pdfURL: pdfEBookURL.pdfUrl ? pdfEBookURL.pdfUrl : null
        }
        // console.log("onFinish : ", obj)

        if (operation.type === "add") {
            const result = await insertEBookFetch(obj)
            if (result.isSuccess) {
                // console.log("result?.formData?.ebookId : ", result?.formData?.ebookId)
                setEBookIdSelected(result?.formData?.ebookId)
                Notification('success', 'สร้างสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง')
            }
        } else if (operation.type === "edit") {
            const result = await updateEBookByIdFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'บันทึกสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถบันทึกได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        getEBookBySearch(null, null, null)

        // set default
        // if (operation.type !== "add") {
        setFormEBookDefault()
        // }
    }

    const onSearchFinish = async (values) => {
        let title = values?.title ? values.title : null
        let startedDate = values?.dateRange ? values.dateRange[0] : null
        let endDate = values?.dateRange ? values.dateRange[1] : null
        await getEBookBySearch(title, startedDate, endDate)
    }

    const handleEBookDelete = async (id) => {
        const result = await deleteEBookByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onEBookPagine = (n) => {
        setPageEBookCurrent(n.current)
    }

    const setFormEBookDefault = () => {
        formEBook.resetFields()

        setImgCoverEBookURL({
            loading: false,
            imageUrl: null
        })

        setPdfEBookURL({
            loading: false,
            pdfUrl: null
        })

        // setSubTitle(null)
        setDetail(null)
        setPromotion(null)

        // subTitleRef.current = null
        detailRef.current = null
        promotionRef.current = null

        setEBookIdSelected(null)
        setOperation({ type: null, isOpen: false })
    }

    useEffect(() => {
        getEBookBySearch(null, null, null)
    }, [])

    // console.log("imgCoverEBookURL : ", imgCoverEBookURL)

    return (
        <Row>
            <Col span={8}>
                <label>จัดการ eBook</label>
            </Col>
            <Col span={16}>
                {!operation.isOpen ?
                    <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                            <div style={{ paddingLeft: 10 }}>
                                <Form.Item
                                    label="ชื่อ eBook"
                                    name="title"
                                    style={{ width: '100%' }}
                                >
                                    <Input />
                                </Form.Item>
                            </div>

                            <div style={{ paddingLeft: 10 }}>
                                <Form.Item
                                    label="ห้วงวันที่สร้าง" name="dateRange"
                                    style={{ width: '100%' }}
                                >
                                    <RangePicker />
                                </Form.Item>
                            </div>

                            <div style={{ paddingLeft: 10, marginTop: 3 }}>
                                <Button
                                    style={{ float: 'right', width: 70 }}
                                    type="primary"
                                    onClick={() => {
                                        audioReboundRef.current.play()
                                        formSearch.submit()}}
                                >
                                    ค้นหา
                                </Button>
                            </div>
                        </div>
                    </Form>
                    : []
                }
            </Col>

            <Col span={24} style={{ paddingBottom: 20 }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                    <div style={{ paddingLeft: 12 }}>
                        <Button
                            type={!operation.isOpen ? "primary" : "default"}
                            style={{ float: 'right' }}
                            onClick={() => {
                                audioReboundRef.current.play()
                                formEBook.resetFields()
                                setEBookIdSelected(null)

                                setImgCoverEBookURL({
                                    loading: false,
                                    imageUrl: null
                                })

                                setPdfEBookURL({
                                    loading: false,
                                    pdfUrl: null
                                })

                                setOperation({ type: "add", isOpen: !operation.isOpen })
                            }}
                        >
                            {!operation.isOpen ? "เพิ่ม eBook" : "ยกเลิกเพิ่ม eBook"}
                        </Button>{"   "}
                    </div>
                </div>
            </Col>

            {operation.isOpen ?
                <Col span={24}>
                    <div style={{ padding: 12, backgroundColor: "#EEEEEE", borderRadius: 8 }}>
                        <Row gutter={[16, 0]}>
                            <Col xs={24} md={24} xl={24}>
                                <Form
                                    layout="vertical"
                                    form={formEBook}
                                    onFinish={onEBookFinish}
                                >
                                    <Row gutter={[16, 0]}>
                                        <Col xs={24} md={12} xl={12}>
                                            <Form.Item name="ebookId" style={{ display: "none" }}>
                                                <Input />
                                            </Form.Item>

                                            <Form.Item
                                                name="title"
                                                label="ชื่อ eBook"
                                                rules={[{ required: true, message: 'กรุณากรอกชื่อ eBook' }]}
                                            >
                                                <Input.TextArea
                                                    style={{ width: "100%" }}
                                                    autoSize={{ minRows: 1, maxRows: 5 }}
                                                    maxLength={250}
                                                    showCount
                                                />
                                            </Form.Item>
                                        </Col>

                                        {/* <Col xs={24} md={12} xl={12}>
                                            <Form.Item
                                                name="price"
                                                label="ราคา eBook"
                                                rules={[{ required: true, message: 'กรุณากรอกราคา eBook' }]}
                                            >
                                                <InputNumber style={{ width: "100%" }} />
                                            </Form.Item>
                                        </Col> */}

                                        <Col xs={24} md={12} xl={12}>
                                            <Form.Item
                                                name="subTitle"
                                                label="รายละเอียดย่อย"
                                                rules={[{ required: true, message: 'กรุณากรอกรายละเอียดย่อย' }]}
                                            >
                                                <Input.TextArea
                                                    style={{ width: "100%" }}
                                                    autoSize={{ minRows: 4, maxRows: 10 }}
                                                    maxLength={700}
                                                    showCount
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} md={24} xl={24}>
                                            <Form.Item
                                                name="detail"
                                                label="รายละเอียด eBook"
                                            >
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    config={{
                                                        toolbar: {
                                                            items: [
                                                                'heading', '|',
                                                                'fontfamily', 'fontsize', '|',
                                                                'bold', 'italic', 'underline', '|',
                                                                'alignment', '|',
                                                                'fontColor', 'fontBackgroundColor', '|',
                                                                'bulletedList', 'numberedList', 'todoList', '|',
                                                                'code', 'codeBlock', '|',
                                                                'undo', 'redo'
                                                            ],
                                                            removeButtons: 'Subscript,Superscript',
                                                            height: 350,
                                                        },
                                                    }}
                                                    data={detail}
                                                    onBlur={(event, editor) => {
                                                        const data = editor.getData()
                                                        detailRef.current = data
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} md={24} xl={24}>
                                            <Form.Item
                                                name="promotion"
                                                label="โปรโมชั่น"
                                            >
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    config={{
                                                        toolbar: {
                                                            items: [
                                                                'heading', '|',
                                                                'fontfamily', 'fontsize', '|',
                                                                'bold', 'italic', 'underline', '|',
                                                                'alignment', '|',
                                                                'fontColor', 'fontBackgroundColor', '|',
                                                                'bulletedList', 'numberedList', 'todoList', '|',
                                                                'code', 'codeBlock', '|',
                                                                'undo', 'redo'
                                                            ],
                                                            removeButtons: 'Subscript,Superscript',
                                                            height: 350,
                                                        },
                                                    }}
                                                    data={promotion}
                                                    onBlur={(event, editor) => {
                                                        const data = editor.getData()
                                                        promotionRef.current = data
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} md={12} xl={12}>
                                            <div style={{ display: "grid" }}>
                                                <label style={{ paddingBottom: 6 }}></label>
                                                {imgCoverEBookURL?.imageUrl ?
                                                    <img
                                                        style={{ borderRadius: 8, maxWidth: 300, border: "1px solid #EEEEEE" }}
                                                        src={`${serverUrl}/${imgCoverEBookURL.imageUrl}`}
                                                    />
                                                    :
                                                    <img
                                                        style={{ width: 207, borderRadius: 8 }}
                                                        src={`./assets/images/default/df-img.png`}
                                                    />
                                                }
                                                <div style={{ paddingTop: 12 }}>
                                                    <Upload
                                                        {...optionCoverEBookImage}
                                                        accept='image/jpeg, image/png, image/jfif'
                                                        style={{ width: "100%" }}
                                                        maxCount={1}
                                                        showUploadList={{ showRemoveIcon: false }}
                                                    >
                                                        <Button
                                                            type="default"
                                                            style={{ width: "100%" }}
                                                            icon={imgCoverEBookURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                            onClick={() => audioReboundRef.current.play()}
                                                        >อัพโหลดรูปภาพ</Button>
                                                    </Upload>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xs={24} md={12} xl={12}>
                                            <div style={{ display: "grid" }}>
                                                <label style={{ paddingBottom: 6 }}></label>
                                                {/* {console.log("pdfEBookURL : ", `${serverUrl}/${pdfEBookURL.pdfUrl}`)} */}
                                                {pdfEBookURL?.pdfUrl ?
                                                    <>
                                                        <iframe
                                                            src={`${serverUrl}/${pdfEBookURL.pdfUrl}`}
                                                            style={{ width: "100%", height: 428 }}
                                                        />
                                                    </>
                                                    :
                                                    <div style={{ padding: 40, backgroundColor: "white", width: 320, borderRadius: 8 }}>ไฟล์ PDF</div>
                                                }
                                                <div style={{ paddingTop: 12 }}>
                                                    <Upload
                                                        {...optionPdfEBookImage}
                                                        accept='application/pdf'
                                                        style={{ width: "100%" }}
                                                        maxCount={1}
                                                        showUploadList={{ showRemoveIcon: false }}
                                                    >
                                                        <Button
                                                            type="default"
                                                            style={{ width: "100%" }}
                                                            icon={pdfEBookURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                            onClick={() => audioReboundRef.current.play()}
                                                        >อัพโหลด PDF ตัวอย่าง</Button>
                                                    </Upload>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xs={24} md={12} xl={12}>
                                            <Form.Item
                                                name="link"
                                                label="ลิงค์ eBook"
                                                rules={[{ required: true, message: 'กรุณากรอกลิงค์ eBook' }]}
                                            >
                                                <Input style={{ width: "100%" }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>

                            <Col span={24} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                                    <div style={{ paddingLeft: 12 }}>
                                        <Button
                                            type="primary"
                                            disabled={operation.type === "add" && eBookIdSelected ? true : false}
                                            onClick={() => {
                                                audioReboundRef.current.play()
                                                formEBook.submit()
                                            }}
                                        >
                                            {operation.type === "add" ? "สร้าง eBook" : []}
                                            {operation.type === "edit" ? "บันทึก" : []}
                                        </Button>
                                    </div>

                                    <div style={{ paddingLeft: 12 }}>
                                        {operation.type === "add" && eBookIdSelected ?
                                            <Button
                                                type="default"
                                                onClick={() => {
                                                    audioReboundRef.current.play()
                                                    setFormEBookDefault()
                                                }}
                                            >
                                                ปิดการเเก้ไข
                                            </Button>
                                            : []
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
                : []
            }

            {!operation.isOpen ?
                <Col span={24}>
                    <Table
                        loading={eBookLoading}
                        columns={columnsEBook}
                        dataSource={eBookList}
                        pagination={{
                            current: pageEBookCurrent,
                            pageSize: 10,
                            total: eBookList?.length
                        }}
                        onChange={(n) => onEBookPagine(n)}
                    >
                    </Table>
                </Col>
                : []
            }
            <audio ref={audioReboundRef}>
                <source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
        </Row>
    )
}
