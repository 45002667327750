/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect,useRef  } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    Popconfirm,
    Upload,
    DatePicker
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import { apiServerUrl, serverUrl } from '../../../../constants'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import parse from 'html-react-parser'
import { deleteLoadingByIdFetch, getLoadingBySearchFetch, insertLoadingFetch, updateLoadingByIdFetch } from './API'
const { RangePicker } = DatePicker

const { TextArea } = Input;

const columns = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "5%",
    },
    {
        title: 'รูปภาพ',
        dataIndex: 'detail',
        width: "20%",
        render: (text, record) => {
            return (
                <img
                    style={{ borderRadius: 8, maxWidth: 60, border: "1px solid #EEEEEE" }}
                    src={`${serverUrl}/${record.imageURL}`}
                />
            )
        }
    },
    {
        title: 'ชื่อ Loading page',
        dataIndex: 'title',
        width: "55%",
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "55%",
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "10%",
    },
]

const formatDate = "DD/MM/YYYY"

export default function LoadingPageManage() {

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)

    const [form] = Form.useForm()
    const [formSearch] = Form.useForm()

    const audioReboundRef = useRef()


    const [pageCurrent, setPageCurrent] = useState(1)

    const [modal, setModal] = useState({
        isShow: false,
        title: null
    })

    const [imgLoadingUrl, setLoadingUrl] = useState({
        loading: false,
        imageUrl: null
    })

    const optionLoading = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            name: "admin",
            path: "upload_file/image/loading"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                if (info.fileList.length > 0) {
                    setLoadingUrl({
                        imageUrl: info.file.response.filePath,
                        loading: false,
                    })
                }
            } else {
                setLoadingUrl({
                    loading: true,
                    imageUrl: imgLoadingUrl.imageUrl
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const getLoadingBySearch = async (objSearch) => {
        setLoading(true)

        // console.log("getLoadingBySearchFetch : ", objSearch)
        const result = await getLoadingBySearchFetch(objSearch)
        // console.log("result : ", result)

        let tempList = [];
        result?.map((val, index) => {
            tempList.push({
                index: (index + 1),
                title: val.title,
                imageURL: val.imageURL,
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <div style={{ paddingBottom: 6 }}>
                            <Button
                                style={{
                                    width: 35,
                                    backgroundColor: "orange",
                                    border: "1px solid orange",
                                    color: 'white',
                                    borderRadius: 50
                                }}
                                onClick={() => {
                                    audioReboundRef?.current?.play()
                                    form.setFieldsValue({
                                        loadingId: val.loadingId,
                                        title: val.title,
                                    })

                                    setLoadingUrl({
                                        imageUrl: val.imageURL,
                                        loading: false,
                                    })

                                    setModal({ isShow: true, title: "edit" })
                                }}
                            >
                                <div style={{ marginTop: 0, marginLeft: -8 }}>
                                    <Icon icon="typcn:edit" style={{ color: "white", width: 20, height: 20 }} />
                                </div>
                            </Button>
                        </div>{"  "}

                        <div style={{ paddingBottom: 0 }}>
                            <Popconfirm
                                title="คุณยืนยันลบหรือไม่ ?"
                                okText={<span style={{ width: 50 }}>ใช่</span>}
                                onConfirm={async () => {
                                    await handleLoadingDelete(val.loadingId)

                                    // reload
                                    await getLoadingBySearch({
                                        title: null,
                                        startedDate: null,
                                        endDate: null
                                    })
                                }}
                                cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                            >
                                <Button
                                    danger
                                    type="primary"
                                    style={{
                                        width: 35,
                                        borderRadius: 50
                                    }}
                                    onClick={() => audioReboundRef?.current?.play()}
                                >
                                    <div style={{ marginTop: 0, marginLeft: -8 }}>
                                        <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 20, height: 20 }} />
                                    </div>
                                </Button>
                            </Popconfirm>
                        </div>
                    </>
            });
        });
        setList(tempList)

        setLoading(false)
    }

    const onFinish = async (values) => {
        let obj = {
            loadingId: values?.loadingId ? values.loadingId : null,
            title: values?.title ? values.title : null,
            imageURL: imgLoadingUrl?.imageUrl ? imgLoadingUrl.imageUrl : null
        }
        // console.log("onFinish : ", obj)
        if (modal.title === "add") {
            const result = await insertLoadingFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'สร้างสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง')
            }
        } else if (modal.title === "edit") {
            const result = await updateLoadingByIdFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'เเก้ไขสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        getLoadingBySearch({
            title: null,
            startedDate: null,
            endDate: null
        })

        // set default
        setFormDefault()
    }

    const onSearchFinish = async (values) => {
        let objSearch = {
            title: values?.title ? values.title : null,
            startedDate: values?.dateRange ? values.dateRange[0] : null,
            endDate: values?.dateRange ? values.dateRange[1] : null
        }
        // console.log("onSearchFinish : ", objSearch)
        await getLoadingBySearch(objSearch)
    }

    const handleLoadingDelete = async (id) => {
        const result = await deleteLoadingByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const setFormDefault = () => {
        form.resetFields()

        setLoadingUrl({
            imageUrl: null,
            loading: false,
        })

        setModal({
            isShow: false,
            title: null,
        })
    }

    useEffect(() => {
        getLoadingBySearch({
            title: null,
            startedDate: null,
            endDate: null
        })
    }, [])

    return (
        <Row>
            <Col span={24}>
                <Row style={{ borderBottom: "1px solid gray" }}>
                    <Col span={12}>
                        <label>จัดการ Loading page</label>
                    </Col>

                    <Col span={12} style={{ paddingBottom: 20 }}>
                        <Button
                            type="primary"
                            style={{ float: 'right' }}
                            onClick={() => {
                                audioReboundRef?.current?.play()
                                form.resetFields()
                                setModal({
                                    isShow: true,
                                    title: "add"
                                })
                            }}
                        >เพิ่มรายการ</Button>
                    </Col>
                </Row>
            </Col>

            <Col span={24} style={{ paddingBottom: 12, paddingTop: 12 }}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12} xl={12}>
                            <Form.Item
                                label="ชื่อรูปภาพ Loading page"
                                name="title"
                                style={{ width: '100%' }}
                            >
                                <TextArea
                                    autoSize={{ minRows: 1, maxRows: 4 }}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} xl={8}>
                            <div style={{ paddingLeft: 10 }}>
                                <Form.Item
                                    label="ห้วงวันที่สร้าง" name="dateRange"
                                    style={{ width: '100%' }}
                                >
                                    <RangePicker />
                                </Form.Item>
                            </div>
                        </Col>

                        <Col xs={24} md={12} xl={4}>
                            <div style={{ marginTop: 38 }}>
                                <Button
                                    style={{ float: 'right', width: "100%" }}
                                    type="primary"
                                    onClick={() => {
                                        audioReboundRef?.current?.play()
                                        formSearch.submit()}}
                                >
                                    ค้นหา
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrent,
                        pageSize: 10,
                        total: list.length
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modal.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}</label></strong>}
                visible={modal.isShow}
                onCancel={() => {

                    // default
                    setFormDefault();
                }}
                width={500}
                onOk={() => {
                    form.submit();
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }} onClick={() =>  audioReboundRef?.current?.play()}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }} onClick={() =>  audioReboundRef?.current?.play()}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item name="loadingId" style={{ display: "none" }}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="title"
                                label="ชื่อภาพ Loading"
                            // rules={[{ required: true, message: 'กรุณากรอก hierarchy' }]}
                            >
                                <Input style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <div style={{ display: "grid" }}>
                                <label style={{ paddingBottom: 6 }}>รูปภาพ Loading page</label>
                                {imgLoadingUrl?.imageUrl ?
                                    <img
                                        style={{ borderRadius: 8, maxWidth: 300, border: "1px solid #EEEEEE" }}
                                        src={`${serverUrl}/${imgLoadingUrl.imageUrl}`}
                                    />
                                    :
                                    <img
                                        style={{ width: 207, borderRadius: 8 }}
                                        src={`./assets/images/default/df-img.png`}
                                    />
                                }
                                <div style={{ paddingTop: 12 }}>
                                    <Upload
                                        {...optionLoading}
                                        accept='image/jpeg, image/png, image/jfif'
                                        style={{ width: "100%" }}
                                        maxCount={1}
                                        showUploadList={{ showRemoveIcon: false }}
                                    >
                                        <Button
                                            type="default"
                                            style={{ width: "100%" }}
                                            icon={imgLoadingUrl.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                            onClick={() => audioReboundRef?.current?.play()}
                                        >อัพโหลดรูปภาพ</Button>
                                    </Upload>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <audio ref={audioReboundRef}>
				<source src="./assets/audio/rebound.mp3" type="audio/mpeg" />
				Your browser does not support the audio element.
			</audio>
        </Row>
    )
}
