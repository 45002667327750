/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from 'react'
import {
    Row,
    Col
} from 'antd'
import { useDimensions } from '../../../../common/mamager/ScreenManager'
import './../../css/overview.css'
import LoadingPage from '../../../../common/components/loading/page'

export default function CourseOverview(props) {

    const { width } = useDimensions()

    const [loading, setLoading] = useState(false)

    const getBaseApi = async () => {

    }

    useEffect(() => {
        getBaseApi()
    })

    return (
        <div style={{ fontSize: 16 }}>
            {!loading ?
                <Row className={"course-overview-course-shop-container"}>
                    <Col
                        span={24}
                        style={{ paddingLeft: 24, paddingBottom: 12, paddingTop: 18, paddingRight: 24 }}
                    >
                        <span>{props.course?.title}</span>
                    </Col>
                </Row>
                : 
                <LoadingPage loading={loading} />
                }
        </div>
    )
}
